import { useEffect, useState } from 'react'
import { Layout, message } from 'antd';
import { MainHeader, MainSider } from '../../../layouts';
import { Box, Typography, Grid, Button, Tooltip } from '@mui/material';
import {auth, network, logger} from '../../../../utils';
import TrainingCard from './TrainingCard';
import { styled } from '@mui/system';
import { Card, CardActions, CardContent} from '@material-ui/core';
import DescriptionIcon from '@mui/icons-material/Description';
import Divider from '@mui/material/Divider';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const { Content } = Layout;

export default function TrainingRenderer() {
    const [Training, setTraining] = useState<object | null>(null)
    const getTrainingData = async () => {
        let dealershipId = auth.getDealershipId();
        try {
            let response = await network.GET(`/api/v1/trainings/get-training-list?dealership_id=${dealershipId}`);
            setTraining(response.data.trainings)
        } catch (e: any) {
            if (e && e.response && e.response.status === 401) {
                message.error('Error while fetching your dealership data');
            } else {
                message.error('Error while fetching your dealership data');
            }
        }
    }
    const attachmentUrl = (url: string) => {
      const baseUrl = process.env?.REACT_APP_LOS_API
      return process.env?.REACT_APP_ENV_VAR === 'Development' ? baseUrl && (baseUrl + url) : url
    }
    
    const handleGenerateTermSheet = async () => {
        try {
            const response = await network.POST(`/api/v1/dealerships/generate-term-sheet`, {});
            message.success("Term Sheet is Generated");
            // @ts-ignore
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = attachmentUrl(response.data?.termSheet?.url) || '';
            a.click();
        } catch (e) {
            logger.error("Error generating Term Sheet", e);
            message.error('Error generating Term Sheet');
        }
    }

    const handleGenerateStipReview = async () => {
        try {
            const response = await network.POST(`/api/v1/dealerships/generate-stip-review`, {});
            message.success("Stip Review is Generated");
            // @ts-ignore
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = attachmentUrl(response.data?.stipReview?.url) || '';
            a.click();
        } catch (e) {
            logger.error("Error generating Stip Review", e);
            message.error('Error generating Stip Review');
        }
    }
    useEffect(() => {
        getTrainingData();
    }, [])

    return (
        <Layout>
            <MainHeader />
            <Layout id="#dealership-edit">
                <MainSider activeKey="home" />
                <Layout id="content-area-layout">
                    <Content id="main-content">
                        <StyledTypography align='center' variant="h4" >
                        Training
                        </StyledTypography>
                        <Box >
                            <Grid container >
                                {
                                    (Training instanceof Array) && Training.length > 0 ?
                                    <Grid item md={12} container spacing={2} >
                                        {Training && Training.map((data: any) => (
                                            <TrainingCard
                                                data={data}
                                            />
                                        ))}
                                    </Grid> :
                                    <Grid item md={12} container spacing={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <h4 style={{marginTop: '50px', color: 'gray'}}>No training materials available.</h4>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Divider sx={{ marginTop: 4, marginBottom: 2 }}/>
                        <StyledTypography align='center' variant="h4" >
                        Resources
                        </StyledTypography>
                        <Grid container >
                            <Grid item md={12} container spacing={2} >
                                <Grid item xs={12} sm={3} md={3} lg={2}>
                                    <Card style={{height: 150}}>
                                        <CardContent style={{ height: 100, textAlign: 'center' }}>
                                            <PictureAsPdfIcon style={{ fontSize: '48px' }}/>
                                            <Tooltip title="Term Sheet">
                                                <Typography variant="subtitle2" style={{ marginTop: '10px', flexWrap: 'wrap' }}>
                                                    Term Sheet
                                                </Typography>
                                            </Tooltip>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={() => handleGenerateTermSheet()} variant="contained" color="primary" sx={{ width: '100%', background: '#06B0F8FF' }}>
                                                Generate
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} lg={2}>
                                    <Card style={{height: 150}}>
                                        <CardContent style={{ height: 100, textAlign: 'center' }}>
                                            <PictureAsPdfIcon style={{ fontSize: '48px' }}/>
                                            <Tooltip title=" Stip Review Documentation Requirments" placement="top">
                                                <Typography variant="subtitle2" style={{ marginTop: '10px', flexWrap: 'wrap' }}>
                                                    Stip Review
                                                </Typography>
                                            </Tooltip>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={() => handleGenerateStipReview()} variant="contained" sx={{ width: '100%', background: '#06B0F8FF'  }}>
                                                Generate
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

const StyledTypography = styled(Typography)({
    padding: 1,
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "15px"
})

