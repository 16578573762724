import { createSlice } from "@reduxjs/toolkit";

const currentYear = () => new Date().getFullYear();
const initialState = {
  applicationLists: [],
  statusData: {},
  unseenPromotionsCount: 0,
  unseenTrainingCount: 0,
  pagination: {
    total: 0
  },
  pageState: {
    total: 0,
    page: 0,
    limit: 25,
    search: "",
    creditStatus: "",
    documentStatus: "",
    isIncludeUnsubmitted: true,
    filterFlag: false,
  },
  timeRange: null,
  filterData: {
    time_range: null
  },
  isFromTimer:false,
}

const adminUsersSlice = createSlice({
  name: "adminUsers",
  initialState: initialState,
  reducers: {
    setApplications: (state, action) => {
      let payload = action.payload.data
      const previousFetchedData = state.applicationLists;
      const newFetchedData = payload.data?.leaseApplications;
      if (action.payload.isTimer === true && (JSON.stringify(previousFetchedData) !== JSON.stringify(newFetchedData))) {

        const newSubmittedAppsLists = newFetchedData.filter(e => {
          return !previousFetchedData.some(item => item.id === e.id);
        });

        if (newSubmittedAppsLists.length > 0) {
          let newAppIds = newSubmittedAppsLists?.map((item) => item.id)
          payload.data?.leaseApplications.map((app) => {
            if (newAppIds.includes(app.id)) {
              app.isNewApp = true;
            }
          });
          state.isNewAppTimer = true
        }
      } else {
        state.isNewAppTimer = false
      }

      if(action.payload.isTimer === true) {
        state.isFromTimer = true;
      } else {
        state.isFromTimer = false;
      }

      state.applicationLists = payload.data?.leaseApplications;
      state.statusData = payload.statusData;
      state.pagination = payload.pagination;
      state.unseenPromotionsCount = payload.unseenPromotionsCount
      state.unseenTrainingCount = payload.unseenTrainingCount
      return state
    },
    setApplicationDetails: (state, action) => {
      state.applicationDetails = action.payload;
      return state
    },
    updatePageState: (state, action) => {
      return {
        ...state,
        pageState: {
          ...state.pageState, ...action.payload
        },
        isFromTimer : false
      }
    },
    updateFilterData: (state, action) => {
      return {
        ...state,
        filterData: {
          ...state.filterData, ...action.payload
        },
        isFromTimer : false
      }
    },
    clearUnseenNotesCount: (state, action) => {
      state.applicationLists.map((app) => {
        if (app.id === action.payload.id) {
          app.unseenNotesCount = 0
        }
      })
    },
    insertNote: (state, action) => {
      state.applicationLists.map((app) => {
        if (app.id === action.payload.id) {
          app.comments.unshift(action.payload.note)
        }
      })
    },
    deleteNoteFromState: (state, action) => {
      state.applicationLists.map((app) => {
        if (app.id === action.payload.id) {
          const commentsAfterDelete = app.comments.filter(comment => comment.id !== action.payload.noteId)
          app.comments = [...commentsAfterDelete]
        }
      })
    },
    editNoteFromState: (state, action) => {
      state.applicationLists.map((app) => {
        if (app.id === action.payload.id) {
          const newComments = [...app.comments]
          const index = app.comments.findIndex(comment => comment.id === action.payload.noteId)
          const copy = { ...newComments[index] }
          copy.body = action.payload.body
          newComments[index] = copy
          app.comments = [...newComments]
        }
      })
    },
    updateBikeInformation: (state, action) => {
      let payload = action.payload
      return {
        ...state,
        applicationDetails: {
          ...state.applicationDetails,
          leaseCalculator: {
            ...state.applicationDetails.leaseCalculator,
            assetMake: payload.assetMake,
            assetModel: payload.assetModel,
            assetYear: payload.assetYear,
            assetVin: payload.assetVin,
            newUsed: payload.newUsed,
            mileageTier: payload.mileageTier
          }
        }
      }
    },
    clearUnseenPromotionsCount: (state, action) => {
      return { ...state, unseenPromotionsCount: 0 }
    },
    clearUnseenTrainingCount: (state, action) => {
      return { ...state, unseenTrainingCount: 0 }
    },
  },
});

export const { setAdminusers, setApplications, updatePageState, updateFilterData, clearUnseenNotesCount, insertNote,
  deleteNoteFromState, editNoteFromState, setApplicationDetails, updateBikeInformation, clearUnseenPromotionsCount, clearUnseenTrainingCount } = adminUsersSlice.actions;

export default adminUsersSlice.reducer;
