import { Radio, Select, Input, Form, Space, InputNumber, Checkbox } from 'antd'
import MaskedInput from 'antd-mask-input'
import '../../styles/Applicant.css'
import { Grid } from '@mui/material'
import { AutoCompleteAddress } from './AutoCompleteAddress'

const { Option } = Select

interface OptionData {
  value?: string | number
  label?: string
  parentId?: number
}

function HomeAddress(props: any) {
  const { handleZeroDefaultInputValue, lesseeHomeStateOptions, autoCompleteLesseeHomeBeforePlacesGetDetails, autoCompleteLesseeHomeOnPlacesGetDetailsResult, handleChange,
    zipHomeValidateStatus, zipHomeErrorMessage, handleLesseeHomeZipcodeBlur, showHomeState, handleHomeStateChange, handleStateTarget,
    showHomeCountyState, handleHomeCountyStateChange, handleCountyTarget, lesseeHomeCountyOptions, showHomeCityState,
    handleHomeCityStateChange, handleCityTarget, lesseeHomeCityOptions,
    lesseeOrColessee, fillLesseeHomeAddress, formValidation
  } = props

  return (
    <>
      {lesseeOrColessee == 'colessee' &&
          <Grid item xs={2} sm={4} md={4}>
            <Checkbox id="sameAddressCheckbox" style={{ fontSize: `13px` }} onChange={fillLesseeHomeAddress}>
              Same As Primary Applicant Home Address?
            </Checkbox>
          </Grid>
      }
      <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label={<label htmlFor='homeAddress'>Search Address</label>} htmlFor='homeAddress'>
            <AutoCompleteAddress beforePlacesGetDetails={autoCompleteLesseeHomeBeforePlacesGetDetails} onPlacesGetDetailsResult={autoCompleteLesseeHomeOnPlacesGetDetailsResult} htmlId={'homeAddress'}/>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item
            label="Street Address"
            name={[`${lesseeOrColessee}Attributes`, 'homeAddressAttributes', 'street1']}
            className="street-address"
            rules={[
              {
                required: formValidation,
                message: 'Street address is required!',
              },
            ]}
          >
            <Input placeholder="Street address" onChange={handleChange} className="ant-input-comp space-up" />
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="Apartment" name={[`${lesseeOrColessee}Attributes`, 'homeAddressAttributes', 'street2']}>
            <Input placeholder="Apartment" onChange={handleChange} className="ant-input-comp space-up" />
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item
            label="ZIP Code"
            name={[`${lesseeOrColessee}Attributes`, 'homeAddressAttributes', 'zipcode']}
            validateStatus={zipHomeValidateStatus}
            help={zipHomeErrorMessage}
            rules={[{ required: formValidation, message: 'ZIP Code is required!' }]}
          >
            <MaskedInput
              mask="11111"
              placeholder="ZIP Code"
              onPressEnter={handleLesseeHomeZipcodeBlur}
              onBlur={handleLesseeHomeZipcodeBlur}
              onChange={handleChange}
            />
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="State" name={[`${lesseeOrColessee}Attributes`, 'homeAddressAttributes', 'state']} rules={[{ required: formValidation, message: 'State is required!' }]}>
            <Select showSearch placeholder="State" {...showHomeState} onSelect={handleHomeStateChange} onChange={handleStateTarget}>
              {lesseeHomeStateOptions &&
                lesseeHomeStateOptions.map((obj: OptionData, index: any) => {
                  if (obj)
                  {
                    return (
                      <Option key={index} value={`${obj.value}`}>
                        {obj.label}
                      </Option>
                    )
                  }
                })}
            </Select>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item
            label="County"
            name={[`${lesseeOrColessee}Attributes`, 'homeAddressAttributes', 'county']}
            rules={[
              {
                required: formValidation,
                message: 'County is required!',
              },
            ]}
          >
            <Select showSearch placeholder="County" {...showHomeCountyState} onSelect={handleHomeCountyStateChange} onChange={handleCountyTarget}>
              {lesseeHomeCountyOptions &&
                lesseeHomeCountyOptions.map((obj: OptionData, index: number) => {
                  if(obj)
                  {
                    return (
                      <Option key={index} value={`${obj.value}`}>
                        {obj.label}
                      </Option>
                    )
                  }
                })}
            </Select>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="City" name={[`${lesseeOrColessee}Attributes`, 'homeAddressAttributes', 'city']} rules={[{ required: formValidation, message: 'City is required!' }]}>
            <Select showSearch placeholder="City" {...showHomeCityState} onSelect={handleHomeCityStateChange} onChange={handleCityTarget}>
              {lesseeHomeCityOptions &&
                lesseeHomeCityOptions.map((obj: OptionData, index: number) => {
                  if(obj)
                  {
                    return (
                      <Option key={index} value={`${obj.value}`}>
                        {obj.label}
                      </Option>
                    )
                  }
                })}
            </Select>
          </Form.Item>
        </Grid>
        <Grid item xs={1} sm={2} md={2}>
          <Form.Item
              label="Years At Address"
              name={[`${lesseeOrColessee}Attributes`, 'atAddressYears']}
              rules={[{ required: formValidation, message: 'Years at Address is required!' }]}
          >
            <Input onClick={handleZeroDefaultInputValue} type='number'/>
          </Form.Item>
        </Grid>
        <Grid item xs={1} sm={2} md={2}>
          <Form.Item
              label="Months At Address"
              name={[`${lesseeOrColessee}Attributes`, 'atAddressMonths']}
              rules={[{ required: formValidation, message: 'Months At Address is required!' }]}
          >
            <Input onClick={handleZeroDefaultInputValue} type='number'/>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4} >
          <Space direction="horizontal">
          <Form.Item
            label="Mortgage/Rent "
            name={[`${lesseeOrColessee}Attributes`, 'monthlyMortgage']}
            rules={[
              {
                required: formValidation,
                message: 'Mortgage/Rent is required!',
              },
            ]}
          >
            <InputNumber onClick={handleZeroDefaultInputValue} className="space-up monthly-mortgage ant-input-comp" placeholder="Mortgage/Rent"
                         formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                         parser={value => `${value}`.replace(/(\,*)|/g, '')}
            />
          </Form.Item>
            <div style={{ marginLeft: '5px' }}></div>
            <Form.Item
                label="Home ownership ?"
                name={[`${lesseeOrColessee}Attributes`, 'homeOwnership']}
                rules={[{ required: formValidation, message: 'Home ownership is required!' }]}
            >
              <Radio.Group name="ownership">
                <Radio value={1}>Own</Radio>
                <Radio value={2}>Rent</Radio>
              </Radio.Group>
            </Form.Item>
          </Space>
        </Grid>
      </Grid>
      {/* <br /> */}
    </>
  )
}

export default HomeAddress
