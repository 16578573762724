import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { message, Alert, Spin } from 'antd'
import 'antd/dist/antd.css'
import ApplicationSteps from './ApplicationSteps'
import { Lessee as SummaryLessee } from './Applicant/Applicant'
import '../../../layouts/styles/Summary.css'
import { logger, network } from '../../../../utils'
import { useSelector } from "react-redux";

//Material UI
import { Typography, Button, Paper } from '@mui/material'
import SummaryTabs from './Components/Summary/SummaryTabs'

interface LeaseCalculator {
  id?: string | number | undefined
  assetMake?: string
  assetModel?: string
  assetYear?: number
  newUsed?: string
  mileageTier?: string
}

interface Props {
  isAddingCoApplicant?: boolean,
  data?: {
    id: string | number
    lessee: SummaryLessee
    colessee: SummaryLessee
    leaseCalculator: LeaseCalculator
    applicationIdentifier: number
    colesseeAddedAfterSubmission: boolean
  }
  label?: any
}

const canSubmitData = window.localStorage.getItem("can_submit_speedlease");

export const Summary: React.FC<Props> = ({isAddingCoApplicant}) => {
  const data = useSelector((state: any) => state?.applications?.applicationDetails);
  let leaseApplicationId: string | number | undefined = data?.id
  let leaseCalculatorId: string | number | undefined = data?.leaseCalculator?.id
  const [submitterMessage, setSubmitterMessage] = useState<string | undefined>('')
  const [checkIfSubmitted, setCheckIfSubmitted] = useState<boolean>(false)
  const [loading, ] = useState<boolean>(false)
  const [disableBtn, setDisableBtn] = useState<boolean>(false)

  const inValidData = (data: any) => {
    return isLesseeInvalid(data.lessee)
  }

  const isLesseeInvalid = (lessee: any) => {
    if (lessee) {
     let value:boolean = (!lessee.firstName || !lessee.lastName || !lessee.ssn || !lessee.dateOfBirth || !lessee.emailAddress || !lessee.homeMobile || !lessee.mobilePhoneNumber ||
      !lessee.homeAddress || !lessee.homeAddress?.street1 || !lessee.homeAddress?.zipcode || !lessee.homeAddress?.state || !lessee.homeAddress?.newCity?.county || !lessee.homeAddress?.newCity?.city ||
      !lessee.employmentStatus);
     let employmentVeification = false
     if(lessee.employmentStatus === 'Employed' || lessee.employmentStatus === 'Self Employed'){
      employmentVeification = !lessee.employerPhoneNumber || !lessee.employerName || !lessee.jobTitle || !lessee.employmentAddress?.city || !lessee.employmentAddress?.state
     }
     return value || employmentVeification;
    } else {
      return true
    }
  }

  useEffect(()=>{
    if(canSubmitData === "false")
    {
      message.warning('You dont have access of this page')
      setDisableBtn(true)
    }
  }, [])

  useEffect(() => {
    if (data?.applicationIdentifier) {
      if (data?.colesseeAddedAfterSubmission || data?.isResubmitting) {
        setCheckIfSubmitted(false)
      } else {
        setCheckIfSubmitted(true)
      }
    }
    if(data){
      if(inValidData(data) && !(data?.isAddCoApplicant && data?.colesseeAddedAfterSubmission)){
        setDisableBtn(true)
      }
    }
  }, [data])

  const path = "/"
  const history = useHistory();
  const sleep = (ms:any) => new Promise(r => setTimeout(r, ms));

  const handleSubmit = async () => {
    if(!disableBtn){
      try {
        await network.GET(`/api/v1/dealers/applications/${leaseApplicationId}`)
        message.success('Successfully Submitted Application')
        setSubmitterMessage('Successfully Submitted Application')
        setCheckIfSubmitted(true)
        history.push(path);
      } catch (e: any) {

        if(e?.response?.status === 409 && e?.response?.data?.message === 'can submit disabled') {
          window.localStorage.setItem('can_submit_speedlease', 'false');
          message.error('This dealership do not have permission to submit application.')
          setDisableBtn(true)
        } else if(e.response.status === 422 && e.response?.data?.message === 'Lease application already submitted!') {
          message.error(`Lease Application (${e.response?.data?.app_number}) already submitted.`)
          await sleep(5000)
          history.push(path);
        } else {
          logger.error('Request Error', e)
          setSubmitterMessage(e.response?.data?.message)
          setDisableBtn(false)
        }
        logger.error('Request Error', e)
        setSubmitterMessage(e.response?.data?.message)
        setDisableBtn(false)

      }
    }
  }

  const colesseeHasValue = () => {
    if (!data?.colessee) {
      return false
    } else {
      return Object.values(data?.colessee).some(val => (val !== null))
    }
  }

  const buttonStyle = {
    color: '#fff',
    backgroundColor: "#e82512", 
    padding: 2, 
     "&:hover": {
      backgroundColor: "#e82512",                                 
      color: '#fff',
    },
  }

  const onClose = () => {
    setSubmitterMessage('')
  };

  return (
    <div>
      <ApplicationSteps stepType={`summary`} isSaved={false} leaseApplicationId={`${leaseApplicationId}`} leaseCalculatorId={`${leaseCalculatorId}`} attribute={true} save={null} isSubmitted ={checkIfSubmitted} isAddingCoApplicant={isAddingCoApplicant || (data?.isAddCoApplicant && data?.colesseeAddedAfterSubmission)}  />
      { submitterMessage && 
        <Alert message={submitterMessage} 
        type="warning" 
        showIcon 
        closable
        onClose={onClose}
        />
      }
      <div style={{ margin: `20px 20px` }}>
        <div style={{ textAlign: `center`, marginBottom: 20}}>
          <div style={{marginBottom: 10}}>
            <Typography variant="h3" > Summary </Typography>
            {
              canSubmitData === "false" &&  
              <Typography variant="h6" sx={{ fontWeight: 400 }} style={{color: "#e93b1b"}}>
                This dealership do not have permission to submit application.
              </Typography>
            }
          </div>
          <Paper
            sx={{
              p: 2,
              marginBottom: 5,
              padding: 5,
              border: '#EF4829',
              width: '100%',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            <div>
            <Spin 
              spinning={loading}
              size='large'
              tip='Loading...'
            >
              <SummaryTabs leaseApplicationId={leaseApplicationId} data={data} tabsData={data} ssnData={data} colesseeHasValue={colesseeHasValue} isSubmitted={checkIfSubmitted} isAddingCoApplicant={isAddingCoApplicant} ></SummaryTabs>
            </Spin>

            </div>  
            <div style={{ marginTop: 20, textAlign: `center` }}>

              { !checkIfSubmitted && 
              (<Button variant="contained" size="medium" disabled={disableBtn} onClick={() => {
                setDisableBtn(true)
                handleSubmit()
              }}
                sx={ buttonStyle }>
                Submit to Speed Leasing
              </Button>)
              }
            </div>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default Summary