import { Col, Row, Typography, Tooltip  } from "antd";
import InfoIcon from '@mui/icons-material/Info';
const { Title } = Typography;

export const CustomerMonthlyPaymentInfo = (props: any) => {
    const { calculatorData, convertUsd, maxAllowablePayment, customerMonthlyPaymentDanger, tooltipColor } = props;

    return (
        <>
            <Title level={5} className="payment-calculator-headers"
                   style={{color: customerMonthlyPaymentDanger}}> Customer Monthly Payment </Title>
            <Row className={'bot-spacer-1'} style={{lineHeight: 2, color: customerMonthlyPaymentDanger}}>
                <Col span={12}>
                    Base Monthly Payment
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.baseMonthlyPayment && convertUsd(Number(calculatorData.baseMonthlyPayment))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2, color: customerMonthlyPaymentDanger}}>
                <Col span={12}>
                    Monthly Sales Tax
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.monthlySaleTax && convertUsd(Number(calculatorData.monthlySaleTax))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1"
                 style={{color: (customerMonthlyPaymentDanger === 'red' ? 'red' : '#1890ff'), lineHeight: 2}}>
                <Col span={12}>
                    <p> Total Monthly Payment <br/>(Max Approved Payment ${maxAllowablePayment})</p>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.totalMonthlyPayment && convertUsd(Number(calculatorData.totalMonthlyPayment))}</span>
                </Col>
            </Row>

            <Title level={5} className="payment-calculator-headers"> Capitalized Cost and Other Info </Title>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                  Purchase Option (Residual)
                  <Tooltip title="Predetermined amount the customer can purchase bike for (if they choose to) after all lease payments are made." color={tooltipColor} key={'cap_cost'}>
                    <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                  </Tooltip>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.purchaseOption && convertUsd(Number(calculatorData.purchaseOption))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                  Adj. Capitalized Cost
                  <Tooltip title="This is the total selling price less the down payment which is equivalent to ‘amount financed’ for a loan." color={tooltipColor} key={'cap_cost'}>
                    <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                  </Tooltip>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.adjustedCapitalizedCost && convertUsd(Number(calculatorData.adjustedCapitalizedCost))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={16}>
                    <p>
                      Acquisition Fee
                      <Tooltip title="Fee paid by the customer to the lessor that is added to the Adjusted Capitalized Cost, not collected from customer at time of signing." color={tooltipColor} key={'cap_cost'}>
                        <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                      </Tooltip>
                    </p>
                </Col>
                <Col span={8} style={{textAlign: 'right'}}>
                    <span>{calculatorData.acquisitionFee && convertUsd(Number(calculatorData.acquisitionFee))}</span>
                </Col>
            </Row>
        </>
    )
}

export default CustomerMonthlyPaymentInfo
