import React, { useState, useEffect } from 'react'
import { Row, Col, Collapse, Select, Form, message, notification } from 'antd'
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { logger, network } from '../../../../../utils'
import ApplicationSteps from '../ApplicationSteps'
import Personal from './Personal'
import HomeAddress from './HomeAddress'
import MailingAddress from './MailingAddress'
import EmploymentDetails from './EmploymentDetails'
import Employer from './Employer'
import '../../styles/Applicant.css'
import type { DatePickerProps } from 'antd';
import {setApplicationDetails} from "../../../../../reducers/LeaseApplicationsReducer";
import { getDriverLicenseInfo } from './driverLicenseUtils.js';

// material UI
import { Typography, Paper, Button } from '@mui/material'
import { useSelector, useDispatch } from "react-redux";

const { Panel } = Collapse

const dateFormat = 'MM/DD/YYYY'
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

export interface Address {
  id?: number | undefined
  state?: string | undefined
  street1?: string | undefined
  street2?: string | undefined
  zipcode?: string | undefined
  county?: string | undefined
  cityId?: string | undefined
  cityOptions?: OptionData | any
  countyOptions?: OptionData | any
  stateOptions?: OptionData | any
  newCity?: newCity
}

export interface employmentAddress {
  id?: number | undefined
  city?: string | undefined
  state?: string | undefined
}

export interface Lessee {
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  dateOfBirth?: string | undefined
  ssn?: string | undefined
  driversLicenseIdNumber?: string | undefined
  homePhoneNumber?: string | undefined
  mobilePhoneNumber?: string | undefined
  homeAddress?: Address
  mailingAddress?: Address
  atAddressMonths?: number | string | undefined
  atAddressYears?: number | string | undefined
  monthYears?: number | string | undefined
  monthlyMortgage?: number | string | undefined
  homeOwnership?: number | undefined
  employerName?: string | undefined
  employerPhoneNumber?: string | undefined
  employmentAddress?: employmentAddress
  employmentStatus?: string | undefined
  jobTitle?: string | undefined
  timeAtEmployerYears?: number | string | undefined
  timeAtEmployerMonths?: number | string | undefined
  grossMonthlyIncome?: number | string | undefined
  driverLicense?: string | undefined
  motorcycleEndorsement?: boolean | undefined;
  firstTimeRider?: boolean | undefined
  homeMobile?: number | undefined
  monthYearAtEmployer?: number | undefined
  emailAddress?: string | undefined
}

interface LeaseCalculator {
  id?: string | number | undefined
}

interface Props {
  data?: {
    id: string | number
    lessee: Lessee
    leaseCalculator: LeaseCalculator
  }
}

interface OptionData {
  value?: string | number
  label?: string
  parentId?: number
}

interface newCity {
  city?: string | undefined
  state?: string | undefined
  county?: string | undefined
}

const formatOptions = (params: { options: Array<any>; type?: string }) => {
  switch (params.type) {
    case 'city': {
      return params.options.map((value: any) => {
        return {
          value: value['id'],
          label: value['name'],
          parentId: value['countyId'],
        }
      })
    }
    default: {
      return params.options.map((value: any) => {
        return {
          value: value['id'],
          label: value['abbreviation'] ? value['abbreviation'] : value['name'],
        }
      })
    }
  }
}

const canSubmitData = window.localStorage.getItem("can_submit_speedlease");

export const Applicant: React.FC<Props> = ({ }: Props) => {

  const data = useSelector((state: any) => state?.applications?.applicationDetails);
  const { lessee } = data || {}

  const dispatch = useDispatch()

  const [lesseeForm] = Form.useForm()

  let leaseApplicationId: string | number | undefined = data?.id
  let leaseCalculatorId: string | number | undefined = data?.leaseCalculator?.id

  const [lesseeHomeStateOptions, setLesseeHomeStateOptions] = useState<Array<OptionData>>([])
  const [lesseeHomeCountyOptions, setLesseeHomeCountyOptions] = useState<Array<OptionData>>([])
  const [lesseeHomeCityOptions, setLesseeHomeCityOptions] = useState<Array<OptionData>>([])
  const [lesseeHomeCityOptionsData, setLesseeHomeCityOptionsData] = useState<Array<OptionData>>([])

  const [lesseeMailStateOptions, setLesseeMailStateOptions] = useState<Array<OptionData>>([])
  const [lesseeMailCountyOptions, setLesseeMailCountyOptions] = useState<Array<OptionData>>([])
  const [lesseeMailCityOptions, setLesseeMailCityOptions] = useState<Array<OptionData>>([])
  const [lesseeMailCityOptionsData, setLesseeMailCityOptionsData] = useState<Array<OptionData>>([])

  const [zipHomeValidateStatus, setZipHomeValidateStatus] = useState<any | undefined>(undefined)
  const [zipHomeErrorMessage, setZipHomeErrorMessage] = useState<string | undefined>(undefined)

  const [zipMailValidateStatus, setZipMailValidateStatus] = useState<any | undefined>(undefined)
  const [zipMailErrorMessage, setZipMailErrorMessage] = useState<string | undefined>(undefined)

  const [employmentStatusOptions, setEmploymentStatusOptions] = useState([])

  const [phoneOption, setPhoneOption] = useState<string | number | undefined>(undefined)
  const [requireEmploymentFields, setRequireEmploymentFields] = useState(false)

  const [showHomeState, setShowHomeState] = useState<object | null>(null)
  const [showHomeCountyState, setShowHomeCountyState] = useState<object | null>(null)
  const [showHomeCityState, setShowHomeCityState] = useState<object | null>(null)
  const [showMailingState, setShowMailingState] = useState<object | null>(null)
  const [showMailingCountyState, setShowMailingCountyState] = useState<object | null>(null)
  const [showMailingCityState, setShowMailingCityState] = useState<object | null>(null)
  const [checkIfSaved, setCheckIfSaved] = useState(false)
  const [employmentYearOrMonth, setEmploymentYearOrMonth] = useState<any>(0)
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
  const [homeAddress, setHomeAddress] = useState({
    street1: '',
    street2: '',
    zipcode: '',
  })

  const [stateTarget, setStateTarget] = useState('')
  const [mailingStateTarget, setMailingStateTarget] = useState('')
  const [homeCountyTarget, setHomeCountyTarget] = useState('')
  const [mailingCountyTarget, setMailingCountyTarget] = useState('')
  const [cityTarget, setCityTarget] = useState('')
  const [cityMailingTarget, setCityMailingTarget] = useState('')
  const [homeCityId, setHomeCityId] = useState('')
  const [mailingCityId, setMailingCityId] = useState('')

  const [mailingAddress, setMailingAddress] = useState<any>({
    street1: '',
    street2: '',
    zipcode: '',
    state: '',
    county: '',
    city: '',
  })

  const [path, setPath] = useState<string>('')

  const [monthYears, setMonthYear] = useState<any | undefined>(undefined)
  const [monthYearAtEmployer, setMonthYearAtEmployer] = useState<any | undefined>(undefined)
  const [manualMonthYears, setManualMonthYear] = useState<any | undefined>(undefined)
  const [manualMonthYearEmployer, setManualMonthYearEmployer] = useState<any | undefined>(undefined)
  const [nullMonthYear, setNullMonthYear] = useState(true)

  const [btnAttribute, setBtnAttribute] = useState(true)
  const [btnClass, setBtnClass] = useState('button')

  const [lesseeDob, setLesseeDob] = useState('')
  const [dateOfBirthValidation, setDateOfBirthValidation] = useState<any | undefined>(undefined)
  const [dateOfBirthValidationMessage, setDateOfBirthValidationMessage] = useState<any | undefined>(undefined)
  const [formValidation, setFormValidation] = useState(true)

  const [employmentYearLabel, setEmploymentYearLabel] = useState('Time At Employer Years')
  const [employmentMonthLabel, setEmploymentMonthLabel] = useState('Time At Employer Months')
  const [motorcycleLicense, setMotorcycleLicense] = useState(false)
  const [licenseFormat, setLicenseFormat] = useState<RegExp>()
  const [licenseFormatMessage, setLicenseFormatMessage] = useState<string>()

  const handleLicenseState = (value: string) => {
    setLicenseFormat(getDriverLicenseInfo(value)?.pattern)
    setLicenseFormatMessage(getDriverLicenseInfo(value)?.message)
  }

  const openNotification = () => {
    const args = {
      message: 'Your attention please!',
      description:
        'The system doesn\'t have the zip code for this address, please add it manually',
      duration: 0,
    };
    notification.open(args);
  };


  const openNotification2 = () => {
    const args = {
      message: 'Your attention please!',
      description:
        'Please Add Valid SSN, 9 digits without letter or special characters',
      duration: 0,
    };
    notification.open(args);
  };


  const validStateArr = ["AL", "AZ", "CA", "DE", "FL", "GA", "IL", "IN", "LA", "MD",
    "MI", "MS", "MO", "NV", "NJ", "NM", "NC", "OH", "OK", "PA", "SC", "TN", "TX", "VA", "CT"]

  let isStateValid;

  const submitApplication = async (values: any) => {
    try {
      let response = await network.PUT(`/api/v1/dealers/update-details?id=${leaseApplicationId}`, values)
      dispatch(setApplicationDetails(response?.data?.data?.leaseApplication))
      setDisableSubmitBtn(true)
      setCheckIfSaved(true)
      setBtnClass('button')
      message.success('Save Successfully')
      if(formValidation){
        history.push(path);
      }
    } catch (e: any) {
      if(e?.response?.status === 409 && e?.response?.data?.message === 'can submit disabled') {
        window.localStorage.setItem('can_submit_speedlease', 'false');
        message.error('This dealership do not have permission to submit application.')
        setDisableSubmitBtn(true)
      } else {
        logger.error('Request Error', e)
        message.error(e.response?.data?.message || 'Error while saving.')
        setDisableSubmitBtn(false)
      }
    }
    setDisableSubmitBtn(false)
  }

  const history = useHistory();

  const handleSubmit = async (values: any) => {

    let dob = values?.lesseeAttributes?.dateOfBirth
    let age = Math.floor((new Date().getTime() - new Date(dob).getTime()) / 3.15576e+10);
    if (age < 18) {
      setDateOfBirthValidation('error');
      setDateOfBirthValidationMessage('Date of Birth must be 18 years old');
      return false
    } else {
      setLesseeDob(dob);
      setDateOfBirthValidation(undefined);
      setDateOfBirthValidationMessage('');
    }

    const myValues = {
      lesseeAttributes: {
        dateOfBirth: values?.lesseeAttributes?.dateOfBirth,
        firstName: values?.lesseeAttributes?.firstName,
        firstTimeRider: values?.lesseeAttributes?.firstTimeRider,
        emailAddress: values?.lesseeAttributes?.emailAddress,
        licenseState:  values?.lesseeAttributes?.licenseState,
        driversLicenseIdNumber:  values?.lesseeAttributes?.driversLicenseIdNumber,
        homeAddressAttributes: {
          cityId: homeCityId,
          city: cityTarget,
          county: homeCountyTarget,
          state: stateTarget,
          street1: values?.lesseeAttributes?.homeAddressAttributes?.street1,
          street2: values?.lesseeAttributes?.homeAddressAttributes?.street2,
          zipcode: values?.lesseeAttributes?.homeAddressAttributes?.zipcode
        },
        homeOwnership: values?.lesseeAttributes?.homeOwnership,
        lastName: values?.lesseeAttributes?.lastName,
        mailingAddressAttributes: {
          cityId: mailingCityId,
          county: mailingCountyTarget,
          state: mailingStateTarget,
          street1: values?.lesseeAttributes?.mailingAddressAttributes?.street1,
          street2: values?.lesseeAttributes?.mailingAddressAttributes?.street2,
          zipcode: values?.lesseeAttributes?.mailingAddressAttributes?.zipcode,
          city: cityMailingTarget
        },
        middleName: values?.lesseeAttributes?.middleName,
        mobilePhoneNumber: values?.lesseeAttributes?.mobilePhoneNumber,
        atAddressMonths: values?.lesseeAttributes?.atAddressMonths,
        atAddressYears: values?.lesseeAttributes?.atAddressYears,
        monthlyMortgage: values?.lesseeAttributes?.monthlyMortgage,
        driverLicense: values?.lesseeAttributes?.driverLicense,
        motorcycleEndorsement: values?.lesseeAttributes?.motorcycleEndorsement,
        ssn: values?.lesseeAttributes?.ssn,
        employerName: values?.lesseeAttributes?.employerName,
        employerPhoneNumber: values?.lesseeAttributes?.employerPhoneNumber,
        grossMonthlyIncome: values?.lesseeAttributes?.grossMonthlyIncome,
        jobTitle: values?.lesseeAttributes?.jobTitle,
        timeAtEmployerMonths: values?.lesseeAttributes?.timeAtEmployerMonths,
        timeAtEmployerYears: values?.lesseeAttributes?.timeAtEmployerYears,
        employmentStatus: values?.lesseeAttributes?.employmentStatus,
        homeMobile: values?.lesseeAttributes?.homeMobile,
        monthYearAtEmployer: values?.lesseeAttributes?.monthYearAtEmployer,
        employmentMonthOrYear: employmentYearOrMonth,
        employmentAddressAttributes: {
          id: values?.lesseeAttributes?.employmentAddressAttributes?.id,
          city: values?.lesseeAttributes?.employmentAddressAttributes?.city,
          state: values?.lesseeAttributes?.employmentAddressAttributes?.state
        }
      }
    }

    let pattern = /^\d{3}-\d{2}-\d{4}$/
    if (formValidation && !values?.lesseeAttributes?.ssn?.match(pattern)) {
      openNotification2();
    } else {
      setDisableSubmitBtn(true)
      submitApplication(myValues)
    }
  }


  const handleRedirectionClick = (buttonAction: any) => {
   if(buttonAction === "review-submit"){
      setPath(`/applications/${leaseApplicationId}/summary`)
    }
    if(buttonAction === "co-applicant"){
      setPath(`/applications/${leaseApplicationId}/co-applicant`)
    }
  }

  const handleLesseeHomeZipcodeBlur = async () => {
    let zipcode = lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          homeAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            setLesseeHomeStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            setLesseeHomeCountyOptions(
              formatOptions({
                options: response.data.county || [],
                type: 'county',
              })
            )
            setLesseeHomeCityOptionsData(formatOptions({ options: response.data.city || [], type: 'city' }))
          }
          if (!isStateValid || !response.data.is_state_active_on_calculator || response.data.city.length < 1 || response.data.city === undefined) {
            setZipHomeValidateStatus('error')
            setZipHomeErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowHomeState(null)

            setLesseeHomeStateOptions(
              formatOptions({
                options: [],
                type: 'state',
              })
            )
            setLesseeHomeCountyOptions(
              formatOptions({
                options: [],
                type: 'county',
              })
            )
            setLesseeHomeCityOptionsData(formatOptions({ options: [], type: 'city' }))
          } else {
            setZipHomeValidateStatus(undefined)
            setZipHomeErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('handleLesseeHomeZipcodeBlur.Request Error', error)
        })
    } catch (e) {
      logger.error('handleLesseeHomeZipcodeBlur Error', e)
    }
  }

  const handleLesseeMailZipcodeBlur = async () => {
    let zipcode = lesseeForm.getFieldValue(['lesseeAttributes', 'mailingAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          mailingAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            setLesseeMailStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            setLesseeMailCountyOptions(
              formatOptions({
                options: response.data.county || [],
                type: 'county',
              })
            )
            setLesseeMailCityOptionsData(formatOptions({ options: response.data.city || [], type: 'city' }))
          }
          if (!isStateValid || !response.data.is_state_active_on_calculator || response.data.city.length < 1 || response.data.city === undefined) {
            setZipMailValidateStatus('error')
            setZipMailErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowMailingState(null)

            setLesseeMailStateOptions(
              formatOptions({
                options: [],
                type: 'state',
              })
            )
            setLesseeMailCountyOptions(
              formatOptions({
                options: [],
                type: 'county',
              })
            )
            setLesseeMailCityOptionsData(formatOptions({ options: [], type: 'city' }))
          } else {
            setZipMailValidateStatus(undefined)
            setZipMailErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('handleLesseeMailZipcodeBlur.Request Error', error)
        })
    } catch (e) {
      logger.error('handleLesseeMailZipcodeBlur Error', e)
    }
  }

  const handleHomeStateChange = () => {
    setShowHomeState(null)
  }

  const handleHomeCountyStateChange = (countyStateId: any) => {
    if (countyStateId) {
      lesseeForm.setFieldsValue({
        lesseeAttributes: { homeAddressAttributes: { cityId: null } },
      })
      setLesseeHomeCityOptions(lesseeHomeCityOptionsData.filter((obj: OptionData) => obj.parentId === parseInt(countyStateId)))
    }

    setShowHomeCountyState(null)
  }

  const handleHomeCityStateChange = (e: any) => {
    setHomeCityId(e)
    setShowHomeCityState(null)
  }

  const handleMailingStateChange = () => {
    setShowMailingState(null)
  }

  const handleMailingCountyStateChange = (countyStateId: any) => {
    if (countyStateId) {
      lesseeForm.setFieldsValue({
        lesseeAttributes: { mailingAddressAttributes: { cityId: null } },
      })
      setLesseeMailCityOptions(lesseeMailCityOptionsData.filter((obj: OptionData) => obj.parentId === parseInt(countyStateId)))
    }

    setShowMailingCountyState(null)
  }

  const handleMailingCityStateChange = (e: any) => {
    setMailingCityId(e)
    setShowMailingCityState(null)
  }

  const autoCompleteLesseeHomeBeforePlacesGetDetails = (value: any, option: any) => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        homeAddressAttributes: {
          street1: option.data.structured_formatting.main_text,
        },
      },
    })
  }

  const autoCompleteLesseeHomeOnPlacesGetDetailsResult = (placeResult: any, placeServiceStatus: any) => {

    let addressLength = placeResult.address_components.length - 1
    let addressToTest = placeResult.address_components[addressLength]

    if (placeServiceStatus == 'OK') {
      placeResult.address_components.map((component: any) => {
        if (component.types.includes('postal_code')) {
          lesseeForm.setFieldsValue({
            lesseeAttributes: {
              homeAddressAttributes: { zipcode: component.long_name },
            },
          })
          autoCompleteLesseeHomeZipcodeStateCountyCity(placeResult.address_components)
        }
      })

      if (addressToTest.types.length > 1) {
        openNotification();
      }
    }
  }

  const autoCompleteLesseeHomeZipcodeStateCountyCity = async (addressComponents: any) => {

    let zipcode = lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          homeAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            lesseeForm.setFieldsValue({
              lesseeAttributes: {
                homeAddressAttributes: { city: response.data.city[0].name }
              }
            })

            setHomeCityId(response.data.city[0].id)

            setHomeCountyTarget(response.data.county[0].name)
            setStateTarget(response.data.state[0].abbreviation)
            setCityTarget(response.data.city[0].name)

            setLesseeHomeStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            let countyParishOptions: any = formatOptions({
              options: response.data.county || [],
              type: 'county',
            })
            setLesseeHomeCountyOptions(countyParishOptions)
            let cityOptions: any = formatOptions({
              options: response.data.city || [],
              type: 'city',
            })
            setLesseeHomeCityOptionsData(cityOptions)

            setShowHomeState(null)
            setShowHomeCountyState(null)
            setShowHomeCityState(null)

            // Try to autocomplete state
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_1')) {
                lesseeForm.setFieldsValue({
                  lesseeAttributes: {
                    homeAddressAttributes: { state: component.short_name },
                  },
                })
              }
            })

            // Try to autocomplete county
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_2')) {
                let countyName: string = component.short_name.toLowerCase().replace(' county', '').toUpperCase()
                lesseeForm.setFieldsValue({
                  lesseeAttributes: {
                    homeAddressAttributes: { county: countyName },
                  },
                })

                // Find state id from countyParishOptions using countyName
                countyParishOptions.map((option: any) => {
                  if (option.label == countyName) {
                    setLesseeHomeCityOptions(cityOptions.filter((obj: OptionData) => obj.parentId === parseInt(option.value)))
                  }
                })
              }
            })

            // Try to autocomplete city
            addressComponents.map((component: any) => {
              if (component.types.includes('locality')) {
                cityOptions.map((option: any) => {
                  if (option.label == component.long_name.toUpperCase()) {
                    lesseeForm.setFieldsValue({
                      lesseeAttributes: {
                        homeAddressAttributes: { city: response.data.city[0].name }
                      },
                    })
                  }
                })
              }
            })
          }
          if (!isStateValid || response.data.city.length < 1 || response.data.city === undefined) {
            setZipHomeValidateStatus('error')
            setZipHomeErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowHomeState(null)
            lesseeForm.setFieldsValue({
              lesseeAttributes: {
                homeAddressAttributes: {
                  state: null,
                  county: null,
                  cityId: null,
                  city: null
                },
              },
            })
          } else {
            setZipHomeValidateStatus(undefined)
            setZipHomeErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('autoCompleteLesseeHomeZipcodeStateCountyCity.Request Error', error)
        })
    } catch (e) {
      logger.error('autoCompleteLesseeHomeZipcodeStateCountyCity Error', e)
    }
  }

  const autoCompleteLesseeMailingBeforePlacesGetDetails = (value: any, option: any) => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        mailingAddressAttributes: {
          street1: option.data.structured_formatting.main_text,
        },
      },
    })
  }

  const autoCompleteLesseeMailingOnPlacesGetDetailsResult = (placeResult: any, placeServiceStatus: any) => {

    let addressLength = placeResult.address_components.length - 1
    let addressToTest = placeResult.address_components[addressLength]

    if (placeServiceStatus == 'OK') {
      placeResult.address_components.map((component: any) => {
        if (component.types.includes('postal_code')) {
          lesseeForm.setFieldsValue({
            lesseeAttributes: {
              mailingAddressAttributes: { zipcode: component.long_name },
            },
          })
          autoCompleteLesseeMailingZipcodeStateCountyCity(placeResult.address_components)
        }
      })

      if (addressToTest.types.length > 1) {
        openNotification();
      }
    }
  }

  const autoCompleteLesseeMailingZipcodeStateCountyCity = async (addressComponents: any) => {
    let zipcode = lesseeForm.getFieldValue(['lesseeAttributes', 'mailingAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          mailingAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            lesseeForm.setFieldsValue({
              lesseeAttributes: {
                mailingAddressAttributes: { city: response.data.city[0].name }
              }
            })

            setMailingCityId(response.data.city[0].id)
            setMailingCountyTarget(response.data.county[0].name)
            setMailingStateTarget(response.data.state[0].abbreviation)
            setCityMailingTarget(response.data.city[0].name)

            setLesseeMailStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            let countyParishOptions: any = formatOptions({
              options: response.data.county || [],
              type: 'county',
            })
            setLesseeMailCountyOptions(countyParishOptions)
            let cityOptions: any = formatOptions({
              options: response.data.city || [],
              type: 'city',
            })
            setLesseeMailCityOptionsData(cityOptions)

            setShowMailingState(null)
            setShowMailingCountyState(null)
            setShowMailingCityState(null)

            // Try to autocomplete state
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_1')) {
                lesseeForm.setFieldsValue({
                  lesseeAttributes: {
                    mailingAddressAttributes: { state: component.short_name },
                  },
                })
              }
            })

            // Try to autocomplete county
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_2')) {
                let countyName: string = component.short_name.toLowerCase().replace(' county', '').toUpperCase()
                lesseeForm.setFieldsValue({
                  lesseeAttributes: {
                    mailingAddressAttributes: { county: countyName },
                  },
                })

                // Find state id from countyParishOptions using countyName
                countyParishOptions.map((option: any) => {
                  if (option.label == countyName) {
                    setLesseeMailCityOptions(cityOptions.filter((obj: OptionData) => obj.parentId === parseInt(option.value)))
                  }
                })
              }
            })

            // Try to autocomplete city
            addressComponents.map((component: any) => {
              if (component.types.includes('locality')) {
                cityOptions.map((option: any) => {
                  if (option.label == component.long_name.toUpperCase()) {
                    lesseeForm.setFieldsValue({
                      lesseeAttributes: {
                        mailingAddressAttributes: { city: response.data.city[0].name },
                      },
                    })
                  }
                })
              }
            })
          }
          if (!isStateValid || response.data.city.length < 1 || response.data.city === undefined) {
            setZipMailValidateStatus('error')
            setZipMailErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowMailingState(null)
            lesseeForm.setFieldsValue({
              lesseeAttributes: {
                mailingAddressAttributes: {
                  state: null,
                  county: null,
                  cityId: null,
                  city: null
                },
              },
            })
          } else {
            setZipMailValidateStatus(undefined)
            setZipMailErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('autoCompleteLesseeMailingZipcodeStateCountyCity.Request Error', error)
        })
    } catch (e) {
      logger.error('autoCompleteLesseeMailingZipcodeStateCountyCity Error', e)
    }
  }

  const getEmployerStatus = async () => {
    try {
      await network.GET(`/api/v1/employment-status`).then((response) => {
        if (response.data.employment_status) {
          setEmploymentStatusOptions(
            response.data.employment_status.map((item: [string, string | number]) => {
              return {
                value: item[1],
                label: item[0],
              }
            })
          )
        } else {
          setEmploymentStatusOptions([])
        }
      })
    } catch (e) {
      logger.error('Request Error', e)
    }
  }

  const handleEmploymentStatus = (value: string, formChange: boolean = true) => {
    if(formChange){
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          jobTitle: '',
          grossMonthlyIncome: '',
          employerName: '',
          employerPhoneNumber: '',
          employmentAddressAttributes: {
            city: '',
            state: ''
          }
        },
      })
    }
    if (value === '0' || value === '1') {
      setRequireEmploymentFields(true)
      setEmploymentYearLabel('Time At Employer Years')
      setEmploymentMonthLabel('Time At Employer Months')
    } else {
      setRequireEmploymentFields(false)
      if(value === '2') {
        setEmploymentYearLabel('Years on Soc Sec')
        setEmploymentMonthLabel('Months on SOc Sec')
      }
      if(value === '3') {
        setEmploymentYearLabel('Unemployed Since (Years)')
        setEmploymentMonthLabel('Unemployed Since (Months)')
      }
      if(value === '4') {
        setEmploymentYearLabel('Disabled Since (Years)')
        setEmploymentMonthLabel('Disabled Since (Months)')
      }
      if(value === '5') {
        setEmploymentYearLabel('How long have you \n been retired? (Years)')
        setEmploymentMonthLabel('How long have you \n been retired? (Months)')
      }
    }
  }

  useEffect(() => {
    if (canSubmitData === "false") {
      message.warning('This dealership do not have permission to submit application.')
      setDisableSubmitBtn(true);
    }
    
    let employmentStatus = lesseeForm.getFieldValue(['lesseeAttributes', 'employmentStatus'])
    if(employmentStatus === undefined) {
      setRequireEmploymentFields(true)
    }

    if (data?.lessee) {
      setCheckIfSaved(true)
    }

    if (data?.lessee?.homeAddress?.cityId) {
      setHomeCityId(data?.lessee?.homeAddress?.cityId)
    }

    if (data?.lessee?.addressMonthOrAddressYear) {
      setEmploymentYearOrMonth(data?.lessee?.employmentMonthOrYear)
    }

    if (data?.lessee?.dateOfBirth !== '') {
      setLesseeDob(data?.lessee?.dateOfBirth)
    }

    if (data?.lessee?.mailingAddress?.cityId) {
      setMailingCityId(data?.lessee?.mailingAddress?.cityId)
    }

    if (data?.lessee?.homeAddress?.newCity?.city) {
      setCityTarget(data?.lessee?.homeAddress?.newCity?.city)
    }

    if (data?.lessee?.mailingAddress?.newCity?.city) {
      setCityMailingTarget(data?.lessee?.mailingAddress?.newCity?.city)
    }

    if (data?.lessee?.homeAddress?.newCity?.county) {
      setHomeCountyTarget(data?.lessee?.homeAddress?.newCity?.county)
    }

    if (data?.lessee?.mailingAddress?.newCity?.county) {
      setMailingCountyTarget(data?.lessee?.mailingAddress?.newCity?.county)
    }

    if (data?.lessee?.homeAddress?.newCity?.state) {
      setStateTarget(data?.lessee?.homeAddress?.newCity?.state)
    }

    if (data?.lessee?.mailingAddress?.newCity?.state) {
      setMailingStateTarget(data?.lessee?.mailingAddress?.newCity?.state)
    }

    if (data?.lessee?.monthYears) {
      setMonthYear(data?.lessee?.monthYears)
    }

    if (data?.lessee?.monthYearAtEmployer) {
      setMonthYearAtEmployer(data?.lessee?.monthYearAtEmployer)
    }

    if (data?.lessee?.driverLicense) {
      setMotorcycleLicense(data?.lessee?.driverLicense)
    }

    if (data?.lessee?.licenseState) {
      setLicenseFormat(getDriverLicenseInfo(data?.lessee?.licenseState)?.pattern)
      setLicenseFormatMessage(getDriverLicenseInfo(data?.lessee?.licenseState)?.message)
    }

    getEmployerStatus()
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
    }
  }, [])

  useEffect(() => {
    if(data?.lessee?.homeAddress?.stateOptions.length > 0 && data?.lessee?.homeAddress?.stateOptions[0] !== null) {
      setLesseeHomeStateOptions(data?.lessee?.homeAddress?.stateOptions)
    }
    if(data?.lessee?.homeAddress?.countyOptions.length > 0 && data?.lessee?.homeAddress?.countyOptions[0] !== null) {
      setLesseeHomeCountyOptions(data?.lessee?.homeAddress?.countyOptions)
    }
    if(data?.lessee?.homeAddress?.cityOptions.length > 0 && data?.lessee?.homeAddress?.cityOptions[0] !== null) {
      setLesseeHomeCityOptions(data?.lessee?.homeAddress?.cityOptions)
    }
    if(data?.lessee?.mailingAddress?.stateOptions.length > 0 && data?.lessee?.mailingAddress?.stateOptions[0] !== null) {
      setLesseeMailStateOptions(data?.lessee?.mailingAddress?.stateOptions)
    }
    if(data?.lessee?.mailingAddress?.countyOptions.length > 0 && data?.lessee?.mailingAddress?.countyOptions[0] !== null) {
      setLesseeMailCountyOptions(data?.lessee?.mailingAddress?.countyOptions)
    }
    if(data?.lessee?.mailingAddress?.cityOptions.length > 0 && data?.lessee?.mailingAddress?.cityOptions[0] !== null) {
      setLesseeMailCityOptions(data?.lessee?.mailingAddress?.cityOptions)
    }
    if(data?.lessee?.employmentStatus){
      let employmentStatus = lesseeForm.getFieldValue(['lesseeAttributes', 'employmentStatus']);
      handleEmploymentStatus(employmentStatus, false)
    }
    
  }, [data])

  const handleTabClosing = () => {
    // lesseeForm.submit()
  }

  const alertUser = (event: any) => {
    // lesseeForm.submit()
    event.preventDefault()
    event.returnValue = ''
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setHomeAddress({
      ...homeAddress,
      [name]: value,
    })
  }

  const handleFormChange = () => {
    setBtnClass('green-border')
  }

  const handleStateTarget = (e: any, f: any) => {
    setStateTarget(f.children)
  }

  const handleMailingStatetarget = (e: any, f: any) => {
    setMailingStateTarget(f.children)
  }

  const handleCountyTarget = (e: any, f: any) => {
    setHomeCountyTarget(f.children)
  }

  const handleMailingCountytarget = (e: any, f: any) => {
    setMailingCountyTarget(f.children)
  }

  const handleCityTarget = (e: any, f: any) => {
    setCityTarget(f.children)
  }

  const handleMailingCityTarget = (e: any, f: any) => {
    setCityMailingTarget(f.children)
  }

  const handleManualMonthYear = (e: any) => {
    setManualMonthYear(null)
    setMonthYear(e)
    setNullMonthYear(false)
  }

  const handleManualMonthYearEmployer = (e: any) => {
    setManualMonthYearEmployer(null)
    setMonthYearAtEmployer(e)
  }

  const handleMonthYear = (e: any) => {
    if (e !== null) {
      let startDate = e._d
      let newDate: any = moment().toDate()
      let monthYearDiff = newDate - startDate
      const toMonthYears = Number((monthYearDiff / (365) / (86400000)).toFixed(2))
      setMonthYear(toMonthYears)
      setManualMonthYear(null)
      setNullMonthYear(false)
    } else {
      if (manualMonthYears !== null) {
        setMonthYear(manualMonthYears)
        setNullMonthYear(false)
      }
    }
  }

  const handleMonthYearEmployer: DatePickerProps['onChange'] = (date, dateString) => {
    if (dateString !== null) {
      let startDate = new Date(dateString).getTime()
      let newDate: any = new Date().getTime()
      let monthYearDiff = newDate - startDate
      const toMonthYears = Number((monthYearDiff / (365) / (86400000)).toFixed(2))
      setMonthYearAtEmployer(toMonthYears)
      setManualMonthYearEmployer(null)
    } else {
      if (manualMonthYearEmployer !== null) {
        setMonthYearAtEmployer(manualMonthYearEmployer)
      }
    }
  };

  const handlePhoneChange = (e: any) => {
    setPhoneOption(e.target.value)
  }

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day');
  }

  const fillMailingAddress = (e: any) => {
    if (e.target.checked) {
      // Copy options
      setLesseeMailStateOptions(lesseeHomeStateOptions)
      setLesseeMailCountyOptions(lesseeHomeCountyOptions)
      setLesseeMailCityOptions(lesseeHomeCityOptions)
      setMailingCityId(homeCityId)
      setCityMailingTarget(cityTarget)
      setMailingCountyTarget(homeCountyTarget)
      setMailingStateTarget(stateTarget)

      // Auto fill fields
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          mailingAddressAttributes: {
            street1: lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'street1']),
            street2: lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'street2']),
            city: lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'city']),
            county: lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'county']),
            state: lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'state']),
            zipcode: lesseeForm.getFieldValue(['lesseeAttributes', 'homeAddressAttributes', 'zipcode']),
          },
        },
      })
    } else {
      // Clear options and fields
      setLesseeMailStateOptions([])
      setLesseeMailCountyOptions([])
      setLesseeMailCityOptions([])
      lesseeForm.setFieldsValue({
        lesseeAttributes: {
          mailingAddressAttributes: {
            street1: null,
            street2: null,
            cityId: null,
            county: null,
            state: null,
            zipcode: null,
            city: null
          },
        },
      })
    }
  }

  lesseeForm.setFieldsValue({
    street1: mailingAddress.street1,
    street2: mailingAddress.street2,
    zipcode: mailingAddress.zipcode,
    state: mailingAddress.state,
    county: mailingAddress.county,
    city: mailingAddress.city,
  })


  const handleValidator = () => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        monthYears: 'T'
      },
    })
  }

  const handleMonthYearEmployerValidator = () => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        monthYearAtEmployer: 'T'
      },
    })
  }

  const handleValidationReset = () => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        monthYears: null
      },
    })
  }

  const handleMonthYearEmployerResetValidator = () => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        monthYearAtEmployer: null
      },
    })
  }

  const onChangeDob: DatePickerProps['onChange'] = (date, dateString) => {
    let age = Math.floor((new Date().getTime() - new Date(dateString).getTime()) / 3.15576e+10);
    if (age < 18) {
      setDateOfBirthValidation('error');
      setDateOfBirthValidationMessage('Date of Birth must be 18 years old');
    }
    else if(dateString === null || dateString === '' || dateString === undefined){
      setLesseeDob(dateString);
      setDateOfBirthValidation('error');
      setDateOfBirthValidationMessage('Date of Birth is required!');
    }
    else {
      setLesseeDob(dateString);
      setDateOfBirthValidation(undefined);
      setDateOfBirthValidationMessage('');
    }
  };

  const handleZeroDefaultInputValue = (e:any)=>{
    if(e.target.value == 0) {
      e.target.value = ''
    }
  }

  return data ? (
    <>
      <ApplicationSteps stepType={`applicant`} leaseApplicationId={`${leaseApplicationId}`} leaseCalculatorId={`${leaseCalculatorId}`} save={null} attribute={btnAttribute} isSaved={checkIfSaved} data={data} isSubmitted={false} isAddingCoApplicant={false} />
      <div className="title-container">
        <div className="subtitle-container">
          <Typography variant="h3">Applicant</Typography>
          <p> Enter information about yourself to apply for a lease. </p>
          {
           canSubmitData === "false" &&  
           <Typography variant="h6" sx={{ fontWeight: 400 }} style={{color: "#e93b1b"}}>
            This dealership do not have permission to submit application.
          </Typography>
          }
        </div>
        <Form
          form={lesseeForm}
          {...layout}
          onFinish={handleSubmit}
          onChange={handleFormChange}
          scrollToFirstError={true}
          initialValues={{
            lesseeAttributes: {
              firstName: data?.lessee?.firstName?.toLocaleLowerCase(),
              middleName: data?.lessee?.middleName?.toLocaleLowerCase(),
              lastName: data?.lessee?.lastName?.toLocaleLowerCase(),
              dateOfBirth: (data?.lessee?.dateOfBirth === null || data?.lessee?.dateOfBirth === undefined) ? null : moment(data?.lessee?.dateOfBirth),
              ssn: data?.lessee?.ssn?.toLocaleLowerCase(),
              homePhoneNumber: data?.lessee?.homePhoneNumber?.toLocaleLowerCase(),
              mobilePhoneNumber: data?.lessee?.mobilePhoneNumber?.toLocaleLowerCase(),
              atAddressMonths: data?.lessee?.atAddressMonths || 0,
              atAddressYears: data?.lessee?.atAddressYears || 0,
              monthYears: data?.lessee?.monthYears,
              monthlyMortgage: data?.lessee?.monthlyMortgage,
              homeOwnership: data?.lessee?.homeOwnership,
              homeMobile: data?.lessee?.homeMobile,
              employerName: data?.lessee?.employerName?.toLocaleLowerCase(),
              employerPhoneNumber: data?.lessee?.employerPhoneNumber,
              employmentStatus: data?.lessee?.employmentStatusIndex,
              jobTitle: data?.lessee?.jobTitle?.toLocaleLowerCase(),
              timeAtEmployerYears: data?.lessee?.timeAtEmployerYears || 0,
              timeAtEmployerMonths: data?.lessee?.timeAtEmployerMonths || 0,
              monthYearAtEmployer: data?.lessee?.monthYearAtEmployer,
              grossMonthlyIncome: data?.lessee?.grossMonthlyIncome,
              driverLicense: data?.lessee?.driverLicense,
              motorcycleEndorsement: data?.lessee?.motorcycleEndorsement,
              firstTimeRider: data?.lessee?.firstTimeRider,
              emailAddress: data?.lessee?.emailAddress,
              licenseState:  data?.lessee?.licenseState,
              driversLicenseIdNumber:  data?.lessee?.driversLicenseIdNumber,
              homeAddressAttributes: {
                state: data?.lessee?.homeAddress?.newCity?.state,
                street1: data?.lessee?.homeAddress?.street1?.toLocaleLowerCase(),
                street2: data?.lessee?.homeAddress?.street2?.toLocaleLowerCase(),
                zipcode: data?.lessee?.homeAddress?.zipcode?.toLocaleLowerCase(),
                county: data?.lessee?.homeAddress?.newCity?.county,
                city: data?.lessee?.homeAddress?.newCity?.city
              },
              mailingAddressAttributes: {
                state: data?.lessee?.mailingAddress?.newCity?.state,
                street1: data?.lessee?.mailingAddress?.street1?.toLocaleLowerCase(),
                street2: data?.lessee?.mailingAddress?.street2?.toLocaleLowerCase(),
                zipcode: data?.lessee?.mailingAddress?.zipcode?.toLocaleLowerCase(),
                county: data?.lessee?.mailingAddress?.newCity?.county,
                city: data?.lessee?.mailingAddress?.newCity?.city
              },
              employmentAddressAttributes: {
                id: lessee?.employmentAddress?.id,
                city: lessee?.employmentAddress?.city?.toLocaleLowerCase(),
                state: lessee?.employmentAddress?.state?.toLocaleLowerCase(),
              },
            },
          }}
        >
          <Paper
            sx={{
              p: 2,
              margin: 2,
              marginBottom: 5,
              padding: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            {/* <Row gutter={[16, 40]}>
              <Col span={24} className="cca-center-text">
                <Typography variant="h3" >About You</Typography>
              </Col>
            </Row> */}

            <Collapse defaultActiveKey={['1', '2', '3']}>
              <Panel header="Personal" key="1">
                <Personal
                  data={data}
                  dateFormat={dateFormat}
                  dateOfBirthValidation={dateOfBirthValidation}
                  dateOfBirthValidationMessage={dateOfBirthValidationMessage}
                  lesseeDob={lesseeDob}
                  onChangeDob={onChangeDob}
                  lesseeForm={lesseeForm}
                  handlePhoneChange={handlePhoneChange}
                  lesseeOrColessee={"lessee"}
                  formValidation={formValidation}
                  motorcycleLicense={motorcycleLicense}
                  setMotorcycleLicense={setMotorcycleLicense}
                  handleLicenseState={handleLicenseState}
                  licenseFormat={licenseFormat}
                  licenseFormatMessage={licenseFormatMessage}
                />
              </Panel>
              <br/>
              <Panel header="Home Address" key="2">
                <HomeAddress
                  data={data}
                  dateFormat={dateFormat}
                  lesseeHomeStateOptions={lesseeHomeStateOptions}
                  autoCompleteLesseeHomeBeforePlacesGetDetails={autoCompleteLesseeHomeBeforePlacesGetDetails}
                  autoCompleteLesseeHomeOnPlacesGetDetailsResult={autoCompleteLesseeHomeOnPlacesGetDetailsResult}
                  handleChange={handleChange}
                  zipHomeValidateStatus={zipHomeValidateStatus}
                  zipHomeErrorMessage={zipHomeErrorMessage}
                  handleLesseeHomeZipcodeBlur={handleLesseeHomeZipcodeBlur}
                  showHomeState={showHomeState}
                  handleHomeStateChange={handleHomeStateChange}
                  handleStateTarget={handleStateTarget}
                  handleValidationReset={handleValidationReset}
                  showHomeCountyState={showHomeCountyState}
                  handleHomeCountyStateChange={handleHomeCountyStateChange}
                  handleCountyTarget={handleCountyTarget}
                  lesseeHomeCountyOptions={lesseeHomeCountyOptions}
                  showHomeCityState={showHomeCityState}
                  handleHomeCityStateChange={handleHomeCityStateChange}
                  handleCityTarget={handleCityTarget}
                  lesseeHomeCityOptions={lesseeHomeCityOptions}
                  handleValidator={handleValidator}
                  handleMonthYear={handleMonthYear}
                  disabledDate={disabledDate}
                  handleManualMonthYear={handleManualMonthYear}
                  monthYears={monthYears}
                  nullMonthYear={nullMonthYear}
                  lesseeOrColessee = {"lessee"}
                  formValidation={formValidation}
                  handleZeroDefaultInputValue={handleZeroDefaultInputValue}
                />
              </Panel>
              <br/>
              <Panel header="Mailing Address" key="3">
                <MailingAddress
                  fillMailingAddress={fillMailingAddress}
                  autoCompleteLesseeMailingBeforePlacesGetDetails={autoCompleteLesseeMailingBeforePlacesGetDetails}
                  autoCompleteLesseeMailingOnPlacesGetDetailsResult={autoCompleteLesseeMailingOnPlacesGetDetailsResult}
                  zipMailValidateStatus={zipMailValidateStatus}
                  zipMailErrorMessage={zipMailErrorMessage}
                  handleLesseeMailZipcodeBlur={handleLesseeMailZipcodeBlur}
                  showMailingState={showMailingState}
                  handleMailingStateChange={handleMailingStateChange}
                  handleMailingStatetarget={handleMailingStatetarget}
                  lesseeMailStateOptions={lesseeMailStateOptions}
                  showMailingCountyState={showMailingCountyState}
                  handleMailingCountyStateChange={handleMailingCountyStateChange}
                  handleMailingCountytarget={handleMailingCountytarget}
                  lesseeMailCountyOptions={lesseeMailCountyOptions}
                  showMailingCityState={showMailingCityState}
                  handleMailingCityStateChange={handleMailingCityStateChange}
                  handleMailingCityTarget={handleMailingCityTarget}
                  lesseeMailCityOptions={lesseeMailCityOptions}
                  lesseeOrColessee = {"lessee"}
                />
              </Panel>
            </Collapse>
          </Paper>

          <Paper
            sx={{
              p: 2,
              margin: 2,
              marginBottom: 5,
              padding: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            {/* <Row gutter={[16, 16]}>
              <Col span={24} className="cca-center-text2">
                <Typography variant="h3">About Your Work</Typography>
                <br />
              </Col>
            </Row> */}
       
              <Collapse defaultActiveKey={['1', '2', '3']} >
                <Panel header="Employment Details" key="2">
                  <EmploymentDetails
                    data={data}
                    handleEmploymentStatus={handleEmploymentStatus}
                    employmentStatusOptions={employmentStatusOptions}
                    requireEmploymentFields={requireEmploymentFields}
                    dateFormat={dateFormat}
                    handleMonthYearEmployerResetValidator={handleMonthYearEmployerResetValidator}
                    handleMonthYearEmployerValidator={handleMonthYearEmployerValidator}
                    handleMonthYearEmployer={handleMonthYearEmployer}
                    disabledDate={disabledDate}
                    handleManualMonthYearEmployer={handleManualMonthYearEmployer}
                    monthYearAtEmployer={monthYearAtEmployer}
                    lesseeOrColessee = {"lessee"}
                    setEmploymentYearOrMonth={setEmploymentYearOrMonth}
                    formValidation={formValidation}
                    employmentYearLabel={employmentYearLabel}
                    employmentMonthLabel={employmentMonthLabel}
                    handleZeroDefaultInputValue={handleZeroDefaultInputValue}
                  />
                </Panel>
                <br/>
                <Panel header="Employer" key="1">
                  <Employer
                    requireEmploymentFields={requireEmploymentFields}
                    lessee={lessee}
                    handleFormChange={handleFormChange}
                    lesseeOrColessee = {"lessee"}
                    formValidation={formValidation}
                  />
                </Panel>
              </Collapse>
           <br/>
            <div className="button-container">
                 <Button variant="contained" style={{backgroundColor: "#e93b1b", marginRight:"3%"}} type="submit" disabled={disableSubmitBtn} onClick={() => {
                   setFormValidation(false)
                 }} >
                   Save Applicant
                 </Button>
                 <Button variant="contained" style={{backgroundColor: "#e93b1b", marginRight:"3%"}} type="submit" disabled={disableSubmitBtn} onClick={() => {
                   setFormValidation(true)
                   handleRedirectionClick('co-applicant')
                 }} >
                    Go to Co-Applicant Page
                </Button>
                <Button variant="contained" style={{backgroundColor: "#e93b1b"}} type="submit" disabled={disableSubmitBtn} onClick={() => {
                  setFormValidation(true)
                  handleRedirectionClick('review-submit')
                }} >
                    Review and Submit
                </Button>
              </div>
          </Paper>
        </Form>
      </div>
    </>
  ) : null
}

export default Applicant