import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './Store';
import { Provider } from "react-redux";

async function main() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={Store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}
main()

reportWebVitals();

