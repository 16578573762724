import React, { useEffect } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SummaryBike from './SummaryBike';
import SummaryPersonalInfo from './SummaryPersonalInfo';
import SummaryAddress from './SummaryAddress';
import SummaryEmployeeCard from './SummaryEmployeeCard';
import { Typography, styled } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function SummaryTabs(props: any) {
  const { leaseApplicationId, data, colesseeHasValue, isSubmitted, tabsData, ssnData, isAddingCoApplicant } = props
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab label="Applicant" {...a11yProps(0)} />
          {colesseeHasValue() != false &&
            <StyledTab label="Co-Applicant" {...a11yProps(2)} />
          }
          <StyledTab label="Bike Information" {...a11yProps(colesseeHasValue() != false ? 2 : 1)} />
        </StyledTabs>
      </StyledBox>

      <TabPanel value={value} index={0}>
        <Grid container direction="row">
          <Grid item md={12} container spacing={2} >
            <Grid item md={12} sm={12} lg={4} >
              <SummaryPersonalInfo
                data={tabsData}
                leaseApplicationId={leaseApplicationId}
                applicantFlag={1}
                isSubmitted={isSubmitted}
                isAddingCoApplicant={isAddingCoApplicant}
              ></SummaryPersonalInfo>
            </Grid>
            <Grid item md={12} sm={12} lg={4}>
              <SummaryAddress
                data={tabsData}
                leaseApplicationId={leaseApplicationId}
                applicantFlag={1}
                isSubmitted={isSubmitted}
                isAddingCoApplicant={isAddingCoApplicant}
              />
            </Grid>
            <Grid item md={12} sm={12} lg={4}>
              <SummaryEmployeeCard
                data={tabsData}
                leaseApplicationId={leaseApplicationId}
                applicantFlag={1}
                isSubmitted={isSubmitted}
                isAddingCoApplicant={isAddingCoApplicant}
              />
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={colesseeHasValue() != false ? 2 : 1}>
        <Grid container direction="row">
          <Grid item container spacing={2} direction="row" justifyContent="center" alignItems="center" >
            <Grid item md={6} lg={5} xl={5} sm >
              <SummaryBike data={data}></SummaryBike>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>

      {colesseeHasValue() != false &&
        <TabPanel value={value} index={1}>
          <Grid container direction="row">
            <Grid item md={12} container spacing={2} >
              <Grid item md={12} sm={12} lg={4}>
                <SummaryPersonalInfo
                  data={tabsData}
                  leaseApplicationId={leaseApplicationId}
                  applicantFlag={2}
                  isSubmitted={isSubmitted}
                  isAddingCoApplicant={isAddingCoApplicant}
                />
              </Grid>
              <Grid item md={12} sm={12} lg={4}>
                <SummaryAddress
                  data={tabsData}
                  leaseApplicationId={leaseApplicationId}
                  applicantFlag={2}
                  isSubmitted={isSubmitted}
                  isAddingCoApplicant={isAddingCoApplicant}
                />
              </Grid>
              <Grid item md={12} sm={12} lg={4}>
                <SummaryEmployeeCard
                  data={tabsData}
                  leaseApplicationId={leaseApplicationId}
                  applicantFlag={2}
                  isSubmitted={isSubmitted}
                  isAddingCoApplicant={isAddingCoApplicant}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      }
    </>
  )
}


const StyledTab = styled(Tab)({
  color: "#fff",
  "&.Mui-selected": {
    color: "#e93b1b",
    borderBlockColor: "#e93b1b !important",
  }
})

const StyledTabs = styled(Tabs)({
  backgroundColor: "black"

})

const StyledBox = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider'

})