import React from 'react'
import { NewApplication } from './Application'

function NewApplicationRender() {
    return (
        <div>
            <NewApplication />
        </div>
    )
}

export default NewApplicationRender
