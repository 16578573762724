import {
    Grid,
    FormControl,
    Paper,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';

export const Notes = (props: any) => {
    const { formValues, handleChange } = props;
    return (
        <>
            <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                <Grid container direction="row" sx={{ marginBottom: 4 }} spacing={2} >
                    <Grid container item md={12} >
                        <Typography sx={{ fontWeight: "bold", color: "#e93b1b" }}> Notes</Typography>
                    </Grid>
                    <Grid container item md={12} spacing={2}>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Notes"
                                    minRows={5}
                                    style={{ width: "100%" }}
                                    name="notes"
                                    value={formValues.notes.value}
                                    // defaultValue={formValues.notes.value}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}


export default Notes;

const StyledTextareaAutosize = styled(TextareaAutosize)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
})