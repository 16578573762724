import {Layout} from 'antd';
import {Grid} from '@mui/material';
const {Content} = Layout;

export default function VideoRenderer() {
  const videoSource = window.location.search.charAt(0) === '?' ? window.location.search.substring(1) : window.location.search;

  return (
    <Layout id="content-area-layout">
      <Content id="main-content">
        <Grid container>
          <video controls src={videoSource} width="1230px" height="650px">
            Video not supported
          </video>
        </Grid>
      </Content>
    </Layout>
  )
}
