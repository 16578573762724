import React from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Row, Steps, Layout, notification, Typography } from 'antd'
import Icon from '@ant-design/icons'
import { SolutionOutlined, UserOutlined, TeamOutlined, PoweroffOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { MotorSvg } from '../../../../utils/Svg'
import '../styles/NewApplication.css'
import Logo from '../../../layouts/Logo'

const { Step } = Steps
const { Header } = Layout
const { Text } = Typography

interface Props {
  stepType: string
  leaseApplicationId: string
  leaseCalculatorId: string
  save: any
  attribute?: boolean
  isSaved?: boolean
  isSubmitted?: boolean
  data?: any
  isAddingCoApplicant?: boolean
}

export const ApplicationSteps: React.FC<Props> = ({ stepType, leaseApplicationId, leaseCalculatorId, attribute , isSaved, isSubmitted, data , isAddingCoApplicant }) => {

  const history = useHistory()

  let applicantUrl: string = `/applications/${leaseApplicationId.trim()}`

  const handleBikeStep = () => {
    setStepRedirect(applicantUrl, `bike`)
  }

  const handleApplicantStep = () => {
    setStepRedirect(applicantUrl, `applicant`)
  }

  const handleCoApplicantStep = () => {
    setStepRedirect(applicantUrl, `co-applicant`)
  }

  const handleSummaryStep = () => {
    setStepRedirect(applicantUrl, `summary`)
  }

  const setStepRedirect = (url: string, page: string) => {
    history.push(`${url}/${page}`)
  }

  const location = useLocation()

  const handleEmptyForm = (e: any) => {
    const rawPathName = location.pathname
    const pathName = rawPathName.replace(/^.*[\\\/]/, '')
    const targetName = e.target.innerText
    if (targetName === undefined){
      return
    }

    switch (pathName) {
      case 'bike':
        if (targetName == 'Applicant' && isSaved) {
          setStepRedirect(applicantUrl, `applicant`)
        }else if(targetName == 'Co-Applicant' && isSaved){
          setStepRedirect(applicantUrl, `co-applicant`)
        }else if(targetName == 'Summary' && isSaved){
          setStepRedirect(applicantUrl, `summary`)
        } else if(targetName == 'Bike'){
        } else {
          notification.open({
            message: <Text type="warning">Please Fill Out The Form</Text>,
            duration: 3,
            icon: <InfoCircleOutlined style={{ color: `#faad14` }} />,
            placement: 'topRight',
          })
        }
        break
      case 'applicant':
        if (targetName == 'Bike'){
          setStepRedirect(applicantUrl, `bike`)
        } else if (targetName == 'Co-Applicant' && isSaved){
          setStepRedirect(applicantUrl, `co-applicant`)
        }else if(targetName == 'Summary' && isSaved){
          setStepRedirect(applicantUrl, `summary`)
        }else if(targetName == 'Applicant'){
        } else {
          notification.open({
            message: <Text type="warning">Please Fill Out The Form</Text>,
            duration: 3,
            icon: <InfoCircleOutlined style={{ color: `#faad14` }} />,
            placement: 'topRight',
          })
        }
        break
      case 'co-applicant':
        if (targetName == 'Applicant' && !isAddingCoApplicant) {
          setStepRedirect(applicantUrl, `applicant`)
        } else if (targetName == 'Bike' && !isAddingCoApplicant) {
          setStepRedirect(applicantUrl, `bike`)
        } else if(targetName == 'Co-Applicant'){
        } else if(targetName == 'Summary' && (isSaved || !isAddingCoApplicant)){
          setStepRedirect(applicantUrl, `summary?add_co_applicant=${isAddingCoApplicant}`)
        } else {
          notification.open({
            message: <Text type="warning">Please Fill Out The Form</Text>,
            duration: 3,
            icon: <InfoCircleOutlined style={{ color: `#faad14` }} />,
            placement: 'topRight',
          })
        } 
        break
      case 'summary':
        if (isAddingCoApplicant === true) {
        } else if (targetName == 'Co-Applicant' && !isSubmitted) {
          setStepRedirect(applicantUrl, `co-applicant`)
        } else if (targetName == 'Applicant' && !isSubmitted) {
          setStepRedirect(applicantUrl, `applicant`)
        } else if (targetName == 'Bike' && !isSubmitted) {
          setStepRedirect(applicantUrl, `bike`)
        } else if (targetName == 'Summary') {
        } else {
          notification.open({
            message: <Text type="warning" style={{marginRight: '5px'}}>The application is already Submitted</Text>,
            duration: 3,
            icon: <InfoCircleOutlined style={{ color: `#faad14` }} />,
            placement: 'topRight',
          })
        }
        break

      default:
        notification.open({
          message: <Text type="warning">Please Fill Out The Form</Text>,
          duration: 3,
          icon: <InfoCircleOutlined style={{ color: `#faad14` }} />,
          placement: 'topRight',
        })
    }
  }

  const applicationSteps = (e: any) => {
    if(leaseApplicationId !== 'undefined')
    {
      if(attribute){
        return handleEmptyForm(e)
      }else{
        handleBikeStep()
      }
    }
  }

  return (
    <Header className="main-header-layout" id="main-menu">
      <Row>
        <Col md={3}  lg={5} xl={6} xxl={6}>
         <Logo />
        </Col>
        <Col md={18} lg={13} xl={12} xxl={12}>
          <Steps size="small">
            <Step
              onClick={(e) => applicationSteps(e)}
              className={`application-steps application-steps-wait ${stepType === 'bike' && 'application-steps-process'}`}
              status="process"
              title="Bike"
              icon={<Icon component={MotorSvg} />}
            />
            <Step
              onClick={(e) => applicationSteps(e)}
              className={`application-steps application-steps-wait ${stepType === 'applicant' && 'application-steps-process'}`}
              status="wait"
              title="Applicant"
              icon={<UserOutlined />}
            />
            <Step
              onClick={(e) => applicationSteps(e)}
              className={`application-steps application-steps-wait ${stepType === 'co-applicant' && 'application-steps-process'}`}
              status="wait"
              title="Co-Applicant"
              icon={<TeamOutlined />}
            />
            <Step
              onClick={(e) => applicationSteps(e)}
              className={`application-steps application-steps-wait ${stepType === 'summary' && 'application-steps-process'}`}
              status="wait"
              title="Summary"
              icon={<SolutionOutlined />}
            />
          </Steps>
        </Col>
        <Col md={3} lg={6} xl={6} xxl={6}>
          <span className="exit-btn">
            <Link to={`/`} className="steps-exit-btn">
              <PoweroffOutlined />
              EXIT
            </Link>
          </span>
        </Col>
      </Row>
    </Header>
  )
}

export default ApplicationSteps
