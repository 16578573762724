import { Input, Select, InputNumber, Form, Radio, Space } from 'antd'
import '../../styles/Applicant.css'
import { Grid } from '@mui/material'

const { Option } = Select

interface OptionData {
  value?: string | number
  label?: string
  parentId?: number
}

function EmploymentDetails(props: any) {
  const {
    handleEmploymentStatus, employmentStatusOptions, requireEmploymentFields, lesseeOrColessee, formValidation, employmentYearLabel, employmentMonthLabel, handleZeroDefaultInputValue} = props
    // console.log('employment field, formValidation', requireEmploymentFields, formValidation);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="Employment Status"
           name={[`${lesseeOrColessee}Attributes`, 'employmentStatus']}
            rules={[{ required: formValidation, message: 'Status is required!' }]}>
            <Select 
            showSearch
            placeholder="Employment Status"
            onChange={handleEmploymentStatus}
            optionFilterProp="children"
            className="space-up">
              {employmentStatusOptions &&
                employmentStatusOptions.map((obj: OptionData, index: number) => {
                  return (
                    <Option key={index} value={`${obj.value}`}>
                      {obj.label}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="Job Title"
           name={[`${lesseeOrColessee}Attributes`, 'jobTitle']}
           rules={[{ required: requireEmploymentFields && formValidation,
                     message: 'Job Title is required!' }]}>
            <Input placeholder="Job Title" className="ant-input-comp space-up" />
          </Form.Item>
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <Form.Item
            label="Gross Monthly Income"
            name={[`${lesseeOrColessee}Attributes`, 'grossMonthlyIncome']}
            rules={[{ required: formValidation, message: 'Gross Monthly Income is required!' }]}
          >
            <InputNumber onClick={handleZeroDefaultInputValue} className="ant-input-comp space-up" placeholder="Gross Monthly Income"
                       formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                       parser={value => `${value}`.replace(/(\,*)|/g, '')} />
          </Form.Item>
        </Grid>

        <Grid item xs={1} sm={2} md={2}>
            <Form.Item
                label={employmentYearLabel}
                name={[`${lesseeOrColessee}Attributes`, 'timeAtEmployerYears']}
                rules={[{ required: formValidation, message: 'Time at Employer Years is required!' }]}>
              <Input onClick={handleZeroDefaultInputValue} type='number'/>
            </Form.Item>
        </Grid>
        <Grid item xs={1} sm={2} md={2}>
          <Form.Item
              label={employmentMonthLabel}
              name={[`${lesseeOrColessee}Attributes`, 'timeAtEmployerMonths']}
              rules={[{ required: formValidation, message: 'Time At Employer Months is required!' }]}>
            <Input onClick={handleZeroDefaultInputValue} type='number'/>
          </Form.Item>
        </Grid>
      </Grid>
      <br />
    </>
  )
}

export default EmploymentDetails
