import * as React from 'react';
import { FormControl, TextField, Paper, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';

export const ConfirmationDialouge = (props: any) => {
    const { handleChange, formValues, setFormValues, openDocusign } = props;
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: "red" },
                },
            },
        },
    })

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenConfirmation = () => {
        setOpenConfirmation(true);
    };


    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    };
    const handleCloseConfirmationWithNo = () => {
        setOpenConfirmation(false);
        setOpen(true);
    };

    const handleCheckEmails = () => {
        setFormValues({
            ...formValues,
            lessee_email: {
                ...formValues.lessee_email,
                error: false,
            },
            dealer_email: {
                ...formValues.dealer_email,
                error: false,
            },
            docusign_email_changed: {
                value: true
            }
        })

        if (formValues.lessee_email.value === '') {
            setFormValues({
                ...formValues,
                lessee_email: {
                    ...formValues.lessee_email,
                    error: true,
                    errorMessage: 'Please enter a valid lessee email'
                }
            })
        }
        else if (formValues.dealer_email.value === '') {
            setFormValues({
                ...formValues,
                dealer_email: {
                    ...formValues.dealer_email,
                    error: true,
                    errorMessage: 'Please enter a valid dealer email'
                }
            })
        } else {
            setOpen(false);
        }
    }


    React.useEffect(() => {
        handleClickOpenConfirmation()
        if(openDocusign === true) {
            setOpen(true);
        }
    }, [openDocusign])

    return (
        <>
         <ThemeProvider theme={theme}>
         <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                <>
                    <div>
                        <Dialog
                            open={openConfirmation}
                            onClose={handleCloseConfirmation}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Do you want to use these emails for Docusign?"}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item md={4}>
                                        Lessee Email:
                                    </Grid>
                                    <Grid item md={6}>
                                        {formValues.lessee_email.value || ''}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item md={4}>
                                        Colessee Email:
                                    </Grid>
                                    <Grid item md={6}>
                                        {formValues.colessee_email.value || 'N/A'}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item md={4}>
                                        Dealer Email:
                                    </Grid>
                                    <Grid item md={6}>
                                        {formValues.dealer_email.value || ''}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button  variant="contained" style={{backgroundColor: "#e93b1b"}} onClick={handleCloseConfirmationWithNo}>NO</Button>
                                <Button  variant="contained" style={{backgroundColor: "#e93b1b"}} onClick={handleCloseConfirmation} autoFocus>
                                    YES
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <Button variant="contained" style={{backgroundColor: "#e93b1b"}}  onClick={handleClickOpen}>
                        Docusign Emails
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle> Do you want to use these emails for Docusign ? </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                To send docusign these emails will be used. Please update emails if necessary. We will send an email with
                                these emails.
                            </DialogContentText>
                            <div style={{ height: '20px' }}></div>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    required
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Lessee Email"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                    name="lessee_email"
                                    value={formValues.lessee_email.value}
                                    onChange={handleChange}
                                    error={formValues.lessee_email.error}
                                    helperText={formValues.lessee_email.error && formValues.lessee_email.errorMessage}
                                    InputLabelProps={{ style: { fontSize: 18 } }}
                                />
                            </FormControl>
                            <div style={{ height: '15px' }}></div>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    autoFocus
                                    id="filled-input"
                                    type="email"
                                    label={"Colessee Email"}
                                    variant="standard"
                                    size="medium"
                                    name="colessee_email"
                                    value={formValues.colessee_email.value}
                                    onChange={handleChange}
                                    InputLabelProps={{ style: { fontSize: 18 } }}
                                />
                            </FormControl>
                            <div style={{ height: '15px' }}></div>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    required
                                    autoFocus
                                    id="filled-input"
                                    type="email"
                                    label={"Dealer Email"}
                                    variant="standard"
                                    size="medium"
                                    name="dealer_email"
                                    value={formValues.dealer_email.value}
                                    onChange={handleChange}
                                    error={formValues.dealer_email.error}
                                    helperText={formValues.dealer_email.error && formValues.dealer_email.errorMessage}
                                    InputLabelProps={{ style: { fontSize: 18 } }}
                                />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" style={{backgroundColor: "#e93b1b"}}  onClick={handleClose}>Cancel</Button>
                            <Button  variant="contained" style={{backgroundColor: "#e93b1b"}}  onClick={handleCheckEmails}>Save</Button>
                        </DialogActions>
                    </Dialog>
                </>
            </Paper>
         </ThemeProvider>
        </>
    )
}

export default ConfirmationDialouge;

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
})
