import {Card, Col, Row, Typography} from "antd";
import React from "react";
import { styled } from '@mui/material'
import BikePriceInfo from "./SalesEconomicsInfo/BikePriceInfo";
import BackendProductsInfo from "./SalesEconomicsInfo/BackendProductsInfo";
import TradeInfo from "./SalesEconomicsInfo/TradeInfo";
const { Title } = Typography;

export const SalesEconomics = (props: any) => {
    const {calculatorData, convertUsd, backendProductsColor, gpsCostColor, tooltipColor, disabledForm  } = props;

    return (
        <StyledCard className="card-header" title="Deal Structure" bordered={true}>
            <BikePriceInfo calculatorData={calculatorData} convertUsd={convertUsd} disabledForm={disabledForm}/>
            <BackendProductsInfo backendProductsColor={backendProductsColor} tooltipColor={tooltipColor} gpsCostColor={gpsCostColor} disabledForm={disabledForm}/>
            <TradeInfo calculatorData={calculatorData} convertUsd={convertUsd} disabledForm={disabledForm}/>
        </StyledCard>
    )
}

const StyledCard = styled(Card)`
.ant-card-head-title {
  font-size: 20px;
}
`;
export default SalesEconomics
