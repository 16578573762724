import React from 'react';

export const AttachmentSvg = () => (
  <svg viewBox="0 0 24 24" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M18.5 16H7c-2.21 0-4-1.79-4-4s1.79-4 4-4h12.5c1.38 0 2.5 1.12 2.5 2.5S20.88 13 19.5 13H9c-.55 0-1-.45-1-1s.45-1 1-1h9.5V9.5H9c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h10.5c2.21 0 4-1.79 4-4s-1.79-4-4-4H7c-3.04 0-5.5 2.46-5.5 5.5s2.46 5.5 5.5 5.5h11.5V16z"/>
  </svg>
);

export const AddNew = () => (
  <svg viewBox="0 0 512 512" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M438.2,0H51.6C23.1,0,0,23.2,0,51.6v386.6c0,28.5,23.2,51.6,51.6,51.6h386.6c28.5,0,51.6-23.2,51.6-51.6V51.6
      C489.8,23.2,466.6,0,438.2,0z M465.3,438.2c0,14.9-12.2,27.1-27.1,27.1H51.6c-14.9,0-27.1-12.2-27.1-27.1V51.6
      c0-14.9,12.2-27.1,27.1-27.1h386.6c14.9,0,27.1,12.2,27.1,27.1V438.2z"/>
    <path d="M337.4,232.7h-80.3v-80.3c0-6.8-5.5-12.3-12.3-12.3s-12.3,5.5-12.3,12.3v80.3h-80.3c-6.8,0-12.3,5.5-12.3,12.2
      c0,6.8,5.5,12.3,12.3,12.3h80.3v80.3c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-80.3h80.3c6.8,0,12.3-5.5,12.3-12.3
      C349.7,238.1,344.2,232.7,337.4,232.7z"/>
</svg>
);

export const AuditSvg = () => (
  <svg viewBox="0 0 1024 1024" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M296 250c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 144H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-48 458H208V148h560v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm440-88H728v-36.6c46.3-13.8 80-56.6 80-107.4c0-61.9-50.1-112-112-112s-112 50.1-112 112c0 50.7 33.7 93.6 80 107.4V764H520c-8.8 0-16 7.2-16 16v152c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V780c0-8.8-7.2-16-16-16zM646 620c0-27.6 22.4-50 50-50s50 22.4 50 50s-22.4 50-50 50s-50-22.4-50-50zm180 266H566v-60h260v60z"/>
  </svg>
);

export const BannerSvg = () => (
  <svg viewBox="0 0 512 512" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M481.806,286.348l29.618-85.491c1.108-3.197,0.6-6.471-1.363-9.227c-1.962-2.757-5.136-4.132-8.519-4.132H397.186V135.73
      c0-5.776-4.455-9.935-10.231-9.935H125.499c-5.776,0-10.685,4.159-10.685,9.935v51.768H10.519c-3.382,0-6.556,1.374-8.518,4.13
      c-1.962,2.755-2.471,6.159-1.366,9.355l29.558,85.392l-29.617,85.46c-1.107,3.197-0.6,6.977,1.363,9.734
      c1.962,2.757,5.136,4.638,8.519,4.638h115.041c5.776,0,10.231-5.205,10.231-10.981v-50.722h240.54v50.722
      c0,5.776,4.909,10.981,10.685,10.981H501.48c3.382,0,6.556-1.897,8.518-4.653c1.962-2.755,2.471-6.42,1.366-9.616L481.806,286.348
      z M25.149,365.289l25.993-75.292c0.768-2.216,0.768-4.495,0.001-6.711l-25.942-74.871h89.611v156.874H25.149z M156.647,303.586
      H135.73V197.434v-50.722h20.917V303.586z M334.437,303.586H177.563V146.712h156.874V303.586z M376.27,197.434v106.151h-20.916
      V146.712h20.916V197.434z M397.186,365.289V208.416h89.664l-25.993,74.769c-0.768,2.216-0.768,4.756-0.001,6.972l25.942,75.132
      H397.186z"/>
  </svg>
);

export const BallotSvg = () => (
  <svg viewBox="0 0 24 24" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M13 7.5h5v2h-5zm0 7h5v2h-5zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM11 6H6v5h5V6zm-1 4H7V7h3v3zm1 3H6v5h5v-5zm-1 4H7v-3h3v3z"/>
  </svg>
);

export const CalculatorSvg = () => (
  <svg viewBox="0 0 1024 1024" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M251.2 387H320v68.8c0 1.8 1.8 3.2 4 3.2h48c2.2 0 4-1.4 4-3.3V387h68.8c1.8 0 3.2-1.8 3.2-4v-48c0-2.2-1.4-4-3.3-4H376v-68.8c0-1.8-1.8-3.2-4-3.2h-48c-2.2 0-4 1.4-4 3.2V331h-68.8c-1.8 0-3.2 1.8-3.2 4v48c0 2.2 1.4 4 3.2 4zm328 0h193.6c1.8 0 3.2-1.8 3.2-4v-48c0-2.2-1.4-4-3.3-4H579.2c-1.8 0-3.2 1.8-3.2 4v48c0 2.2 1.4 4 3.2 4zm0 265h193.6c1.8 0 3.2-1.8 3.2-4v-48c0-2.2-1.4-4-3.3-4H579.2c-1.8 0-3.2 1.8-3.2 4v48c0 2.2 1.4 4 3.2 4zm0 104h193.6c1.8 0 3.2-1.8 3.2-4v-48c0-2.2-1.4-4-3.3-4H579.2c-1.8 0-3.2 1.8-3.2 4v48c0 2.2 1.4 4 3.2 4zm-195.7-81l61.2-74.9c4.3-5.2.7-13.1-5.9-13.1H388c-2.3 0-4.5 1-5.9 2.9l-34 41.6l-34-41.6a7.85 7.85 0 0 0-5.9-2.9h-50.9c-6.6 0-10.2 7.9-5.9 13.1l61.2 74.9l-62.7 76.8c-4.4 5.2-.8 13.1 5.8 13.1h50.8c2.3 0 4.5-1 5.9-2.9l35.5-43.5l35.5 43.5c1.5 1.8 3.7 2.9 5.9 2.9h50.8c6.6 0 10.2-7.9 5.9-13.1L383.5 675zM880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-36 732H180V180h664v664z"/>
  </svg>
);

export const DollarBillSvg = () => (
  <svg viewBox="0 0 584.877 584.877" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
		<path d="M566.395,135.219H18.477c-5.006,0-9.339,1.824-12.993,5.484C1.824,144.362,0,148.689,0,153.695v277.487    c0,5.006,1.83,9.339,5.484,12.992c3.653,3.66,7.986,5.483,12.993,5.483H566.4c5,0,9.332-1.823,12.992-5.483    c3.654-3.653,5.484-7.986,5.484-12.992V153.689c0-5-1.83-9.333-5.484-12.993C575.732,137.049,571.4,135.219,566.395,135.219z     M547.924,338.801c-20.404,0-37.816,7.216-52.252,21.652c-14.432,14.438-21.652,31.849-21.652,52.253H110.852    c0-20.398-7.215-37.815-21.652-52.253c-14.431-14.437-31.854-21.652-52.252-21.652v-92.73c20.398,0,37.815-7.215,52.252-21.652    c14.437-14.437,21.652-31.842,21.652-52.252H474.02c0,20.404,7.215,37.815,21.652,52.252    c14.43,14.438,31.848,21.652,52.252,21.652V338.801z"/>
		<path d="M306.471,268.127v-39.26c4.309,3.207,7.045,7.993,8.312,14.498c0.318,1.592,1.879,2.632,3.402,2.448l30.307-3.953    c0.832-0.11,1.578-0.545,2.068-1.218c0.494-0.668,0.691-1.519,0.551-2.338c-2.174-12.583-7.314-22.81-15.275-30.386    c-7.418-7.062-17.283-11.579-29.365-13.446v-9.192c0-1.689-1.371-3.06-3.059-3.06h-17.369c-1.689,0-3.06,1.371-3.06,3.06v9.143    c-13.183,1.824-23.997,7.209-32.185,16.028c-8.776,9.449-13.219,21.255-13.219,35.086c0,13.599,3.917,25.349,11.634,34.927    c7.326,9.082,18.678,16.034,33.77,20.667v42.424c-2.203-1.707-4.29-3.855-6.242-6.426c-3.213-4.235-5.447-9.382-6.628-15.281    c-0.306-1.549-1.658-2.583-3.329-2.442l-31.267,3.354c-0.833,0.092-1.585,0.515-2.099,1.176c-0.508,0.661-0.722,1.505-0.6,2.325    c2.484,16.322,8.335,29.187,17.393,38.244c8.519,8.513,19.529,13.874,32.766,15.948v19.138c0,1.689,1.371,3.06,3.06,3.06h17.37    c1.688,0,3.059-1.37,3.059-3.06v-19.786c15.014-2.632,27.02-8.947,35.717-18.795c9.211-10.435,13.887-23.408,13.887-38.562    c0-13.623-3.746-24.988-11.139-33.776C337.781,280.165,325.186,273.439,306.471,268.127z M320.596,326.757    c0,5.287-1.604,9.755-4.908,13.653c-2.521,2.98-5.562,5.08-9.223,6.347v-38.52c4.541,1.744,7.926,3.96,10.08,6.616    C319.281,318.194,320.596,322.093,320.596,326.757z M273.644,244.1c0-4.002,1.175-7.57,3.611-10.899    c1.573-2.16,3.464-3.886,5.729-5.22v31.696c-2.558-1.598-4.584-3.446-6.053-5.545C274.715,251.003,273.644,247.717,273.644,244.1z"/>
  </svg>
);

export const CoLesseeSvg = () => (
  <svg viewBox="0 0 512 512" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M119.2,143.944c-29.456,0-53.424,24.448-53.424,54.512c0,30.064,23.968,54.496,53.424,54.496s53.408-24.448,53.408-54.512
    C172.608,168.376,148.656,143.944,119.2,143.944z M119.2,236.968c-20.64,0-37.424-17.28-37.424-38.512
    s16.784-38.512,37.424-38.512c20.624,0,37.408,17.28,37.408,38.512S139.824,236.968,119.2,236.968z"/>
    <path d="M171.744,269.4l-3.952-1.216L119.2,305.672l-48.592-37.504l-3.952,1.216C-0.064,289.896,0,359.464,0,360.168l0.096,7.904
    h238.208l0.096-7.904C238.416,359.464,238.48,289.896,171.744,269.4z M16.608,352.072c2.096-16,11.184-51.872,50.88-66.096
    l51.712,39.92l51.696-39.92c40.08,14.32,48.976,50.064,50.944,66.096H16.608z"/>
    <path d="M392.8,143.928c-29.456,0-53.408,24.448-53.408,54.512c0,30.064,23.952,54.512,53.408,54.512s53.424-24.448,53.424-54.512
    C446.224,168.376,422.256,143.928,392.8,143.928z M392.8,236.968c-20.624,0-37.408-17.28-37.408-38.512
    s16.784-38.512,37.408-38.512c20.64,0,37.424,17.28,37.424,38.512S413.44,236.968,392.8,236.968z"/>
    <path d="M445.344,269.4l-3.952-1.216L392.8,305.688l-48.576-37.504l-3.952,1.216c-66.736,20.512-66.672,90.064-66.656,90.768
    l0.096,7.904h238.192l0.096-7.904C512,359.464,512.064,289.896,445.344,269.4z M290.224,352.072
    c2.08-16,11.168-51.872,50.864-66.096l51.696,39.92l51.712-39.92c40.08,14.32,48.976,50.064,50.944,66.096H290.224z"/>
  </svg>
);

export const CreditSvg = () => (
  <svg viewBox="0 0 24 24" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/>
  </svg>
);

export const ExclamationCircleOutlinedSvg = () => (
  <svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
  </svg>
);

export const ExternalLink = () => (
  <svg width="13.5" height="13.5" aria-hidden="true" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/>
  </svg>
);

export const FileErrorSvg = () => (
  <svg viewBox="0 0 512 512" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <g>
        <path d="M21.333,21.333h213.333v96c0,5.867,4.8,10.667,10.667,10.667h96v74.667h21.333v-85.333c0-2.88-1.173-5.547-3.093-7.573
          L252.907,3.093C250.88,1.173,248.213,0,245.333,0H10.667C4.8,0,0,4.8,0,10.667v448c0,5.867,4.8,10.667,10.667,10.667H224V448
          H21.333V21.333z M256,36.373l70.293,70.293H256V36.373z"/>
        <path d="M510.827,496.533l-128-256c-3.627-7.253-15.467-7.253-19.093,0l-128,256c-2.667,5.227-0.533,11.627,4.8,14.293
          c1.493,0.747,3.093,1.173,4.8,1.173h256C507.2,512,512,507.2,512,501.333C512,499.733,511.573,498.027,510.827,496.533z
          M262.613,490.667l110.72-221.44l110.72,221.44H262.613z"/>
        <path d="M362.667,350.08v69.44c0,5.333,3.84,10.133,9.067,10.88c6.613,0.96,12.267-4.16,12.267-10.56V350.4
          c0-5.333-3.84-10.133-9.067-10.88C368.32,338.56,362.667,343.68,362.667,350.08z"/>
        <path d="M373.333,444.16c-5.867,0-10.667,4.8-10.667,10.667c0,5.867,4.8,10.667,10.667,10.667c5.867,0,10.667-4.8,10.667-10.667
          C384,448.96,379.2,444.16,373.333,444.16z"/>
      </g>
  </svg>
);

export const GlobeSvg = () => (
  <svg viewBox="0 0 64 64" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <g>
      <path d="M36,4c13.233,0,24,10.766,24,24S49.233,52,36,52c-4.197,0-7.928-0.133-11.076-1.773c-0.978-0.509-2.187-0.13-2.697,0.849
        s-0.13,2.188,0.85,2.698c2.803,1.461,5.756,1.962,8.924,2.135V60h-6c-1.104,0-2,0.896-2,2s0.896,2,2,2h16c1.104,0,2-0.896,2-2
        s-0.896-2-2-2h-6v-4c15.439,0,28-12.561,28-28S51.439,0,36,0c-1.104,0-2,0.896-2,2S34.896,4,36,4z" />
      <path d="M36,49c11.579,0,21-9.42,21-21S47.579,7,36,7s-21,9.42-21,21S24.421,49,36,49z M36,11c9.374,0,17,7.626,17,17
        s-7.626,17-17,17s-17-7.626-17-17S26.626,11,36,11z"/>
    </g>
  </svg>
);

export const GpsSvg = () => (
  <svg viewBox="0 0 512 512" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <g>
      <path d="M296.661,414.012c-10.65,0-23.142,12.254-27.699,23.253c-3.558,8.593-2.5,16.614,2.91,22.016
        c5.094,5.094,12.655,8.021,20.745,8.021c8.09,0,15.65-2.927,20.753-8.021c5.666-5.666,7.049-13.995,3.891-23.441
        c-2.125-6.383-6.34-13.133-11.255-18.048C303.565,415.352,300.254,414.012,296.661,414.012z M301.303,447.206
        c-3.789,3.789-13.636,3.686-17.323,0.102c-0.12-0.367-0.111-2.5,2.15-6.238c2.944-4.864,7.1-8.294,9.353-9.515
        c2.423,2.876,4.523,6.477,5.589,9.677C302.02,444.058,302.106,446.413,301.303,447.206z"/>
      <path d="M85.069,215.381c3.405,0,6.622-2.048,7.945-5.41c1.732-4.386-0.427-9.344-4.813-11.068
        c-21.973-8.653-39.535-26.223-48.179-48.196c-1.715-4.386-6.656-6.545-11.059-4.821c-4.386,1.724-6.545,6.682-4.821,11.068
        c10.368,26.359,31.437,47.445,57.805,57.83C82.978,215.185,84.036,215.381,85.069,215.381z"/>
      <path d="M392.499,294.571c-2.466,0.708-5.837,1.673-6.733,1.741c-11.793,0-19.268,9.131-24.721,15.795
        c-2.091,2.551-6.135,7.296-8.243,7.177c-3.857-1.929-7.919-2.901-12.049-2.901c-10.061,0-18.773,5.982-20.719,14.242
        c-1.374,5.803,0.648,13.756,10.419,18.637c13.858,6.929,28.186,14.089,34.56,20.463c4.386,4.386,11.972,6.903,20.821,6.903
        c13.44,0,31.309-6.528,37.444-24.943c2.424-7.253,8.218-12.8,13.824-18.159c6.502-6.229,12.655-12.109,12.74-20.557
        c0.06-4.983-2.15-9.745-6.554-14.157c-5.999-5.99-14.165-9.037-24.286-9.037C409.293,289.775,399.582,292.548,392.499,294.571z
         M432.777,312.738c-0.401,1.681-4.651,5.751-7.475,8.457c-6.443,6.161-14.455,13.833-18.21,25.096
        c-4.215,12.638-18.449,13.269-21.257,13.269c-4.975,0-8.098-1.314-8.747-1.894c-8.346-8.346-23.236-15.787-39.006-23.671
        c-0.034-0.017-0.06-0.026-0.094-0.043c1.604-0.649,4.574-0.7,7.185,0.597c2.756,1.382,5.581,2.082,8.405,2.082
        c9.446,0,15.411-7.287,20.668-13.713c3.849-4.685,7.808-9.54,11.52-9.54c3.029,0,6.554-0.998,11.418-2.398
        c6.46-1.843,14.498-4.139,21.82-4.139c5.504,0,9.498,1.323,12.211,4.036C432.401,312.073,432.717,312.738,432.777,312.738z"/>
      <path d="M110.942,187.153l0.008,43.247c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533l-0.009-43.247
        c30.814-3.874,55.27-28.339,59.136-59.153H230.4c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-43.255
        C183.27,80.119,158.814,55.671,128,51.797V8.533C128,3.823,124.177,0,119.467,0s-8.533,3.823-8.533,8.533v43.264
        c-30.814,3.874-55.27,28.322-59.145,59.136H8.533c-4.71,0-8.533,3.823-8.533,8.533S3.823,128,8.533,128h43.255
        C55.654,158.822,80.119,183.287,110.942,187.153z M119.467,68.275c28.237,0,51.2,22.963,51.2,51.2
        c0,28.22-22.946,51.174-51.157,51.2c-0.017,0-0.026-0.009-0.034-0.009c-0.017,0-0.017,0.009-0.034,0.009
        c-28.228-0.017-51.174-22.972-51.174-51.2C68.267,91.238,91.23,68.275,119.467,68.275z"/>
      <path d="M509.884,259.43c-0.648-4.676-5.018-7.927-9.617-7.287c-4.668,0.649-7.936,4.958-7.287,9.626
        c1.297,9.378,1.954,18.927,1.954,28.365c0,112.111-90.539,203.46-202.342,204.792c-30.345-19.388-58.786-43.128-46.114-72.687
        c22.75-53.103,1.579-72.892-7.467-81.357l-1.698-1.613c-2.586-2.577-5.504-4.326-8.073-5.879
        c-6.246-3.746-7.927-4.753-4.574-14.814c2.219-6.682,5.171-9.796,8.576-13.406c7.893-8.346,13.926-16.649,13.926-40.781
        c0-14.046-7.125-25.25-19.55-30.737c-14.089-6.238-32.905-4.036-42.854,5.001c-6.212,5.649-8.303,13.338-5.726,21.077
        c6.084,18.219-7.817,44.553-33.792,64.034c-6.878,5.154-8.934,2.79-10.155,1.382c-7.62-8.781-7.031-33.749-0.776-40.004
        c4.19-4.19,5.436-9.54,3.328-14.31c-6.912-15.676-51.012-14.473-59.75-14.054c-0.358,0.017-0.657,0.196-0.998,0.256
        c-0.529,0.085-1.024,0.196-1.527,0.375c-0.58,0.222-1.092,0.486-1.596,0.819c-0.41,0.256-0.777,0.512-1.135,0.836
        c-0.452,0.41-0.819,0.853-1.178,1.348c-0.299,0.41-0.555,0.811-0.777,1.271c-0.256,0.529-0.418,1.075-0.563,1.664
        c-0.077,0.324-0.265,0.58-0.307,0.913c-1.084,8.823-1.545,16.563-1.545,25.873c0,102.315,69.282,190.882,168.474,215.39
        c0.683,0.171,1.374,0.256,2.048,0.256c3.831,0,7.322-2.603,8.277-6.494c1.135-4.574-1.664-9.199-6.238-10.325
        C149.274,466.338,85.333,384.572,85.333,290.133c0-5.914,0.222-11.145,0.674-16.469c14.396-0.051,26.692,1.638,32.939,3.55
        c-9.788,15.283-8.713,45.329,3.251,59.119c8.576,9.882,21.018,10.291,33.289,1.084c32.776-24.585,48.384-57.208,39.74-83.081
        c-0.273-0.845-0.546-1.638,1.024-3.063c4.48-4.079,15.915-5.803,24.465-2.014c6.315,2.79,9.387,7.74,9.387,15.13
        c0,19.26-3.883,23.373-9.259,29.056c-3.985,4.224-8.951,9.472-12.373,19.738c-7.672,23.049,4.634,30.438,11.989,34.85
        c1.903,1.143,3.695,2.219,4.787,3.302c0.623,0.623,1.331,1.289,2.108,2.014c7.501,7.023,21.47,20.079,3.439,62.157
        c-20.446,47.71,32.273,80.973,54.784,95.181c1.365,0.853,2.944,1.314,4.557,1.314C412.467,512,512,412.476,512,290.133
        C512,279.91,511.283,269.585,509.884,259.43z"/>
      <path d="M244.642,91.452c14.481-3.789,36.25-6.118,45.491-6.118c14.49,0,28.894,1.51,42.923,4.506
        c-0.307,12.279,4.992,20.702,8.405,26.112c0.939,1.502,2.236,3.55,2.389,3.831c0,30.609-6.263,35.584-6.187,35.61
        c-13.833,0-36.531,12.348-45.065,28.279c-5.24,9.779-4.813,20.113,1.186,29.124c4.113,6.17,7.654,13.03,11.085,19.669
        c7.253,14.046,15.3,29.628,27.93,29.628c2.68,0,5.564-0.7,8.687-2.261c9.95-4.975,17.084-19.115,23.979-32.794
        c3.038-6.025,7.501-14.865,10.146-17.673c8.661,1.178,12.894,9.011,18.62,21.419c4.215,9.131,8.576,18.56,17.135,22.844
        c14.43,7.202,61.85-0.341,88.055-11.469c0.35-0.145,0.589-0.435,0.913-0.623c0.503-0.299,0.981-0.58,1.408-0.964
        c0.41-0.367,0.725-0.785,1.05-1.22c0.333-0.435,0.64-0.862,0.879-1.348c0.247-0.495,0.384-0.998,0.521-1.536
        c0.145-0.529,0.273-1.033,0.316-1.579c0.034-0.563-0.034-1.118-0.119-1.698c-0.043-0.375,0.034-0.734-0.068-1.109
        c-17.911-66.219-66.56-121.301-130.15-147.337c-4.369-1.783-9.353,0.299-11.136,4.659c-1.784,4.361,0.307,9.344,4.668,11.127
        c56.311,23.066,99.874,70.835,117.922,128.649c-26.547,9.344-60.57,12.177-66.62,9.182c-3.209-1.604-6.434-8.576-9.276-14.729
        c-5.948-12.885-14.097-30.532-34.901-31.454c-10.786-0.512-16.811,11.725-24.602,27.179
        c-4.574,9.062-11.477,22.767-16.981,25.472c-3.166-0.751-9.455-12.928-13.21-20.198c-3.473-6.724-7.407-14.345-12.049-21.299
        c-2.517-3.772-2.62-7.356-0.341-11.605c5.862-10.94,23.45-19.268,30.02-19.268c15.428,0,23.253-17.724,23.253-52.676
        c0-4.966-2.552-9.011-5.018-12.919c-3.61-5.734-7.339-11.665-5.129-21.786c0.981-4.497-1.783-8.96-6.246-10.095
        c-17.673-4.454-35.977-6.716-54.391-6.716c-11.503,0-34.79,2.739-49.809,6.682c-4.565,1.195-7.287,5.854-6.093,10.411
        C235.426,89.916,240.085,92.672,244.642,91.452z"/>
      <path d="M119.467,153.609c18.825,0,34.133-15.309,34.133-34.133s-15.309-34.133-34.133-34.133s-34.133,15.309-34.133,34.133
        S100.642,153.609,119.467,153.609z M119.467,102.409c9.412,0,17.067,7.654,17.067,17.067c0,9.412-7.654,17.067-17.067,17.067
        s-17.067-7.654-17.067-17.067C102.4,110.063,110.054,102.409,119.467,102.409z"/>
      <path d="M199.031,150.4c-8.602,22.101-26.189,39.774-48.247,48.478c-4.386,1.732-6.536,6.69-4.804,11.076
        c1.323,3.354,4.54,5.402,7.945,5.402c1.041,0,2.099-0.196,3.123-0.597c26.47-10.453,47.565-31.65,57.89-58.172
        c1.707-4.395-0.469-9.344-4.855-11.051C205.688,143.821,200.738,146.005,199.031,150.4z"/>
      <path d="M28.979,92.971c1.033,0.401,2.091,0.597,3.132,0.597c3.405,0,6.622-2.048,7.945-5.402
        c8.627-21.854,26.095-39.364,47.932-48.034c4.386-1.741,6.528-6.699,4.787-11.085c-1.732-4.369-6.699-6.511-11.085-4.779
        C55.484,34.671,34.526,55.68,24.175,81.903C22.451,86.289,24.602,91.238,28.979,92.971z"/>
      <path d="M150.929,40.132c22.016,8.73,39.552,26.402,48.119,48.478c1.306,3.379,4.531,5.453,7.953,5.453
        c1.024,0,2.065-0.188,3.089-0.58c4.386-1.707,6.571-6.647,4.864-11.042c-10.274-26.487-31.317-47.693-57.719-58.18
        c-4.403-1.741-9.344,0.41-11.085,4.787C144.41,33.434,146.551,38.391,150.929,40.132z"/>
    </g>
  </svg>
);

export const LesseeSvg = () => (
  <svg viewBox="0 0 490.1 490.1" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M245,261.75c71.9,0,131.4-57.3,131.4-130.3S316.9,0.05,245,0.05s-131.4,57.3-131.4,130.3S173.1,261.75,245,261.75z
     M245,40.75c50,0,90.7,40.7,90.7,89.7s-40.7,89.6-90.7,89.6s-90.7-40.7-90.7-89.7S195,40.75,245,40.75z"/>
    <path d="M333.6,274.25c-8.3-2.1-16.7,0-21.9,6.3l-66.7,76.1l-66.7-76.1c-5.2-6.3-14.6-8.3-21.9-6.3C61.5,305.55,0,382.65,0,469.15
    c0,11.5,9.4,20.9,20.9,20.9h448.3c11.5,0,20.9-9.4,20.9-20.9C490,382.65,428.5,305.55,333.6,274.25z M42.7,449.35
    c8.4-57.3,50.1-106.3,114.7-131.3l73,83.4c7.3,9.4,22.9,9.4,30.2,0l73-83.4c63.6,25,106.4,75,114.7,131.3H42.7z"/>
  </svg>
);

export const LogoutSvg = () => (
  <svg viewBox="0 0 24 24" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M12 12H19M19 12L16 15M19 12L16 9" stroke="currentColor"/>
    <path d="M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18" stroke="currentColor"/>
  </svg>
);

export const MagnifyingGlassSvg = () => (
  <svg viewBox="0 0 512 512" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <g>
      <path d="m495,466.1l-119.2-119.2c29.1-35.5 46.5-80.8 46.5-130.3 0-113.5-92.1-205.6-205.6-205.6-113.6,0-205.7,92.1-205.7,205.7s92.1,205.7 205.7,205.7c49.4,0 94.8-17.4 130.3-46.5l119.1,119.1c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9zm-443.2-249.4c-1.42109e-14-91 73.8-164.8 164.8-164.8 91,0 164.8,73.8 164.8,164.8s-73.8,164.8-164.8,164.8c-91,0-164.8-73.8-164.8-164.8z"/>
    </g>
  </svg>
);

export const MailboxSvg = () => (
  <svg viewBox="0 0 465 465" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <g>
      <path d="M337.5,110h-56.464c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5H337.5c43.541,0,78.964,35.423,78.964,78.964v137.021
        h-195V203.964c0-33.077-17.182-62.215-43.088-78.964h42.659c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H127.5
        c-51.812,0-93.964,42.152-93.964,93.964v144.521c0,4.143,3.358,7.5,7.5,7.5h165.429V457.5c0,4.143,3.358,7.5,7.5,7.5
        s7.5-3.357,7.5-7.5V355.985h22.071V457.5c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5V355.985h165.429c4.142,0,7.5-3.357,7.5-7.5
        V203.964C431.464,152.152,389.312,110,337.5,110z M48.536,203.964C48.536,160.423,83.959,125,127.5,125
        s78.964,35.423,78.964,78.964v137.021H48.536V203.964z"/>
      <path d="M251.036,283.725c4.142,0,7.5-3.357,7.5-7.5V73h67.695c9.098,0,16.5-7.402,16.5-16.5v-40c0-9.098-7.402-16.5-16.5-16.5
        h-75.195c-4.142,0-7.5,3.357-7.5,7.5v268.725C243.536,280.367,246.894,283.725,251.036,283.725z M327.73,16.5v40
        c0,0.827-0.673,1.5-1.5,1.5h-67.695V15h67.695C327.058,15,327.73,15.673,327.73,16.5z"/>
      <path d="M127.5,160c-4.142,0-7.5,3.357-7.5,7.5v23c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-23
        C135,163.357,131.642,160,127.5,160z"/>
    </g>
  </svg>
);

export const MotorcycleDealership = () => (
  <svg viewBox="0 0 465 465" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M239.778,179.14c-0.529,7.945-0.845,16.435-0.845,25.66c0,42.743,12.757,68.267,34.133,68.267
      c26.948,0,34.133-32.179,34.133-51.2c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533
      c0,0.341-0.734,34.133-17.067,34.133c-9.89,0-17.067-21.53-17.067-51.2c0-99.49,34.918-110.822,36.019-111.138
      c4.497-1.024,7.39-5.453,6.477-10.001c-0.913-4.617-5.385-7.62-10.035-6.69c-1.707,0.341-7.893,1.963-15.334,8.337
      c0.009-1.468,0.128-2.935,0.521-4.395c1.178-4.403,4.002-8.081,7.953-10.359c3.942-2.278,8.55-2.884,12.945-1.707
      c4.403,1.186,8.09,4.011,10.359,7.953c4.71,8.149,1.911,18.611-6.238,23.313c-4.087,2.364-5.478,7.586-3.123,11.665
      c1.579,2.731,4.446,4.258,7.398,4.258c1.451,0,2.918-0.367,4.258-1.143c16.299-9.421,21.897-30.336,12.493-46.626
      c-4.557-7.893-11.921-13.542-20.727-15.906c-8.823-2.364-18.014-1.143-25.899,3.413c-7.893,4.557-13.542,11.921-15.906,20.727
      c-2.21,8.243-1.161,16.802,2.85,24.593c-7.322,11.913-14.165,29.653-17.971,56.038c-12.774-15.3-20.173-34.79-20.173-54.733
      c0-47.053,38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333s-38.281,85.333-85.333,85.333
      c-9.267,0-18.381-1.476-27.076-4.378c-4.463-1.485-9.31,0.922-10.795,5.385c-1.502,4.471,0.913,9.301,5.385,10.795
      c10.445,3.49,21.376,5.265,32.486,5.265c56.465,0,102.4-45.935,102.4-102.4C409.6,45.935,363.665,0,307.2,0
      S204.8,45.935,204.8,102.4C204.8,131.499,217.865,159.778,239.778,179.14z"/>
    <path d="M509.5,249.967l-88.627-88.627c-3.328-3.328-8.73-3.328-12.066,0c-3.328,3.337-3.328,8.738,0,12.066l86.127,86.127
      v30.601h-30.601l-3.533-3.533v-22.067c0-4.71-3.823-8.533-8.533-8.533h-8.533v-8.533c0-4.71-3.823-8.533-8.533-8.533h-22.059
      l-3.541-3.541v-22.059c0-4.71-3.823-8.533-8.533-8.533H379l-0.828-0.828c-3.328-3.328-8.73-3.328-12.066,0
      c-3.328,3.337-3.328,8.738,0,12.066l3.328,3.328c1.596,1.604,3.772,2.5,6.033,2.5h17.067v17.058c0,2.261,0.896,4.429,2.492,6.025
      l8.533,8.55c1.604,1.604,3.772,2.5,6.042,2.5h17.067v8.533c0,4.71,3.823,8.533,8.533,8.533h8.533v17.067
      c0,2.261,0.896,4.437,2.5,6.033l8.533,8.533c1.596,1.604,3.772,2.5,6.033,2.5h42.667c4.71,0,8.533-3.823,8.533-8.533V256
      C512,253.739,511.104,251.563,509.5,249.967z"/>
    <path d="M51.2,145.067c0,4.71,3.823,8.533,8.533,8.533h8.533v51.2h-8.533c-4.71,0-8.533,3.823-8.533,8.533
      c0,4.71,3.823,8.533,8.533,8.533h8.533v51.2h-8.533c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h8.533v51.2h-8.533
      c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h8.533v51.2h-8.533c-4.71,0-8.533,3.823-8.533,8.533
      s3.823,8.533,8.533,8.533h8.533v42.667c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-42.667h34.133v42.667
      c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-42.667h34.133v42.667c0,4.71,3.823,8.533,8.533,8.533
      s8.533-3.823,8.533-8.533v-42.667h34.133v42.667c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-42.667h8.533
      c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-8.533v-51.2h8.533c4.71,0,8.533-3.823,8.533-8.533
      s-3.823-8.533-8.533-8.533h-8.533v-51.2c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533
      c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533h-34.133v-51.2h25.6c4.71,0,8.533-3.823,8.533-8.533
      c0-4.71-3.823-8.533-8.533-8.533h-25.6v-51.2H192c4.71,0,8.533-3.823,8.533-8.533c0-4.71-3.823-8.533-8.533-8.533H59.733
      C55.023,136.533,51.2,140.356,51.2,145.067z M187.733,290.133h34.133v51.2h-34.133V290.133z M187.733,358.4h34.133v51.2h-34.133
      V358.4z M136.533,153.6h34.133v51.2h-34.133V153.6z M136.533,221.867h34.133v51.2h-34.133V221.867z M136.533,290.133h34.133v51.2
      h-34.133V290.133z M136.533,358.4h34.133v51.2h-34.133V358.4z M85.333,153.6h34.133v51.2H85.333V153.6z M85.333,221.867h34.133
      v51.2H85.333V221.867z M85.333,290.133h34.133v51.2H85.333V290.133z M85.333,358.4h34.133v51.2H85.333V358.4z"/>
    <path d="M179.2,119.467c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H128c-4.71,0-8.533,3.823-8.533,8.533
      s3.823,8.533,8.533,8.533H179.2z"/>
    <path d="M503.467,494.933h-128v-34.995c19.447-3.968,34.133-21.197,34.133-41.805c0-1.109-0.486-110.933-42.667-110.933
      s-42.667,109.824-42.667,110.933c0,20.608,14.686,37.837,34.133,41.805v34.995h-68.267V298.667c0-4.71-3.823-8.533-8.533-8.533
      s-8.533,3.823-8.533,8.533v196.267H34.133V119.467h59.733c4.71,0,8.533-3.823,8.533-8.533v-25.6h76.8
      c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H93.867c-4.71,0-8.533,3.823-8.533,8.533v25.6H25.6
      c-4.71,0-8.533,3.823-8.533,8.533v384H8.533c-4.71,0-8.533,3.823-8.533,8.533S3.823,512,8.533,512h494.933
      c4.71,0,8.533-3.823,8.533-8.533S508.177,494.933,503.467,494.933z M341.333,418.133c0-42.513,11.418-93.867,25.6-93.867
      s25.6,51.354,25.6,93.867c0,14.114-11.486,25.6-25.6,25.6S341.333,432.247,341.333,418.133z"/>
  </svg>
);

export const MotorSvg = () => (
  <svg viewBox="0 0 467.161 467.161" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M391.083,210.102c-10.262,0-20.05,2.054-28.991,5.754l-4.204-7.886c10.289-4.373,21.533-6.766,33.195-6.766
      c14.523,0,28.863,3.744,41.472,10.826c3.608,2.028,8.183,0.746,10.212-2.866c2.028-3.611,0.745-8.184-2.866-10.212
      c-14.846-8.339-31.727-12.748-48.817-12.748c-14.184,0-27.84,3.01-40.273,8.49l-7.419-13.917c0.697,0.047,1.398,0.08,2.107,0.08
      h17.363c4.143,0,7.5-3.358,7.5-7.5v-48.49c0-4.142-3.357-7.5-7.5-7.5h-17.363c-11.763,0-22.005,6.624-27.203,16.335l-13.238-24.832
      c-1.303-2.445-3.848-3.972-6.618-3.972H263.11c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h30.828l12.748,23.913
      c-1.641-1.205-3.383-2.306-5.232-3.278c-11.296-5.936-24.958-6.282-36.543-0.927l-23.357,10.8
      c-8.455,3.909-15.074,10.358-19.271,18.091c-6.716-7.44-16.062-12.511-26.711-13.715L81.73,141.913h0
      c-5.808-0.657-11.636,1.2-15.994,5.092c-4.357,3.893-6.857,9.477-6.857,15.32v32.943c-1.436,0.62-2.85,1.281-4.235,1.995
      c-25.867,13.334-44.631,37.128-51.48,65.28c-0.979,4.025,1.49,8.081,5.515,9.061c0.596,0.145,1.192,0.214,1.779,0.214
      c3.379,0,6.447-2.3,7.282-5.729c5.821-23.925,21.777-44.152,43.778-55.493c7.854-4.049,16.868-6.189,26.068-6.189h10.355h120.262
      v21.75h-71.313c-12.899-10.042-29.069-16.052-46.575-16.052c-39.079,0-71.561,29.868-75.634,67.969H7.5c-4.142,0-7.5,3.358-7.5,7.5
      v24.938c0,4.142,3.358,7.5,7.5,7.5h23.716c12.314,26.667,39.442,44.25,69.1,44.25s56.786-17.583,69.1-44.25H221.1
      c16.36,0,29.842-12.538,31.354-28.509c13.915-5.003,25.737-15.026,32.921-28.55l35.532-69.341
      c2.005-5.34,2.823-10.867,2.584-16.278l14.085,26.421c-18.799,11.922-33.568,30.048-41.125,52.147
      c-1.34,3.919,0.751,8.183,4.67,9.523c0.804,0.275,1.622,0.405,2.428,0.405c3.118,0,6.03-1.96,7.096-5.075
      c6.297-18.414,18.478-33.591,34.006-43.729l4.212,7.902c-20.399,13.661-33.859,36.912-33.859,63.252
      c0,41.95,34.129,76.078,76.078,76.078s76.078-34.128,76.078-76.078S433.032,210.102,391.083,210.102z M329.664,148.199
      c0-8.73,7.104-15.833,15.834-15.833h9.863v33.49h-9.863c-8.73,0-15.834-7.103-15.834-15.833V148.199z M247.848,164.021l23.357-10.8
      c7.379-3.411,16.077-3.19,23.271,0.59c11.715,6.155,17.041,20.139,12.388,32.528l-1.152,3.069h-73.178
      c-0.101-0.567-0.216-1.129-0.339-1.688C232.504,177.704,238.391,168.394,247.848,164.021z M97.942,189.408H87.586H73.88v-27.083
      c0-2.159,1.159-3.516,1.85-4.134c0.691-0.617,2.174-1.616,4.315-1.374h0l113.841,12.869c11.285,1.276,20.311,9.268,23.278,19.721
      H97.942z M175.95,278.075c-1.466-13.71-6.618-26.346-14.425-36.917h30.533l19.21,36.917H175.95z M100.316,225.106
      c30.824,0,56.523,23.15,60.514,52.969H39.802C43.793,248.257,69.491,225.106,100.316,225.106z M100.316,347.263
      c-21.4,0-41.16-11.401-52.12-29.25h9.511c10.045,13.46,25.669,21.391,42.609,21.391c16.939,0,32.564-7.931,42.609-21.391h9.511
      C141.477,335.862,121.716,347.263,100.316,347.263z M79.176,318.013h42.28c-6.162,4.108-13.462,6.391-21.14,6.391
      C92.638,324.403,85.338,322.121,79.176,318.013z M236.239,293.075c-2.542,5.843-8.371,9.938-15.139,9.938H15v-9.938H236.239z
      M272.129,253.917c-7.917,14.901-23.317,24.159-40.191,24.159h-3.76l-19.21-36.917h16.737c4.142,0,7.5-3.358,7.5-7.5v-29.25h65.227
      L272.129,253.917z M391.083,347.258c-33.679,0-61.078-27.399-61.078-61.078c0-20.614,10.275-38.862,25.962-49.928l3.726,6.989
      c-13.222,9.692-21.828,25.326-21.828,42.939c0,29.345,23.874,53.219,53.219,53.219s53.219-23.874,53.219-53.219
      c0-29.345-23.874-53.219-53.219-53.219c-6.378,0-12.496,1.13-18.168,3.197l-3.723-6.984c6.801-2.621,14.178-4.073,21.891-4.073
      c33.679,0,61.078,27.4,61.078,61.079S424.761,347.258,391.083,347.258z M375.128,272.196c1.35,2.531,3.943,3.973,6.625,3.973
      c1.19,0,2.397-0.284,3.521-0.883c3.655-1.948,5.038-6.491,3.09-10.146l-8.292-15.555c3.489-1.052,7.183-1.624,11.01-1.624
      c21.074,0,38.219,17.145,38.219,38.219c0,21.074-17.145,38.219-38.219,38.219s-38.219-17.145-38.219-38.219
      c0-11.88,5.45-22.511,13.979-29.526L375.128,272.196z"/>
    <path d="M384.153,289.049c2.018,4.77,8.537,6.235,12.23,2.43c2.896-2.815,2.893-7.783,0-10.6c-2.452-2.526-6.564-2.878-9.47-0.94
      C383.954,281.913,382.844,285.772,384.153,289.049C384.343,289.499,383.973,288.599,384.153,289.049z"/>
  </svg>
);

export const SalesSvg = () => (
  <svg viewBox="0 0 173.309 173.309" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M173.295,59.534c-0.001-4.142-3.358-7.499-7.5-7.499h-57.784l8.781-34.368c0.573-2.244,0.077-4.627-1.343-6.457
    c-1.421-1.83-3.607-2.9-5.924-2.9H74.73c-3.427,0-6.417,2.322-7.266,5.642l-9.735,38.06L7.497,52.036
    C3.355,52.038,0,55.395,0,59.536v83.849c0,4.142,3.357,7.5,7.5,7.5h40.154l26.668,13.322c1.041,0.52,2.188,0.791,3.351,0.791h78.951
    c4.143,0,7.5-3.358,7.5-7.5V140.51h1.684c1.989,0,3.898-0.791,5.304-2.197c1.406-1.407,2.196-3.315,2.196-5.304L173.295,59.534z
    M123.727,125.51c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h25.398v9.487H79.443l-26.668-13.322
    c-1.041-0.52-2.188-0.79-3.352-0.79H15V67.032l48.556-0.022c3.426-0.002,6.414-2.323,7.263-5.642l9.735-38.058h19.314l-8.781,34.368
    c-0.573,2.244-0.077,4.627,1.343,6.456c1.421,1.83,3.607,2.9,5.924,2.9h59.942l0.002,9.473H123.74c-4.142,0-7.5,3.358-7.5,7.5
    s3.358,7.5,7.5,7.5h34.561l0.002,9.503H123.74c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h34.565l0.002,9.499H123.727z"
    />
  </svg>
);

export const StickManSvg = () => (
  <svg viewBox="0 0 489.3 489.3" x="0" y="0" focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M181.95,62.7c0,34.6,28.1,62.7,62.7,62.7s62.7-28.1,62.7-62.7S279.25,0,244.65,0S181.95,28.1,181.95,62.7z M244.65,24.5
    c21.1,0,38.2,17.1,38.2,38.2s-17.1,38.2-38.2,38.2s-38.2-17.1-38.2-38.2S223.55,24.5,244.65,24.5z"/>
    <path d="M196.25,138.5c-34.3,0-62.2,27.9-62.2,62.2v79.7c0,23,12.9,44,32.8,54.7v104.7c0,27.3,22.2,49.5,49.5,49.5h56.6
    c27.3,0,49.5-22.2,49.5-49.5V335c19.9-10.7,32.8-31.7,32.8-54.7v-79.7c0-34.3-27.9-62.2-62.2-62.2h-96.8V138.5z M330.75,200.6
    v79.7c0,15.7-9.9,29.9-24.7,35.3c-4.8,1.8-8,6.4-8,11.5v112.6c0,13.8-11.2,25-25,25h-56.6c-13.8,0-25-11.2-25-25V327.2
    c0-5.1-3.2-9.7-8-11.5c-14.8-5.4-24.7-19.6-24.7-35.3v-79.8c0-20.8,16.9-37.7,37.7-37.7h96.8
    C313.85,163,330.75,179.9,330.75,200.6z"/>
  </svg>
);

export const UnderwritingSvg = () => (
  <svg viewBox="0 0 491.695 491.695" x="0" y="0"  focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M436.714,0H149.471c-16.438,0-29.812,13.374-29.812,29.812v66.714c-54.49,15.594-94.489,65.857-94.489,125.288
      c0,59.431,39.998,109.694,94.489,125.288v114.783c0,16.438,13.374,29.812,29.812,29.812h234.733c2.785,0,5.455-1.106,7.425-3.075
      l71.821-71.822c1.969-1.969,3.075-4.64,3.075-7.425V29.812C466.525,13.374,453.152,0,436.714,0z M149.471,21h287.243
      c4.858,0,8.811,3.953,8.811,8.812v31.689H140.659V29.812C140.659,24.953,144.612,21,149.471,21z M46.17,221.813
      c0-60.263,49.027-109.29,109.29-109.29c60.263,0,109.29,49.027,109.29,109.29s-49.027,109.291-109.29,109.291
      C95.197,331.104,46.17,282.076,46.17,221.813z M140.659,461.884V351.258c4.86,0.552,9.797,0.846,14.802,0.846
      c39.135,0,74.292-17.347,98.195-44.752h64.336c5.799,0,10.5-4.701,10.5-10.5s-4.701-10.5-10.5-10.5h-49.381
      c9.133-15.95,14.984-34.005,16.644-53.242h32.736c5.799,0,10.5-4.701,10.5-10.5c0-5.799-4.701-10.5-10.5-10.5h-32.603
      c-1.42-19.194-7.02-37.242-15.886-53.241h48.488c5.799,0,10.5-4.701,10.5-10.5c0-5.799-4.701-10.5-10.5-10.5h-62.974
      c-23.918-28.323-59.67-46.347-99.558-46.347c-5.005,0-9.942,0.294-14.802,0.846v-9.867h304.866v316.372h-42.009
      c-16.439,0-29.811,13.374-29.811,29.811v42.011H149.471C144.612,470.695,140.659,466.743,140.659,461.884z M394.705,455.845v-27.16
      c0-4.859,3.953-8.811,8.811-8.811h27.16L394.705,455.845z"/>
    <path d="M359.246,158.869h34.87c5.799,0,10.5-4.701,10.5-10.5c0-5.799-4.701-10.5-10.5-10.5h-34.87c-5.799,0-10.5,4.701-10.5,10.5
      C348.746,154.168,353.447,158.869,359.246,158.869z"/>
    <path d="M359.246,233.11h34.87c5.799,0,10.5-4.701,10.5-10.5c0-5.799-4.701-10.5-10.5-10.5h-34.87c-5.799,0-10.5,4.701-10.5,10.5
      C348.746,228.409,353.447,233.11,359.246,233.11z"/>
    <path d="M359.246,307.352h34.87c5.799,0,10.5-4.701,10.5-10.5s-4.701-10.5-10.5-10.5h-34.87c-5.799,0-10.5,4.701-10.5,10.5
      S353.447,307.352,359.246,307.352z"/>
    <path d="M394.116,381.593c5.799,0,10.5-4.701,10.5-10.5s-4.701-10.5-10.5-10.5h-98.225c-5.799,0-10.5,4.701-10.5,10.5
      s4.701,10.5,10.5,10.5H394.116z"/>
    <path d="M236.982,168.845l-12.81-12.81c-3.45-3.449-8.036-5.349-12.915-5.349s-9.465,1.9-12.915,5.349l-67.19,67.19l-18.573-18.573
      c-3.449-3.448-8.036-5.348-12.914-5.348c-4.878,0-9.465,1.9-12.914,5.349l-12.813,12.812c-7.12,7.121-7.12,18.708,0.001,25.829
      l44.297,44.296c3.45,3.451,8.037,5.351,12.916,5.351c0,0,0.001,0,0.001,0c4.878,0,9.465-1.9,12.913-5.349l92.917-92.917
      C244.103,187.554,244.103,175.966,236.982,168.845z M131.151,270.807l-40.429-40.428l8.942-8.942l24.062,24.062
      c4.101,4.101,10.749,4.101,14.85,0l72.681-72.681l8.942,8.942L131.151,270.807z"/>
  </svg>
);

export const NotePenSvg = () => (
  <svg viewBox="0 0 411.438 411.438" x="0" y="0" focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <g>
      <path d="M248.655,22.146h-3.66V10c0-5.522-4.477-10-10-10c-5.523,0-10,4.478-10,10v12.146h-40V10c0-5.522-4.477-10-10-10
      c-5.523,0-10,4.478-10,10v12.146h-40V10c0-5.522-4.477-10-10-10c-5.523,0-10,4.478-10,10v12.146h-40V10c0-5.522-4.477-10-10-10
      c-5.523,0-10,4.478-10,10v12.146h-3.66c-22.057,0-40,17.944-40,40v309.291c0,22.056,17.943,40,40,40h207.32
      c22.057,0,40-17.944,40-40V62.146C288.655,40.091,270.711,22.146,248.655,22.146z M268.655,371.438c0,11.028-8.971,20-20,20H41.334
      c-11.027,0-20-8.972-20-20V62.146c0-11.028,8.973-20,20-20h3.66v25.689c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10V42.146h40
      v25.689c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10V42.146h40v25.689c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10V42.146h40
      v25.689c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10V42.146h3.66c11.029,0,20,8.972,20,20V371.438z" />
      <path d="M234.491,156.469H55.498c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h178.992c5.523,0,10-4.478,10-10
      C244.491,160.946,240.014,156.469,234.491,156.469z" />
      <path d="M234.491,216.469H55.498c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h178.992c5.523,0,10-4.478,10-10
      C244.491,220.946,240.014,216.469,234.491,216.469z" />
      <path d="M234.491,276.469H55.498c-5.523,0-10,4.477-10,10c0,5.522,4.477,10,10,10h178.992c5.523,0,10-4.478,10-10
      C244.491,280.946,240.014,276.469,234.491,276.469z" />
      <path d="M406,42.543c-0.354-3.571-2.596-6.679-5.873-8.143c-12.336-5.509-22.994-8.075-33.541-8.075
      c-10.547,0-21.207,2.566-33.547,8.074c-3.277,1.464-5.52,4.572-5.875,8.145c-5.463,55.046-5.463,108.563,0,163.607
      c0.348,3.486,2.49,6.531,5.641,8.036l6.793,139.384c0.035,0.686,0.139,1.366,0.311,2.031l11.496,44.172
      c1.148,4.406,5.125,7.481,9.678,7.481h11c4.555,0,8.533-3.076,9.678-7.482l11.49-44.172c0.174-0.664,0.277-1.345,0.311-2.03
      l6.799-139.384c3.148-1.505,5.293-4.548,5.639-8.033C411.471,151.151,411.471,97.634,406,42.543z M346.498,50.465
      c3.551-1.35,6.887-2.348,10.084-3.03v74.224c0,5.522,4.478,10,10,10c5.523,0,10-4.478,10-10V47.434
      c3.199,0.683,6.535,1.682,10.086,3.03c4.553,49.403,4.574,97.604,0.072,146.919h-40.316
      C341.93,148.029,341.952,99.833,346.498,50.465z M373.635,351.565l-7.053,27.116l-7.057-27.116l-6.541-134.183h27.195
      L373.635,351.565z" />
    </g>
  </svg>
);

export const BriefCaseSvg = () => (
  <svg viewBox="0 0 511 511" x="0px" y="0px" focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
    <path d="M471.5,104H351V81.495c7.048-2.408,12.782-8.111,15.002-15.478c1.809-6.01,1.17-12.365-1.8-17.895
    c-2.971-5.53-7.917-9.573-13.927-11.382C322.59,28.405,289.817,24,255.5,24s-67.09,4.405-94.775,12.74
    c-6.011,1.809-10.957,5.852-13.927,11.382c-2.97,5.53-3.609,11.885-1.799,17.899c1.816,6.022,5.868,10.973,11.409,13.943
    c1.164,0.624,2.366,1.135,3.592,1.551V104H39.5C17.72,104,0,121.72,0,143.5v139.209c0,12.653,6.07,24.305,16,31.709V447.5
    c0,21.78,17.72,39.5,39.5,39.5h400c21.78,0,39.5-17.72,39.5-39.5V314.418c9.93-7.404,16-19.056,16-31.709V143.5
    C511,121.72,493.28,104,471.5,104z M160.013,55.22c1.074-2,2.863-3.462,5.036-4.116C191.35,43.186,222.627,39,255.5,39
    s64.15,4.186,90.452,12.104c2.173,0.654,3.961,2.116,5.036,4.116c1.075,2,1.306,4.3,0.652,6.472
    c-1.074,3.565-4.418,6.055-8.131,6.055c-0.83,0-1.654-0.122-2.458-0.365C316.326,59.936,286.744,56,255.5,56
    s-60.826,3.936-85.56,11.384c-2.165,0.655-4.453,0.427-6.447-0.642c-2.007-1.076-3.475-2.87-4.132-5.049
    C158.707,59.52,158.938,57.22,160.013,55.22z M175,81.535C198.197,74.645,226.006,71,255.5,71c29.496,0,57.308,3.646,80.5,10.535
    V104H175V81.535z M455.5,472h-400C41.991,472,31,461.009,31,447.5V322.014c61.606,25.367,126.462,40.001,193,43.547v1.938
    c0,17.369,14.131,31.5,31.5,31.5s31.5-14.131,31.5-31.5v-1.939c66.539-3.547,131.395-18.18,193-43.547V447.5
    C480,461.009,469.009,472,455.5,472z M336,345.757c-16.219,2.286-32.568,3.882-49.024,4.781C286.464,333.613,272.546,320,255.5,320
    s-30.964,13.613-31.476,30.538c-16.456-0.899-32.805-2.495-49.024-4.781V279.5c0-9.098,7.402-16.5,16.5-16.5h128
    c9.098,0,16.5,7.402,16.5,16.5V345.757z M272,351.5v16c0,9.098-7.402,16.5-16.5,16.5s-16.5-7.402-16.5-16.5v-16
    c0-9.098,7.402-16.5,16.5-16.5S272,342.402,272,351.5z M496,282.709c0,9.863-5.965,18.777-15.198,22.71
    C438.94,323.253,395.497,335.964,351,343.443V279.5c0-17.369-14.131-31.5-31.5-31.5h-128c-17.369,0-31.5,14.131-31.5,31.5v63.943
    c-44.497-7.478-87.939-20.19-129.802-38.024C20.965,301.486,15,292.572,15,282.709V143.5c0-13.509,10.991-24.5,24.5-24.5h432
    c13.509,0,24.5,10.991,24.5,24.5V282.709z" />
  </svg>
);

export const ArchiveSvg = () => (
  <svg viewBox="0 0 60 60" x="0px" y="0px" focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
  	<path d="M47,36c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S53.617,36,47,36z M47,58c-5.514,0-10-4.486-10-10
  		s4.486-10,10-10s10,4.486,10,10S52.514,58,47,58z"/>
  	<path d="M53,47h-5v-5c0-0.553-0.447-1-1-1s-1,0.447-1,1v6c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1S53.553,47,53,47z"/>
  	<path d="M22.505,18h10.99C35.428,18,37,16.428,37,14.495V13c0-0.553-0.447-1-1-1s-1,0.447-1,1v1.495C35,15.325,34.325,16,33.495,16
  		h-10.99C21.675,16,21,15.325,21,14.495V13c0-0.553-0.447-1-1-1s-1,0.447-1,1v1.495C19,16.428,20.572,18,22.505,18z"/>
  	<path d="M20,41c-0.553,0-1,0.447-1,1v1.495C19,45.428,20.572,47,22.505,47H30c0.553,0,1-0.447,1-1s-0.447-1-1-1h-7.495
  		C21.675,45,21,44.325,21,43.495V42C21,41.447,20.553,41,20,41z"/>
  	<path d="M33,58H3.832C3.373,58,3,57.627,3,57.168V31h50v2c0,0.553,0.447,1,1,1s1-0.447,1-1V2.832C55,1.271,53.729,0,52.168,0H3.832
  		C2.271,0,1,1.271,1,2.832v54.336C1,58.729,2.271,60,3.832,60H33c0.553,0,1-0.447,1-1S33.553,58,33,58z M3.832,2h48.336
  		C52.627,2,53,2.373,53,2.832V29H3V2.832C3,2.373,3.373,2,3.832,2z"/>
  </svg>
);
