import React, { useState, useEffect } from 'react'
import { BikeInformation } from '.'
import { logger, network } from '../../../../utils'
import {setApplicationDetails} from "../../../../reducers/LeaseApplicationsReducer";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function BikeInformationRenderer(props: any) {
  const leaseApplicationId: string = `${props.match.params.leaseApplicationId}`
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch();
  const dataFromReducer = useSelector((state: any) => state?.applications?.applicationDetails);

  useEffect(() => {
    getApplicationDetails()
  }, [])

  const getApplicationDetails = async () => {
    if (!loading) {
      setLoading(true)
    }
    try {
      if(dataFromReducer?.id !== parseInt(leaseApplicationId)){
        let data = await network.GET(`/api/v1/dealers/get-details?id=${leaseApplicationId}`)
        if (data?.data?.data?.leaseApplication) {
          dispatch(setApplicationDetails(data?.data?.data?.leaseApplication))
        }
        setData(data.data.data.leaseApplication)
      }
      else{
        setData(dataFromReducer)
      }
    } catch (e) {
      setLoading(false)
      logger.error('Error fetching Applicatins', e)
    }
    setLoading(false)
  }

  return data ? (
    <div>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <BikeInformation/>
    </div>
  ) : null
}

export default BikeInformationRenderer
