import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import { Paper } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import capitalizeFirstLetter from './DataFormat';
import { Typography, styled } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SsnInput from '../../Applicant/SsnInput';
import { Link } from 'react-router-dom'
import '../../../styles/Applicant.css'

export default function SummaryPersonalInfo(props: any) {

    const { leaseApplicationId, data, applicantFlag, isSubmitted, isAddingCoApplicant } = props

    const [hide, setHide] = useState<boolean>(true);

    let lesseeOrCoLessee;
    let routeName = ""
    if (applicantFlag == 1) {
        lesseeOrCoLessee = data?.lessee
        routeName = "applicant";
    } else {
        lesseeOrCoLessee = data?.colessee
        routeName = "co-applicant";
    }

    let phoneLabel = "";
    let phoneData = "";
    if (lesseeOrCoLessee?.homePhoneNumber) {
        phoneLabel = "Home Phone Number"
        phoneData = lesseeOrCoLessee?.homePhoneNumber
    } else if (lesseeOrCoLessee?.mobilePhoneNumber) {
        phoneLabel = "Mobile Phone Number"
        phoneData = lesseeOrCoLessee?.mobilePhoneNumber
    }

    const starredMask = (ssn: any, showLast4 = true) => {
        // replace numeric digits with * asterisk
        if (ssn) {
            let valueHidden = ssn.replace(/[\d]/g, "*");

            if (valueHidden.length <= 3) {
                return valueHidden;
            }

            if (valueHidden.length <= 5) {
                return valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
            }

            if (showLast4) {
                return (
                    valueHidden.slice(0, 3) +
                    "-" +
                    valueHidden.slice(3, 5) +
                    "-" +
                    ssn.substr(6)
                );
            } else {
                return (
                    valueHidden.slice(0, 3) +
                    "-" +
                    valueHidden.slice(3, 5) +
                    "-" +
                    valueHidden.slice(5, 8)
                );
            }
        }
    }

    const handleView = () => {
        setHide(false)
    }

    const handleHide = () => {
        setHide(true)
    }

    const addingCoApplicant = () => {
        return isAddingCoApplicant && routeName === 'co-applicant';
    }
    let redirectRoute = addingCoApplicant() ? `/applications/${leaseApplicationId}/${routeName}?add_co_applicant=true` : `/applications/${leaseApplicationId}/${routeName}`

    return (
        <>
            <Paper elevation={12} sx={{ background: "#ef0d00" }}>
                <Typography sx={{ color: "#fff", float: "left", padding: 1, fontWeight: "bold", fontSize: "14px" }} >Personal</Typography>
                <Typography sx={{ color: "#fff", float: "right", padding: 1 }} >
                    <Link to={redirectRoute} style={{display: addingCoApplicant() ? '' : 'none'}}>
                        {!isSubmitted && (<EditOutlinedIcon sx={{ color: "#fff" }} />)}
                    </Link>
                </Typography>
                <TableContainer component={Paper} sx={{ height: "546px" }}>
                    <Table aria-label="customized table" sx={{ height: "546px" }}>
                        <TableBody>

                            {
                                routeName === 'co-applicant' ? (
                                    <>
                                        <StyledTableRow >
                                            <StyledtableCell1> Relationship To Applicant:</StyledtableCell1>
                                            <StyledtableCell2>{lesseeOrCoLessee?.relationshipToLessee?.description ? lesseeOrCoLessee?.relationshipToLessee?.description : 'N/A'}</StyledtableCell2>
                                        </StyledTableRow>
                                    </>
                                ) : ""
                            }
                            <StyledTableRow >
                                <StyledtableCell1>First Name:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.firstName ? capitalizeFirstLetter(lesseeOrCoLessee?.firstName) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1>Middle Name:</StyledtableCell1>
                                <StyledtableCell2>{lesseeOrCoLessee?.middleName ? capitalizeFirstLetter(lesseeOrCoLessee?.middleName) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1> Last Name: </StyledtableCell1>
                                <StyledtableCell2>{lesseeOrCoLessee?.lastName ? capitalizeFirstLetter(lesseeOrCoLessee?.lastName) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1> Date Of Birth(mm/dd/yyyy):</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.dateOfBirth ? lesseeOrCoLessee?.dateOfBirth : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1>Social Security Number:</StyledtableCell1>
                                <StyledtableCell2 >{hide ? starredMask(lesseeOrCoLessee?.ssn) : lesseeOrCoLessee?.ssn} {hide && (<VisibilityOffIcon onClick={handleView} className="hide-unhide" />)} {!hide && (<VisibilityIcon onClick={handleHide} className="hide-unhide" />)} </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledtableCell1> {phoneLabel}:</StyledtableCell1>
                                <StyledtableCell2>{phoneData}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1> Email:</StyledtableCell1>
                                <StyledtableCell2> {lesseeOrCoLessee?.emailAddress ? lesseeOrCoLessee?.emailAddress : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            {
                                routeName === 'applicant' ? (
                                    <><StyledTableRow >
                                        <StyledtableCell1> First Motorcycle Lease:</StyledtableCell1>
                                        <StyledtableCell2>{lesseeOrCoLessee?.firstTimeRider ? 'Yes' : 'No'}</StyledtableCell2>
                                    </StyledTableRow>
                                </>) : ""
                            }
                            <StyledTableRow >
                                <StyledtableCell1>Driver License:</StyledtableCell1>
                                <StyledtableCell2>{lesseeOrCoLessee?.driverLicense}</StyledtableCell2>
                            </StyledTableRow>
                            {lesseeOrCoLessee?.driverLicense === 'Yes' &&
                              <>
                                <StyledTableRow >
                                  <StyledtableCell1>License State:</StyledtableCell1>
                                  <StyledtableCell2>{lesseeOrCoLessee?.licenseState}</StyledtableCell2>
                                </StyledTableRow>
                                <StyledTableRow >
                                  <StyledtableCell1>License Number:</StyledtableCell1>
                                  <StyledtableCell2>{lesseeOrCoLessee?.driversLicenseIdNumber}</StyledtableCell2>
                                </StyledTableRow>
                              </>
                            }
                            <StyledTableRow >
                                <StyledtableCell1>Motorcycle Endorsement:</StyledtableCell1>
                                <StyledtableCell2>{lesseeOrCoLessee?.motorcycleEndorsement ? 'Yes' : 'No'}</StyledtableCell2>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}


const StyledtableCell1 = styled(TableCell)({
    fontWeight: "bold",
    fontSize: "14px"
})

const StyledTableRow = styled(TableRow)({
    "& td": { border: 0 }
})


const StyledtableCell2 = styled(TableCell)({
    fontSize: "14px"
})

const StyledTypography1 = styled(Typography)({
    color: "#fff",
    float: "left",
    padding: 1,
    fontWeight: "bold",
    fontSize: "14px"
})
const StyledTypography2 = styled(Typography)({
    color: "#fff",
    float: "right",
    padding: 1

})

