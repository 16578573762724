import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Collapse, Select, Layout, message, DatePicker, notification } from 'antd'
import moment from 'moment'
import { logger, network } from '../../../../../utils'
import ApplicationSteps from '../ApplicationSteps'
import { useHistory } from "react-router-dom";
import '../../styles/Applicant.css'
import type { DatePickerProps } from 'antd';
import Personal from './Personal'
import HomeAddress from './HomeAddress'
import MailingAddress from './MailingAddress'
import EmploymentDetails from './EmploymentDetails'
import Employer from './Employer'

//Material UI
import { Typography, Paper, Button } from '@mui/material'
import { useSelector, useDispatch } from "react-redux";
import {setApplicationDetails} from "../../../../../reducers/LeaseApplicationsReducer";

const dateFormat = 'MM/DD/YYYY'
const { Panel } = Collapse
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const formLayouts = {
  horizontal: {
    container: {
      placeholderCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
      formCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
    },
    field: {
      col: { xs: 24, sm: 24, md: 8, lg: 9, xl: 8 },
      colgroup: {
        2: { xs: 24, sm: 24, md: 16, lg: 10, xl: 8 },
      },
      colmem: {
        2: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
      },
    },
  },
  vertical: {
    container: {
      placeholderCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
      formCol: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
    },
    field: {
      col: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
      colgroup: {
        2: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
      },
      colmem: {
        2: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
      },
    },
  },
}

const openNotification = () => {
  const args = {
    message: 'Your attention please!',
    description:
      'The system doesn\'t have the zip code for this address, please add it manually',
    duration: 0,
  };
  notification.open(args);
};

const openNotification2 = () => {
  const args = {
    message: 'Your attention please!',
    description:
      'Please Add Valid SSN, 9 digits without letter or special characters',
    duration: 0,
  };
  notification.open(args);
};


interface Address {
  id?: number | undefined
  state?: string | undefined
  street1?: string | undefined
  street2?: string | undefined
  zipcode?: string | undefined
  county?: string | undefined
  cityId?: string | undefined
  cityOptions?: OptionData | any
  countyOptions?: OptionData | any
  stateOptions?: OptionData | any
  newCity?: newCity
}

interface employmentAddress {
  id?: number | undefined
  city?: string | undefined
  state?: string | undefined
}

interface Lessee {
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  dateOfBirth?: string | undefined
  ssn?: string | undefined
  driversLicenseIdNumber?: string | undefined
  homePhoneNumber?: string | undefined
  mobilePhoneNumber?: string | undefined
  homeAddress?: Address
  mailingAddress?: Address
  atAddressMonths?: number | string | undefined
  atAddressYears?: number | string | undefined
  monthYears?: number | string | undefined
  monthlyMortgage?: number | string | undefined
  homeOwnership?: number | undefined
  employerName?: string | undefined
  employerPhoneNumber?: string | undefined
  employmentAddress?: employmentAddress
  employmentStatus?: string | undefined
  jobTitle?: string | undefined
  timeAtEmployerYears?: number | string | undefined
  timeAtEmployerMonths?: number | string | undefined
  grossMonthlyIncome?: number | string | undefined
  relationshipToLesseeId?: number | string | undefined
  isDriving: number | undefined
  homeMobile?: number | undefined
  monthYearAtEmployer?: number | undefined
  emailAddress?: string | undefined
  driverLicense?: string | undefined
  motorcycleEndorsement?: boolean | undefined;
}

interface LeaseCalculator {
  id?: string | number | undefined
}

interface Props {
  isAddingCoApplicant?: boolean,
  data?: {
    id: string | number
    colessee?: Lessee | undefined
    leaseCalculator: LeaseCalculator
    relationshipToLesseeOptions?: any | undefined
  }
}

interface OptionData {
  value?: string | number
  label?: string
  parentId?: number
}

interface newCity {
  city?: string | undefined
  state?: string | undefined
  county?: string | undefined
}

const formatOptions = (params: { options: Array<any>; type?: string }) => {
  switch (params.type) {
    case 'city': {
      return params.options.map((value: any) => {
        return {
          value: value['id'],
          label: value['name'],
          parentId: value['countyId'],
        }
      })
    }
    default: {
      return params.options.map((value: any) => {
        return {
          value: value['id'],
          label: value['abbreviation'] ? value['abbreviation'] : value['name'],
        }
      })
    }
  }
}

const canSubmitData = window.localStorage.getItem("can_submit_speedlease");

export const CoApplicant: React.FC<Props> = ({isAddingCoApplicant}: Props) => {

  const data = useSelector((state: any) => state?.applications?.applicationDetails);
  const { lessee } = data || {}
  const [lesseeForm] = Form.useForm()

  const dispatch = useDispatch()

  let leaseApplicationId: string | number | undefined = data?.id
  let leaseCalculatorId: string | number | undefined = data?.leaseCalculator?.id

  const [requireCoApplicantFields, setRequireCoApplicantFields] = useState(false)

  const [lesseeHomeStateOptions, setLesseeHomeStateOptions] = useState<Array<OptionData>>([])
  const [lesseeHomeCountyOptions, setLesseeHomeCountyOptions] = useState<Array<OptionData>>([])
  const [lesseeHomeCityOptions, setLesseeHomeCityOptions] = useState<Array<OptionData>>([])
  const [lesseeHomeCityOptionsData, setLesseeHomeCityOptionsData] = useState<Array<OptionData>>([])

  const [lesseeMailStateOptions, setLesseeMailStateOptions] = useState<Array<OptionData>>([])
  const [lesseeMailCountyOptions, setLesseeMailCountyOptions] = useState<Array<OptionData>>([])
  const [lesseeMailCityOptions, setLesseeMailCityOptions] = useState<Array<OptionData>>([])
  const [lesseeMailCityOptionsData, setLesseeMailCityOptionsData] = useState<Array<OptionData>>([])

  const [zipHomeValidateStatus, setZipHomeValidateStatus] = useState<any | undefined>(undefined)
  const [zipHomeErrorMessage, setZipHomeErrorMessage] = useState<string | undefined>(undefined)

  const [zipMailValidateStatus, setZipMailValidateStatus] = useState<any | undefined>(undefined)
  const [zipMailErrorMessage, setZipMailErrorMessage] = useState<string | undefined>(undefined)

  const [relationshipToLesseeOptions, setRelationshipToLesseeOptions] = useState<Array<OptionData>>([])

  const [employmentStatusOptions, setEmploymentStatusOptions] = useState([])
  const [requireEmploymentFields, setRequireEmploymentFields] = useState(false)

  const [showHomeState, setShowHomeState] = useState<object | null>(null)
  const [showHomeCountyState, setShowHomeCountyState] = useState<object | null>(null)
  const [showHomeCityState, setShowHomeCityState] = useState<object | null>(null)
  const [showMailingState, setShowMailingState] = useState<object | null>(null)
  const [showMailingCountyState, setShowMailingCountyState] = useState<object | null>(null)
  const [showMailingCityState, setShowMailingCityState] = useState<object | null>(null)
  const [checkIfSaved, setCheckIfSaved] = useState(false)

  const [employmentYearOrMonth, setEmploymentYearOrMonth] = useState<any>(0)

  const [homeAddress, setHomeAddress] = useState({
    street1: '',
    street2: '',
    zipcode: '',
  })

  const [stateTarget, setStateTarget] = useState('')
  const [mailingStateTarget, setMailingStateTarget] = useState('')
  const [homeCountyTarget, setHomeCountyTarget] = useState('')
  const [mailingCountyTarget, setMailingCountyTarget] = useState('')
  const [cityTarget, setCityTarget] = useState('')
  const [cityMailingTarget, setCityMailingTarget] = useState('')
  const [homeCityId, setHomeCityId] = useState('')
  const [mailingCityId, setMailingCityId] = useState('')
  const [mailingAddress, setMailingAddress] = useState<any>({
    street1: '',
    street2: '',
    zipcode: '',
    state: '',
    county: '',
    city: '',
  })

  const [manualMonthYears, setManualMonthYear] = useState<any | undefined>(undefined)
  const [monthYears, setMonthYear] = useState<any | undefined>(undefined)
  const [nullMonthYear, setNullMonthYear] = useState(true)

  const [monthYearAtEmployer, setMonthYearAtEmployer] = useState<any | undefined>(undefined)
  const [manualMonthYearEmployer, setManualMonthYearEmployer] = useState<any | undefined>(undefined)

  const [btnAttribute, setBtnAttribute] = useState(true)
  const [btnClass, setBtnClass] = useState('button')
  const [otherRelationshipType, setOtherRelationshipType] = useState<string>("hidden")
  const [otherRelationshipRules, setOtherRelationshipRules] = useState<boolean>(false)
  const [otherRelationshipReset, setOtherRelationshipReset] = useState<boolean>(false)

  const [dateOfBirthValidation, setDateOfBirthValidation] = useState<any | undefined>(undefined)
  const [dateOfBirthValidationMessage, setDateOfBirthValidationMessage] = useState<any | undefined>(undefined)
  const [colesseeDob, setColesseeDob] = useState('')
  const [phoneOption, setPhoneOption] = useState<string | number | undefined>(undefined)

  
  const [employmentYearLabel, setEmploymentYearLabel] = useState('Time At Employer Years')
  const [employmentMonthLabel, setEmploymentMonthLabel] = useState('Time At Employer Months')
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
  const [motorcycleLicense, setMotorcycleLicense] = useState(false)
  const [licenseFormat, setLicenseFormat] = useState<RegExp>()

  const handleLicenseState = (value: string) => {
    setLicenseFormat(stateLicenseFormat(value))
  }

  const stateLicenseFormat = (state: string) => {
    let format = /^$/
    if(state === 'ALABAMA'){ //1
      format = /^\d{7,8}$/
    }
    else if(state === 'ALASKA'){ //2
      format = /^\d{7}$/
    }
    else if(state === 'ARIZONA'){ //3
      format = /^[a-zA-Z]\d{8}$|^\d{9}$/
    }
    else if(state === 'ARKANSAS'){ //4
      format = /^9\d{8}$/
    }
    else if(state === 'CALIFORNIA'){ //5
      format = /^[a-zA-Z]\d{7}$/
    }
    else if(state === 'COLORADO'){ //6
      format = /^\d{2}-\d{3}-\d{4}$/
    }
    else if(state === 'CONNECTICUT'){ //7
      format = /^\d{9}$/
    }
    else if(state === 'DELAWARE'){ //8
      format = /^\d{7}$/
    }
    else if(state === 'DISTRICT OF COLUMBIA'){ //9
      format = /^\d{7}$/
    }
    else if(state === 'FLORIDA'){ //10
      format = /^[a-zA-Z]\d{3}\d{3}\d{3}\d{3}$|^[a-zA-Z]\d{12}$|^[a-zA-Z]\d{3}-\d{3}-\d{2}-\d{3}-\d$|^[a-zA-Z]\d{3}-\d{3}-\d{3}-\d{3}$/
    }
    else if(state === 'GEORGIA'){ //11
      format = /^\d{7,9}$/
    }
    else if(state === 'ILLINOIS'){ //12
      format = /^[a-zA-Z]\d{3}-\d{4}-\d{4}$|^[a-zA-Z]\d{11}$/
    }
    else if(state === 'INDIANA'){ //13
      format = /^\d{4}-\d{2}-\d{4}$/
    }
    else if(state === 'KANSAS'){ //14
      format = /^[a-zA-Z]\d{2}-\d{2}-\d{4}$|^\d{9}$/
    }
    else if(state === 'KENTUCKY'){ //15
      format = /^[a-zA-Z]\d{2}-\d{3}-\d{3}$/
    }
    else if(state === 'MAINE'){ //16
      format = /^\d{7}$|^\d{7}[a-zA-Z]$|^\d{8}$/
    }
    else if(state === 'LOUSIANA'){ //17
      format = /^\d{9}$/
    }
    else if(state === 'MARYLAND'){ //18
      format = /^[a-zA-Z]\d{12}$|^[a-zA-Z]-\d{3}-\d{3}-\d{3}-\d{3}$/
    }
    else if(state === 'MASSACHUSETTS'){ //19
      format = /^[a-zA-Z]\d{8}$|^\d{9}$/
    }
    else if(state === 'MICHIGAN'){ //20
      format = /^[a-zA-Z]\d{12}$|^[a-zA-Z]\s\d{3}\s\d{3}\s\d{3}\s\d{3}$/
    }
    else if(state === 'MINNESOTA'){ //21
      format = /^[a-zA-Z]\d{12}$/
    }
    else if(state === 'MISSISSIPPI'){ //22
      format = /^\d{3}-\d{2}-\d{4}$/
    }
    else if(state === 'MISSOURI'){ //23
      format = /^[a-zA-Z]\d{9}$|^\d{3}[a-zA-Z]\d{6}$|^[a-zA-Z]\d{6}R$|^\d{8}[a-zA-Z]{2}$|^\d{9}[a-zA-Z]$|^\d{9}$/
    }
    else if(state === 'MONTANA'){ //24
      format = /^(([0][1-9]|[1][0-2])\d{3}([1-9][0-9]{3})41([0][1-9]|[1][0-9]|[2][0-9]|[3][0-1]))$|^\d{9}$/
    }
    else if(state === 'NEBRASKA'){ //25
      format = /^[a-zA-Z]\d{6,8}$/
    }
    else if(state === 'NEVADA'){ //26
      format = /^\d{9,10}$|^\d{12}$/
    }
    else if(state === 'NEW HAMPSHIRE'){ //27
      format = /^([0][1-9]|[1][0-2])[a-zA-Z]{3}\d{2}(0[1-9]|[1-2][0-9]|3[0-1])\d$/
    }
    else if(state === 'NEW JERSEY'){ //28
      format = /^[a-zA-Z]\d{14}$|^[a-zA-Z]\d{4}\s\d{5}\s\d{5}$/
    }
    else if(state === 'NEW MEXICO'){ //29
      format = /^\d{8,9}$/
    }
    else if(state === 'NEW YORK'){ //30
      format = /^\d{3}\s\d{3}\s\d{3}$|^\d{9}$/
    }
    else if(state === 'NORTH CAROLINA'){ //31
      format = /^\d{12}$/
    }
    else if(state === 'NORTH DAKOTA'){ //32
      format = /^[a-zA-Z]{3}-\d{2}-\d{4}$/
    }
    else if(state === 'OHIO'){ //33
      format = /^[a-zA-Z]{1}[0-9]{4,8}$|^[a-zA-Z]{2}[0-9]{3,7}$|^[0-9]{8}$/
    }
    else if(state === 'OKLAHOMA'){ //34
      format = /^[a-zA-Z]\d{9}$|^\d{9}$/
    }
    else if(state === 'OREGON'){ //35
      format = /^\d{7}$/
    }
    else if(state === 'PENNSYLVANIA'){ //36
      format = /^\d{2}\s\d{3}\s\d{3}$/
    }
    else if(state === 'RHODE ISLAND'){ //37
      format = /^[1-9]{2}\d{5}$/
    }
    else if(state === 'SOUTH CAROLINA'){ //38
      format = /^\d{9}$/
    }
    else if(state === 'SOUTH DAKOTA'){ //39
      format = /^\d{8}$/
    }
    else if(state === 'TENNESSEE'){ //40
      format = /^\d{7,9}$/
    }
    else if(state === 'TEXAS'){ //41
      format = /^\d{8}$/
    }
    else if(state === 'UTAH'){ //42
      format = /^\d{9}$/
    }
    else if(state === 'VERMONT'){ //43
      format = /^\d{8}$|^\d{7}[a-zA-Z]$/
    }
    else if(state === 'VIRGINIA'){ //44
      format = /^[a-zA-Z]\d{2}-\d{2}-\d{4}$|^[a-zA-Z]\d{8}$|^\d{9}$|/
    }
    else if(state === 'WASHINGTON'){ //45
      format = /^[a-zA-Z]{3}\*\*[a-zA-Z]{2}\d{3}[a-zA-Z]\d$/
    }
    else if(state === 'WEST VIRGINIA'){ //46
      format = /^\d{7}$|^[a-zA-Z]\d{6}$|^[a-zA-Z]{2}\d{5}$/
    }
    else if(state === 'WISCONSIN'){ //47
      format = /^[a-zA-Z]\d{3}-\d{4}-\d{4}-\d{2}$/
    }
    else if(state === 'WYOMING'){ //48
      format = /^\d{6}-\d{3}$/
    }
    else if(state === 'IDAHO'){ //49
      format = /^[a-zA-Z]{2}\d{6}[a-zA-Z]$|^\d{9}$/
    }
    else if(state === 'IOWA'){ //50
      format = /^\d{3}[a-zA-Z]{2}\d{4}$|^\d{9}$/
    }
    else if(state === 'HAWAII'){ //51
      format = /^[a-zA-Z]\d{8}$|^\d{9}$/
    }
    return format;
  }

  const validStateArr = ["AL", "AZ", "CA", "DE", "FL", "GA", "IL", "IN", "LA", "MD",
    "MI", "MS", "MO", "NV", "NJ", "NM", "NC", "OH", "OK", "PA", "SC", "TN", "TX", "VA", "CT"]

  let isStateValid;

  const submitApplication = async (values: any) => {
    try {
      let response: any = await network.PUT(`/api/v1/dealers/update-details?id=${leaseApplicationId}`, values)
      if (response?.data?.success === false){
        message.error(response?.data?.message);
        setDisableSubmitBtn(false)
      } else {
        dispatch(setApplicationDetails(response?.data?.data?.leaseApplication))
        setBtnAttribute(false)
        setBtnClass('button')
        message.success('Save Successfully')
        let path;
        if (isAddingCoApplicant){
          path = `/applications/${leaseApplicationId}/summary?add_co_applicant=true`
        } else {
          path = `/applications/${leaseApplicationId}/summary`
        }
        history.push(path);
      }
    } catch (e:any) {
      if(e?.response?.status === 409 && e?.response?.data?.message === 'can submit disabled') {
        window.localStorage.setItem('can_submit_speedlease', 'false');
        message.error('This dealership do not have permission to submit application.')
        setDisableSubmitBtn(true)
      } else {
        logger.error('Request Error', e)
        message.error('Error saving details')
        setDisableSubmitBtn(false)
      }
    }
  }

  const handlePhoneChange = (e: any) => {
    setPhoneOption(e.target.value)
  }
  const handleMailingCountytarget = (e: any, f: any) => {
    setMailingCountyTarget(f.children)
  }
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    
    let dob = values?.colesseeAttributes?.dateOfBirth
    let age = Math.floor((new Date().getTime() - new Date(dob).getTime()) / 3.15576e+10);
    if (age < 18) {
      setDateOfBirthValidation('error');
      setDateOfBirthValidationMessage('Date of Birth must be 18 years old');
      return false
    } else {
      setDateOfBirthValidation(undefined);
      setDateOfBirthValidationMessage('');
    }
    const myValues = {
      isAddCoApplicant: isAddingCoApplicant === true,
      colesseeAttributes: {
        dateOfBirth: colesseeDob,
        employerName: values?.colesseeAttributes?.employerName,
        employerPhoneNumber: values?.colesseeAttributes?.employerPhoneNumber,
        emailAddress: values?.colesseeAttributes?.emailAddress,
        licenseState:  values?.colesseeAttributes?.licenseState,
        driversLicenseIdNumber:  values?.colesseeAttributes?.driversLicenseIdNumber,
        employmentAddressAttributes: {
          city: values?.colesseeAttributes?.employmentAddressAttributes?.city,
          state: values?.colesseeAttributes?.employmentAddressAttributes?.state
        },
        employmentStatus: values?.colesseeAttributes?.employmentStatus,
        firstName: values?.colesseeAttributes?.firstName,
        grossMonthlyIncome: values?.colesseeAttributes?.grossMonthlyIncome,
        homeAddressAttributes: {
          cityId: homeCityId,
          city: cityTarget,
          county: homeCountyTarget,
          state: stateTarget,
          street1: values?.colesseeAttributes?.homeAddressAttributes?.street1,
          street2: values?.colesseeAttributes?.homeAddressAttributes?.street2,
          zipcode: values?.colesseeAttributes?.homeAddressAttributes?.zipcode
        },
        homeOwnership: values?.colesseeAttributes?.homeOwnership,
        isDriving: values?.colesseeAttributes?.isDriving,
        jobTitle: values?.colesseeAttributes?.jobTitle,
        lastName: values?.colesseeAttributes?.lastName,
        mailingAddressAttributes: {
          cityId: mailingCityId,
          city: cityMailingTarget,
          county: mailingCountyTarget,
          state: mailingCountyTarget,
          street1: values?.colesseeAttributes?.mailingAddressAttributes?.street1,
          street2: values?.colesseeAttributes?.mailingAddressAttributes?.street2,
          zipcode: values?.colesseeAttributes?.mailingAddressAttributes?.zipcode
        },
        middleName: values?.colesseeAttributes?.middleName,
        mobilePhoneNumber: values?.colesseeAttributes?.mobilePhoneNumber,
        atAddressMonths: values?.colesseeAttributes?.atAddressMonths,
        atAddressYears: values?.colesseeAttributes?.atAddressYears,
        monthlyMortgage: values?.colesseeAttributes?.monthlyMortgage,
        driverLicense: values?.colesseeAttributes?.driverLicense,
        motorcycleEndorsement: values?.colesseeAttributes?.motorcycleEndorsement,
        relationshipToLesseeId: values?.colesseeAttributes?.relationshipToLesseeId,
        ssn: values?.colesseeAttributes?.ssn,
        homeMobile: values?.colesseeAttributes?.homeMobile,
        timeAtEmployerMonths: values?.colesseeAttributes?.timeAtEmployerMonths,
        timeAtEmployerYears: values?.colesseeAttributes?.timeAtEmployerYears,
        monthYearAtEmployer: values?.colesseeAttributes?.monthYearAtEmployer,
        // employmentMonthOrYear: values?.colesseeAttributes?.employmentMonthOrYear,
        employmentMonthOrYear: employmentYearOrMonth,
        otherRelationship: otherRelationshipReset ? "" : values?.colesseeAttributes?.otherRelationship
      }
    }
    let pattern = /^\d{3}-\d{2}-\d{4}$/
    if (!values?.colesseeAttributes?.ssn?.match(pattern)) {
      openNotification2();
      setDisableSubmitBtn(false)
    } else {
      setDisableSubmitBtn(true);
      submitApplication(myValues)
    }
  }

  const returnToApplicant = () => {
    let path = `/applications/${leaseApplicationId}/applicant`
    history.push(path);
  }

  const handleRelationshiptoApplicant = (value: any) => {

    if (value == 6) {
      setOtherRelationshipReset(false)
    } else {
      setOtherRelationshipReset(true)
    }
  }

  const handleLesseeHomeZipcodeBlur = async () => {
    let zipcode = lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          homeAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            setLesseeHomeStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            setLesseeHomeCountyOptions(
              formatOptions({
                options: response.data.county || [],
                type: 'county',
              })
            )
            setLesseeHomeCityOptionsData(formatOptions({ options: response.data.city || [], type: 'city' }))
          }
          if (!isStateValid || response.data.city.length < 1 || response.data.city === undefined) {
            setZipHomeValidateStatus('error')
            setZipHomeErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowHomeState(null)
          } else {
            setZipHomeValidateStatus(undefined)
            setZipHomeErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('handleLesseeHomeZipcodeBlur.Request Error', error)
        })
    } catch (e) {
      logger.error('handleLesseeHomeZipcodeBlur Error', e)
    }
  }

  const handleLesseeMailZipcodeBlur = async () => {
    let zipcode = lesseeForm.getFieldValue(['colesseeAttributes', 'mailingAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          mailingAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            setLesseeMailStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            setLesseeMailCountyOptions(
              formatOptions({
                options: response.data.county || [],
                type: 'county',
              })
            )
            setLesseeMailCityOptionsData(formatOptions({ options: response.data.city || [], type: 'city' }))
          }
          if (!isStateValid || response.data.city.length < 1 || response.data.city === undefined) {
            setZipMailValidateStatus('error')
            setZipMailErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowMailingState(null)
          } else {
            setZipMailValidateStatus(undefined)
            setZipMailErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('handleLesseeMailZipcodeBlur.Request Error', error)
        })
    } catch (e) {
      logger.error('handleLesseeMailZipcodeBlur Error', e)
    }
  }

  const handleHomeStateChange = () => {
    setShowHomeState(null)
  }

  const handleHomeCountyStateChange = (countyStateId: any) => {
    if (countyStateId) {
      lesseeForm.setFieldsValue({
        colesseeAttributes: { homeAddressAttributes: { cityId: null } },
      })
      setLesseeHomeCityOptions(lesseeHomeCityOptionsData.filter((obj: OptionData) => obj.parentId === parseInt(countyStateId)))
    }

    setShowHomeCountyState(null)
  }

  const handleHomeCityStateChange = (e: any) => {
    setHomeCityId(e)
    setShowHomeCityState(null)
  }

  const handleMailingStateChange = () => {
    setShowMailingState(null)
  }

  const handleMailingCountyStateChange = (countyStateId: any) => {
    if (countyStateId) {
      lesseeForm.setFieldsValue({
        colesseeAttributes: { mailingAddressAttributes: { cityId: null } },
      })
      setLesseeMailCityOptions(lesseeMailCityOptionsData.filter((obj: OptionData) => obj.parentId === parseInt(countyStateId)))
    }

    setShowMailingCountyState(null)
  }

  const handleMailingCityStateChange = (e: any) => {
    setMailingCityId(e)
    setShowMailingCityState(null)
  }

  const autoCompleteLesseeHomeBeforePlacesGetDetails = (value: any, option: any) => {
    lesseeForm.setFieldsValue({
      colesseeAttributes: {
        homeAddressAttributes: {
          street1: option.data.structured_formatting.main_text,
        },
      },
    })
  }

  const autoCompleteLesseeHomeOnPlacesGetDetailsResult = (placeResult: any, placeServiceStatus: any) => {

    let addressLength = placeResult.address_components.length - 1
    let addressToTest = placeResult.address_components[addressLength]

    if (placeServiceStatus == 'OK') {
      placeResult.address_components.map((component: any) => {
        if (component.types.includes('postal_code')) {
          lesseeForm.setFieldsValue({
            colesseeAttributes: {
              homeAddressAttributes: { zipcode: component.long_name },
            },
          })
          autoCompleteLesseeHomeZipcodeStateCountyCity(placeResult.address_components)
        }
      })
      if (addressToTest.types.length > 1) {
        openNotification();
      }
    }
  }

  const autoCompleteLesseeHomeZipcodeStateCountyCity = async (addressComponents: any) => {
    let zipcode = lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          homeAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {
            lesseeForm.setFieldsValue({
              colesseeAttributes: {
                homeAddressAttributes: { city: response.data.city[0].name }
              },
            })

            setStateTarget(response.data.state[0].abbreviation)
            setHomeCityId(response.data.city[0].id)
            setHomeCountyTarget(response.data.county[0].name)
            setCityTarget(response.data.city[0].name)


            setLesseeHomeStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            let countyParishOptions: any = formatOptions({
              options: response.data.county || [],
              type: 'county',
            })
            setLesseeHomeCountyOptions(countyParishOptions)
            let cityOptions: any = formatOptions({
              options: response.data.city || [],
              type: 'city',
            })
            setLesseeHomeCityOptionsData(cityOptions)

            setShowHomeState(null)
            setShowHomeCountyState(null)
            setShowHomeCityState(null)

            // Try to autocomplete state
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_1')) {
                lesseeForm.setFieldsValue({
                  colesseeAttributes: {
                    homeAddressAttributes: { state: component.short_name },
                  },
                })
              }
            })

            // Try to autocomplete county
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_2')) {
                let countyName: string = component.short_name.toLowerCase().replace(' county', '').toUpperCase()
                lesseeForm.setFieldsValue({
                  colesseeAttributes: {
                    homeAddressAttributes: { county: countyName },
                  },
                })

                // Find state id from countyParishOptions using countyName
                countyParishOptions.map((option: any) => {
                  if (option.label == countyName) {
                    setLesseeHomeCityOptions(cityOptions.filter((obj: OptionData) => obj.parentId === parseInt(option.value)))
                  }
                })
              }
            })

            // Try to autocomplete city
            addressComponents.map((component: any) => {
              if (component.types.includes('locality')) {
                cityOptions.map((option: any) => {
                  if (option.label == component.long_name.toUpperCase()) {
                    lesseeForm.setFieldsValue({
                      colesseeAttributes: {
                        homeAddressAttributes: { city: response.data.city[0].name },
                      },
                    })
                  }
                })
              }
            })
          }
          if (!isStateValid || response.data.city.length < 1 || response.data.city === undefined) {
            setZipHomeValidateStatus('error')
            setZipHomeErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowHomeState(null)
            lesseeForm.setFieldsValue({
              colesseeAttributes: {
                homeAddressAttributes: {
                  state: null,
                  county: null,
                  cityId: null,
                  city: null
                },
              },
            })
          } else {
            setZipHomeValidateStatus(undefined)
            setZipHomeErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('autoCompleteLesseeHomeZipcodeStateCountyCity.Request Error', error)
        })
    } catch (e) {
      logger.error('autoCompleteLesseeHomeZipcodeStateCountyCity Error', e)
    }
  }

  const autoCompleteLesseeMailingBeforePlacesGetDetails = (value: any, option: any) => {
    lesseeForm.setFieldsValue({
      colesseeAttributes: {
        mailingAddressAttributes: {
          street1: option.data.structured_formatting.main_text,
        },
      },
    })
  }

  const autoCompleteLesseeMailingOnPlacesGetDetailsResult = (placeResult: any, placeServiceStatus: any) => {

    let addressLength = placeResult.address_components.length - 1
    let addressToTest = placeResult.address_components[addressLength]

    if (placeServiceStatus == 'OK') {
      placeResult.address_components.map((component: any) => {
        if (component.types.includes('postal_code')) {
          lesseeForm.setFieldsValue({
            colesseeAttributes: {
              mailingAddressAttributes: { zipcode: component.long_name },
            },
          })
          autoCompleteLesseeMailingZipcodeStateCountyCity(placeResult.address_components)
        }
      })

      if (addressToTest.types.length > 1) {
        openNotification();
      }
    }
  }

  const autoCompleteLesseeMailingZipcodeStateCountyCity = async (addressComponents: any) => {
    let zipcode = lesseeForm.getFieldValue(['colesseeAttributes', 'mailingAddressAttributes', 'zipcode'])

    try {
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          mailingAddressAttributes: {
            state: null,
            county: null,
            cityId: null,
          },
        },
      })

      await network
        .GET(`/api/v1/address/city-details?zipcode=${zipcode}`)
        .then((response) => {
          isStateValid = validStateArr.includes(response.data.state[0]?.abbreviation);
          if (response.data.is_state_active_on_calculator) {

            lesseeForm.setFieldsValue({
              colesseeAttributes: {
                mailingAddressAttributes: { city: response.data.city[0].name }
              },
            })

            setMailingCityId(response.data.city[0].id)
            setMailingCountyTarget(response.data.county[0].name)
            setMailingStateTarget(response.data.state[0].abbreviation)

            setLesseeMailStateOptions(
              formatOptions({
                options: response.data.state || [],
                type: 'state',
              })
            )
            let countyParishOptions: any = formatOptions({
              options: response.data.county || [],
              type: 'county',
            })
            setLesseeMailCountyOptions(countyParishOptions)
            let cityOptions: any = formatOptions({
              options: response.data.city || [],
              type: 'city',
            })
            setLesseeMailCityOptionsData(cityOptions)

            setShowMailingState(null)
            setShowMailingCountyState(null)
            setShowMailingCityState(null)

            // Try to autocomplete state
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_1')) {
                lesseeForm.setFieldsValue({
                  colesseeAttributes: {
                    mailingAddressAttributes: { state: component.short_name },
                  },
                })
              }
            })

            // Try to autocomplete county
            addressComponents.map((component: any) => {
              if (component.types.includes('administrative_area_level_2')) {
                let countyName: string = component.short_name.toLowerCase().replace(' county', '').toUpperCase()
                lesseeForm.setFieldsValue({
                  colesseeAttributes: {
                    mailingAddressAttributes: { county: countyName },
                  },
                })

                // Find state id from countyParishOptions using countyName
                countyParishOptions.map((option: any) => {
                  if (option.label == countyName) {
                    setLesseeMailCityOptions(cityOptions.filter((obj: OptionData) => obj.parentId === parseInt(option.value)))
                  }
                })
              }
            })

            // Try to autocomplete city
            addressComponents.map((component: any) => {
              if (component.types.includes('locality')) {
                cityOptions.map((option: any) => {
                  if (option.label == component.long_name.toUpperCase()) {
                    lesseeForm.setFieldsValue({
                      colesseeAttributes: {
                        mailingAddressAttributes: { city: response.data.city[0].name },
                      },
                    })
                  }
                })
              }
            })
          }
          if (!isStateValid || response.data.city.length < 1 || response.data.city === undefined) {
            setZipMailValidateStatus('error')
            setZipMailErrorMessage('Speed Leasing currently does not lease to residents of this state.')
            setShowMailingState(null)
            lesseeForm.setFieldsValue({
              colesseeAttributes: {
                mailingAddressAttributes: {
                  state: null,
                  county: null,
                  cityId: null,
                  city: null
                },
              },
            })
          } else {
            setZipMailValidateStatus(undefined)
            setZipMailErrorMessage(undefined)
          }
        })
        .catch((error) => {
          logger.error('autoCompleteLesseeMailingZipcodeStateCountyCity.Request Error', error)
        })
    } catch (e) {
      logger.error('autoCompleteLesseeMailingZipcodeStateCountyCity Error', e)
    }
  }

  const getEmployerStatus = async () => {
    try {
      await network.GET(`/api/v1/employment-status`).then((response) => {
        if (response.data.employment_status) {
          setEmploymentStatusOptions(
            response.data.employment_status.map((item: [string, string | number]) => {
              return {
                value: item[1],
                label: item[0],
              }
            })
          )
        } else {
          setEmploymentStatusOptions([])
        }
      })
    } catch (e) {
      logger.error('Request Error', e)
    }
  }

  const handleEmploymentStatus = (value: string) => {
    if (value === '0' || value === '1') {
      setRequireEmploymentFields(true)
      setEmploymentYearLabel('Time At Employer Years')
      setEmploymentMonthLabel('Time At Employer Months')
    } else {
      setRequireEmploymentFields(false)
      if(value === '2') {
        setEmploymentYearLabel('Years on Soc Sec')
        setEmploymentMonthLabel('Months on SOc Sec')
      }
      if(value === '3') {
        setEmploymentYearLabel('Unemployed Since (Years)')
        setEmploymentMonthLabel('Unemployed Since (Months)')
      }
      if(value === '4') {
        setEmploymentYearLabel('Disabled Since (Years)')
        setEmploymentMonthLabel('Disabled Since (Months)')
      }
      if(value === '5') {
        setEmploymentYearLabel('How long have you \n been retired? (Years)')
        setEmploymentMonthLabel(' How long have you \n been retired? (Months)')
      }
    }
  }

  useEffect(() => {
    if (canSubmitData === "false") {
      message.warning('This dealership do not have permission to submit application.')
      setDisableSubmitBtn(true);
    }
    getEmployerStatus()
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    window.scrollTo(0, 0)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
    }
  }, [])

  useEffect(() => {
    let employmentStatus = lesseeForm.getFieldValue(['colesseeAttributes', 'employmentStatus'])
    if(employmentStatus === undefined) {
      setRequireEmploymentFields(true)
    }
    setMonthYear(data?.colessee?.monthYears)
    setLesseeHomeStateOptions(data?.colessee?.homeAddress?.stateOptions)
    setLesseeHomeCountyOptions(data?.colessee?.homeAddress?.countyOptions)
    setLesseeHomeCityOptions(data?.colessee?.homeAddress?.cityOptions)

    setLesseeMailStateOptions(data?.colessee?.mailingAddress?.stateOptions)
    setLesseeMailCountyOptions(data?.colessee?.mailingAddress?.countyOptions)
    setLesseeMailCityOptions(data?.colessee?.mailingAddress?.cityOptions)
    setRelationshipToLesseeOptions(data?.relationshipToLesseeOptions)
    if (data?.colessee) {
      setCheckIfSaved(true)
    }

    if (data?.colessee?.dateOfBirth !== '') {
      setColesseeDob(data?.colessee?.dateOfBirth)
    }

    if (data?.colessee?.homeAddress?.cityId) {
      setHomeCityId(data?.colessee?.homeAddress?.cityId)
    }

    if (data?.colessee?.mailingAddress?.cityId) {
      setMailingCityId(data?.colessee?.mailingAddress?.cityId)
    }

    if (data?.colessee?.homeAddress?.newCity?.city) {
      setCityTarget(data?.colessee?.homeAddress?.newCity?.city)
    }

    if (data?.colessee?.mailingAddress?.newCity?.city) {
      setCityMailingTarget(data?.colessee?.mailingAddress?.newCity?.city)
    }

    if (data?.colessee?.homeAddress?.newCity?.county) {
      setHomeCountyTarget(data?.colessee?.homeAddress?.newCity?.county)
    }

    if (data?.colessee?.mailingAddress?.newCity?.county) {
      setMailingCountyTarget(data?.colessee?.mailingAddress?.newCity?.county)
    }

    if (data?.colessee?.homeAddress?.newCity?.state) {
      setStateTarget(data?.colessee?.homeAddress?.newCity?.state)
    }

    if (data?.colessee?.mailingAddress?.newCity?.state) {
      setMailingStateTarget(data?.colessee?.mailingAddress?.newCity?.state)
    }

    if (data?.colessee?.monthYearAtEmployer) {
      setMonthYearAtEmployer(data?.colessee?.monthYearAtEmployer)
    }

    if (data?.colessee?.driverLicense) {
      setMotorcycleLicense(data?.colessee?.driverLicense)
    }

    if (data?.colessee?.licenseState) {
      setLicenseFormat(stateLicenseFormat(data?.colessee?.licenseState))
    }

    if (data?.colessee?.otherRelationship) {
      setOtherRelationshipType("")
    }

  }, [data])

  const handleTabClosing = () => {
    // lesseeForm.submit()
  }

  const alertUser = (event: any) => {
    event.preventDefault()
    event.returnValue = ''
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setHomeAddress({
      ...homeAddress,
      [name]: value,
    })
  }

  const handleFormChange = () => {
    setBtnClass('green-border')
  }

  const handleStateTarget = (e: any, f: any) => {
    setStateTarget(f.children)
  }

  const handleMailingStatetarget = (e: any, f: any) => {
    setMailingStateTarget(f.children)
  }

  const handleCountyTarget = (e: any, f: any) => {
    setHomeCountyTarget(f.children)
  }

  const handleMalingCountyTarget = (e: any, f: any) => {
    setMailingCountyTarget(f.children)
  }

  const handleCityTarget = (e: any, f: any) => {
    setCityTarget(f.children)
  }

  const fillMailingAddress = (e: any) => {
    if (e.target.checked) {
      // Copy options
      setLesseeMailStateOptions(lesseeHomeStateOptions)
      setLesseeMailCountyOptions(lesseeHomeCountyOptions)
      setLesseeMailCityOptions(lesseeHomeCityOptions)
      setMailingCityId(homeCityId)
      setCityMailingTarget(cityTarget)
      setMailingCountyTarget(homeCountyTarget)
      setMailingStateTarget(stateTarget)

      // Auto fill fields
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          mailingAddressAttributes: {
            street1: lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'street1']),
            street2: lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'street2']),
            city: lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'city']),
            county: lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'county']),
            state: lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'state']),
            zipcode: lesseeForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'zipcode']),
          },
        },
      })
    } else {
      // Clear options and fields
      setLesseeMailStateOptions([])
      setLesseeMailCountyOptions([])
      setLesseeMailCityOptions([])
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          mailingAddressAttributes: {
            street1: null,
            street2: null,
            cityId: null,
            county: null,
            state: null,
            zipcode: null,
            city: null
          },
        },
      })
    }
  }

  const handleManualMonthYear = (e: any) => {
    setManualMonthYear(null)
    setMonthYear(e)
  }

  const handleMailingCityTarget = (e: any, f: any) => {
    setCityMailingTarget(f.children)
  }

  const handleMonthYear = (e: any) => {
    if (e !== null) {
      let startDate = e._d
      let newDate: any = moment().toDate()
      let monthYearDiff = newDate - startDate
      const toMonthYears = Number((monthYearDiff / (365) / (86400000)).toFixed(2))
      setMonthYear(toMonthYears)
      setManualMonthYear(null)
      setNullMonthYear(false)
    } else {
      if (manualMonthYears !== null) {
        setMonthYear(manualMonthYears)
        setNullMonthYear(false)
      }
    }
  }

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day');
  }

  const handleMonthYearEmployerResetValidator = () => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        monthYearAtEmployer: null
      },
    })
  }

  const handleMonthYearEmployerValidator = () => {
    lesseeForm.setFieldsValue({
      lesseeAttributes: {
        monthYearAtEmployer: 'T'
      },
    })
  }

  const usStates = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' },
  ]

  lesseeForm.setFieldsValue({
    street1: mailingAddress.street1,
    street2: mailingAddress.street2,
    zipcode: mailingAddress.zipcode,
    state: mailingAddress.state,
    county: mailingAddress.county,
    city: mailingAddress.city,
  })

  const handleValidator = () => {
    lesseeForm.setFieldsValue({
      colesseeAttributes: {
        monthYears: 'T'
      },
    })
  }

  const handleValidationReset = () => {
    lesseeForm.setFieldsValue({
      colesseeAttributes: {
        monthYears: null
      },
    })
  }

  const handleManualMonthYearEmployer = (e: any) => {
    setManualMonthYearEmployer(null)
    setMonthYearAtEmployer(e)
  }

  const handleMonthYearEmployer = (e: any) => {
    if (e !== null) {
      let startDate = e._d
      let newDate: any = moment().toDate()
      let monthYearDiff = newDate - startDate
      const toMonthYears = Number((monthYearDiff / (365) / (86400000)).toFixed(2))
      setMonthYearAtEmployer(toMonthYears)
      setManualMonthYearEmployer(null)
    } else {
      if (manualMonthYearEmployer !== null) {
        setMonthYearAtEmployer(manualMonthYearEmployer)
      }
    }
  }

  const onChangeDob: DatePickerProps['onChange'] = (date, dateString) => {
    let age = Math.floor((new Date().getTime() - new Date(dateString).getTime()) / 3.15576e+10);
    if (age < 18) {
      setDateOfBirthValidation('error');
      setDateOfBirthValidationMessage('Date of Birth must be 18 years old');
    }
    else if(dateString === null || dateString === '' || dateString === undefined){
      setColesseeDob(dateString);
      setDateOfBirthValidation('error');
      setDateOfBirthValidationMessage('Date of Birth is required!');
    }
    else {
      setColesseeDob(dateString);
      setDateOfBirthValidation(undefined);
      setDateOfBirthValidationMessage('');
    }
  };

  const fillLesseeHomeAddress = (e: any) => {
    if (e.target.checked) {
      // Auto fill fields
      setLesseeHomeStateOptions(data?.lessee?.homeAddress?.stateOptions)
      setLesseeHomeCountyOptions(data?.lessee?.homeAddress?.countyOptions)
      setLesseeHomeCityOptions(data?.lessee?.homeAddress?.cityOptions)
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          homeAddressAttributes: {
            state: data?.lessee?.homeAddress?.newCity?.state,
            street1: data?.lessee?.homeAddress?.street1?.toLocaleLowerCase(),
            street2: data?.lessee?.homeAddress?.street2?.toLocaleLowerCase(),
            zipcode: data?.lessee?.homeAddress?.zipcode,
            county: data?.lessee?.homeAddress?.newCity?.county,
            city: data?.lessee?.homeAddress?.newCity?.city,
          },
        },
      })
      setHomeCityId(data?.lessee?.homeAddress?.cityId)
      setCityTarget(data?.lessee?.homeAddress?.newCity?.city)
      setHomeCountyTarget(data?.lessee?.homeAddress?.newCity?.county)
      setStateTarget(data?.lessee?.homeAddress?.newCity?.state)
    } else {
      // Clear options and fields
      lesseeForm.setFieldsValue({
        colesseeAttributes: {
          homeAddressAttributes: {
            state: null,
            street1: null,
            street2: null,
            zipcode: null,
            county: null,
            city: null,
          },
        },
      })
      setHomeCityId('')
      setCityTarget('')
      setHomeCountyTarget('')
      setStateTarget('')
      setLesseeHomeStateOptions([])
      setLesseeHomeCountyOptions([])
      setLesseeHomeCityOptions([])
    }
  }

  const handleZeroDefaultInputValue = (e:any)=>{
    if(e.target.value == 0) {
      e.target.value = ''
    }
  }

  return data ? (
    <>
      <ApplicationSteps stepType={`co-applicant`} leaseApplicationId={`${leaseApplicationId}`} leaseCalculatorId={`${leaseCalculatorId}`} save={null} attribute={btnAttribute} data={data} isSaved={checkIfSaved} isSubmitted={false} isAddingCoApplicant={isAddingCoApplicant} />
      <div className="title-container">
        <div className="subtitle-container">
          <Typography variant="h3">Co-Applicant</Typography>
          <p> Enter information about yourself to apply for a lease. </p>
          {
           canSubmitData === "false" &&  
           <Typography variant="h6" sx={{ fontWeight: 400 }} style={{color: "#e93b1b"}}>
            This dealership do not have permission to submit application.
          </Typography>
          }
        </div>
        <Form
          form={lesseeForm}
          {...layout}
          onFinish={handleSubmit}
          onChange={handleFormChange}
          scrollToFirstError={true}
          initialValues={{
            colesseeAttributes: {
              firstName: data?.colessee?.firstName?.toLocaleLowerCase(),
              middleName: data?.colessee?.middleName?.toLocaleLowerCase(),
              lastName: data?.colessee?.lastName?.toLocaleLowerCase(),
              // dateOfBirth: data?.colessee?.dateOfBirth?.toLocaleLowerCase(),
              ssn: data?.colessee?.ssn,
              homePhoneNumber: data?.colessee?.homePhoneNumber,
              mobilePhoneNumber: data?.colessee?.mobilePhoneNumber,
              atAddressMonths: data?.colessee?.atAddressMonths || 0,
              atAddressYears: data?.colessee?.atAddressYears || 0,
              monthlyMortgage: data?.colessee?.monthlyMortgage,
              homeOwnership: data?.colessee?.homeOwnership,
              employerName: data?.colessee?.employerName?.toLocaleLowerCase(),
              employerPhoneNumber: data?.colessee?.employerPhoneNumber,
              employmentStatus: data?.colessee?.employmentStatusIndex,
              jobTitle: data?.colessee?.jobTitle,
              homeMobile: data?.colessee?.homeMobile,
              timeAtEmployerYears: data?.colessee?.timeAtEmployerYears || 0,
              timeAtEmployerMonths: data?.colessee?.timeAtEmployerMonths || 0,
              grossMonthlyIncome: data?.colessee?.grossMonthlyIncome,
              driverLicense: data?.colessee?.driverLicense,
              motorcycleEndorsement: data?.colessee?.motorcycleEndorsement,
              relationshipToLesseeId: data?.colessee?.relationshipToLesseeId,
              isDriving: data?.colessee?.isDriving,
              monthYearAtEmployer: data?.colessee?.monthYearAtEmployer,
              employmentMonthOrYear: data?.colessee?.employmentMonthOrYear,
              emailAddress: data?.colessee?.emailAddress,
              licenseState:  data?.colessee?.licenseState,
              driversLicenseIdNumber: data?.colessee?.driversLicenseIdNumber,
              otherRelationship: data?.colessee?.otherRelationship,
              homeAddressAttributes: {
                state: data?.colessee?.homeAddress?.newCity?.state,
                street1: data?.colessee?.homeAddress?.street1?.toLocaleLowerCase(),
                street2: data?.colessee?.homeAddress?.street2?.toLocaleLowerCase(),
                zipcode: data?.colessee?.homeAddress?.zipcode,
                county: data?.colessee?.homeAddress?.newCity?.county,
                city: data?.colessee?.homeAddress?.newCity?.city,
              },
              mailingAddressAttributes: {
                state: data?.colessee?.mailingAddress?.newCity?.state,
                street1: data?.colessee?.mailingAddress?.street1?.toLocaleLowerCase(),
                street2: data?.colessee?.mailingAddress?.street2?.toLocaleLowerCase(),
                zipcode: data?.colessee?.mailingAddress?.zipcode,
                county: data?.colessee?.mailingAddress?.newCity?.county,
                city: data?.colessee?.mailingAddress?.newCity?.city,
              },
              employmentAddressAttributes: {
                id: data?.colessee?.employmentAddress?.id,
                city: data?.colessee?.employmentAddress?.city,
                state: data?.colessee?.employmentAddress?.state,
              },
            },
          }}
        >
          <Paper
            sx={{
              p: 2,
              margin: 2,
              marginBottom: 5,
              padding: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            {/* <Row gutter={[16, 40]}>
              <Col span={24} className="cca-center-text">
                <Typography variant='h3'>About you</Typography>
              </Col>
            </Row> */}
              <Collapse defaultActiveKey={['1', '2', '3']}>
                <Panel header="Personal" key="1">
                  <Personal
                    data={data}
                    dateFormat={dateFormat}
                    dateOfBirthValidation={dateOfBirthValidation}
                    dateOfBirthValidationMessage={dateOfBirthValidationMessage}
                    colesseeDob={colesseeDob}
                    onChangeDob={onChangeDob}
                    lesseeForm={lesseeForm}
                    handlePhoneChange={handlePhoneChange}
                    lesseeOrColessee={"colessee"}
                    otherRelationshipType={otherRelationshipType}
                    otherRelationshipRules={otherRelationshipRules}
                    relationshipToLesseeOptions={relationshipToLesseeOptions}
                    handleRelationshiptoApplicant={handleRelationshiptoApplicant}
                    requireCoApplicantFields={requireCoApplicantFields}
                    formValidation={true}
                    motorcycleLicense={motorcycleLicense}
                    setMotorcycleLicense={setMotorcycleLicense}
                    handleLicenseState={handleLicenseState}
                    licenseFormat={licenseFormat}
                  />
                </Panel>
                <br/>
                <Panel header="Home Address" key="2">
                  <HomeAddress
                    data={data}
                    dateFormat={dateFormat}
                    lesseeHomeStateOptions={lesseeHomeStateOptions}
                    autoCompleteLesseeHomeBeforePlacesGetDetails={autoCompleteLesseeHomeBeforePlacesGetDetails}
                    autoCompleteLesseeHomeOnPlacesGetDetailsResult={autoCompleteLesseeHomeOnPlacesGetDetailsResult}
                    handleChange={handleChange}
                    zipHomeValidateStatus={zipHomeValidateStatus}
                    zipHomeErrorMessage={zipHomeErrorMessage}
                    handleLesseeHomeZipcodeBlur={handleLesseeHomeZipcodeBlur}
                    showHomeState={showHomeState}
                    handleHomeStateChange={handleHomeStateChange}
                    handleStateTarget={handleStateTarget}
                    handleValidationReset={handleValidationReset}
                    showHomeCountyState={showHomeCountyState}
                    handleHomeCountyStateChange={handleHomeCountyStateChange}
                    handleCountyTarget={handleCountyTarget}
                    lesseeHomeCountyOptions={lesseeHomeCountyOptions}
                    showHomeCityState={showHomeCityState}
                    handleHomeCityStateChange={handleHomeCityStateChange}
                    handleCityTarget={handleCityTarget}
                    lesseeHomeCityOptions={lesseeHomeCityOptions}
                    handleValidator={handleValidator}
                    handleMonthYear={handleMonthYear}
                    disabledDate={disabledDate}
                    handleManualMonthYear={handleManualMonthYear}
                    monthYears={monthYears}
                    nullMonthYear={nullMonthYear}
                    lesseeOrColessee={"colessee"}
                    fillLesseeHomeAddress={fillLesseeHomeAddress}
                    formValidation={true}
                    handleZeroDefaultInputValue={handleZeroDefaultInputValue}
                  />
                </Panel>
                <br/>
                <Panel header="Mailing Address" key="3">
                  <MailingAddress
                    fillMailingAddress={fillMailingAddress}
                    autoCompleteLesseeMailingBeforePlacesGetDetails={autoCompleteLesseeMailingBeforePlacesGetDetails}
                    autoCompleteLesseeMailingOnPlacesGetDetailsResult={autoCompleteLesseeMailingOnPlacesGetDetailsResult}
                    zipMailValidateStatus={zipMailValidateStatus}
                    zipMailErrorMessage={zipMailErrorMessage}
                    handleLesseeMailZipcodeBlur={handleLesseeMailZipcodeBlur}
                    showMailingState={showMailingState}
                    handleMailingStateChange={handleMailingStateChange}
                    handleMailingStatetarget={handleMailingStatetarget}
                    lesseeMailStateOptions={lesseeMailStateOptions}
                    showMailingCountyState={showMailingCountyState}
                    handleMailingCountyStateChange={handleMailingCountyStateChange}
                    handleMailingCountytarget={handleMailingCountytarget}
                    lesseeMailCountyOptions={lesseeMailCountyOptions}
                    showMailingCityState={showMailingCityState}
                    handleMailingCityStateChange={handleMailingCityStateChange}
                    handleMailingCityTarget={handleMailingCityTarget}
                    lesseeMailCityOptions={lesseeMailCityOptions}
                    lesseeOrColessee={"colessee"}
                  />

                </Panel>
              </Collapse>
            
          </Paper>

          <Paper
            sx={{
              p: 2,
              margin: 2,
              marginBottom: 5,
              padding: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            {/* <Row gutter={[16, 16]}>
              <Col span={24} className="cca-center-text2">
                <Typography variant='h3'>About Your Work</Typography>
                <br />
              </Col>
            </Row> */}
              <Collapse defaultActiveKey={['1', '2', '3']}>
                <Panel header="Employment Details" key="2">
                  <EmploymentDetails
                    handleEmploymentStatus={handleEmploymentStatus}
                    employmentStatusOptions={employmentStatusOptions}
                    requireEmploymentFields={requireEmploymentFields}
                    dateFormat={dateFormat}
                    handleMonthYearEmployerResetValidator={handleMonthYearEmployerResetValidator}
                    handleMonthYearEmployerValidator={handleMonthYearEmployerValidator}
                    handleMonthYearEmployer={handleMonthYearEmployer}
                    disabledDate={disabledDate}
                    handleManualMonthYearEmployer={handleManualMonthYearEmployer}
                    monthYearAtEmployer={monthYearAtEmployer}
                    lesseeOrColessee={"colessee"}
                    setEmploymentYearOrMonth ={setEmploymentYearOrMonth}
                    formValidation={true}
                    employmentYearLabel={employmentYearLabel}
                    employmentMonthLabel={employmentMonthLabel}
                    handleZeroDefaultInputValue={handleZeroDefaultInputValue}
                  />
                </Panel>
                <br/>
                <Panel header="Employer" key="1">
                  <Employer
                    requireEmploymentFields={requireEmploymentFields}
                    lessee={lessee}
                    handleFormChange={handleFormChange}
                    lesseeOrColessee={"colessee"}
                    formValidation={true}
                  />
                </Panel>
              </Collapse>
            <br/>
            <div className="button-container">
              <Button variant="contained" onClick={returnToApplicant} style={{ backgroundColor: "#e93b1b", marginRight: '3%', display: isAddingCoApplicant === true ? 'none': '' }} disabled={disableSubmitBtn}>
                Return to Applicant
              </Button>
              <Button variant="contained" style={{ backgroundColor: "#e93b1b" }} type="submit" disabled={disableSubmitBtn}>
                Review and Submit
              </Button>
            </div>
          </Paper>
        </Form>
      </div>
    </>
  ) : null
}

export default CoApplicant

