import { Radio, Input, Form, Space, DatePicker, Select } from 'antd'
import moment from 'moment';
import MaskedInput from 'antd-mask-input'
import SsnInput from './SsnInput'
import '../../styles/Applicant.css'
import { Grid } from '@mui/material'
import usStates from "../Constants/UsStates";
const { Option } = Select

function Personal(props: any) {
    const { data, dateFormat, dateOfBirthValidation, dateOfBirthValidationMessage, lesseeDob, onChangeDob, lesseeForm,
        handlePhoneChange, lesseeOrColessee, otherRelationshipType, otherRelationshipRules, relationshipToLesseeOptions,
        handleRelationshiptoApplicant, requireCoApplicantFields, colesseeDob, formValidation, motorcycleLicense, setMotorcycleLicense,
        handleLicenseState, licenseFormat, licenseFormatMessage } = props

    let notStates = ['AS', 'PW', 'PR', 'VI']
    let applicableStates = usStates.filter((state) => !notStates.includes(state.abbreviation))
    let otherStates = [
        { name: 'CONNECTICUT', abbreviation: 'CT' },
        { name: 'IDAHO', abbreviation: 'ID' },
        { name: 'IOWA', abbreviation: 'IA' },
        { name: 'HAWAII', abbreviation: 'HI' },
    ]
    let allStates = applicableStates.concat(otherStates).
    sort((a, b) => a.name.localeCompare(b.name))

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                 {lesseeOrColessee == 'colessee' && (
                    <>
                        <Grid item xs={2} sm={4} md={4} >
                            <Form.Item
                                label={<label htmlFor='relation' style={{ fontWeight: "bold" }}>Relationship To Applicant</label>}
                                name={[`${lesseeOrColessee}Attributes`, 'relationshipToLesseeId']}
                                rules={[{ required: true, message: 'Relationship To Applicant is requred' }]}
                                htmlFor='relation'
                            >
                                <Select
                                    showSearch
                                    placeholder="Relationship To Applicant"
                                    id='relation'
                                    onChange={handleRelationshiptoApplicant}
                                >
                                    {relationshipToLesseeOptions &&
                                        relationshipToLesseeOptions.map(({ value, label }: any, index: any) => {
                                            return (
                                                <Option key={index} value={`${value}`}>
                                                    {label}
                                                </Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>
                        </Grid>
                    </>
                )}
                <Grid item xs={2} sm={4} md={4} >
                    <Form.Item label="First Name" name={[`${lesseeOrColessee}Attributes`, 'firstName']} rules={[{ required: true, message: 'First Name is required!' }]}>
                        <Input placeholder="First Name" className="ant-input-comp" />
                    </Form.Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Form.Item label="Middle Name" name={[`${lesseeOrColessee}Attributes`, 'middleName']}>
                        <Input placeholder="Middle Name" className="ant-input-comp" />
                    </Form.Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Form.Item label="Last Name" name={[`${lesseeOrColessee}Attributes`, 'lastName']} rules={[{ required: true, message: 'Last Name is required!' }]}>
                        <Input placeholder="Last Name" className="ant-input-comp" />
                    </Form.Item>
                </Grid>
                <Grid item xs={2} sm={4} md={4}  >
                    {lesseeOrColessee == "lessee" ?
                        <Form.Item
                            label="Date of Birth (MM/DD/YYYY)"
                            className="dob-wrapper"
                            name={[`${lesseeOrColessee}Attributes`, 'dateOfBirth']} validateStatus={dateOfBirthValidation} help={dateOfBirthValidationMessage}
                            rules={[{ required: (lesseeDob === '' || lesseeDob === undefined || lesseeDob === null) ? true : false, message: 'Date of Birth is required!' }]}>
                            {(data?.lessee?.dateOfBirth !== null && data?.lessee?.dateOfBirth !== undefined && data?.lessee?.dateOfBirth !== '') ? <DatePicker className="ant-input-comp" defaultValue={moment(data?.lessee?.dateOfBirth)} format={dateFormat} onChange={onChangeDob} /> :
                                <DatePicker className="ant-input-comp" format={dateFormat} onChange={onChangeDob} /> }
                        </Form.Item>
                        :
                        <Form.Item
                            label="Date of Birth (MM/DD/YYYY)"
                            className="dob-wrapper"
                            name={[`${lesseeOrColessee}Attributes`, 'dateOfBirth']} validateStatus={dateOfBirthValidation} help={dateOfBirthValidationMessage}
                            rules={[{ required: (colesseeDob === '' || colesseeDob === undefined || colesseeDob === null) ? true : false, message: 'Date of Birth is required!' }]}>
                            {(data?.colessee?.dateOfBirth !== null && data?.colessee?.dateOfBirth !== undefined && data?.colessee?.dateOfBirth !== '') ? <DatePicker className="ant-input-comp" defaultValue={moment(data?.colessee?.dateOfBirth)} format={dateFormat} onChange={onChangeDob} /> :
                                <DatePicker className="ant-input-comp" format={dateFormat} onChange={onChangeDob} /> }
                        </Form.Item>
                    }
                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    {lesseeOrColessee == "lessee" ?
                        <Form.Item label="Social Security Number" name={[`${lesseeOrColessee}Attributes`, 'ssn']}  rules={[{ required: true, message: 'SSN is required!' }]} htmlFor='securityNumber'>
                            <Input type='hidden' />
                            <SsnInput defaultValue={(data?.lessee && data?.lessee?.ssn?.replace(/-/g, '')) || ''} form={lesseeForm} lesseeType={lesseeOrColessee} />
                        </Form.Item>
                        :
                        <Form.Item label="Social Security Number" name={[`${lesseeOrColessee}Attributes`, 'ssn']} rules={[{ required: true, message: 'SSN is required!' }]} htmlFor='securityNumber'>
                            <Input type='hidden' />
                            <SsnInput defaultValue={(data?.colessee && data?.colessee?.ssn?.replace(/-/g, '')) || ''} form={lesseeForm} lesseeType={lesseeOrColessee} />
                        </Form.Item>
                    }

                </Grid>
                <Grid item xs={2} sm={4} md={4} >
                    <Space direction="horizontal">
                        <Form.Item label="Phone Number"
                            name={[`${lesseeOrColessee}Attributes`, 'mobilePhoneNumber']}
                            rules={[{ required: formValidation, message: 'Phone Number is required' },
                            {
                                pattern: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                                message: "must be a valid phone number"
                            }
                            ]}
                        >
                            <MaskedInput mask="(111) 111-1111" placeholder="Phone Number" className="credit-app-phone-no" />
                        </Form.Item>
                        <div style={{ marginLeft: '5px' }}></div>
                        <Form.Item
                            label="Home phone or mobile number ?"
                            name={[`${lesseeOrColessee}Attributes`, 'homeMobile']}
                            rules={[{ required: formValidation, message: 'Home Phone or Mobile Number is required' }]}
                        >
                            <Radio.Group name="phoneType" onChange={handlePhoneChange}>
                                <Radio value={1}>Mobile</Radio>
                                <Radio value={2}>Home</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Space>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Form.Item label="Email Address" name={[`${lesseeOrColessee}Attributes`, 'emailAddress']} rules={[{ type: 'email', required: formValidation, message: "Please Add valid Email Address" }]} >
                        <Input placeholder="Email Address" className="ant-input-comp" />
                    </Form.Item>
                </Grid>

                {lesseeOrColessee == "lessee" ? (
                    <>
                        <Grid item xs={2} sm={4} md={4}>
                            <Form.Item label="First Motorcycle Purchase/Lease?" name={[`${lesseeOrColessee}Attributes`, 'firstTimeRider']} rules={[{ required: formValidation, message: 'First Motorcycle Purchase/Lease is required!' }]}>
                                <Radio.Group name='firstMotorcycle'>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Grid>
                    </>
                )
                    :
                    (<Grid item xs={2} sm={4} md={4}>
                        <Form.Item
                            label="Will this applicant also be riding the motorcycle ?"
                            name={[`${lesseeOrColessee}Attributes`, 'isDriving']}
                            rules={[{ required: true, message: 'Please pick option if co-applicant is driving or not.' }]}
                            className="riding-style"
                        >
                            <Radio.Group name="driving">
                                <Radio value={1}>Yes</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Grid>)
                }
                <Grid item xs={2} sm={4} md={4}>
                    <Form.Item label="Driver License ?"
                               name={[`${lesseeOrColessee}Attributes`, 'driverLicense']}
                               rules={[{ required: formValidation, message: 'Driver License is required!' }]}
                    >
                        <Radio.Group name='License' onChange={(e: any) => setMotorcycleLicense(e.target.value)}>
                            <Radio  value={"Yes"}>Yes</Radio>
                            <Radio  value={"No"}>No</Radio>
                            <Radio  value={"Unknown"}>Unknown</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Grid>
                {
                    motorcycleLicense === "Yes" &&
                  <Grid item xs={2} sm={4} md={4}>
                    <Form.Item label="Driver License State" name={[`${lesseeOrColessee}Attributes`, 'licenseState']}
                               rules={[{ required: formValidation, message: "Driver's License State is required" }]}>
                      <Select showSearch placeholder="License State" className="space-up"
                              onChange={(value: any) => handleLicenseState(value)}
                      >
                          {allStates &&
                              allStates.map(({ name, abbreviation }, index) => {
                                  return (
                                      <Option key={index} value={`${name}`}>
                                          {name}
                                      </Option>
                                  )
                              })}
                      </Select>
                    </Form.Item>
                  </Grid>
                }
                {
                    motorcycleLicense === "Yes" &&
                  <Grid item xs={2} sm={4} md={4}>
                    <Form.Item label="Drivers License Number"
                               name={[`${lesseeOrColessee}Attributes`, 'driversLicenseIdNumber']}
                               rules={[{ required: formValidation, message: "Driver's License Number is required" },
                                   {
                                       pattern: licenseFormat,
                                       message: licenseFormatMessage ?? "Must be a valid License Number"
                                   }
                               ]}
                    >
                      <Input placeholder="License Number"/>
                    </Form.Item>
                  </Grid>
                }
                <Grid item xs={2} sm={4} md={4}>
                    <Form.Item
                        label="Motorcycle Endorsement ?"
                        name={[`${lesseeOrColessee}Attributes`, 'motorcycleEndorsement']}
                        rules={[{ required: formValidation, message: 'Motorcycle Endorsement is required!' }]}>
                        <Radio.Group  name="endorsement">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Grid>
            </Grid>
        </>
    )
}

export default Personal
