import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Form, Grid, Header, Segment, Checkbox } from 'semantic-ui-react';
import { logger, network, auth } from '../../../utils';
import { GET } from "../../../utils/network";
import "./SignIn.css";
import { Link, useLocation } from "react-router-dom";

const SITE_KEY: string | undefined = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function SignIn() {
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [hasError, setError] = useState<boolean>(false);
  const [errMsg, setErrMmsg] = useState(null)
  const location = useLocation();

  useEffect(() => {
    let search = new URLSearchParams(location.search);
    if (search?.get("dealership_id")) {
      let dealership_id = search?.get("dealership_id") || "";
      let admin_token = search?.get("admin_token") || "";
      let full_name = search?.get("full_name") || "";
      let dealership_name = search?.get("dealership_name") || "";
      let dealership_state = search?.get("dealership_state") || "";
      let zipcode = search?.get("zipcode") || "";
      window.localStorage.setItem("dealership_id", dealership_id);
      window.localStorage.setItem("admin_token", admin_token);
      auth.setAuth(
        admin_token,
        full_name,
        dealership_name,
        dealership_state,
        zipcode,
        dealership_id
      );
      window.location.href = "/home";
    } 
  });

  useEffect(() => {
    if (auth.isAuth()) {
      window.location.href = "/home";
    }
   });

  const handleSubmit = async () => {
    try {
      await network.POST(`/api/v1/sign-in`,
        {
          dealer_login: {
            email,
            password,
          }
        }).then(response => {
          if (response?.data?.data) {
            auth.setAuth(response.data.data.auth_token, response.data.data.full_name, response.data.data.dealership_name, response.data.data.dealership_state, response.data.data.dealership_zipcode)
            window.localStorage.setItem('user_data', JSON.stringify(response.data.data));
            window.localStorage.setItem('can_submit_speedlease', response.data.data.can_submit);
            window.location.href = '/home'
          } else {
            setError(true)
          }
          }).catch(error => {
            setErrMmsg(error.response.data?.errors[0]?.detail)
            setError(true)
        })
    } catch (e) {
      setError(true);
      logger.error("Error sign in", e);
    }
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column id="login-column">
        <Header
          as="h3"
          style={{ color: "0F1419", marginTop: "10px" }}
          textAlign="center"
        >
          Speed Leasing Dealer Portal
        </Header>
        {hasError && (<span style={{ color: 'red' }}>{errMsg ?? "Invalid Email or password."}</span>)}
        <Form size='large' onSubmit={() => handleSubmit()}>

          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              name="email"
              placeholder="E-mail address"
              value={email}
              onChange={handleEmail}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={password}
              name="password"
              onChange={handlePassword}
            />
            <Form.Field>
              <div className="login-helpers">
                <div className="column left">
                  <Checkbox label="Remember me" />
                </div>
                <div className="column right">
                  <Link to="/forgot-password">Forgot password ?</Link>
                </div>
              </div>
            </Form.Field>
            <Button id="login-button" primary fluid size="large" type="submit">
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default SignIn;
