import { useState } from 'react';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { network } from '../../../utils';
import './SignIn.css'
import { message } from 'antd';

function SendEmail() {
  const [email, setEmail] = useState<string>('')
  const [hasMessage, setHasMessage] = useState<String>('')
  const [hasError, setHasError] = useState<boolean>(false)
  const sentMail = async () => {
    try {
      await network.POST(`/api/v1/sent-reset-password-mail`,
        {
          dealers: {
            email
          }
        }).then(response => {
          if (response.status == 200) {
            message.success(response.data.message)
            setHasError(false)
          }
        })
    } catch (e: any) {
      setHasError(true)

    };
  };

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column id='login-column'>
        <Header as='h3' style={{ color: '0F1419', marginTop: '10px' }} textAlign='center'>
          Speed Leasing Dealer Portal
        </Header>
        {hasMessage && (<span style={{ color: 'green' }}>{hasMessage} </span>)}
        {hasError && (<span style={{ color: 'red' }}>Invalid Email Address </span>)}
        <Form size='large' onSubmit={() => sentMail()}>
          <Segment stacked>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              name="email"
              placeholder='E-mail address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            <Button
              id='login-button'
              primary
              fluid
              size='large'
              type='submit'
            >
              Reset Password
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid >
  );
}

export default SendEmail;