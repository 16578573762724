const getDriverLicenseInfo = (state) => {
    const licenseInfo = {
        "ALABAMA":{ //1
            pattern: /^\d{1,8}$/,
            message: "Must be between 1 to 8 number."
          },
          "ALASKA":{ //2
            pattern: /^\d{1,7}$/,
            message: "Must be between 1 to 7 number"
          },
          "ARIZONA":{ //3
            pattern: /^[a-zA-Z]\d{8}$|^\d{9}$/,
            message: "Must be between 1 alpha 8 number or 9 number"
          },
          "ARKANSAS":{ //4
            pattern: /^\d{4,9}$/,
            message: "Must be between 4 to 9 numbers"
          },
          "CALIFORNIA":{ //5
            pattern: /^[a-zA-Z]\d{7}$/,
            message: "Must be 1 alpha 7 number"
          },
          "COLORADO":{ //6
            pattern: /^\d{9}$|^[a-zA-Z]\d{3,6}$|^[a-zA-Z]{2}\d{2,5}$/,
            message: "Must be 9 number or 1 alpha 3 to 6 number or 2 alpha 2 to 5 number"
          },
          "CONNECTICUT":{ //7
            pattern: /^\d{9}$/,
            message: "Must be 9 number"
          },
          "DELAWARE":{ //8
            pattern: /^\d{1,7}$/,
            message: "Must be between 1 to 7 number"
          },
          "DISTRICT OF COLUMBIA":{ //9
            pattern: /^\d{7}$|^\d{9}$/,
            message: "Must be 7 number or 9 number"
          },
          "FLORIDA":{ //10
            pattern: /^[a-zA-Z]\d{12}$/,
            message: "Must be 1 alpha 12 number"
          },
          "GEORGIA":{ //11
            pattern: /^\d{7,9}$/,
            message: "Must be between 7 to 9 number"
          },
          "ILLINOIS":{ //12
            pattern:  /^[a-zA-Z]\d{11,12}$/,
            message: "Must be 1 alpha 12 number or 9 to 10 number"
          },
          "INDIANA":{ //13
            pattern: /^[a-zA-Z]\d{9}$|^\d{10}$/,
            message: "Must be a valid License Number"
          },
          "KANSAS":{ //14
            pattern: /^[a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]$|^[a-zA-Z]\d{8}$|^\d{9}$/,
            message: "Must be 9 number or 1 alpha 8 number or 1 alpha 1 number 1 alpha 1 number 1 alpha"
          },
          "KENTUCKY":{ //15
            pattern: /^[a-zA-Z]\d{8}$|^[a-zA-Z]\d{9}$|^\d{9}$/,
            message: "Must be 1 alpha 8 to 9 number or 9 number"
          },
          "MAINE":{ //16
            pattern:  /^\d{7}$|^\d{7}[a-zA-Z]$|^\d{8}$/,
            message: "Must be 7 number or 7 number 1 alpha or 8 number"
          },
          "LOUSIANA":{ //17
            pattern: /^\d{1,9}$/,
            message: "Must be between 1 to 9 number"
          },
          "MARYLAND":{ //18
            pattern: /^[a-zA-Z]\d{12}$/,
            message: "Must be 1 alpha 12 number"
          },
          "MASSACHUSETTS":{ //19
            pattern: /^[a-zA-Z]\d{8}$|^\d{9}$/,
            message: "Must be 1 alpha 8 number or 9 number"
          },
          "MICHIGAN":{ //20
            pattern: /^[a-zA-Z]\d{10}$|^[a-zA-Z]\d{12}$/,
            message: "Must be 1 alpha 10 or 1 alpha 12 number"
          },
          "MINNESOTA":{ //21
            pattern: /^[a-zA-Z]\d{12}$/,
            message: "Must be 1 alpha and 12 number"
          },
          "MISSISSIPPI":{ //22
            pattern: /^\d{9}$/,
            message: "Must be 9 number"
          },
          "MISSOURI":{ //23
            pattern: /^([0-9]{3}[A-Za-z]{1}[0-9]{6})$|^([A-Za-z]{1}[0-9]{5,9})$|^([A-Za-z]{1}[0-9]{6}R)$|^([0-9]{8}[A-Za-z]{2})$|^([0-9]{9}[A-Za-z]{1})$|^([0-9]{9})$/,
            message: "Must be 9 number or 9 number 1 alpha or 8 number 2 alpha or 1 alpha 6 number + R or 1 alpha 5 to 9 number or 3 number 1 alpha 6 number"
          },
          "MONTANA":{ //24
            pattern: /^[a-zA-Z]\d{8}$|^\d{9}$|^\d{13,14}$/,
            message: "Must be 1 alpha 8 number or 9 number or 13 to 14 number"
          },
          "NEBRASKA":{ //25
            pattern: /^[a-zA-Z]\d{6,8}$/,
            message: "Must be 1 alpha 6 to 8 number "
          },
          "NEVADA":{ //26
            pattern: /^\d{9,10}$|^\d{12}$|^[X]\d{8}$/,
            message: "Must be 9 to 10 number or 12 number or X + 8 number"
          },
          "NEW HAMPSHIRE":{ //27
            pattern: /^\d{2}[a-zA-Z]{3}\d{5}$/,
            message: "Must be 2 number 3 alpha 5 number"
          },
          "NEW JERSEY":{ //28
            pattern:  /^[a-zA-Z]\d{14}$|^[a-zA-Z]\d{4}\s\d{5}\s\d{5}$/,
            message: "Must be 1 alpha 13 number"
          },
          "NEW MEXICO":{ //29
            pattern: /^\d{8,9}$/,
            message: "Must be between 8 to 9 number"
          },
          "NEW YORK":{ //30
            pattern:  /^[a-zA-Z]\d{7}$|^[a-zA-Z]\d{18}$|^\d{8,9}$|^\d{16}$|^[a-zA-Z]{8}$/,
            message: "Must be 1 alpha 7 number or 1 alpha 18 number or between 8 to 9 number or 	16 number or 8 alpha"
          },
          "NORTH CAROLINA":{ //31
            pattern: /^\d{1,12}$/,
            message: "Must be between 1 to 12 number"
          },
          "NORTH DAKOTA":{ //32
            pattern: /^[a-zA-Z]{3}\d{6}$|^\d{9}$/,
            message: "Must be 3 alpha 6 number or 9 number"
          },
          "OHIO":{ //33
            pattern: /^[a-zA-Z]\d{4,8}$|^[a-zA-Z]{2}\d{3,7}$|^\d{8}$/,
            message: "Must be 1 alpha between 4 to 8 numbers or 2 alpha between 3 to 7 numbers 		or 8 numbers"
          },
          "OKLAHOMA":{ //34
            pattern: /^[A-Za-z][0-9]{9}$|^[0-9]{9}$/,
            message: "Must be 1 alpha 9 numbers or 9 numbers"
          },
          "OREGON":{ //35
            pattern: /^\d{1,9}$/,
            message: "Must be between 1 to 9 number"
          },
          "PENNSYLVANIA":{ //36
            pattern: /^\d{8}$/,
            message: "Must be 8 numbers"
          },
          "RHODE ISLAND":{ //37
            pattern: /^\d{7}$|^[a-zA-Z]\d{6}$/,
            message: "Must be 7 numbers or 1 alpha 6 numbers"
          },
          "SOUTH CAROLINA":{ //38
            pattern: /^\d{5,11}$/,
            message: "Must be between 5 to 11 number"
          },
          "SOUTH DAKOTA":{ //39
            pattern: /^\d{6,10}$|^\d{12}$/,
            message: "Must be between 6 to 10 numbers or 12 numbers"
          },
          "TENNESSEE":{ //40
            pattern: /^\d{7,9}$/,
            message: "Must be between 7 to 9 numbers"
          },
          "TEXAS":{ //41
            pattern: /^\d{7,8}$/,
            message: "Must be between 7 to 8 numbers"
          },
          "UTAH":{ //42
            pattern: /^\d{4,10}$/,
            message: "Must be between 4 to 10 numbers"
          },
          "VERMONT":{ //43
            pattern: /^([0-9]{8})$|^([0-9]{7})[A]$/,
            message: "Must be 8 number or 7 number + A"
          },
          "VIRGINIA":{ //44
            pattern: /^[a-zA-Z]\d{8,11}$|^\d{9}$/,
            message: "Must be 1 alpha 8 to 11 numbers or 9 numbers"
          },
          "WASHINGTON":{ //45
            pattern: /^[1-7a-zA-Z]{1}[a-zA-Z0-9]{11}$/,
            message: "Must be 12 characters, starts with between 1 to 7 alpha "
          },
          "WEST VIRGINIA":{ //46
            pattern: /^[0-9]{7}$|^([A-Za-z]{1,2})[0-9]{5,6}$/,
            message: "Must be 7 numbers or 1 to 2 alpha 5 to 6 numbers"
          },
          "WISCONSIN":{ //47
            pattern: /^[a-zA-Z]\d{13}$/,
            message: "Must be 1 alpha 13 numbers"
          },
          "WYOMING":{ //48
            pattern: /^\d{9,10}$/,
            message: "Must be between 9 to 10 numbers"
          },
          "IDAHO":{ //49
            pattern: /^[a-zA-Z]{2}\d{6}[a-zA-Z]$|^\d{9}$/,
            message: "Must be 2 alpha 6 numbers 1 alpha or 9 numbers"
          },
          "IOWA":{ //50
            pattern: /^\d{3}[a-zA-Z]{2}\d{4}$|^\d{9}$/,
            message: "Must be 9 numbers or 3 numbers 2 alpha 4 numbers"
          },
          "HAWAII":{ //51
            pattern: /^[a-zA-Z]\d{8}$|^\d{9}$/,
            message: "Must be 1 alpha 8 numbers or 9 numbers"
          },
    };
    return licenseInfo[state.toUpperCase()] || null;
  };
  
  export { getDriverLicenseInfo };