import { Input, Select, Form, Checkbox } from 'antd'
import { AutoCompleteAddress } from './AutoCompleteAddress'
import MaskedInput from 'antd-mask-input'
import '../../styles/Applicant.css'
import { Grid } from '@mui/material'

const { Option } = Select

interface OptionData {
  value?: string | number
  label?: string
  parentId?: number
}

function MailingAddress(props: any) {
  const { fillMailingAddress, autoCompleteLesseeMailingBeforePlacesGetDetails, autoCompleteLesseeMailingOnPlacesGetDetailsResult,
    zipMailValidateStatus, zipMailErrorMessage, handleLesseeMailZipcodeBlur, showMailingState, handleMailingStateChange, handleMailingStatetarget,
    lesseeMailStateOptions, showMailingCountyState, handleMailingCountyStateChange, handleMailingCountytarget, lesseeMailCountyOptions, showMailingCityState,
    handleMailingCityStateChange, handleMailingCityTarget, lesseeMailCityOptions, lesseeOrColessee
  } = props

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Checkbox  style={{ fontSize: `13px`, marginTop: `39px` }} id="mailingAddressCheckbox" onChange={fillMailingAddress}>
            Check Box If Mailing Address Same As Home Address?
          </Checkbox>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label={<label htmlFor='mailingAddress'>Search Address</label>} htmlFor='mailingAddress'>
            <AutoCompleteAddress beforePlacesGetDetails={autoCompleteLesseeMailingBeforePlacesGetDetails} onPlacesGetDetailsResult={autoCompleteLesseeMailingOnPlacesGetDetailsResult} htmlId={'mailingAddress'}/>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item
            label="Street Address"
            name={[`${lesseeOrColessee}Attributes`, 'mailingAddressAttributes', 'street1']}
            className="street-address"
          >
            <Input placeholder="Street address" className="ant-input-comp space-up" />
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="Apartment" name={[`${lesseeOrColessee}Attributes`, 'mailingAddressAttributes', 'street2']}>
            <Input placeholder="Apartment" className="ant-input-comp space-up" />
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item
            label="ZIP Code"
            name={[`${lesseeOrColessee}Attributes`, 'mailingAddressAttributes', 'zipcode']}
            validateStatus={zipMailValidateStatus}
            help={zipMailErrorMessage}
          >
            <MaskedInput mask="11111" placeholder="ZIP Code" onPressEnter={handleLesseeMailZipcodeBlur} onBlur={handleLesseeMailZipcodeBlur} className="ant-input-comp space-up" />
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="State" name={[`${lesseeOrColessee}Attributes`, 'mailingAddressAttributes', 'state']}>
            <Select showSearch placeholder="State" {...showMailingState} onSelect={handleMailingStateChange} onChange={handleMailingStatetarget} className="space-up">
              {lesseeMailStateOptions &&
                lesseeMailStateOptions.map(((obj: OptionData, index: number) => {
                  if(obj)
                  {
                    return (
                      <Option key={index} value={`${obj.value}`}>
                        {obj.label}
                      </Option>
                    )
                  }
                }))}
            </Select>
          </Form.Item>

        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="County"
            name={[`${lesseeOrColessee}Attributes`, 'mailingAddressAttributes', 'county']}>
            <Select showSearch placeholder="County" {...showMailingCountyState} onSelect={handleMailingCountyStateChange} onChange={handleMailingCountytarget} className="space-up">
              {lesseeMailCountyOptions &&
                lesseeMailCountyOptions.map((obj: OptionData, index: number) => {
                  if(obj)
                  {
                    return (
                      <Option key={index} value={`${obj.value}`}>
                        {obj.label}
                      </Option>
                    )
                  }
                })}
            </Select>
          </Form.Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item label="City" name={[`${lesseeOrColessee}Attributes`, 'mailingAddressAttributes', 'city']}>
            <Select showSearch placeholder="City" {...showMailingCityState} onSelect={handleMailingCityStateChange} onChange={handleMailingCityTarget} className="space-up" >
              {lesseeMailCityOptions &&
                lesseeMailCityOptions.map((obj: OptionData, index: number) => {
                  if(obj)
                  {
                    return (
                      <Option key={index} value={`${obj.value}`}>
                        {obj.label}
                      </Option>
                    )
                  }
                })}
            </Select>
          </Form.Item>
        </Grid>
      </Grid>
      <br />
    </>
  )
}

export default MailingAddress
