import {Card} from "antd";
import React from "react";
import { styled } from '@mui/material'
import CustomerMonthlyPaymentInfo from "./PaymentTermsInfo/CustomerMonthlyPaymentInfo";
import TermsInfo from "./PaymentTermsInfo/TermsInfo";
import DealerFundingBreakdownInfo from "./PaymentTermsInfo/DealerFundingBreakdownInfo";

export const PaymentTerms = (props: any) => {
    const { calculatorData, convertUsd, cashDownPaymentDanger, maxAllowablePayment, minimumRequiredValueColor, handleCashDownPaymentChange,
            customerMonthlyPaymentDanger, tooltipColor, disabledForm } = props;

    return (
        <StyledCard className="card-header" title="Payment and Funding" bordered={true}>
            <TermsInfo
                calculatorData={calculatorData}
                convertUsd={convertUsd}
                cashDownPaymentDanger={cashDownPaymentDanger}
                minimumRequiredValueColor={minimumRequiredValueColor}
                handleCashDownPaymentChange={handleCashDownPaymentChange}
                tooltipColor={tooltipColor}
                disabledForm={disabledForm}
            />
            <CustomerMonthlyPaymentInfo
                calculatorData={calculatorData}
                convertUsd={convertUsd}
                maxAllowablePayment={maxAllowablePayment}
                customerMonthlyPaymentDanger={customerMonthlyPaymentDanger}
                tooltipColor={tooltipColor}
            />
            <DealerFundingBreakdownInfo tooltipColor={tooltipColor} calculatorData={calculatorData} convertUsd={convertUsd}/>
        </StyledCard>
    )
}

const StyledCard = styled(Card)`
.ant-card-head-title {
  font-size: 20px;
}
.ant-card-body {
   padding-bottom: 10px;
 }
`;
export default PaymentTerms
