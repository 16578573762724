// @ts-nocheck
import {Col, Form, InputNumber, Row, Typography, Tooltip} from "antd";
import InfoIcon from "@mui/icons-material/Info";
const { Title } = Typography;

export const BackendProductsInfo = (props: any) => {
    const { backendProductsColor, gpsCostColor, tooltipColor, disabledForm  } = props;

    return (
        <>
            <Title level={5} className="payment-calculator-headers"> Back End Products </Title>
            <Row className="first-field-height customer-town-field">
                <Col span={18} style={{ textAlign: 'left', color: backendProductsColor }}>
                    GAP
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'guaranteedAutoProtectionCost']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right', color: backendProductsColor }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18} style={{ textAlign: 'left', color: backendProductsColor }}>
                    Pre-Paid Maintenance
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5} style={{ textAlign: 'right', height: '6vh' }}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'prepaidMaintenanceCost']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right', color: backendProductsColor }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18} style={{ textAlign: 'left', color: backendProductsColor }}>
                    Service Contract
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5} style={{ textAlign: 'right', height: '6vh' }}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'extendedServiceContractCost']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right', color: backendProductsColor }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18} style={{ textAlign: 'left', color: backendProductsColor }}>
                    Tire and Wheel
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5} style={{height: '6vh'}}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'tireAndWheelContractCost']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right', color: backendProductsColor }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field" style={{ textAlign: 'left', color: gpsCostColor }}>
                <Col span={18}>
                    GPS
                    <Tooltip title="May enter $0.00, or an amount between $195.00 and $500.00." color={tooltipColor} key={'cap_cost'}>
                        <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                    </Tooltip>
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5} style={{ textAlign: 'right', color: gpsCostColor, height:'0vh'}}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'gpsCost']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right', color: gpsCostColor }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default BackendProductsInfo
