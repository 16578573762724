import { Input, Select, Form } from 'antd'
import { TextField } from '@mui/material'
import '../../styles/Applicant.css'
import { Grid } from '@mui/material'
import MaskedInput from 'antd-mask-input'
import usStates from "../Constants/UsStates"

const { Option } = Select

interface OptionData {
  value?: string | number
  label?: string
  parentId?: number
}



function Employer(props: any) {
  const {
    requireEmploymentFields, lessee, handleFormChange, lesseeOrColessee, formValidation
  } = props

  return (
  <>
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={2} sm={4} md={4}>
        <Form.Item
          label="Employer Name"
          name={[`${lesseeOrColessee}Attributes`, 'employerName']}
          rules={[{ required: requireEmploymentFields && formValidation , message: 'Employer Name is required!' }]}
        >
          <Input placeholder="Employer Name" className="ant-input-comp space-up" />
        </Form.Item>
      </Grid>

      <Grid item xs={2} sm={4} md={4}>
        <Form.Item
          label="City"
          name={[`${lesseeOrColessee}Attributes`, 'employmentAddressAttributes', 'city']}
          rules={[{ required:  requireEmploymentFields && formValidation, message: 'City is required!' }]}>
          <Input placeholder="City" className="ant-input-comp space-up" />
        </Form.Item>
      </Grid>

      <Grid item xs={2} sm={4} md={4}>
        <Form.Item label="State" name={[`${lesseeOrColessee}Attributes`, 'employmentAddressAttributes', 'state']}
                   rules={[{ required:  requireEmploymentFields && formValidation, message: 'State is required!' }]}>
          <Select showSearch placeholder="State" className="space-up">
            {usStates &&
              usStates.map(({ name, abbreviation }, index) => {
                return (
                  <Option key={index} value={`${name}`}>
                    {abbreviation}
                  </Option>
                )
              })}
          </Select>
        </Form.Item>
      </Grid>

      <Grid item xs={2} sm={4} md={4}>
        <Form.Item
          label="Phone Number"
          name={[`${lesseeOrColessee}Attributes`, 'employerPhoneNumber']}
          rules={[{ required:  requireEmploymentFields && formValidation, message: 'Phone Number is required!' },
          {
            pattern: /^\(\d{3}\)\s\d{3}-\d{4}$/,
            message: "must be a valid phone number"
          }
          ]}>
          <MaskedInput mask="(111) 111-1111" placeholder="Phone Number" className="credit-app-phone-no space-up" onChange={handleFormChange} />
        </Form.Item>
      </Grid>

      {lessee?.employmentAddress && (
        <Grid item xs={2} sm={4} md={4}>
          <Form.Item style={{ display: 'none' }} name={[`${lesseeOrColessee}Attributes`, 'employmentAddressAttributes', 'id']}>
            <TextField variant="standard" className="space-up" />
          </Form.Item>
        </Grid>
      )}
    </Grid>
    <br />
  </>
  )
}

export default Employer
