import { Spin } from 'antd';
import { styled } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import CreditStatus from "../CreditStatus";
import DocumentStatus from "../DocumentStatus";
import ActionsButton from "../ActionsButton";
import NotesIcon from '@mui/icons-material/Notes';
import Badge from '@material-ui/core/Badge';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from "react-redux";
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

function AppListDataGrid(props: any) {

    const {data, loading, isFromTimer, statusData, archiveApplication, pageState, setPaginationData, removeCoApplicant, setUnlockArchiveDialog, setApplicationRow, 
        setUnlockNotesDialog, setNotes, visitNotesByCurrentUser, updatePageState, setResubmitApplicationDialog } = props;

    const dispatch = useDispatch();

    const columns: GridColDef[] = [
        {
            field: 'ApplicationIdentifier', headerName: 'Application Identifier', type: 'string', headerAlign: 'center', flex: 1.5, minWidth: 75,
            renderCell: params => {
                return (
                    <Link to={`/applications/${params.row.id}/summary`} target="_blank">
                        {" "}
                        {params.row.applicationIdentifier == null ? "N/A" : params.row.applicationIdentifier}{" "}
                    </Link>
                );
            }
        },

        { field: 'applicant', headerName: 'Applicant', type: 'string', headerAlign: 'center', flex: 1.5, minWidth: 75 },
        { field: 'coApplicant', headerName: 'Co-Applicant', type: 'string', headerAlign: 'center', flex: 1.5, minWidth: 75 },
        { field: 'modelAndYear', headerName: 'Model and Year', type: 'string', headerAlign: 'center', flex: 1.2, minWidth: 70 },
        {
            field: 'creditTier', headerName: 'Credit Tier', type: 'string', headerAlign: 'center', flex: 1.5, minWidth: 75
        },
        {
            field: 'creditStatus', headerName: 'Credit Status', type: 'string', headerAlign: 'center', flex: 2.2, minWidth: 128,
            renderCell: params => {
                return <CreditStatus params={params} />
            }
        },
        {
            field: 'documentStatus', headerName: 'Document Status', type: 'string', headerAlign: 'center', flex: 2.2, minWidth: 130,
            renderCell: params => {
                return <DocumentStatus params={params} />
            }
        },
        {
            field: 'daysSubmitted', headerName: 'Days Submitted', type: 'string', headerAlign: 'center', flex: 1, minWidth: 50,
            renderCell: (params) => {
                if (params.row.daysSubmitted == "Expired") {
                    return <span style={{ color: "#ff0000", fontWeight: 700, fontSize: 11, padding: "7px", borderRadius: "10px" }}> Expired</span>
                } else {
                    return params.row.daysSubmitted
                }
            }
        },
        { field: 'lastUpdated', headerName: 'Last Updated', type: 'string', headerAlign: 'center', flex: 1.3, minWidth: 60, },
        {
            field: 'actions', headerName: 'Actions', type: 'string', headerAlign: 'center', flex: 1,
            minWidth: 98,
            renderCell: params => {
                return (
                    <>
                        <ActionsButton
                            params={params.row}
                            archiveApplication={archiveApplication}
                            removeCoApplicant={removeCoApplicant}
                            setUnlockArchiveDialog={setUnlockArchiveDialog}
                            setResubmitApplicationDialog={setResubmitApplicationDialog}
                            setApplicationRow={setApplicationRow}
                        />
                    </>
                )
            }
        },
        {
            field: 'notes', headerName: 'Notes', type: 'string', headerAlign: 'center', flex: 1,
            renderCell: params => {
                return (
                    <IconButton onClick={() => {
                        setUnlockNotesDialog(true)
                        setApplicationRow(params.row)
                        setNotes(params.row.comments)
                        visitNotesByCurrentUser(params.row)
                    }}>
                        <Badge badgeContent={params.row.unseenNotesCount} color="error">
                            <NotesIcon style={{ color: "blue", fontSize: "30px" }}></NotesIcon>
                        </Badge>
                    </IconButton>
                )
            }
        },
    ]

    return (
        <>
            <Spin spinning={!isFromTimer && loading}>
                <Box sx={{
                    height: 820, display: "flex", flexGrow: 1, width: '100%', '& .super-app-theme--header': {
                        backgroundColor: 'rgba(255, 7, 0, 0.55)'
                    },
                }}>
                    <StyledDataGrid
                        getRowHeight={() => 'auto'}
                        rows={data ? data : []}
                        // This may need in future
                        // getRowClassName={(params) => {
                        //     return params.row?.isNewApp === true ? "highlight" : "";
                        // }}
                        // sx={{
                        //     ".highlight": {
                        //         bgcolor: "#e6f3ff !important",
                        //         "&:hover": {
                        //             bgcolor: "darkgrey",
                        //         },
                        //     },
                        // }}
                        columns={columns}
                        rowsPerPageOptions={[25, 50, 100]}
                        density="comfortable"
                        pagination
                        rowCount={data ? statusData.total : 0}
                        page={data && pageState.page <= 0 ? 0 : pageState.page - 1}
                        pageSize={data ? pageState.limit : 0}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                            dispatch(updatePageState({ page: newPage + 1 }))
                            setPaginationData({
                                page: newPage,
                                pageSize: pageState.limit,
                            });
                        }}
                        onPageSizeChange={(newPageSize) => {
                            dispatch(updatePageState({ limit: newPageSize }))
                            setPaginationData({
                                page: pageState.page,
                                pageSize: newPageSize,
                            });
                        }}
                    />
                </Box>
            </Spin>
        </>
    )
}

export default AppListDataGrid


const StyledDataGrid = styled(DataGridPro)({
    boxShadow: 'none !important',
    border: "none !important",

    '& .MuiDataGrid-cell': {
        padding: '10px',
    },
    '& .MuiDataGrid-row': {
        fontSize: 12
    },

    '& .wrapHeader .MuiDataGrid-colCellTitle': {
        overflow: "hidden",
        lineHeight: "20px",
        whiteSpace: "normal"
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: 14,
        fontWeight: 600,
        whiteSpace: 'normal !important',
        lineHeight: 'normal',
        overflowWrap: "break-word",
        letterSpacing: '-1px'
    },
    '&  .MuiDataGrid-columnHeaderTitleContainer':{
        justifyContent: "flex-start !important"
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: "#e8ecf0",
        color: "#000000"
    },

    '& .MuiChip-label': {
        paddingLeft: '12px',
        paddingRight: '12px',
        whiteSpace: 'normal !important',
        wordWrap: ' break-word',
        lineHeight: ' 1',
        paddingBottom: '2px',
        paddingTop: '2px',
        letterSpacing: '-0.5px'
    },

    '& .MuiToolbar-root': {
        width: '19%'
    },

    '& p#mui-2': {
        marginTop: '20%'
    },

    '& p.MuiTablePagination-displayedRows': {
        marginTop: '20%'
    },
})
