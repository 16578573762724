import { useEffect, useState } from 'react'
import '../styles/RequestLeaseDocuments.css';
import { Layout, message, Spin } from 'antd';

import {
  MainHeader,
  MainSider,
} from '../../../layouts';
import {
  Box,
  Typography,
  Button
} from '@mui/material';
import AssetInformation from './Components/RequestLeaseDocuments/AssetInformation';
import BankingInformation from './Components/RequestLeaseDocuments/BankingInformation';
import ContactInformation from './Components/RequestLeaseDocuments/ContractInformation';
import ConfirmationDialouge from './Components/RequestLeaseDocuments/ConfirmationDialouge';
import Notes from './Components/RequestLeaseDocuments/Notes';
import { logger, network } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import AssetVerficationAttachment from './Components/RequestLeaseDocuments/AssetVerficationAttachment';
import ConditionReports from "./Components/RequestLeaseDocuments/ConditionReports";

const { Content } = Layout;

export const RequestLeaseDocuments = (props: any) => {
  const data = useSelector((state: any) => state?.applications?.applicationDetails);
  const serviceOrMaintainanceOrWheelExist = data?.leaseCalculator?.extendedServiceContractCost > 0 || data?.leaseCalculator?.prepaidMaintenanceCost > 0 || data?.leaseCalculator?.tireAndWheelContractCost > 0;


  const extendedServiceContractCostValue = data?.leaseCalculator?.extendedServiceContractCost || 0;
  const prepaidMaintenanceCostValue = data?.leaseCalculator?.prepaidMaintenanceCost || 0;
  const tireAndWheelContractCostValue = data?.leaseCalculator?.tireAndWheelContractCost || 0;

  const tradeAllowanceExist = data?.leaseCalculator?.grossTradeInAllowance > 0 || data?.leaseCalculator?.tradeInPayoff > 0;

  const history = useHistory();
  const [openDocusign, setOpenDocusign] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const contractTermLength = data?.leaseCalculator?.term || 0
  const [formValues, setFormValues] = useState<any>({
    asset_make: {
      value: '',
      error: false,
      errorMessage: 'You must enter a make'
    },
    asset_vin: {
      value: '',
      error: false,
      errorMessage: 'You must enter vin'
    },
    asset_model: {
      value: '',
      error: false,
      errorMessage: 'You must enter asset model'
    },
    asset_year: {
      value: '',
      error: false,
      errorMessage: 'You must enter asset year'
    },
    asset_color: {
      value: '',
      error: false,
      errorMessage: 'You must enter asset color'
    },
    exact_odometer_mileage: {
      value: '',
      error: false,
      errorMessage: 'You must enter asset exact odometer mileage'
    },
    delivery_date: {
      value: null,
      error: false,
      errorMessage: 'You must enter delivery date'
    },
    payment_bank_name: {
      value: '',
      error: false,
      errorMessage: 'You must enter payment bank name'
    },
    payment_aba_routing_number: {
      value: '',
      error: false,
      errorMessage: 'You must enter payment aba routing number'
    },
    payment_account_number: {
      value: '',
      error: false,
      errorMessage: 'You must enter payment account number'
    },
    payment_account_type: {
      value: '',
      error: false,
      errorMessage: 'You must enter payment account type'
    },
    payment_account_holder: {
      value: '',
      error: false,
      errorMessage: 'You must choose payment account holder'
    },
    lessee_email: {
      value: '',
      error: false,
      errorMessage: 'You must enter lessee email'
    },
    colessee_email: {
      value: '',
    },
    dealer_email: {
      value: '',
      error: false,
      errorMessage: 'You must enter dealer email'
    },
    contract_term_length: {
      value: contractTermLength,
      error: false,
      errorMessage: 'You must select contract term length'
    },
    notes: {
      value: '',
    },
    gap_contract_term: {
      value: ''
    },
    service_contract_term: {
      value: '',
      error: false,
      errorMessage: 'You must select service contract term'
    },
    ppm_contract_term: {
      value: '',
      error: false,
      errorMessage: 'You must select PPM contract term'
    },
    tire_contract_term: {
      value: '',
      error: false,
      errorMessage: 'You must select Tire/Wheel contract term'
    },
    equipped_with: {
      value: ''
    },
    trade_in_make: {
      value: '',
      error: false,
      errorMessage: 'You must enter Trade in make'
    },
    trade_in_model: {
      value: '',
      error: false,
      errorMessage: 'You must enter Trade in model'
    },
    trade_in_year: {
      value: '',
      error: false,
      errorMessage: 'You must enter Trade in year'
    },
    docusign_email_changed: {
      value: false
    },
    condition_reports: {
      value: '',
      error: false,
      errorMessage: 'You must enter condition reports'
    },
  })

  const [attachment, setAttachment] = useState({
    asset_verification_attachment_1: {
      value: '',
      error: false,
      errorMessage: 'Attachment 1 is required'
    },
    asset_verification_attachment_2:{
      value: '',
      error: false,
      errorMessage: 'Attachment 2 is required'
    },
    asset_verification_attachment_3: {
      value: '',
      error: false,
      errorMessage: 'Attachment 3 is required'
    },
    asset_verification_attachment_4: {
      value: '',
      error: false,
      errorMessage: 'Attachment 4 is required'
    },
  })

  const [loading, setLoading] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
  }

  const handleAttachment = async (e: any) => {
    let file = e.target.files[0];
    let name = e.target.name

    if(file && file.type === 'application/pdf' && file.size > 4000000){
      setAttachment({
        ...attachment,
        [name]: {
          value: file,
          error: true,
          errorMessage: 'File size must be under 4 MB'
        }
      })
    }
    else{
      setAttachment({
        ...attachment,
        [name]: {
          value: file
        }
      })
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();


    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    let attachmentupdate = { ...attachment}
    let formData = new FormData()


    let formError = false;
    let requiredFields = ['asset_make', 'asset_vin', 'delivery_date', 'asset_model', 'asset_color', 'exact_odometer_mileage', 'lessee_email', 'dealer_email', 'payment_bank_name', 'payment_aba_routing_number', 'payment_account_number', 'payment_account_type', 'payment_account_holder', 'condition_reports']

    if (extendedServiceContractCostValue > 0) {
      requiredFields.push('service_contract_term')
    }
    if (prepaidMaintenanceCostValue > 0) {
      requiredFields.push('ppm_contract_term')
    }
    if (tireAndWheelContractCostValue > 0) {
      requiredFields.push('tire_contract_term')
    }

    if(tireAndWheelContractCostValue > 0) {
      requiredFields.push('tire_contract_term')
    }

    if(tradeAllowanceExist){
      requiredFields.push('trade_in_year')
      requiredFields.push('trade_in_make')
      requiredFields.push('trade_in_model')
    }
  

    if(attachmentupdate.asset_verification_attachment_1.value == "" || attachmentupdate.asset_verification_attachment_1.error == true){
      attachmentupdate.asset_verification_attachment_1.error =true
      formError = true;
    }else{
      formData.append("asset_verification_attachment_1", attachmentupdate.asset_verification_attachment_1.value)
    }
    if(attachmentupdate.asset_verification_attachment_2.value == "" || attachmentupdate.asset_verification_attachment_2.error == true){
      attachmentupdate.asset_verification_attachment_2.error =true
      formError = true;
    }else{
      formData.append("asset_verification_attachment_2", attachmentupdate.asset_verification_attachment_2.value)
    }
    if(attachmentupdate.asset_verification_attachment_3.value == "" || attachmentupdate.asset_verification_attachment_3.error == true){
      attachmentupdate.asset_verification_attachment_3.error =true
      formError = true;
    }else{
      formData.append("asset_verification_attachment_3", attachmentupdate.asset_verification_attachment_3.value)
    }
    if(attachmentupdate.asset_verification_attachment_4.value == "" || attachmentupdate.asset_verification_attachment_4.error == true){
      attachmentupdate.asset_verification_attachment_4.error =true
      formError = true;
    }else{
      formData.append("asset_verification_attachment_4", attachmentupdate.asset_verification_attachment_4.value)
    }


    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;

      if (currentValue === '' || currentValue === null || currentValue === 0) {

        if (currentField === 'lessee_email') {
          setOpenDocusign(true)
        }

        if (requiredFields.includes(currentField)) {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              value: '',
              error: true
            }
          }
        }



        if (currentField === 'contract_term_length' && currentValue === 0) {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              value: 0,
              error: true
            }
          }
        }

        if (requiredFields.includes(currentField)) {
          formError = true
        }
      } else {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false
          }
        }

        if (currentField === 'payment_aba_routing_number') {
          if (currentValue.length != 9) {
            newFormValues = {
              ...newFormValues,
              [currentField]: {
                ...newFormValues[currentField],
                error: true,
                errorMessage: 'You must enter 9 digits of payment aba routing number'
              }
            }
            formError = true
          } else {
            let aa = validRoutingNumber(currentValue);
            if (!aa) {
              newFormValues = {
                ...newFormValues,
                [currentField]: {
                  ...newFormValues[currentField],
                  error: true,
                  errorMessage: 'Please enter a valid payment aba routing number e.g (7 8 9 4 5 6 1 2 4)'
                }
              }
              formError = true
            }
          }
        }
      }

      // if (currentField === 'contract_term_length' && currentValue === '' && !serviceOrMaintainanceOrWheelExist) {
      //   newFormValues = {
      //     ...newFormValues,
      //     [currentField]: {
      //       ...newFormValues[currentField],
      //       value: 0,
      //       error: false
      //     }
      //   }
      // }
    }

    setFormValues(newFormValues)
    if (!formError) {
      let finalFormValues: any = {};
      Object.keys(formValues).forEach(function (key) {
        finalFormValues[key] = formValues[key].value
      });
      Object.keys(finalFormValues).forEach(function (key) {
        formData.append(key , finalFormValues[key])
      });

      try{
        setLoading(true)
        let res = await network.POST(`/api/v1/dealers/save-request-lease-document?id=${data?.id}`, formData)
        if(res.status  === 200){
          setLoading(false)
          setDisable(true)
          messageApi.open({
            type: 'success',
            content: '',
            duration: 10,
          });
          message.success("Lease Request document submitted successfully", 1)
          setTimeout(() => {
            window.location.href = "/home";
          }, 1000);
        }
      }catch(e){
        setLoading(false)
        message.error("Error submitting Lease Request document")
        logger.error("Error fetching Applicatins", e);

      }

    }
  }

  const validRoutingNumber = (routing_niumber: any) => {

    var checksumTotal = (7 * (parseInt(routing_niumber.charAt(0), 10) + parseInt(routing_niumber.charAt(3), 10) + parseInt(routing_niumber.charAt(6), 10))) +
      (3 * (parseInt(routing_niumber.charAt(1), 10) + parseInt(routing_niumber.charAt(4), 10) + parseInt(routing_niumber.charAt(7), 10))) +
      (9 * (parseInt(routing_niumber.charAt(2), 10) + parseInt(routing_niumber.charAt(5), 10) + parseInt(routing_niumber.charAt(8), 10)));
    var checksumMod = checksumTotal % 10;

    if (checksumMod != 0) {
      return false;
    } else {
      return true;
    }
  }

  const getRequestLeaseDocuments = async () => {
    try {
      let resposne = await network.GET(`/api/v1/dealers/request-lease-document?id=${data?.id}`);
      if (resposne?.data.data.lease_calculator !== undefined) {
        setFormValues({
          ...formValues,
          asset_make: {
            ...formValues.asset_make,
            value: resposne?.data.data.make_value,
            error: false
          },
          asset_model: {
            ...formValues.asset_model,
            value: resposne?.data.data.lease_calculator.asset_model,
            error: false
          },
          asset_year: {
            ...formValues.asset_year,
            value: resposne?.data.data.lease_calculator.asset_year,
            error: false
          },
          asset_vin: {
            ...formValues.asset_vin,
            value: resposne?.data.data.lease_calculator.asset_vin,
            error: false
          },
          gap_contract_term: {
            ...formValues.gap_contract_term,
            value: data?.leaseCalculator?.guaranteedAutoProtectionCost > 0 ? data.leaseCalculator.term : "",
          },
          lessee_email: {
            ...formValues.lessee_email,
            value: resposne?.data?.data?.lessee_email,
            error: false
          },
          colessee_email: {
            ...formValues.colessee_email,
            value: resposne?.data?.data?.colessee_email,
          },
          dealer_email: {
            ...formValues.dealer_email,
            value: resposne?.data?.data?.dealer_email,
            error: false
          },
          payment_bank_name: {
            ...formValues.payment_bank_name,
            value: resposne?.data?.data?.payment_bank?.payment_bank_name,
            error: false
          },
          payment_aba_routing_number: {
            ...formValues.payment_aba_routing_number,
            value: resposne?.data?.data?.payment_bank?.payment_aba_routing_number,
            error: false
          },
          payment_account_number: {
            ...formValues.payment_account_number,
            value: resposne?.data?.data?.payment_bank?.payment_account_number,
            error: false
          },
          payment_account_type: {
            ...formValues.payment_account_type,
            value: resposne?.data?.data?.payment_bank?.payment_account_type,
            error: false
          },
        })
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  useEffect(() => {
    getRequestLeaseDocuments();
  }, [])

  return (
    <>
      <Layout>
        <MainHeader />
        <Layout id="#dealership-edit">
          <MainSider activeKey="home" />
          <Layout id="content-area-layout">
            <Content id="main-content">
            <Spin
             spinning={loading}
             size="large"
             tip="Loading..."
             >
              {contextHolder}
              <Typography align='center' variant="h4" sx={{ marginTop: "15px" }}  >
                Request Lease Documents
              </Typography>
              <div style={{ marginTop: '-3%', position: "relative", textAlign: 'right' }}>
                <p className="paraph">Application Identifier:  <span className="paraph-data">{data?.applicationIdentifier || 'N/A'}</span></p>
                <p className="paraph">Applicant Name: <span className="paraph-data">{(typeof data?.lessee === 'undefined') || data?.lessee === null ? 'N/A' : (data?.lessee?.firstName || '') + ' ' + (data?.lessee?.lastName || '')}</span></p>
              </div>
              <Box >
                <form noValidate onSubmit={handleSubmit}>
                  <ConfirmationDialouge
                      openDocusign={openDocusign}
                      setFormValues={setFormValues}
                      formValues={formValues}
                      handleChange={handleChange}
                  />
                  <AssetInformation calculatorData={data} formValues={formValues} handleChange={handleChange}/>
                  <ContactInformation
                    calculatorData={data}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    handleChange={handleChange}
                    extendedServiceContractCostValue={extendedServiceContractCostValue}
                    prepaidMaintenanceCostValue={prepaidMaintenanceCostValue}
                    tireAndWheelContractCostValue={tireAndWheelContractCostValue}
                  />
                  <BankingInformation formValues={formValues} handleChange={handleChange} />
                  <AssetVerficationAttachment attachment={attachment} formValues={formValues} handleChange={handleAttachment} />
                  <ConditionReports formValues={formValues} handleChange={handleChange} />
                  <Notes formValues={formValues} handleChange={handleChange} />
                  <Button variant="contained" disabled={disable} style={{ backgroundColor: "#e93b1b", marginTop: "15px", float: "right" }} type="submit" size="large"  >
                    Save Lease Document Request
                  </Button>
                </form>
              </Box>
            </Spin>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default RequestLeaseDocuments;