import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from '@mui/material/Avatar';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Textarea from '@mui/joy/Textarea';

const useStyles = makeStyles({
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
});

const useStylesCardSubheader = makeStyles(({
    subAlignment: {
        color: 'black',
        textAlign: 'right',
        fontSize: '12px'
    }
}));

const useStylesCardContent = makeStyles(({
    content: {
        marginTop: '-2%',
        marginLeft: '-3%',
        marginRight: '-3%'
    }
}));

const useStylesCardContentReply = makeStyles(({
    content: {
        marginTop: '-3%',
        marginBottom: '-3%',
        marginLeft: '-3%',
        marginRight: '-3%'
    }
}));

const useStylesDialogContent = makeStyles(({
    height: {
        overflowY: 'visible'
    }
}));

const useStylesCloseIcon = makeStyles(({
    size: {
        fontSize: '9px'
    }
}));

interface NoteReply {
    id: number,
    authorId: number,
    author: string,
    body: string
    createdAt: string
}

interface Note {
    id: number,
    author: string,
    body: string
    createdAt: string
    authorId: number
    replies: NoteReply[]
    namespace: string
}

const ReadMore = (props: any) => {
    const { text } = props;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const readMoreSpan = () => {
        if(text.length > 150){
            return <span onClick={toggleReadMore} style={{ color: 'blue' }}>
                {isReadMore ? "...Read more" : " Show less"}
            </span>
        }
    }

    return (
        <p style={{ marginLeft: '67px', fontSize: "14px", flex: 1, overflow: "auto", width: '90%', marginBottom: '10px' }}>
            {isReadMore ? text.slice(0, 150) : text}
            { readMoreSpan() }
        </p>
    );
};

const canSubmitData = window.localStorage.getItem("can_submit_speedlease");

export default function NotesDialogModal(props: any) {
    const classes = useStyles();
    const classesSubheader = useStylesCardSubheader()
    const classesDialogContent = useStylesDialogContent()
    const classesCardContent = useStylesCardContent()
    const classesCardContentReply = useStylesCardContentReply()
    const classesCloseIcon = useStylesCloseIcon()
    const { unlockDialog, handleClose, notes, addNote, saveEditedNote, deleteNote } = props;
    const [newNote, setNewNote] = useState<string>('');
    const authData: any =  JSON.parse(`${window.localStorage.getItem('user_data')}`)
    const [editedNotes, setEditedNotes] = useState<Note[]>([])
    const disableBtn = canSubmitData === "false" ? true : false;

    const avatarBackgroundColor = (note: Note) => {
        if(note.authorId === parseInt(authData.id)){
            return 'green'
        }
        else if(note.authorId !== parseInt(authData.id) && note.namespace == 'admins'){
            return 'red'
        }
        else{
            return 'blue'
        }
    }

    const editNotes = (id: number, note: string) => {
        const newEditedNotes = [...editedNotes.filter(eNote => eNote.id !== id)]
        const index = editedNotes.findIndex(note => note.id === id)
        const copy = {...editedNotes[index]}
        copy.body = note
        newEditedNotes.push(copy)
        setEditedNotes(newEditedNotes)
    }

    const cardNoteReply = (note: any) => {
        return <Card elevation={1} style={{ marginLeft: '66px', width: '88%' }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ backgroundColor: 'red', width: 30, height: 30 }}>
                        {note.author && note.author.charAt(0)}
                    </Avatar>
                }
                title={
                    <Typography variant={"body2"}>
                        {note.author}
                    </Typography>
                }
                subheader={
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant={"body2"}>Admin</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"body2"} className={classesSubheader.subAlignment}>{moment(note.createdAt).format('LLL')}</Typography>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent className={classesCardContentReply.content}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} sx={{ paddingRight: '20px' }}>
                        <ReadMore text={note.body}></ReadMore>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    }

    const cardNote = (note: Note, index: number) => {
        return <Card elevation={4}>
            <CardHeader
                avatar={
                    <Avatar sx={{ backgroundColor: avatarBackgroundColor(note), width: 30, height: 30 }}>
                        {note.author && note.author.charAt(0)}
                    </Avatar>
                }
                action={deleteNoteButton(note)}
                title={
                    <Typography variant={"body2"}>
                        {note.author}
                    </Typography>
                }
                subheader={
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant={"body2"}>{ note.namespace === 'admins' ? 'Admin' : 'Dealer' }</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"body2"} className={classesSubheader.subAlignment}>{moment(note.createdAt).format('LLL')}</Typography>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent className={classesCardContent.content}>
                <Grid container rowSpacing={1}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={11}>
                            {
                                noteBody(note)
                            }
                        </Grid>
                        <Grid item xs={1}>
                            {
                                saveEditNoteButton(note)
                            }
                        </Grid>
                    </Grid>
                    {
                        note.authorId === parseInt(authData.id) && (note.replies instanceof Array) && note.replies.map((reply, index) => {
                            return <Grid item xs={12}>
                                {
                                    cardNoteReply(reply)
                                }
                            </Grid>
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    }

    const noteBody = (note: Note) => {
        const notesWithId = editedNotes.filter(n => n.id === note.id)
        if(notesWithId.length === 0){
            return <ReadMore text={note.body}></ReadMore>
        }
        else{
            return <TextField
                autoFocus
                multiline
                style={{ width: '90%' }}
                InputProps={{ classes, style: { fontSize: '14px', marginLeft: '66px' } }}
                defaultValue={note.body}
                onChange={event => editNotes(note.id, event.target.value)}
                disabled={note.authorId !== parseInt(authData.id) ? true : false}
            />
        }
    }

    const saveEditNoteButton = (note: Note) => {
        const notesWithId = editedNotes.filter(n => n.id === note.id)
        if(notesWithId.length > 0){
            return saveNoteButton(note)
        }
        else{
            return editNoteButton(note)
        }
    }

    const editNoteButton = (note: Note) => {
        if(note.authorId === parseInt(authData.id)){
            return <Tooltip title={<h5>Edit Note</h5>}>
                <IconButton onClick={() => {
                    const newEditedNotes = [...editedNotes]
                    newEditedNotes.push(note)
                    setEditedNotes(newEditedNotes)}}><EditIcon color={'error'} style={{ fontSize: '16px' }}/>
                </IconButton>
            </Tooltip>
        }
    }

    const saveNoteButton = (note: Note) => {
        if(note.authorId === parseInt(authData.id)){
            return <Tooltip title={<h5>Save Note</h5>}>
                <IconButton onClick={() => {
                    const editedNoteBody = editedNotes.find(editedNote => editedNote.id === note.id)
                    const body = editedNoteBody?.body || ''
                    setEditedNotes(editedNotes.filter(n => n.id !== note.id))
                    saveEditedNote(body, note.id);
                }}>
                    <SaveIcon color={'error'} style={{ fontSize: '16px' }}/>
                </IconButton>
            </Tooltip>
        }
    }

    const deleteNoteButton = (note: Note) => {
        if(note.authorId === parseInt(authData.id)){
            return <Tooltip title={<h5>Delete Note</h5>}>
                <IconButton onClick={() => { deleteNote(note.id) }}>
                    <DeleteIcon color={'error'} style={{ fontSize: '16px' }}/>
                </IconButton>
            </Tooltip>
        }
    }

    return (
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "820px",
                        maxHeight: "540px"
                    },
                },
            }}
            open={unlockDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle sx={{m: 0, p: 2}}>
                {'Notes'}
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            handleClose()
                        }}
                        color={"warning"}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            backgroundColor: "#e93b1b",
                            color: 'black',
                    }}
                    >
                        <CloseIcon className={classesCloseIcon.size}/>
                    </IconButton>
            </DialogTitle>
            {
                (notes instanceof Array) && notes.length > 0 &&
                <DialogContent dividers>
                  <Grid container rowSpacing={1}>
                      {
                          notes.map((note, index) => {
                              return <Grid item xs={12}>
                                  <Box sx={{ p: 1 }}>
                                      {
                                          cardNote(note, index)
                                      }
                                  </Box>
                              </Grid>
                          })
                      }
                  </Grid>
                </DialogContent>
            }

            <DialogContent className={classesDialogContent.height}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={1}>
                        <Avatar sx={{ backgroundColor: 'green' }}>
                            {authData?.full_name.charAt(0)}
                        </Avatar>
                    </Grid>
                    <Grid item xs={11}>
                        <Textarea
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Click here to add a note..."
                            style={{ width: '100%' }}
                            value={newNote}
                            onChange={(event) => {
                                setNewNote(event.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button style={{  backgroundColor: "#e93b1b", color: 'white', marginRight: '2%' }}
                        onClick={() => {
                            addNote(newNote)
                            setNewNote('')
                        }}
                        disabled = {disableBtn}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
