import React, { useContext } from 'react'
import { Avatar, Col, Layout, Row, Popover } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { UserDataContext } from "../../contexts/UserDataContext";
import './styles/MainHeader.css'
import Logo from './Logo';

const { Header } = Layout;

const AvatarContent = (
  <div>
    <a href={`/logout`}> Logout </a>
  </div>
);

function MainHeader() {

  const { fullName, dealershipName } = useContext(UserDataContext);

  return (
    <Header className="main-header-layout" style={{ position: 'fixed', zIndex: 9999, width: '100%' }}>
      <Row>
        <Col flex='1 1 200px'>
          <Logo/>
        </Col>
        <Col flex='0 1 500px'>
          <div className='main-header-info'>
            <Popover placement="bottomRight" content={AvatarContent} trigger="click">
              <div>
                <span style={{ padding: `0 5px` }} > {` ${fullName} `} </span>
              </div>
              <div>
                <span style={{ padding: `0 5px` }} > {`${dealershipName}`}</span>
              </div>
              <Avatar size={36} icon={<UserOutlined />} className="ant-avatar-icon" />
            </Popover>
          </div>
        </Col>
      </Row>
    </Header>
  )
}

export default MainHeader
