import React from 'react'
import { Home } from './Home'

function HomeRenderer() {
    return (
        <div>
            <Home/>
        </div>
    )
}

export default HomeRenderer
