import React, { createContext } from "react";

interface UserDataProps {
    fullName?: string,
    dealershipName?: string,
    dealershipState?: string,
    dealershipZipcode?: string
}

export const UserDataContext = createContext<UserDataProps>({});

export default {UserDataContext};