import { useHistory } from 'react-router-dom';
import logoImage from '../../assets/speed-leasing.png';

function Logo() {
    const history = useHistory();
    return (
        <div className="logo" >
        <img className="logo-img" onClick={()=>{history.push('/home')}} src={logoImage} alt="SpeedLeasing"/>
      </div>
    )
}

export default Logo
