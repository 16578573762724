import React, { useState, useEffect, useMemo } from "react";
import { Spin, Row, Col, message, Statistic, Card, Progress } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import { LicenseInfo } from '@mui/x-license-pro';
import AppListDataGrid from './AppListDataGrid';
import { logger, network, auth } from "../../../../utils";
import "../styles/ApplicationList.css";
import NotesDialogModal from "../NotesDialogModal";
import Filters from "./Components/Filters";
import PieChart from "./Components/PieChart";
import FundedBarChart from "./Components/FundedBarChart";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MUITypography from '@mui/material/Typography';
import { Carousel } from 'antd';
import { styled } from '@mui/system';
import { useSelector, useDispatch } from "react-redux";
import { setApplications, updatePageState, updateFilterData, clearUnseenNotesCount, insertNote, deleteNoteFromState, editNoteFromState } from '../../../../reducers/LeaseApplicationsReducer'
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import HomeDialogModal from "./HomeDialogModal";
import moment from 'moment';
import {useHistory} from "react-router-dom";

LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

interface Note {
  id: number,
  authorId: number,
  author: string,
  body: string
  createdAt: string
  replies: NoteReply[]
}

interface NoteReply {
  id: number,
  authorId: number,
  author: string,
  body: string
  createdAt: string
}

interface Data {
  id: number
  applicationIdentifier: string;
  applicant: string;
  coApplicant: string;
  modelAndYear: string;
  creditStatus: string;
  documentStatus: string;
  daysSubmitted: string;
  lastUpdated: string;
  comments: Note
  unseenNotesCount: number
}

const cardStyle: React.CSSProperties = {
  margin: '0 10px 20px 10px',
  border: '1px solid lightgray',
}
const cardStyle2: React.CSSProperties = {
  margin: '4px 10px 20px 10px',
  border: '1px solid lightgray',
}

const selectDropdownStyle: React.CSSProperties = {
  border: '1px solid lightgray'
}

function ApplicationList() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);

  const currentYear = () => new Date().getFullYear();

  const data = useSelector((state: any) => state?.applications?.applicationLists);
  const isFromReduxTimer = useSelector((state: any) => state?.applications?.isFromTimer);
  const statusData = useSelector((state: any) => state?.applications?.statusData);
  const { submitted, chartdata2, funded, approved, documents_issued, documents_requested, no_documents, approved_no_documents,
    approved_documents_requested, approved_documents_issued, approved_lease_package_received, approved_funded,
    approved_funding_approved, approved_funding_delay, approved_canceled } = statusData;

  const [paginationData, setPaginationData] = useState<object>({});
  const [unlockNotesDialog, setUnlockNotesDialog] = useState<boolean>(false);
  const [applicationRow, setApplicationRow] = useState<Data>();
  const [notes, setNotes] = useState<any[]>([])

  const dispatch = useDispatch();
  const pageState = useSelector((state: any) => state.applications.pageState);
  const filterData = useSelector((state: any) => state.applications.filterData);
  const [unlockArchiveDialog, setUnlockArchiveDialog] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage()

  const [isCustomFilter, setIsCustomFilter] = useState(false)
  const [rangeStartDate, setRangeStartDate] = useState('')
  const [rangeEndDate, setRangeEndDate] = useState('')
  const [rangeEndMinimumStartDate, setRangeEndMinimumStartDate] = useState('')
  const [resubmitApplicationDialog, setResubmitApplicationDialog] = useState<boolean>(false);

  const handleClose = () => {
    setUnlockNotesDialog(false)
  }

  const handleArchiveDialogClose = () => {
    setUnlockArchiveDialog(false)
  }

  const handleResubmitApplicationDialogClose = () => {
    setResubmitApplicationDialog(false)
  }

  const resubmitApplication = async (id: number) => {
    if (!loading) {
      setLoading(true);
    }
    try {
      await network.PUT(`/api/v1/dealers/update_to_resubmit/${id}`, {}).then(() => {
        history.push(`/applications/${id}/bike`);
      })
    } catch (e: any) {
      if (e && e.response && e.response.status === 401) {
        messageApi.open({
          type: 'error',
          content: 'Error while resubmitting application.',
          duration: 5,
        });
        message.error('Error while resubmitting application.');
      } else if (e && e.response && e.response.status === 406) {
        messageApi.open({
          type: 'error',
          content: e.response?.data?.message,
          duration: 5,
        });
        message.error(e.response?.data?.message);
      } else {
        messageApi.open({
          type: 'error',
          content: 'Unable to resubmit.',
          duration: 5,
        });
        message.error('Unable to resubmit lease application.');
      }
    }
    setLoading(false);
  };

  const filterParams = () => {
    if (filterData && Object.keys(filterData).length > 0) {
      return { filter: { ...filterData } };
    } else {
      return {};
    }
  };

  const paginationParams = () => {
    if (paginationData && Object.keys(paginationData).length > 0) {
      return { pagination: { ...paginationData } };
    } else {
      return {};
    }
  };

  const getApplications = async (params: any) => {
    if (!loading && !params.isFromTimer) {
      setLoading(true);
    }

    try {
      await network
        .POST(`/api/v1/dealers/applications`, {
          ...filterParams(),
          ...paginationParams(),
          creditStatus: pageState.creditStatus,
          documentStatus: pageState.documentStatus,
          search: pageState.search,
          isIncludeUnsubmitted: pageState.isIncludeUnsubmitted,
          dealership_id: auth.getDealershipId(),
        })
        .then((response) => {
          if (response?.data) {
            if (
              params.isFromTimer &&
              data.length > 0 &&
              JSON.stringify(data) !==
                JSON.stringify(response?.data?.data?.leaseApplications)
            ) {
              dispatch(
                setApplications({ data: response?.data, isTimer: true })
              );
            } else {
              dispatch(
                setApplications({ data: response?.data, isTimer: false })
              );
            }
          }
        })
        .catch((error) => {
          logger.error("Error fetching Applications", error);
        });
    } catch (e) {
      logger.error("Error fetching Applications", e);
    }
    setLoading(false);
  };

  const archiveApplication = async (id: number) => {
    if (!loading) {
      setLoading(true);
    }
    try {
      await network
        .POST(`/api/v1/dealers/applications/${id}/archive`, {})
        .then((response) => {
          message.success(response.data.message);
          getApplications({ isFromTimer: false });
        })
        .catch((error) => {
          logger.error("Error fetching filter options", error);
        });
    } catch (e) {
      logger.error("Error fetching filter options", e);
    }
    setLoading(false);
  };

  const handleChangeDateStart = (newValue: any) => {
    let startdate = new Date(newValue);
    const formatedDate = new Date(startdate).toLocaleDateString('fr-CA').split('-').join('/');
    setRangeStartDate(formatedDate);
    setRangeEndMinimumStartDate(formatedDate);
    if(rangeEndDate !== '') {
      const timeRangeDates = formatedDate + ' - ' + rangeEndDate
      dispatch(updateFilterData({ timeRange: timeRangeDates }))
    }
};

const handleChangeDateEnd = (newValue: any) => {
    let endDate = new Date(newValue);
    const formatedDate = new Date(endDate).toLocaleDateString('fr-CA').split('-').join('/');
    setRangeEndDate(formatedDate)
    const timeRangeDates = rangeStartDate + ' - ' + formatedDate
    dispatch(updateFilterData({ timeRange: timeRangeDates }))
};

  const handleSelectFilterParams = (value: any) => {
    setIsCustomFilter(false)
    if (value == "All") {
      dispatch(updateFilterData({ timeRange: null }))
    } else if(value === "custom") {
      setIsCustomFilter(true)
    } else {
      dispatch(updateFilterData({ timeRange: value }))
    }
  };

  const lastQuarterly = () => {
    let lastQuaterStart = moment().subtract(1, 'quarter').startOf('quarter').format('YYYY/MM/DD')
    let lastQuaterEnd = moment().subtract(1, 'quarter').endOf('quarter').format('YYYY/MM/DD')
    let lastQuaterDate = lastQuaterStart + " - " + lastQuaterEnd
    return lastQuaterDate;
  }

  const lastMonth = () => {
    const date = new Date();
    const firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1).toLocaleDateString('fr-CA').split('-').join('/');
    const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).toLocaleDateString('fr-CA').split('-').join('/');
    return `${firstDayPrevMonth} - ${lastDayPrevMonth}`;
  }

  const currentMonth = () => {
    const date = new Date();
    const firstDayThisvMonth = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('fr-CA').split('-').join('/');
    const lastDayThisMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('fr-CA').split('-').join('/');
    return `${firstDayThisvMonth} - ${lastDayThisMonth}`;
  }

  const [isTimer, setIsTimer] = useState(false);
  useEffect(() => {
    getApplications({ isFromTimer: false });

    const interval = setInterval(() => {
      setIsTimer(true)
      getApplications({ isFromTimer: true });
    }, 20000);
    return () => clearInterval(interval);

  }, [paginationData, pageState, filterData]);

  const addNote = async (newNote: string) => {
    setLoading(true)
    try {
      const response = await network.POST(`/api/v1/comments/x/lease-applications/${applicationRow?.id}`, { commit: newNote })
      const addedNotes = [response.data, ...notes]
      setNotes(addedNotes)
      dispatch(insertNote({ id: applicationRow?.id, note: response.data }))
      setLoading(false)
      messageApi.open({
        type: 'success',
        content: 'Note saved successfully!',
        duration: 5,
      });
      message.success("Note saved successfully!")
      setTimeout(() => {
      }, 1000);
    } catch (e) {
      logger.error("Submit Notes Error", e)
      setLoading(false)
      message.error("Error while saving!")
    }
  }

  const editNote = async (editedNote: string, commentId: string) => {
    setLoading(true)
    try {
      const response = await network.PUT(`/api/v1/comments/${commentId}/edit`, { commit: editedNote })
      const newNotes = [...notes]
      const index = notes.findIndex(note => note.id === commentId)
      const copy = { ...newNotes[index] }
      copy.body = response.data.body
      newNotes[index] = copy
      setNotes(newNotes)
      dispatch(editNoteFromState({ id: applicationRow?.id, noteId: commentId, body: editedNote }))
      setLoading(false)
      messageApi.open({
        type: 'success',
        content: 'Note updated successfully!',
        duration: 5,
      });
      message.success("Note updated successfully!")
      setTimeout(() => {
      }, 1000);
    } catch (e) {
      logger.error("Update Notes Error", e)
      setLoading(false)
      message.error("Error while updating!")
    }
  }

  const deleteNote = async (commentId: string) => {
    setLoading(true)
    try {
      await network.DELETE(`/api/v1/comments/${commentId}`)
      const newNotes = notes.filter(note => note.id !== commentId)
      setNotes(newNotes)
      dispatch(deleteNoteFromState({ id: applicationRow?.id, noteId: commentId }))
      setLoading(false)
      messageApi.open({
        type: 'success',
        content: 'Note deleted successfully!',
        duration: 5,
      });
      message.success("Note deleted successfully!")
      setTimeout(() => {
      }, 1000);
    } catch (e) {
      logger.error("Delete Notes Error", e)
      setLoading(false)
      message.error("Error while deleting!")
    }
  }

  const visitNotesByCurrentUser = async (application: Data) => {
    if (application.unseenNotesCount > 0) {
      try {
        await network.PUT(`/api/v1/comments/x/lease-applications/${application.id}/visit-notes`, {})
        dispatch(clearUnseenNotesCount({ id: application.id }))
      } catch (e) {
        logger.error("Visiting Notes Error", e)
        setLoading(false)
        message.error("Error while visiting notes")
      }
    }
  }

  const getStatusPercentage = (total: number, statusDataValue: number) => {
    const percentage = (statusDataValue * 100) / total
    return Number(percentage.toFixed(2))
  }


  const removeCoApplicant = async (id: number) => {
    if (!loading) {
      setLoading(true);
    }
    try {
      await network
        .POST(`/api/v1/dealers/applications/${id}/co-applicant`, {})
        .then((response) => {
          message.success(response.data.message);
          getApplications({ isFromTimer: false });
        })
        .catch((error) => {
          logger.error("Error fetching filter options", error);
        });
    } catch (e) {
      logger.error("Error fetching filter options", e);
    }
    setLoading(false);
  }

  const [checkedGraph, setCheckedGraph] = React.useState(false);

  const handleChangeGraph = () => {
    setCheckedGraph((prev) => !prev);
  };

  const pieChart = useMemo(() =>
    <PieChart
      approved={approved}
      no_documents={no_documents}
      documents_requested={documents_requested}
      documents_issued={documents_issued}
      funded={funded}
      submitted={submitted}
      approved_no_documents={approved_no_documents}
      approved_documents_requested={approved_documents_requested}
      approved_documents_issued={approved_documents_issued}
      approved_lease_package_received={approved_lease_package_received}
      approved_funded={approved_funded}
      approved_funding_approved={approved_funding_approved}
      approved_funding_delay={approved_funding_delay}
      approved_canceled={approved_canceled}
    />,
    [approved, no_documents, documents_requested, documents_issued, funded, submitted, approved_no_documents,
      approved_documents_requested, approved_documents_issued, approved_lease_package_received, approved_funded,
      approved_funding_approved, approved_funding_delay, approved_canceled]
  );

  const chartData = JSON.stringify(chartdata2 !== undefined ? chartdata2 : [{}])
  const fundedChart = useMemo(() => {
    return (<FundedBarChart
      chartdata2={JSON.parse(chartData)}
    />);
  }, [chartData]);

  return (
    <Spin spinning={isTimer === true ? false : loading}>
      {contextHolder}
      {
        statusData.banner !== undefined && statusData.banner.length > 0 ? (
          <Row align='middle'>
            <Col flex='auto'>
              <Carousel autoplay autoplaySpeed={20000} arrows dots={false}>
                {
                  statusData.banner.map((item: any, index: any) => (
                    < div >
                      <Alert severity="info" sx={{
                        mb: 2,
                        backgroundColor: "#f05134",
                        "& .MuiAlert-icon": { color: "#fff" }
                      }}>
                        <AlertTitle sx={{ color: "#fff" }}>{item?.headline}</AlertTitle>
                        <MUITypography sx={{ color: "#fff" }}>{item?.message} </MUITypography>
                      </Alert>
                    </div>
                  ))
                }
              </Carousel>
            </Col>
          </Row>) : ''
      }
      <Row>
        <Col lg={6} xl={6} md={12}>
          <Card style={cardStyle} hoverable={true}>
            <Row align='middle'>
              <Col flex='auto'>
                <Statistic title="SUBMITTED" value={submitted} />
              </Col>
              <Col>
                <ShopOutlined style={{ fontSize: '20px' }} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={6} xl={6} md={12}>
          <Card style={cardStyle} hoverable={true}>
            <Row align='middle'>
              <Col flex='auto'>
                <Statistic title="APPROVED" value={approved} />
              </Col>
              <Col>
                <Progress type="circle" percent={getStatusPercentage(submitted, approved)} width={60} strokeColor='green' strokeWidth={3} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={6} xl={6} md={12}>
          <Card style={cardStyle2} hoverable={true}>
            <Row align='middle'>
              <Col flex="auto">
                <span style={{ fontSize: "14px", marginBottom: "4px", position: "relative", bottom: "20px", color: "#00000073", }}>CONVERSION RATE</span>
              </Col>
              <Col>
                <Progress type="circle" percent={getStatusPercentage(approved, funded)} width={60} strokeColor='red' strokeWidth={3} />
              </Col>
            </Row>

          </Card>
        </Col>
        <Col lg={6} xl={6} md={12}>
          <Card style={cardStyle} hoverable={true}>
            <Row align='middle'>
              <Col flex='auto'>
                <span style={{ fontWeight: "bold", color: "#04b51eed", }}>FUNDED </span>
                <Statistic className="custom-statistic" value={`${funded || 0} (${statusData.total_sales_price || 0})`} />
              </Col>
            </Row>
          </Card>
        </Col>

      </Row>

      <Row wrap={false}>
        <StyledFormControlLabel
          control={<Switch checked={checkedGraph} onChange={handleChangeGraph} color="warning" />}
          label="Show Graphs"

        />
      </Row>
      <Row >
        <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={12}>
          <Collapse in={checkedGraph}>
            <Card size="small" title="APP PERFORMANCE" style={{ ...cardStyle }} hoverable={true}>
              {pieChart}
            </Card>
          </Collapse>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={12}>
          <Collapse in={checkedGraph}>
            <Card size="small" title="Funded By Months/Years" style={{ ...cardStyle }} hoverable={true}>
              {fundedChart}
            </Card>
          </Collapse>
        </Col>

      </Row>

      <Filters
        handleSelectFilterParams={handleSelectFilterParams}
        selectDropdownStyle={selectDropdownStyle}
        lastQuarterly={lastQuarterly}
        currentYear={currentYear}
        lastMonth={lastMonth}
        currentMonth={currentMonth}
        pageState={pageState}
        rangeStartDate={rangeStartDate}
        rangeEndDate={rangeEndDate}
        rangeEndMinimumStartDate={rangeEndMinimumStartDate}
        handleChangeDateStart={handleChangeDateStart}
        handleChangeDateEnd={handleChangeDateEnd}
        isCustomFilter={isCustomFilter}
      />

      <AppListDataGrid
        data={data}
        loading={loading}
        isFromTimer={isFromReduxTimer}
        statusData={statusData}
        archiveApplication={archiveApplication}
        removeCoApplicant={removeCoApplicant}
        setUnlockArchiveDialog={setUnlockArchiveDialog}
        setResubmitApplicationDialog={setResubmitApplicationDialog}
        setApplicationRow={setApplicationRow}
        setUnlockNotesDialog={setUnlockNotesDialog}
        setNotes={setNotes}
        visitNotesByCurrentUser={visitNotesByCurrentUser}
        updatePageState={updatePageState}
        pageState={pageState}
        setPaginationData={setPaginationData}
      />
      <NotesDialogModal
        unlockDialog={unlockNotesDialog}
        handleClose={handleClose}
        notes={notes}
        addNote={addNote}
        saveEditedNote={editNote}
        deleteNote={deleteNote}
      />
      <HomeDialogModal
        unlockDialog={unlockArchiveDialog}
        handleClose={handleArchiveDialogClose}
        submitApplication={archiveApplication}
        contentData={"This will archive the application. Are you sure you want to continue?"}
        title={"Archive Lease Application?"}
        params={applicationRow}
      />
      <HomeDialogModal
        unlockDialog={resubmitApplicationDialog}
        handleClose={handleResubmitApplicationDialogClose}
        submitApplication={resubmitApplication}
        contentData={"This will resubmit the application. Are you sure you want to continue?"}
        title={"Resubmit Lease Application?"}
        params={applicationRow}
      />
    </Spin >
  );
}

export default ApplicationList;


const StyledFormControlLabel = styled(FormControlLabel)({
  float: "right",
  fontWeight: "600 !important",
  marginBottom: "20px",
  '&  .MuiFormControlLabel-label': {
    fontSize: '14px',
    fontWeight: 600
  }
})