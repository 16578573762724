import React from 'react';
import { Archived } from './Archived';

function ArchivedRenderer() {
  return (
    <div>
      <Archived/>
    </div>
  )
};

export default ArchivedRenderer
