import Cookies from 'js-cookie'

export const isAuth = () => {
  // const dealershipIdCookies = Cookies.get("dealershipId");
  // if (dealershipIdCookies) {
  //   return false;
  // }
  const authTokenCookies = Cookies.get("authToken");
  const fullNameCookies = Cookies.get('fullName')
  if (!authTokenCookies || !fullNameCookies){
    return false 
  }
  return true
}


export const getFullName = () => {
  return Cookies.get('fullName')
}

export const getDealershipName = () => {
  return Cookies.get('dealershipName')
}

export const getDealershipState = () => {
  return Cookies.get('dealershipState')
}

export const getDealershipZipcode = () => {
  return Cookies.get('dealershipZipcode')
}


export const getToken = () => {
  return Cookies.get('authToken')
}

export const getDealershipId = () => {
  return Cookies.get("dealershipId");
};

export const setAuth = (token: string, fullName: string, dealershipName: string, dealershipState: string, dealershipZipcode: string, dealershipId?: string )  => {

  console.log(token)
  console.log(fullName)
  Cookies.set('authToken', token, { secure: true })
  Cookies.set('fullName', fullName, { secure: true })
  Cookies.set('dealershipName', dealershipName, { secure: true })
  Cookies.set('dealershipState', dealershipState )
  Cookies.set('dealershipZipcode', dealershipZipcode)
  Cookies.set('dealershipId', dealershipId || '')

  const authTokenCookies = Cookies.get('authToken')
  const fullNameCookies = Cookies.get('fullName')

  console.log(`after`)
  console.log(authTokenCookies)
  console.log(fullNameCookies)


  return authTokenCookies && fullNameCookies;
}

export const logout = () => {
  Cookies.remove('authToken')
  Cookies.remove('fullName')
  Cookies.remove("dealershipId")
}

export default {isAuth, setAuth, getFullName, logout, getToken, getDealershipId};