import { Col, Row, Typography, Tooltip } from "antd";
import InfoIcon from "@mui/icons-material/Info";
const { Title } = Typography;

export const DealerFundingBreakdownInfo = (props: any) => {
    const { calculatorData, convertUsd, tooltipColor } = props;

    return (
        <>
            <Title level={5} className="payment-calculator-headers"> Dealer Funding Breakdown </Title>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                    Total Sales Price
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.totalBikePrice && convertUsd(Number(calculatorData.totalBikePrice))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                    Less Net Trade In
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.netTradeInAllowance && convertUsd(Number(calculatorData.netTradeInAllowance))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                    Less Cash at Signing
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.totalCashAtSignIn && convertUsd(Number(calculatorData.totalCashAtSignIn))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                    Fund to Dealer
                    <Tooltip title="The exact amount Speed will send your dealership if all Funding requirements are met." color={tooltipColor} key={'cap_cost'}>
                        <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                    </Tooltip>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.remitToDealer && convertUsd(Number(calculatorData.remitToDealer))}</span>
                </Col>
            </Row>
        </>
    )
}

export default DealerFundingBreakdownInfo
