import React from 'react'
import { SavedCalculators } from './SavedCalculators'

function SavedCalculatorsRenderer() {
    return (
        <div>
            <SavedCalculators />
        </div>
    )
}

export default SavedCalculatorsRenderer
