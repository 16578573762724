import {
    Grid,
    FormControl,
    TextField,
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Typography,
    Select,
    MenuItem, OutlinedInput
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
import AbaRoutingImage from '../../../../../../assets/bank-routing-sample.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';

export const BankingInformation = (props: any) => {
    const { formValues, handleChange } = props;

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: "red" }
                },
            },
        },
    })

    const IconWithTooltip = withStyles({
        tooltip: {
            color: "black",
            backgroundColor: "transparent"
        }
    })(Tooltip);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                    <Grid container direction="row" sx={{ marginBottom: 4 }} spacing={2} >
                        <Grid container item md={12} >
                            <Typography sx={{ fontWeight: "bold", color: "#e93b1b" }}> Banking Information </Typography>
                        </Grid>
                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <StyledTextField
                                        required
                                        id="filled-input"
                                        label="Bank Name"
                                        variant="outlined"
                                        size="small"
                                        name="payment_bank_name"
                                        value={formValues.payment_bank_name.value}
                                        onChange={handleChange}
                                        error={formValues.payment_bank_name.error}
                                        helperText={formValues.payment_bank_name.error && formValues.payment_bank_name.errorMessage}
                                        inputProps={{ tabIndex: 16 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }} required={true}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Lessee or Co-Lessee ?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="payment_account_holder"
                                        value={formValues.payment_account_holder.value}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="Lessee" control={<Radio />} label="Lessee" />
                                        <FormControlLabel value="Co-Lessee" control={<Radio />} label="Co-Lessee" />
                                        <FormControlLabel value="Both" control={<Radio />} label="Both" />
                                    </RadioGroup>
                                    <FormHelperText error={formValues.payment_account_holder.error}>{formValues.payment_account_holder.error && formValues.payment_account_holder.errorMessage}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item md={3} spacing={2}>
                            <Grid item md={10}>
                                <FormControl sx={{ width: "100%" }}>
                                    <StyledTextField
                                        required
                                        id="filled-input"
                                        label="Payment ABA Routing Number"
                                        variant="outlined"
                                        size="small"
                                        name="payment_aba_routing_number"
                                        type="number"
                                        value={formValues.payment_aba_routing_number.value}
                                        onChange={handleChange}
                                        error={formValues.payment_aba_routing_number.error}
                                        helperText={formValues.payment_aba_routing_number.error && formValues.payment_aba_routing_number.errorMessage}
                                        inputProps={{ tabIndex: 17 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                <IconWithTooltip title={<img width="150%" height="100%" alt="Remy Sharp" src={AbaRoutingImage} />}>
                                    <HelpIcon style={{color: '#e93b1b'}}/>
                                </IconWithTooltip>
                            </Grid>
                        </Grid>

                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <StyledTextField
                                        required
                                        id="filled-input"
                                        label="Account Number"
                                        variant="outlined"
                                        size="small"
                                        name="payment_account_number"
                                        type="number"
                                        value={formValues.payment_account_number.value}
                                        onChange={handleChange}
                                        error={formValues.payment_account_number.error}
                                        helperText={formValues.payment_account_number.error && formValues.payment_account_number.errorMessage}
                                        inputProps={{ tabIndex: 18 }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }} error={formValues.payment_account_type.error}>
                                    <InputLabel style={{ marginTop: '-8px' }} id="demo-customized-select-label">Checking or Savings Account</InputLabel>
                                    <StyledSelect
                                        required
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        size="small"
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Tag" />}
                                        name="payment_account_type"
                                        value={formValues.payment_account_type.value}
                                        inputProps={{ tabIndex: 19 }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="checking">Checking</MenuItem>
                                        <MenuItem value="savings">Savings</MenuItem>
                                    </StyledSelect>
                                    {formValues.payment_account_type.error &&
                                        <FormHelperText>{formValues.payment_account_type.error && formValues.payment_account_type.errorMessage}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </>
    )
}

export default BankingInformation;

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
    '& .MuiFormHelperText-root':{
        marginTop: "0px !important",
        marginBottom: "-12px !important"
    }
})

const StyledSelect = styled(Select)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    "&.MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#001629"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#001629"
        },
    },
    '& .MuiFormHelperText-root':{
        marginTop: "0px !important",
        marginBottom: "-12px !important"
    }
})