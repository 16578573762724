import {useState, useEffect} from 'react'
import { Spin } from "antd";
import { logger, network } from '../../../../utils';
import Summary from './Summary'
import {setApplicationDetails} from "../../../../reducers/LeaseApplicationsReducer";
import { useDispatch, useSelector } from "react-redux";


function SummaryRenderer(props: any) {
    const leaseApplicationId: string = `${props.match.params.leaseApplicationId}`
    const isAddingCoApplicant: boolean = props.location.search.length > 0 && props.location.search.includes('add_co_applicant=true')
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch();
    const dataFromReducer = useSelector((state: any) => state?.applications?.applicationDetails);

    useEffect(() => {
        getApplicationDetails();
      },[]);
      
      const getApplicationDetails = async () => {
        if (!loading) {
          setLoading(true)
        }
        try {
            if(dataFromReducer?.id !== parseInt(leaseApplicationId)){
                let response = await network.GET(`/api/v1/dealers/get-details?id=${leaseApplicationId}`)
                if (response?.data?.data?.leaseApplication) {
                    dispatch(setApplicationDetails(response?.data?.data?.leaseApplication))
                }
            }
        } catch (e) {
            setLoading(false)
            logger.error("Error fetching Applicatins", e);
        }
        setLoading(false)
      }


    return (
        <Spin 
        spinning={loading}
        size='large'
        tip='Loading...'
        >
            <Summary isAddingCoApplicant={isAddingCoApplicant}/>
        </Spin>
    )
}

export default SummaryRenderer
