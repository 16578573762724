import  '../../layouts/styles/GeorgiaTaxGuidesStyle.css'
import image1 from '../dealer/asset-img/georgia_image1.png';
import image2 from '../dealer/asset-img/georgia_image2.png';
import image3 from '../dealer/asset-img/georgia_image3.png';
import image4 from '../dealer/asset-img/georgia_image4.png';
import image5 from '../dealer/asset-img/georgia_image5.png';

function GeorgiaTaxGuides() {
    return (
        <div className="calculator-wrapper">
            <div className="c8">
                <p className="c7 paragraph" id="h.gjdgxs">
               <span className="c1">
                  In Georgia, the taxes work a little different than in other states. When you do a lease in this state, you will need to run the VIN number through a Georgia Tax Website. The site will give you the correct tax amount to enter in the Speed Leasing Calculator.
               </span>
                </p>
                <p className="c7 paragraph">
               <span className="c1">
                  To begin, select Georgia as the State in the top left of the calculator. Click the link directly below that says &ldquo;Tax Amount from Georgia Drives&rdquo;.
               </span>
                </p>
                <p className="c2 paragraph">
               <span>
                  <img src={image4} alt="image-4-georgia"/>
               </span>
                </p>
                <p className="c7 paragraph"><span className="c1">When the GA Tax site opens, select Yes under &ldquo;Do you know your VIN.&rdquo; Enter the VIN. &nbsp;Once you enter the VIN the site will begin to populate other questions about the purchase (see below).</span></p>
                <p className="c2 paragraph">
               <span>
                  <img src={image2} alt="image-2-georgia"/>
               </span>
                </p>
                <p className="c7 paragraph"><span className="c1">Answer the questions as follows:</span></p>
                <div className="c20">
                    <div className="c21">
                        <ol className="c6 lst-kix_list_1-0 start">
                            <li className="c3"><span className="c9 c5">New Georgia Resident?</span><span className="c1">&nbsp;Choose accordingly </span></li>
                            <li className="c3"><span className="c9 c5">Did you purchase the vehicle from a dealer?</span><span className="c1">&nbsp;Yes</span></li>
                            <li className="c3"><span className="c5 c9">Is this a new or used vehicle?</span><span className="c1">&nbsp;Choose accordingly</span></li>
                            <li className="c3"><span className="c9 c5">Purchase Date?</span><span className="c1">&nbsp;The date used on the lease contracts</span></li>
                            <li className="c3"><span className="c9 c5">Purchase Price?</span><span className="c1">&nbsp;Bike Sale Price (do not include prep or other fees)</span></li>
                            <li className="c3"><span className="c9 c5">Did you Trade a Vehicle?</span><span className="c1">&nbsp;Choose accordingly. &nbsp;</span><span className="c11">IMPORTANT: If there is a trade, you must select &ldquo;Yes&rdquo; and enter the trade amount.</span><span className="c1">&nbsp;</span></li>
                            <li className="c3"><span className="c9 c5">Is the vehicle leased?</span><span className="c1">&nbsp;Yes</span></li>
                            <li className="c3"><span className="c9 c5">&ldquo;Total Payments&rdquo; or the &ldquo;Agreed Upon Value:&rdquo;</span><span className="c1">&nbsp;Please select &ldquo;Agreed Upon Value.&rdquo; Enter the Bike Sale Price again. </span></li>
                        </ol>
                    </div>
                </div>
                <p className="c7 paragraph"><span>When the form is completed, it will look like this (see below). You will be able to click the blue </span><span className="c5">Calculate Fees</span><span className="c1">&nbsp;button at the bottom.</span></p>
                <p className="c7 c12 paragraph"><span className="c1"></span></p>
                <p className="c2 paragraph">
               <span>
                  <img src={image1} alt="image-1-georgia"/>
               </span>
                </p>
                <p className="c0 paragraph"><span className="c1"></span></p>
                <p className="c4 paragraph">
                    <span>When you click </span>
                    <span className="c5">
                  Calculate Fees
               </span>
                    <span className="c1">, it will give you the exact tax amount you need to enter in the Speed Leasing Payment Calculator.
               </span>
                </p>
                <p className="c2 paragraph">
               <span>
                  <img src={image5} alt="image-5-georgia"/>
               </span>
                </p>
                <p className="c0 paragraph"><span className="c1"></span></p>
                <p className="c7 paragraph">
               <span className="c1">
                  Take the given amount, return to the Speed Leasing Calculator and enter the correct Tax amount next to the Blue GA Tax Site link. &nbsp;Once you have the correct number, you are free to continue working your deal.
               </span>
                </p>
                <p className="c2 paragraph">
               <span>
                  <img src={image3} alt="image-3-georgia"/>
               </span>
                </p>
                <p className="c7 paragraph">
               <span className="c1">
                  Remember, if you change bikes, your price, or the trade numbers, you will need to repeat this process before contracting to make sure you have the taxes correct.
               </span>
                </p>
                <p className="c7 paragraph">
               <span className="c1">
                  If you have any questions or would like to walk through it with someone on the phone, please give Underwriting a call at 1-844-390-0717. &nbsp;Option 1 for Dealer, then 1 again for Underwriting.
               </span>
                </p>
            </div>
        </div>
    )
}
export default GeorgiaTaxGuides;
