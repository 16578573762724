import { Table, TableBody, TableContainer, TableRow } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import { Paper } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import capitalizeFirstLetter from './DataFormat';
import { Link } from 'react-router-dom'
import { Typography, styled } from '@mui/material'
export default function SummaryAddress(props: any) {
    const { leaseApplicationId, data, applicantFlag, isSubmitted, isAddingCoApplicant } = props
    let lesseeOrCoLessee;
    let routeName = ""
    if (applicantFlag == 1) {
        lesseeOrCoLessee = data?.lessee
        routeName = "applicant";
    } else {
        lesseeOrCoLessee = data?.colessee
        routeName = "co-applicant";
    }

    const CurrencyFormat = require('react-currency-format');
    let addressYears = lesseeOrCoLessee?.atAddressYears || 1
    let addressMonths = lesseeOrCoLessee?.atAddressMonths
    let addressMonthsSummary = addressMonths ? (addressMonths > 1 ? (addressMonths + ' months') : (addressMonths + 'month')) : ''
    let addressDuration = addressYears + ' ' + (addressYears > 1 ? 'years' : 'year') + ' ' + addressMonthsSummary
    const addingCoApplicant = () => {
        return isAddingCoApplicant && routeName === 'co-applicant';
    }
    let redirectRoute = addingCoApplicant() ? `/applications/${leaseApplicationId}/${routeName}?add_co_applicant=true` : `/applications/${leaseApplicationId}/${routeName}`

    return (
        <>
            <Paper elevation={12} sx={{ background: "#001629" }}>
                <Typography sx={{ color: "#fff", float: "left", padding: 1, fontWeight: "bold", fontSize: "14px" }} >Address</Typography>
                <Typography sx={{ color: "#fff", float: "right", padding: 1 }} >
                    <Link to={redirectRoute} style={{display: addingCoApplicant() ? '' : 'none'}}>
                        {!isSubmitted && (<EditOutlinedIcon sx={{ color: "#fff" }} />)}
                    </Link>
                </Typography>
                <TableContainer component={Paper} sx={{ height: "546px" }}>
                    <Table aria-label="customized table" sx={{ height: "546px" }}>
                        <TableBody>
                            <StyledTableRow >
                                <StyledtableCell1 >Street Address 1(No PO Boxes):</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.homeAddress?.street1 ? capitalizeFirstLetter(lesseeOrCoLessee?.homeAddress?.street1) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 >Street Address 2:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.homeAddress?.street2 ? capitalizeFirstLetter(lesseeOrCoLessee?.homeAddress?.street2) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Zip Code: </StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.homeAddress?.zipcode ? capitalizeFirstLetter(lesseeOrCoLessee?.homeAddress?.zipcode) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > State:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.homeAddress?.state ? capitalizeFirstLetter(lesseeOrCoLessee?.homeAddress?.state) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 >County:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.homeAddress?.newCity?.county ? capitalizeFirstLetter(lesseeOrCoLessee?.homeAddress?.newCity?.county) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > City:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.homeAddress?.newCity?.city ? capitalizeFirstLetter(lesseeOrCoLessee?.homeAddress?.newCity?.city) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 >Length Of Stay At Current Address:</StyledtableCell1>
                                <StyledtableCell2 >{addressDuration}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Monthly At Mortgage Or Rent:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.monthlyMortgage ? <CurrencyFormat value={lesseeOrCoLessee?.monthlyMortgage} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Ownership:</StyledtableCell1>
                                <StyledtableCell2 > {lesseeOrCoLessee?.homeOwnership == 1 ? 'Own' : 'Rent'}</StyledtableCell2>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </>
    )
}

const StyledtableCell1 = styled(TableCell)({
    fontWeight: "bold", fontSize: "14px"
})
const StyledTableRow = styled(TableRow)({
    "& td": { border: 0 }
})
const StyledtableCell2 = styled(TableCell)({
    fontSize: "14px"
})

const StyledTypography1 = styled(Typography)({
    color: "#fff",
    float: "left",
    padding: 1,
    fontWeight: "bold",
    fontSize: "14px"
})
const StyledTypography2 = styled(Typography)({
    color: "#fff",
    float: "right",
    padding: 1

})
