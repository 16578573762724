import {Col, Form, Radio, Row, Select, Space} from "antd";
import React from "react";

const {Option} = Select;

export const BikeInfo = (props: any) => {
    interface OptionProps {
        value?: string | number,
        label?: string,
        id?: number,
        maximumTermLength?: number
    }

    const { hideBikeSelectOptions, handleNewUsedStateChange, handleMakesStateChange, handleMakes, makesOptions, handleYear, handleYearStateChange,
        yearsOptions, handleModelStateChange, modelsOptions, handleMileageRangeStateChange,
        mileageRangeOptions, creditTierOptions, data, maximumTermLength, creditTierDisableFlag, calculateWithoutApp, handleModel, disabledForm } = props;

    return (
        <>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    New/Used
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'newUsed']}
                        rules={[{required: true, message: 'New/Used is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="New/Used"
                            onSelect={handleNewUsedStateChange}
                            onBlur={hideBikeSelectOptions}
                            disabled={disabledForm}
                        >
                            <Option key="1" value="New">New</Option>
                            <Option key="2" value="Used">Used</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Make
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'assetMake']}
                        rules={[{required: true, message: 'Make is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="Make"
                            onChange={handleMakes}
                            onSelect={handleMakesStateChange}
                            onBlur={hideBikeSelectOptions}
                            disabled={disabledForm}
                        >
                            {
                                makesOptions && makesOptions.map((obj: OptionProps, index: any) => {
                                    return <Option key={index} value={`${obj.value}`}>{obj.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Year
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'assetYear']}
                        rules={[{required: true, message: 'Year is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="Year"
                            onChange={handleYear}
                            onSelect={handleYearStateChange}
                            onBlur={hideBikeSelectOptions}
                            disabled={disabledForm}
                        >
                            {
                                yearsOptions && yearsOptions.map((obj: OptionProps, index: any) => {
                                    return <Option key={index} value={`${obj.value}`}>{obj.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Model
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'assetModel']}
                        rules={[{required: true, message: 'Model is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="Model"
                            onSelect={handleModelStateChange}
                            onBlur={hideBikeSelectOptions}
                            onChange={handleModel}
                            disabled={disabledForm}
                        >
                            {
                                modelsOptions && modelsOptions.map((obj: OptionProps, index: any) => {
                                    return <Option key={index} value={`${obj.value}`}>{obj.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Mileage Range
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'mileageTier']}
                        rules={[{required: true, message: 'Mileage Range is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="Mileage Range"
                            onSelect={handleMileageRangeStateChange}
                            onBlur={hideBikeSelectOptions}
                            disabled={disabledForm}
                        >
                            {
                                mileageRangeOptions && mileageRangeOptions.map((obj: OptionProps, index: any) => {
                                    return <Option key={index} value={`${obj.value}`}>{obj.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Credit Tier
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'creditTierId']}
                        //rules={[{required: true, message: 'Credit Tier is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="Credit Tier"
                            onSelect={hideBikeSelectOptions}
                            onBlur={hideBikeSelectOptions}
                            disabled={(!calculateWithoutApp && creditTierDisableFlag) || disabledForm}
                        >
                            {
                                creditTierOptions && creditTierOptions.map((obj: OptionProps, index: any) => {
                                    return <Option key={index} value={obj.id}>{obj.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Lessee Term
                </Col>
                <Col span={14}>
                    <Space direction="horizontal">
                        <Form.Item
                            name={['leaseCalculatorAttributes', 'term']}
                            rules={[{required: true, message: ' is required'}]}
                        >
                            <Radio.Group disabled={disabledForm} >
                                <Radio value={24} disabled={maximumTermLength && maximumTermLength < 24 ? true : false}>24</Radio>
                                <Radio value={36} disabled={maximumTermLength && maximumTermLength < 36 ? true : false}>36</Radio>
                                <Radio value={48} disabled={maximumTermLength && maximumTermLength < 48 ? true : false}>48</Radio>
                                <Radio value={60} disabled={maximumTermLength && maximumTermLength < 60 ? true : false}>60</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        </>
    )
}

export default BikeInfo
