import {
    Grid,
    FormControl,
    TextField,
    Paper,
    Typography,
    Input
} from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';

export const AssetVerficationAttachment = (props: any) => {
    const { formValues, attachment, handleChange } = props;

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: "red" }
                },
            },
        },
    })

    const IconWithTooltip = withStyles({
        tooltip: {
            color: "black",
            backgroundColor: "transparent"
        }
    })(Tooltip);



    return (
        <>
            <ThemeProvider theme={theme}>
                <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                    <Grid container direction="row" sx={{ marginBottom: 4 }} spacing={2} >
                        <Grid container item md={12} >
                            <Typography sx={{ fontWeight: "bold", color: "#e93b1b" }}> Please attach four (4) pictures of the motorcycle in its current condition. Must show front, back, left side, and right side. Should be taken within last 30 days. Thank you. </Typography>
                        </Grid>
                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <Typography mb={1} > Attachment 1 <span style={{ color: "red" }}> *</span></Typography>
                                    <StyledTextField
                                        id="filled-input"
                                        size="medium"
                                        type="file"
                                        name="asset_verification_attachment_1"
                                        onChange={handleChange}
                                        inputProps={{ accept: 'image/*,.pdf' }}
                                        required={true}
                                        error={attachment.asset_verification_attachment_1.error}
                                    />
                                    <span style={{ color: "red" }}>{attachment.asset_verification_attachment_1.error && attachment.asset_verification_attachment_1.errorMessage}</span>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <Typography mb={1} > Attachment 2 <span style={{ color: "red" }}> *</span></Typography>
                                    <StyledTextField
                                        id="filled-input"
                                        size="medium"
                                        name="asset_verification_attachment_2"
                                        type="file"
                                        onChange={handleChange}
                                        inputProps={{ accept: 'image/*,.pdf' }}
                                        required={true}
                                        error={attachment.asset_verification_attachment_2.error}
                                    />
                                    <span style={{ color: "red" }}>{attachment.asset_verification_attachment_2.error && attachment.asset_verification_attachment_2.errorMessage}</span>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <Typography mb={1} > Attachment 3 <span style={{ color: "red" }}> *</span></Typography>
                                    <StyledTextField
                                        id="filled-input"
                                        size="medium"
                                        name="asset_verification_attachment_3"
                                        type="file"
                                        onChange={handleChange}
                                        inputProps={{ accept: 'image/*,.pdf' }}
                                        required={true}
                                        error={attachment.asset_verification_attachment_3.error}
                                    />
                                    <span style={{ color: "red" }}>{attachment.asset_verification_attachment_3.error && attachment.asset_verification_attachment_3.errorMessage}</span>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item md={3} spacing={2}>
                            <Grid item md={12}>
                                <FormControl sx={{ width: "100%" }} >
                                    <Typography mb={1} > Attachment 4 <span style={{ color: "red" }}> *</span></Typography>
                                    <StyledTextField
                                        id="filled-input"
                                        size="medium"
                                        name="asset_verification_attachment_4"
                                        type="file"
                                        onChange={handleChange}
                                        inputProps={{ accept: 'image/*,.pdf' }}
                                        required={true}
                                        error={attachment.asset_verification_attachment_4.error}
                                    />
                                    <span style={{ color: "red" }}>{attachment.asset_verification_attachment_4.error && attachment.asset_verification_attachment_4.errorMessage}</span>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </>
    )
}

export default AssetVerficationAttachment;

const StyledTextField = styled(Input)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
    '& .MuiFormHelperText-root': {
        marginTop: "0px !important",
        marginBottom: "-12px !important"
    }
})
