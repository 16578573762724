import {Col, Form, InputNumber, Row, Select} from "antd";
import React from "react";
import { Link } from "react-router-dom";

const { Option } = Select;

export const CustomerInfo = (props: any) => {
    interface ActiveStatesOption {
        label: string,
        value: string,
        tax_label: string
    }

    const { handleDealershipStateChange, activeStatesOptions, taxJurisdictionLabel, taxJurisdictionOptions,
            GeorgiaTaxLink, taxLink, lesseeForm, disabledForm } = props;

    const taxField = () => {
        let state = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'usState'])
        if (state === 'georgia') {
            return <Form.Item
                name={['leaseCalculatorAttributes', 'gaTavtValue']}
            >
                <InputNumber
                    disabled={disabledForm}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                    precision={2}
                    style={{textAlign: 'right'}}
                />
            </Form.Item>
        } else {
            return <Form.Item
                name={['leaseCalculatorAttributes', 'taxJurisdiction']}
            >
                <Select
                    showSearch
                    disabled={disabledForm}
                >
                    {
                        taxJurisdictionOptions && taxJurisdictionOptions.map((value: string, index: any) => {
                            return <Option key={index} value={`${value}`}>{value}</Option>
                        })
                    }
                </Select>
            </Form.Item>
        }
    }

    const taxLabel = () => {
        let state = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'usState'])
        if (taxLink || state === 'georgia') {
            return <GeorgiaTaxLink/>
        } else {
            return `${taxJurisdictionLabel}` || 'Customer County/Tax Jurisdiction'
        }
    }

    const georgiaHelpGuide = () => {
        let state = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'usState'])
        if (taxLink || state === 'georgia') {
            return <Row className="first-field-height">
                <Link className="georgia-help" to="/georgia-tax-guides" target="_blank"><u>Click here for help
                    completing this field</u></Link>
            </Row>
        }
    }

    return (
        <>
            <Row className="first-field-height customer-town-field">
                <Col span={10}>
                    Customer State
                </Col>
                <Col span={14}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'usState']}
                        rules={[{required: true, message: 'Customer State is required'}]}
                    >
                        <Select
                            showSearch
                            onChange={handleDealershipStateChange}
                            disabled={disabledForm}
                        >
                            {
                                activeStatesOptions && activeStatesOptions.map((obj: ActiveStatesOption, index: any) => {
                                    return <Option key={index} value={`${obj.value}`}
                                                   taxLabel={`${obj.tax_label}`}>{obj.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={9}>
                    <span className="custom-town-label">{taxLabel()}</span>
                </Col>
                <Col offset={1} span={14}>
                    {taxField()}
                </Col>
            </Row>
            {georgiaHelpGuide()}
        </>
    )
}

export default CustomerInfo
