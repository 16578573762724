import { Typography, Paper, Grid, CardMedia } from '@mui/material';
import { styled } from '@mui/system';
import { logger, network, auth } from "../../../../utils";

export default function PromotionCard(props: any) {
    const { data } = props

    const trackPromotionsByCurrentUser = async () => {
        try {
            await network.PUT(`/api/v1/promotions/${data.id}/click`, {
                dealership_id: auth.getDealershipId()
            })
        } catch (e) {
            logger.error("Tracking Promotions Error", e)
        }
    }

    return (
        <>
            <Grid item md={4} sm >
                <a href={data.file.url} target="_blank">
                    <Paper elevation={12} onClick={() => trackPromotionsByCurrentUser()} >
                        <StyledTypography align='center' sx={{}} >
                            {data.title}
                        </StyledTypography>
                        <CardMedia
                            component="img"
                            height="400"
                            image={data.image.url}
                        />
                    </Paper>
                </a>
            </Grid>
        </>
    )
}

const StyledTypography = styled(Typography)({
    padding: 2,
    color: "#ef4829",
    fontWeight: "bold",
    fontSize: "15px"
})
