import {Col, Row, Typography, Tooltip} from "antd";
import React from "react";
import InfoIcon from '@mui/icons-material/Info';

const { Title } = Typography;

export const MaximumAdvances = (props: any) => {
    const { calculatorData, convertUsd, backendProductsColor } = props;
    const hoverMessage = "Vehicle values based on current " + calculatorData.assetSource +" Average Retail values. Mileage and other factors taken into consideration.  Values update every other month."

    return (
        <div>
            <h4 className="payment-calculator-headers"> Maximum Advances </h4>
            <Row className={'bot-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12}>
                    <em>Front-End Max Advance</em>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <span>{calculatorData.frontEndMaxAdvance && convertUsd(Number(calculatorData.frontEndMaxAdvance))}</span>
                </Col>
            </Row>
            <Row className={'bot-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12} style={{ textAlign: 'left', color: backendProductsColor }}>
                    <em>Back-End Max Advance</em>
                </Col>
                <Col span={12} style={{ textAlign: 'right', color: backendProductsColor }}>
                    <span>{calculatorData.backEndMaxAdvance && convertUsd(Number(calculatorData.backEndMaxAdvance))}</span>
                </Col>
            </Row>
            <h4 className="payment-calculator-headers"> Current Book Values </h4>
            <Row className={'bot-spacer-1 top-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12}>
                <span>
                {`${calculatorData.assetSource}`} <strong>Retail Value</strong>
                <Tooltip title={hoverMessage} color={'#e93b1b'}>
                    <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                </Tooltip>
                </span>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <span>{calculatorData.nadaRental && convertUsd(Number(calculatorData.nadaRental))}</span>
                </Col>
            </Row>
            {/* <Row className={'bot-spacer-1 top-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12}>
                    NADA <strong>Rough Trade-In Value</strong>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <span>{calculatorData.nadaRough && convertUsd(Number(calculatorData.nadaRough))}</span>
                </Col>
            </Row> */}
        </div>
    )
}

export default MaximumAdvances
