// @ts-nocheck
import { Pie } from '@ant-design/plots';
import { useDispatch } from "react-redux";
import { updatePageState } from '../../../../../reducers/LeaseApplicationsReducer.js'

export default function PieChart(props: any) {
    const { approved_no_documents, approved_documents_requested, approved_documents_issued, approved_lease_package_received, approved_funded,
        approved_funding_approved, approved_funding_delay, approved_canceled } = props

    const dispatch = useDispatch();

    const filterByCreditDocumentStatus = (creditStatus: string, documentStatus: string) => {
        dispatch(updatePageState({ documentStatus: documentStatus, creditStatus: creditStatus, filterFlag: true }))
    }

    const pieStatus = [
        {
            type: 'Approved - No Documents',
            value: approved_no_documents,
        },
        {
            type: 'Approved - Documents Requested',
            value: approved_documents_requested,
        },
        {
            type: 'Approved - Documents Issued',
            value: approved_documents_issued,
        },
        {
            type: 'Approved - Lease Received',
            value: approved_lease_package_received,
        },
        {
            type: 'Approved - Funding Approved',
            value: approved_funding_approved,
        },
        {
            type: 'Approved - Funding Delay',
            value: approved_funding_delay,
        },
        {
            type: 'Approved - Funded',
            value: approved_funded,
        },
        {
            type: 'Approved - Canceled',
            value: approved_canceled,
        },
    ];

    const pieConfig = {
        appendPadding: 10,
        padding: 'auto',
        data: pieStatus,
        angleField: 'value',
        colorField: 'type',
        color: ['#7666f9', "#ff3030", '#ff9418', '#52c6ea', '#3b8b8e', '#3b8e4c', '#0000ff', '#ff6418'],
        radius: 0.6,
        label: {
            type: 'outer',
            labelHeight: 10,
            content: '{percentage}',
        },
        legend: {
            layout: 'vertical',
            position: 'right',
            offsetX: -51
        },
        interactions: [
            {
                type: 'element-active',
            }
        ],
        onReady: (plot: any) => {
            plot.on('element:click', (...args: any) => {
                const label = args[0].data?.data?.type
                if (label === "Approved - No Documents") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'no_documents')
                }
                else if (label === "Approved - Documents Requested") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'documents_requested')
                }
                else if (label === "Approved - Documents Issued") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'documents_issued')
                }
                else if (label === "Approved - Lease Received") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'lease_package_received')
                }
                else if (label === "Approved - Funded") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'funded')
                }
                else if (label === "Approved - Funding Approved") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'funding_approved')
                }
                else if (label === "Approved - Funding Delay") {
                    filterByCreditDocumentStatus("approved_or_approved_with_contingencies", 'funding_delay')
                }
                else if (label === "Approved - Canceled") {
                    filterByCreditDocumentStatus('approved_or_approved_with_contingencies', 'canceled')
                }
            });
        }
    };

    return (
        <>
            <Pie {...pieConfig} />
        </>
    )
}
