import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logger, network, auth } from '../../../../utils';

import {
  Table,
  Spin,
  Select,
  message
} from 'antd';
import {Button} from '@mui/material'

import { ColumnsType } from 'antd/es/table';

import '../styles/ArchivedApplicationList.css';

const { Option } = Select;

interface Applications {
  key: number;
  name: string;
  id: number;
}

interface Data {
  applicationIdentifier: string;
  applicant: string;
  coApplicant: string;
  modelAndYear: string;
  creditStatus: string;
  documentStatus: string;
  daysSubmitted: string;
  lastUpdated: string;
}

const canSubmitData = window.localStorage.getItem("can_submit_speedlease");
const cookieDealershipId =  auth.getDealershipId();

function ArchivedApplicationList() {

  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Data[] | any>([])

  const [paginationProps, setPaginationProps] = useState<object>({ total: 0 })
  const [paginationData, setPaginationData] = useState<object>({})
  const disableBtn = cookieDealershipId ? true : canSubmitData === "false";

    const columns: ColumnsType<Applications> = [
        {
            key: 'applicationIdentifier',
            title: 'Application Identifier',
            dataIndex: 'applicationIdentifier',
            render(val, row) {
                return (
                    <Link to={`/applications/${row.id}/summary`}> {val == null ? 'N/A' : val} </Link>
                )
            }
        },
        {
            key: 'applicant',
            title: 'Applicant',
            dataIndex: 'applicant',
        },
        {
            key: 'coApplicant',
            title: 'Co-Applicant',
            dataIndex: 'coApplicant',
        },
        {
            key: 'modelAndYear',
            title: 'Model and Year',
            dataIndex: 'modelAndYear',
        },
        {
            key: 'creditStatus',
            title: 'Credit Status',
            dataIndex: 'creditStatus',
        },
        {
            key: 'documentStatus',
            title: 'Document Status',
            dataIndex: 'documentStatus',
        },
        {
            key: 'lastUpdated',
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'action',
            // Don't remove val from here. If you remove val from here, it will break the code.
            render(val, row) {
                return (
                    <Button
                        variant="contained"
                        size="small"
                        style={{backgroundColor: "#e93b1b"}}
                        onClick={() => {unarchiveApplication(row?.id)}}
                        disabled={disableBtn}
                    >
                        Unarchive
                    </Button>
                )
            }
        },
    ]

  const unarchiveApplication = async (id: number) => {
    if (!loading) {
      setLoading(true);
    }
    try {
      await network
          .POST(`/api/v1/dealers/applications/${id}/unarchive`, {})
          .then((response) => {
            message.success(response.data.message);
            getArchivedApplications();
          })
          .catch((error) => {
            logger.error("Error fetching filter options", error);
          });
    } catch (e) {
      logger.error("Error fetching filter options", e);
    }
    setLoading(false);
  };

  const onPaginationChange = (page: any, pageSize: any) => {
    setPaginationData({
      page: page,
      pageSize: pageSize,
    })
  }

  const filterParams = () => {
    return 
  }

  const paginationParams = () => {
    if (paginationData && Object.keys(paginationData).length > 0) {
      return { pagination: { ...paginationData } }
    } else {
      return {}
    }
  }

  const getArchivedApplications = async () => {
    if (!loading) { setLoading(true) }

    try {
      await network.POST(`/api/v1/dealers/applications`, {
        filter: { archived: true },
        ...paginationParams(),
        isIncludeUnsubmitted: true,
        dealership_id: auth.getDealershipId(),
      }).then(response => {
        setData(response.data.data.leaseApplications)
        setPaginationProps({
          ...paginationProps,
          total: response.data.pagination.total
        })
      }).catch(error => {
        logger.error("Error fetching Applications", error)
      })
    } catch (e) {
      logger.error("Error fetching Applications", e)
    }

    setLoading(false)
  }

  useEffect(() => {
    getArchivedApplications()
  },[paginationData]);

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(val) => val.id}
        pagination={{
          defaultPageSize: 20,
          onChange: onPaginationChange,
          pageSizeOptions: ["20", "50", "100"],
          ...paginationProps,
        }}
        size='small'
        scroll={{y:725}}
      />
    </Spin>
  )
}

export default ArchivedApplicationList
