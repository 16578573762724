import {Col, Form, InputNumber, Row, Tooltip} from "antd";
import InfoIcon from "@mui/icons-material/Info";

export const TermsInfo = (props: any) => {
    const {calculatorData, convertUsd, cashDownPaymentDanger, handleCashDownPaymentChange, tooltipColor, disabledForm} = props;
    const signInColor = cashDownPaymentDanger === 'black' ? '#1890ff' : cashDownPaymentDanger
    return (
        <>
            <Row style={{height:'4vh'}}>
                <Col span={18} style={{color: cashDownPaymentDanger}}>
                    <p>Minimum Required Down Payment
                        ({calculatorData.minimumRequired && convertUsd(Number(calculatorData.minimumRequired))})</p>
                </Col>
                <Col span={1} style={{paddingTop: '5px', color: cashDownPaymentDanger}}>$</Col>
                <Col span={5} style={{textAlign: 'right'}}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'cashDownPayment']}
                    >
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{textAlign: 'right', color: cashDownPaymentDanger}}
                            onChange={(e) => {
                                handleCashDownPaymentChange(e)
                            }}
                            disabled={disabledForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ lineHeight: 2, color: cashDownPaymentDanger }}>
                <Col span={18}>
                    Plus First Month's Payment
                </Col>
                <Col span={6} style={{textAlign: 'right', color: cashDownPaymentDanger}}>
                    <span>{calculatorData.firstMonthlyPayment && convertUsd(Number(calculatorData.firstMonthlyPayment))}</span>
                </Col>
            </Row>
            <Row style={{ lineHeight: 2 }}>
                <Col span={18} style={{fontWeight: 600, color: signInColor}}>
                    Total Due at Signing
                    <Tooltip title="Includes down payment and 1st month’s payment. Dealer keeps these funds, as well as any trade-ins." color={tooltipColor} key={'cap_cost'}>
                        <InfoIcon style={{marginLeft: '2px'}} color="primary" fontSize="small" />
                    </Tooltip>
                </Col>
                <Col span={6} style={{textAlign: 'right', fontWeight: 500, color: cashDownPaymentDanger}}>
                    <span>{calculatorData.totalCashAtSignIn && convertUsd(Number(calculatorData.totalCashAtSignIn))}</span>
                </Col>
            </Row>
        </>
    )
}

export default TermsInfo
