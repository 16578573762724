import { useEffect, useState } from 'react'
import { Layout, message } from 'antd';
import { MainHeader, MainSider } from '../../../layouts';
import { Box, Typography, Grid } from '@mui/material';
import { network, auth } from '../../../../utils';
import PromotionCard from './PromotionCard';
import { styled } from '@mui/system';
const { Content } = Layout;

export default function PromotionRenderer() {
    const [promotion, setPromotion] = useState<object | null>(null)
    const getPromotionData = async () => {
        let dealershipId = auth.getDealershipId();
        try {
            let response = await network.GET(`/api/v1/promotions/get-promotion-list?dealership_id=${dealershipId}`);
            setPromotion(response.data.promotions)
        } catch (e: any) {
            if (e && e.response && e.response.status === 401) {
                message.error('Error while fetching your dealership data');
            } else {
                message.error('Error while fetching your dealership data');
            }
        }
    }
    useEffect(() => {
        getPromotionData();
    }, [])

    return (
        <Layout>
            <MainHeader />
            <Layout id="#dealership-edit">
                <MainSider activeKey="home" />
                <Layout id="content-area-layout">
                    <Content id="main-content">
                        <StyledTypography align='center' variant="h4" >
                            Promotions
                        </StyledTypography>
                        <Box >
                            <Grid container >
                                {
                                    (promotion instanceof Array) && promotion.length > 0 &&
                                        <Grid item md={12} container spacing={2} >
                                            {promotion && promotion.map((data: any) => (
                                                <PromotionCard
                                                    data={data}
                                                />
                                            ))}
                                        </Grid>
                                }
                            </Grid>
                        </Box>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

const StyledTypography = styled(Typography)({
    padding: 1,
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "15px"
})

