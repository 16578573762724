import {
    Grid,
    FormControl,
    TextField,
    Paper,
    Typography
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';

export const AssetInformation = (props: any) => {
    const { calculatorData, formValues, handleChange } = props;

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: "red" },
                },
            },

        },
    })

    const tradeAllowanceExist = calculatorData?.leaseCalculator?.grossTradeInAllowance > 0 || calculatorData?.leaseCalculator?.tradeInPayoff > 0;

    return (
        <>
            {
                <ThemeProvider theme={theme}>
                    <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                        <Grid container direction="row" sx={{ marginBottom: 4 }} spacing={2} >
                            <Grid container item md={12} >
                                <Typography sx={{ fontWeight: "bold", color: "#e93b1b" }}> Asset Information </Typography>
                            </Grid>

                            <Grid container item md={4} spacing={2}>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required
                                            id="filled-input"
                                            label="Year"
                                            variant="outlined"
                                            size="small"
                                            name="asset_year"
                                            value={formValues.asset_year.value}
                                            onChange={handleChange}
                                            error={formValues.asset_year.error}
                                            helperText={formValues.asset_year.error && formValues.asset_year.errorMessage}
                                            inputProps={{ tabIndex: 3 }}
                                            disabled={formValues.asset_year.value ? true : false}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required
                                            id="filled-input"
                                            label="VIN"
                                            variant="outlined"
                                            size="small"
                                            name="asset_vin"
                                            value={formValues.asset_vin.value}
                                            onInput={(e) => {
                                                (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^a-z0-9]/gi, '')
                                            }}
                                            onChange={handleChange}
                                            error={formValues.asset_vin.error}
                                            helperText={formValues.asset_vin.error && formValues.asset_vin.errorMessage}
                                            inputProps={{ tabIndex: 4, maxLength: 17 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} >
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required={tradeAllowanceExist}
                                            id="filled-input"
                                            label="Trade in Year"
                                            variant="outlined"
                                            size="small"
                                            onInput={(e) => {
                                                (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '')
                                            }}
                                            name="trade_in_year"
                                            value={formValues.trade_in_year.value}
                                            onChange={handleChange}
                                            inputProps={{ tabIndex: 9, maxLength: 4 }}
                                            error={formValues.trade_in_year.error}
                                            helperText={formValues.trade_in_year.error && formValues.trade_in_year.errorMessage}
                                        />

                                    </FormControl> </Grid>
                            </Grid>

                            <Grid container item md={4} spacing={2}>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required={tradeAllowanceExist}
                                            id="filled-input"
                                            label={"Make"}
                                            variant="outlined"
                                            size="small"
                                            name="asset_make"
                                            value={formValues.asset_make.value}
                                            onChange={handleChange}
                                            error={formValues.asset_make.error}
                                            helperText={formValues.asset_make.error && formValues.asset_make.errorMessage}
                                            inputProps={{ tabIndex: 1 }}
                                            disabled={formValues.asset_make.value ? true : false}
                                        />

                                    </FormControl></Grid>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required
                                            id="filled-input"
                                            label="Color/ Markings"
                                            variant="outlined"
                                            size="small"
                                            name="asset_color"
                                            value={formValues.asset_color.value}
                                            onChange={handleChange}
                                            error={formValues.asset_color.error}
                                            helperText={formValues.asset_color.error && formValues.asset_color.errorMessage}
                                            inputProps={{ tabIndex: 5 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} >
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required={tradeAllowanceExist}
                                            id="filled-input"
                                            label="Trade in Make"
                                            variant="outlined"
                                            size="small"
                                            name="trade_in_make"
                                            value={formValues.trade_in_make.value}
                                            onChange={handleChange}
                                            inputProps={{ tabIndex: 7, maxLength: 20 }}
                                            error={formValues.trade_in_make.error}
                                            helperText={formValues.trade_in_make.error && formValues.trade_in_make.errorMessage}
                                        />

                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Grid container item md={4} spacing={2}>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required
                                            id="filled-input"
                                            label="Model"
                                            variant="outlined"
                                            size="small"
                                            name="asset_model"
                                            value={formValues.asset_model.value}
                                            onChange={handleChange}
                                            error={formValues.asset_model.error}
                                            helperText={formValues.asset_model.error && formValues.asset_model.errorMessage}
                                            inputProps={{ tabIndex: 2 }}
                                            disabled={formValues.asset_model.value ? true : false}
                                        />
                                    </FormControl></Grid>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required
                                            id="filled-input"
                                            label="Exact Odometer Mileage"
                                            variant="outlined"
                                            size="small"
                                            name="exact_odometer_mileage"
                                            value={formValues.exact_odometer_mileage.value}
                                            onChange={handleChange}
                                            error={formValues.exact_odometer_mileage.error}
                                            helperText={formValues.exact_odometer_mileage.error && formValues.exact_odometer_mileage.errorMessage}
                                            inputProps={{ tabIndex: 6 }}
                                        />
                                    </FormControl></Grid>
                                <Grid item md={12} >
                                    <FormControl sx={{ width: "100%" }}>
                                        <StyledTextField
                                            required={tradeAllowanceExist}
                                            id="filled-input"
                                            label="Trade in Model"
                                            variant="outlined"
                                            size="small"
                                            name="trade_in_model"
                                            value={formValues.trade_in_model.value}
                                            error={formValues.trade_in_model.error}
                                            onChange={handleChange}
                                            inputProps={{ tabIndex: 8, maxLength: 20 }}
                                            helperText={formValues.trade_in_model.error && formValues.trade_in_model.errorMessage}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </ThemeProvider>
            }

        </>
    )
}

export default AssetInformation;


const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
    '& .MuiFormHelperText-root': {
        marginTop: "0px !important",
        marginBottom: "-12px !important"
    }
})