import { useState, useEffect } from 'react';
import { logger, network, auth } from '../../../../utils';
import { useDebouncedCallback } from 'use-debounce';
import { Row, Col, Button, Form, Input, Typography, Layout, message, Spin } from 'antd';
import CalculatorHead from '../../../layouts/CalculatorHead';
import '../../../layouts/styles/Calculator.css'
import { useSelector } from "react-redux";
import CustomerAndBikeInformation from "./Components/Calculator/CustomerAndBikeInformation";
import SalesEconomics from "./Components/Calculator/SalesEconomics";
import PaymentTerms from "./Components/Calculator/PaymentTerms";
import CalculatorHeader from "./Components/Calculator/CalculatorHeader";
import { Card, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
const { Title } = Typography;
const { Content } = Layout;

interface CalculatorDataProps {
  nadaRental?: string,
  nadaRough?: string,
  purchaseOption?: string,
  totalBikePrice?: string,
  upfrontTax?: string,
  netTradeInAllowance?: string,
  totalCapCostReduction?: string,
  netDueOnMtorcycle?: string,
  acquisitionFee?: string,
  totalCapCost?: string,
  totalGrossCapCost?: string,
  baseMonthlyPayment?: string,
  monthlySaleTax?: string,
  totalMonthlyPayment?: string,
  firstMonthlyPayment?: string,
  refundableSecurityDeposit?: string,
  additionalCashDown?: string,
  totalCashAtSignIn?: string,
  cashIn?: string,
  bikeMinimum?: string,
  totalDealerParticipation?: string,
  totalTransactionPrice?: string,
  minusTradeIn?: string,
  minusDownPayment?: string,
  minusFirstMonthlyPayment?: string,
  minuseSecurityDeposit?: string,
  cashOnDeliveryBike?: string,
  plusDealerParticipation?: string,
  remitToDealer?: string,
  frontEndMaxAdvance?: string,
  backEndMaxAdvance?: string,
  minimumRequired?: string,
  adjustedCapitalizedCost?: string
}

interface ActiveStatesOption {
  label: string,
  value: string,
  tax_label: string
}

interface OptionProps {
  value?: string | number,
  label?: string,
  id?: number,
  maximumTermLength?: number
}

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

export const Calculator = (props: any) => {
  const { calculateWithoutApp } = props
  const dataFromState = useSelector((state: any) => state?.applications?.applicationDetails)
  const data = calculateWithoutApp === true ? { leaseCalculator: { cashDownPayment: 0 }} : dataFromState;
  const [lesseeForm] = Form.useForm();

  const [calculatorData, setCalculatorData] = useState<CalculatorDataProps>({})

  const [activeStatesOptions, setActiveStatesOptions] = useState<Array<ActiveStatesOption>>([])
  const [taxJurisdictionLabel, setTaxJurisdictionLabel] = useState<string>("Customer County/Tax Jurisdiction")
  const [taxJurisdictionOptions, setTaxJurisdictionOptions] = useState<string[]>([])

  const [makesOptions, setMakesOptions] = useState<OptionProps[]>([])
  const [yearsOptions, setYearsOptions] = useState<OptionProps[]>([])
  const [modelsOptions, setModelsOptions] = useState<OptionProps[]>([])
  const [mileageRangeOptions, setMileageRangeOptions] = useState<OptionProps[]>([])
  const [creditTierOptions, setCreditTierOptions] = useState<OptionProps[]>(data?.leaseCalculator?.creditTiers || [])

  const [showMakeState, setShowMakeState] = useState<object | null>(null)
  const [showYearState, setShowYearState] = useState<object | null>(null)
  const [showModelState, setShowModelState] = useState<object | null>(null)
  const [showMileageRangeState, setShowMileageRangeState] = useState<object | null>(null)
  const [showCreditTierState, setShowCreditTierState] = useState<object | null>(null)

  const [cashDownPaymentDanger, setCashDownPaymentDanger] = useState<string>('black')
  const [backendProductsColor, setBackendProductsColor] = useState<string>('black')
  const [calculatorAttributes, setCalculatorAttributes] = useState<object | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isNoDocument, setIsNoDocument] = useState<boolean>(true)
  const [maximumTermLength, setMaximumTermLength] = useState<number>()
  const [taxLink, setTaxLink] = useState<boolean>(false)
  const [customerMonthlyPaymentDanger, setCustomerMonthlyPaymentDanger] = useState<string>('black')
  const [creditTierDisableFlag, setCreditTierDisableFlag] = useState<Boolean>(true)
  const [gpsCostColor, setGpsCostColor] = useState<string>('black')
  const [open, setOpen] = useState<boolean>(false);
  const formDisabled = isNoDocument && data?.canViewPaymentCalculator;

  const debouncedHandleOnFieldsChange = useDebouncedCallback(
    (changedValues: any, allValues: any) => {
      handleOnValuesChange(changedValues, allValues);
    },
    1000
  )

  const GeorgiaTaxLink = () => {
    return (
      <div>
        <a href="https://eservices.drives.ga.gov/?Link=TAVTEst" target="_blank"><u>Tax Amount from Georgia Drives</u></a>
      </div>
    )
  }

  const handleMakes = (value: any) => {
    getYears(value)
  }

  const handleYear = (value: any) => {
    let year = value
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    getModels(make, year)
  }

  const handleModel = (value: any) => {
    let model = value
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    let year = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetYear'])
    if (make !== null && year !== null && model !== null) {
      getCreditTiers(make, year, model)
    }
  }
  const hideBikeSelectOptions = () => {
    setShowMakeState(null)
    setShowYearState(null)
    setShowModelState(null)
    setShowMileageRangeState(null)
    setShowCreditTierState(null)
  }

  const handleNewUsedStateChange = () => {
    hideBikeSelectOptions()
    setShowMakeState({ "open": true })
  }

  const handleMakesStateChange = () => {
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        assetYear: "",
        assetModel: "",
      }
    })
    hideBikeSelectOptions()
    setTimeout(() => {
      setShowYearState({ "open": true })
    }, 500)
  }

  const handleYearStateChange = () => {
    hideBikeSelectOptions()
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        assetModel: "",
      }
    })
    setTimeout(() => {
      setShowModelState({ "open": true })
    }, 500);
  }

  const handleModelStateChange = (value: any) => {
    hideBikeSelectOptions()
    setShowMileageRangeState({ "open": true })
    const assetModel = modelsOptions.find(model => model.value === value)
    const termLength = (assetModel && assetModel?.maximumTermLength) || 60
    setMaximumTermLength(termLength)
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        term: termLength,
      },
    })
  }

  const handleMileageRangeStateChange = () => {
    hideBikeSelectOptions()
    setShowCreditTierState({ "open": true })
  }

  const handleCashDownPaymentChange = (value: any) => {
    let color = typeof value === 'number' &&
      typeof calculatorData.minimumRequired === 'string' &&
      parseInt(String(value)) < parseInt(calculatorData.minimumRequired) ? 'red' : 'black'
    setCashDownPaymentDanger(color)
  }

  const handleCustomerMonthlyPaymentChange = (value: any) => {
    if (value === 0) {
      return 'black'
    }
    let color = (typeof value === 'number' && typeof calculatorData.totalMonthlyPayment === 'string' &&
      parseFloat(calculatorData.totalMonthlyPayment) > value) ? 'red' : 'black'

    setCustomerMonthlyPaymentDanger(color)
  }

  const handleBackendProductsChange = (values: any) => {
    let backendProductsSum: number = 0;
    backendProductsSum += typeof values?.guaranteedAutoProtectionCost === 'number' ? values.guaranteedAutoProtectionCost : 0
    backendProductsSum += typeof values?.prepaidMaintenanceCost === 'number' ? values.prepaidMaintenanceCost : 0
    backendProductsSum += typeof values?.extendedServiceContractCost === 'number' ? values.extendedServiceContractCost : 0
    backendProductsSum += typeof values?.tireAndWheelContractCost === 'number' ? values.tireAndWheelContractCost : 0
    backendProductsSum += typeof values?.gpsCost === 'number' ? values.gpsCost : 0

    let color = typeof calculatorData.backEndMaxAdvance === 'string' &&
      backendProductsSum > parseFloat(calculatorData.backEndMaxAdvance) ? 'red' : 'black'
    setBackendProductsColor(color)
    let gpsCostColor = 'black';
    let gpsCost = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'gpsCost'])
    if (typeof gpsCost === 'number' && gpsCost !== 0 && (gpsCost < 195 || gpsCost > 500)){
      gpsCostColor = 'red';
    }
    gpsCostColor = gpsCostColor === 'red' ? gpsCostColor : color
    setGpsCostColor(gpsCostColor)
  }

  const closeDialog = () => {
    setOpen(false);
    setGpsCostColor('black');
  }

  const gpsValueCorrection = (values: any) => {
    let gpsCost = values?.leaseCalculatorAttributes?.gpsCost;
    if (typeof gpsCost === 'number' && gpsCost !== 0 && (gpsCost < 195 || gpsCost > 500)){
      setOpen(true);
      values.leaseCalculatorAttributes.gpsCost = 0
      lesseeForm.setFieldsValue({
        leaseCalculatorAttributes: {
          gpsCost: 0
        },
      });
      return values;
    } else {
      return values;
    }
  }

  const getMakes = async () => {
    try {
      let result = await network.GET('/api/v1/bike-information/makes-name-options');
      setMakesOptions(formatOptions(result.data.makes || []))
      setMileageRangeOptions(formatOptions((result.data.mileage_range || [])))
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const getYears = async (make: string) => {
    try {
      let result = await network.GET(`/api/v1/bike-information/years-options?make=${make}`);
      setYearsOptions(formatOptions(result.data.years || []))
      const creditTierValues = result.data.credit_tiers.map((ob: { value: string; }) => ob.value.trim())
      if(data?.leaseCalculator?.hasOwnProperty('creditTierId') && data?.leaseCalculator.creditTierId !== null){
        const value = data?.leaseCalculator?.creditTiers[0].value.trim()
        if(!creditTierValues.includes(value))
        {
          lesseeForm.setFieldsValue({
            leaseCalculatorAttributes: {
              creditTierId: '',
            },
          })
        }
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const getModels = async (make: string, year: string | number) => {
    try {
      let result = await network.GET(`/api/v1/bike-information/models-options?make=${make}&year=${year}&calculator_id=${data?.leaseCalculator?.id}`);
      setModelsOptions(formatOptions((result.data.models || []), 'collection', 'assetModel'))
      let modelValue = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetModel'])
      if (modelValue !== null) {
        const assetModels: OptionProps[] = formatOptions((result.data.models || []), 'collection', 'assetModel')
        const assetModel = assetModels.find(model => model.value === modelValue)
        const termLength = (assetModel && assetModel?.maximumTermLength) || 60
        setMaximumTermLength(termLength)
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const getCreditTiers = async (make: string, year: string | number, model: string) => {
    try {
      let result = await network.GET(`/api/v1/bike-information/credit-tier-options?make=${make}&year=${year}&model=${model}&calculator_id=${data?.leaseCalculator?.id}`);
      const creditTiers = result.data.credit_tiers
      setCreditTierOptions(result.data.credit_tiers || [])

      const creditTierValues = result.data.credit_tiers.map((ob: { value: string; }) => ob.value.trim())
      if(data?.leaseCalculator?.hasOwnProperty('creditTierId') && data?.leaseCalculator.creditTierId !== null){
        const value = data?.leaseCalculator?.creditTiers[0].value.trim()
        if(creditTierValues.includes(value))
        {
          const index = creditTiers.findIndex((ob: any) => ob.value === value)
          lesseeForm.setFieldsValue({
            leaseCalculatorAttributes: {
              creditTierId: creditTiers[index].id,
            },
          })
        }
        else
        {
          let minCreditTier = creditTiers.pop()
          lesseeForm.setFieldsValue({
            leaseCalculatorAttributes: {
              creditTierId: minCreditTier?.id || '',
            },
          })
          //setCreditTierDisableFlag(false)
        }
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const formatOptions = (options: Object[], type?: string, attributeType?: string) => {
    let newOptions: Array<object> = [];
    if (type === 'collection' && attributeType === 'assetModel') {

      options.map((value: any) => { newOptions.push({ value: value[1], label: value[0], maximumTermLength: value[2]['data-maximum-term-length'] }) })
    }
    else if (type === 'collection') {
      options.map((value: any) => { newOptions.push({ value: value[1], label: value[0] }) })
    } else {
      options.map((value: any) => { newOptions.push({ value: value, label: value }) })
    }
    return newOptions
  }

  const handleDealershipStateChange = (value: any, option: any) => {
    if (option.value === "georgia") {
      setTaxLink(true)
    } else {
      setTaxLink(false)
    }
    getTaxJurisdiction(value)
    setTaxJurisdictionLabel(option.taxLabel)
    getMakes()
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        taxJurisdiction: null,
      },
    })
  }

  const getTaxJurisdiction = async (usState: string) => {
    try {
      await network.GET(`/api/v1/calculators/tax-jurisdiction-select-option?us_state=${usState}`).then(response => {
        setTaxJurisdictionOptions(response.data.tax_jurisdiction_select_option)
      }).catch(error => {
        logger.error("getTaxJurisdiction.Request Error", error);
      });
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const setDealershipState = async () => {
    try {
      await network.GET('/api/v1/calculators/active-state-select-option').then(response => {
        setActiveStatesOptions(response.data.active_state_select_option)
      }).catch(error => {
        logger.error("setDealershipState.Request Error", error);
      });
    } catch (e) {
      logger.error("setDealershipState Error", e);
    }
  }

  const setCalculatorFromResult = (responseObj: any) => {
    let calculations: any = responseObj.data.calculations
    let calData: Object = {
      nadaRental: calculations.nada_retail_value || 0,
      nadaRough: calculations.nada_rough_value || 0,
      purchaseOption: calculations.customer_purchase_option || 0, // NEEDS TRACING
      upfrontTax: calculations.upfront_tax || 0,
      totalBikePrice: calculations.total_sales_price || 0, // NEEDS TRACING
      netTradeInAllowance: calculations.net_trade_in_allowance || 0,
      totalCapCostReduction: 0, // NO EXACT MATCH
      netDueOnMtorcycle: 0, // NO EXACT MATCH
      acquisitionFee: calculations.acquisition_fee || 0,
      totalCapCost: calculations.adjusted_capitalized_cost || 0, // NEEDS TRACING
      totalGrossCapCost: calculations.gross_capitalized_cost || 0, // NEEDS TRACING
      baseMonthlyPayment: calculations.base_monthly_payment || 0,
      monthlySaleTax: calculations.monthly_sales_tax || 0,
      totalMonthlyPayment: calculations.total_monthly_payment || 0,
      firstMonthlyPayment: calculations.total_monthly_payment || 0, // NEEDS TRACING
      refundableSecurityDeposit: calculations.refundable_security_deposit || 0,
      additionalCashDown: 0, // NEEDS TRACING
      totalCashAtSignIn: calculations.total_cash_at_signing || 0,
      cashIn: 0, // NO EXACT MATCH
      bikeMinimum: 0, // NO EXACT MATCH
      totalDealerParticipation: 0, // NO EXACT MATCH
      totalTransactionPrice: 0, // NO EXACT MATCH
      minusTradeIn: 0, // NO EXACT MATCH
      minusDownPayment: 0, // NO EXACT MATCH
      minusFirstMonthlyPayment: 0, // NO EXACT MATCH
      minuseSecurityDeposit: 0, // NO EXACT MATCH
      cashOnDeliveryBike: 0, // NO EXACT MATCH
      plusDealerParticipation: 0, // NO EXACT MATCH
      remitToDealer: calculations.remit_to_dealer || 0,
      frontEndMaxAdvance: calculations.frontend_max_advance || 0,
      backEndMaxAdvance: calculations.backend_max_advance || 0,
      minimumRequired: calculations.cash_down_minimum || 0,
      adjustedCapitalizedCost: calculations.adjusted_capitalized_cost|| 0,
      assetSource: calculations.asset_source || '',
    }
    setCalculatorData(calData)
  }

  const submitCalculator = async (values: any, save: boolean) => {
    try {
      if (save) {
        let new_values = {};
        delete Object.assign(new_values, values, { ['lease_calculator']: values['leaseCalculatorAttributes'] })['leaseCalculatorAttributes'];
        setLoading(true);
        await network.POST('/api/v1/calculators/save', new_values).then(response => {
          setCreditTierDisableFlag(true);
          setLoading(false);
          message.success("Data saved successfully")
        }).catch(() => {
          setLoading(false);
          message.error("Error while saving data")
        })
      }
      else {
        await network.POST('/api/v1/calculators', values).then(response => {
          setCalculatorFromResult(response)
        }).catch(() => {
        })
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const handleSubmit = async (values: any) => {
    let obj = {
      nadaRetailValue: calculatorData.nadaRental
    }
    values = { ...values.leaseCalculatorAttributes, ...obj };
    values = { leaseCalculatorAttributes: { ...values } }
    values = gpsValueCorrection(values);
    // if (!calculateWithoutApp) { delete values.leaseCalculatorAttributes.creditTierId; }
    submitCalculator(values, true)
  }

  const handleOnValuesChange = async (changedValues: any, allValues: any) => {
    let values: any = {}

    allValues.map((data: any) => {
      values[`${data.name[1]}`] = data.value
    })

    setCalculatorAttributes(values);
    handleBackendProductsChange(values);
    submitCalculator({ lease_calculator: values }, false)
  }

  useEffect(() => {
    setDealershipState()
    let usState = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'usState'])
    if(usState && usState !== undefined) {
      getTaxJurisdiction(usState)
    }

    let taxJurisdictionType = data?.leaseCalculator?.stateTaxJurisdictionType
    if (typeof taxJurisdictionType === 'string') {
      setTaxJurisdictionLabel(taxJurisdictionType)
    }
    getMakes()
    getYearsAndModels()
    getYearsAndModelsAndCreditTiers()
    setNoDocumentsState()
    // californiaTaxJurisdictionFieldSet()
    clearUnnecessaryAttributesAndCalculate(usState)
  }, []);

  const getYearsAndModels = () => {
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    let year = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetYear'])
    if (make !== null) {
      getYears(make)
      if (year !== null) {
        getModels(make, year)
      }
    }
  }

  const getYearsAndModelsAndCreditTiers = () => {
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    let year = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetYear'])
    let model = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetModel'])
    if (make !== null && year !== null && model !== null) {
      getCreditTiers(make, year, model)
    }
  }

  const setNoDocumentsState = () => {
    if (calculateWithoutApp) {
      setIsNoDocument(false)
    } else {
        setIsNoDocument(true)
    }
  }

  const californiaTaxJurisdictionFieldSet = () => {
    if (data?.leaseCalculator?.usState == "california") {
      if (typeof data?.lessee?.homeAddress?.newCity?.city === "string" && typeof data?.lessee?.homeAddress?.newCity?.county === "string") {
        lesseeForm.setFieldsValue({
          leaseCalculatorAttributes: {
            taxJurisdiction: californiaTaxJurisdiction()
          },
        })
      }
    }
  }

  const californiaTaxJurisdiction = () => {
    return `${data?.lessee?.homeAddress?.newCity?.city?.charAt(0).toUpperCase()}${data?.lessee?.homeAddress?.newCity?.city?.slice(1).toLowerCase()} -
          ${data?.lessee?.homeAddress?.newCity?.county?.charAt(0).toUpperCase()}${data?.lessee?.homeAddress?.newCity?.county?.slice(1).toLowerCase()}`
  }

  const clearUnnecessaryAttributesAndCalculate = async (state: string) => {
    let creditTiers = data?.leaseCalculator?.creditTiers
    if (creditTiers) {
      let newData = { ...data }
      if (typeof newData?.leaseCalculator !== 'undefined' && typeof state === 'string') {
        const { creditTiers, stateTaxJurisdictionType, maxAllowablePayment, ...rest } = newData.leaseCalculator;
        newData.leaseCalculator = rest;
        submitCalculator(newData, false)
      }
    }
  }

  useEffect(() => {
    if (calculatorAttributes === null) {
      handleBackendProductsChange(data?.leaseCalculator)
      handleMaximumTermLength()
    }
    let cashDownPayment = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'cashDownPayment'])
    handleCashDownPaymentChange(cashDownPayment)
    handleCustomerMonthlyPaymentChange(data?.leaseCalculator?.maxAllowablePayment)
  }, [calculatorData]);

  const handleMaximumTermLength = () => {
    let modelValue = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetModel'])
    if (modelValue !== null) {
      const assetModel = modelsOptions.find(model => model.value === modelValue)
      const termLength = (assetModel && assetModel?.maximumTermLength) || 60
      lesseeForm.setFieldsValue({
        leaseCalculatorAttributes: {
          term:  data?.leaseCalculator?.term || termLength
        },
      })
    }
  }


  const convertUsd = (value: any) => {
    return (value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }

  const openInNewTab = () => {
    window.open('https://secure.passtimeusa.com/onlineordering/codesite/SpeedLeasing.aspx?otag=SLF', '_blank', 'noopener,noreferrer');
  };

  const tooltipColor = '#e93b1b'

  return (
    <>
      <CalculatorHead calculateWithoutApp={calculateWithoutApp === true ? true : false} />
      <Spin
        spinning={loading}
        size="large"
        tip="Loading..."
      >
        <div>
          <CalculatorHeader data={data} calculateWithoutApp={calculateWithoutApp} openInNewTab={openInNewTab} />
          <Form
            form={lesseeForm}
            onFinish={handleSubmit}
            onFieldsChange={(changedValues, allValues) => debouncedHandleOnFieldsChange(changedValues, allValues)}
            initialValues={{
              leaseCalculatorAttributes: {
                id: data?.leaseCalculator?.id,
                usState: data?.leaseCalculator?.usState,
                taxJurisdiction: data?.leaseCalculator?.taxJurisdiction,
                newUsed: data?.leaseCalculator?.newUsed,
                assetMake: data?.leaseCalculator?.assetMake,
                assetYear: data?.leaseCalculator?.assetYear,
                assetModel: data?.leaseCalculator?.assetModel,
                mileageTier: data?.leaseCalculator?.mileageTier,
                creditTierId: data?.leaseCalculator?.creditTierId,
                dealerSalesPrice: data?.leaseCalculator?.dealerSalesPrice,
                dealerFreightAndSetup: data?.leaseCalculator?.dealerFreightAndSetup,
                titleLicenseAndLienFee: data?.leaseCalculator?.titleLicenseAndLienFee,
                dealerDocumentationFee: data?.leaseCalculator?.dealerDocumentationFee,
                guaranteedAutoProtectionCost: data?.leaseCalculator?.guaranteedAutoProtectionCost,
                prepaidMaintenanceCost: data?.leaseCalculator?.prepaidMaintenanceCost,
                extendedServiceContractCost: data?.leaseCalculator?.extendedServiceContractCost,
                tireAndWheelContractCost: data?.leaseCalculator?.tireAndWheelContractCost,
                gpsCost: data?.leaseCalculator?.gpsCost,
                grossTradeInAllowance: data?.leaseCalculator?.grossTradeInAllowance,
                tradeInPayoff: data?.leaseCalculator?.tradeInPayoff,
                cashDownPayment: data?.leaseCalculator?.cashDownPayment,
                gaTavtValue: data?.leaseCalculator?.gaTavtValue,
              }
            }}
            className="form-calculator"
            {...layout}
          >
            {
              data?.leaseCalculator?.id &&
              <Form.Item
                style={{ display: 'none' }}
                name={['leaseCalculatorAttributes', 'id']}
              >
                <Input />
              </Form.Item>
            }
            <Content className="content-3">
              <div className="site-card-wrapper">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <CustomerAndBikeInformation
                      handleDealershipStateChange={handleDealershipStateChange}
                      activeStatesOptions={activeStatesOptions}
                      taxJurisdictionLabel={taxJurisdictionLabel}
                      taxJurisdictionOptions={taxJurisdictionOptions}
                      handleNewUsedStateChange={handleNewUsedStateChange}
                      hideBikeSelectOptions={hideBikeSelectOptions}
                      handleMakesStateChange={handleMakesStateChange}
                      handleMakes={handleMakes}
                      showMakeState={showMakeState}
                      makesOptions={makesOptions}
                      handleYear={handleYear}
                      handleYearStateChange={handleYearStateChange}
                      showYearState={showYearState}
                      yearsOptions={yearsOptions}
                      handleModelStateChange={handleModelStateChange}
                      showModelState={showModelState}
                      modelsOptions={modelsOptions}
                      handleMileageRangeStateChange={handleMileageRangeStateChange}
                      showMileageRangeState={showMileageRangeState}
                      mileageRangeOptions={mileageRangeOptions}
                      showCreditTierState={showCreditTierState}
                      creditTierOptions={creditTierOptions}
                      data={data}
                      maximumTermLength={maximumTermLength}
                      calculatorData={calculatorData}
                      convertUsd={convertUsd}
                      backendProductsColor={backendProductsColor}
                      GeorgiaTaxLink={GeorgiaTaxLink}
                      taxLink={taxLink}
                      lesseeForm={lesseeForm}
                      creditTierDisableFlag={creditTierDisableFlag}
                      calculateWithoutApp={calculateWithoutApp}
                      handleModel={handleModel}
                      tooltipColor={tooltipColor}
                      disabledForm={formDisabled}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <SalesEconomics tooltipColor={tooltipColor} calculatorData={calculatorData} convertUsd={convertUsd} backendProductsColor={backendProductsColor} gpsCostColor={gpsCostColor} disabledForm={formDisabled}/>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <PaymentTerms
                      calculatorData={calculatorData}
                      convertUsd={convertUsd}
                      cashDownPaymentDanger={cashDownPaymentDanger}
                      handleCashDownPaymentChange={handleCashDownPaymentChange}
                      maxAllowablePayment={data?.leaseCalculator?.maxAllowablePayment}
                      customerMonthlyPaymentDanger={customerMonthlyPaymentDanger}
                      tooltipColor={tooltipColor}
                      disabledForm={formDisabled}
                    />
                    <div style={{ marginTop: '1%', textAlign: 'right' }}>
                      {data?.canEditPaymentCalculator && 
                      (<Button disabled={auth.getDealershipId() ? true : false} style={{ marginRight: 30, backgroundColor: "#e93b1b", color: 'white' }} htmlType="submit">Save Lease Calculator</Button>)}
                    </div>
                    <Dialog
                        open={open}
                        onClose={closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle style={{color: '#fa4b65', paddingTop: '50px', paddingLeft: '50px', paddingRight: '50px'}}>
                        {"If including a fee for PassTime InTouch Services, fee must be between $195.00 and $500.00."}
                      </DialogTitle>
                      <DialogActions style={{paddingRight: '50px', paddingBottom: '50px'}}>
                        <Button onClick={closeDialog}
                                style={{backgroundColor: '#5c94ed', color: '#ffffff', borderRadius: '10%'}}
                        >OK</Button>
                      </DialogActions>
                    </Dialog>
                  </Col>
                </Row>

                {
                  calculateWithoutApp && ( 
                  <div style={{textAlign: 'center', position: "relative"}}>
                    <h5 style={{color: "#e93b1b"}}> Questions? Please contact Dealer Support at <b>1-844-221-0102</b> (option 1 for Dealer) or <a href="mailto:support@speedleasing.com">support@speedleasing.com</a> </h5>
                  </div>)
                }

                <Row>
                  <Col span={24}>
                    <Card style={{ padding: "10px", marginTop: "10px" }}>
                      {
                        typeof data !== 'undefined' && Array.isArray(data.stipulations) && (data.stipulations.length > 0) && <Title level={4}>STIPULATIONS STILL REQUIRED FOR THIS DEAL INCLUDE</Title>
                      }
                      {
                        data?.leaseApplicationStipulations?.map((leaseApplicationStipulations: any, index: number) => {
                          return (leaseApplicationStipulations.status !== 'Cleared' && leaseApplicationStipulations.status !== 'Not Required') ? (<Row className={'bot-spacer-1'} style={{ lineHeight: 2 }}>
                            <Col span={24} style={{ color: '#1890ff' }}>
                             {leaseApplicationStipulations.stipulation}{leaseApplicationStipulations.notes}
                            </Col>
                          </Row>) : <></>
                        })
                      }
                    </Card>
                  </Col>
                </Row>
              </div>
            </Content>
          </Form>
        </div>
      </Spin>
    </>
  )
}
export default Calculator
