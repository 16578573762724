import {Typography, Grid} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {Button, CardActionArea, CardActions} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';

export default function TrainingCard(props: any) {
    const {data} = props
    const videoSource = `${data.file_url}#toolbar=0&navpanes=0&scrollbar=0`

    const handlePopUp = () => {
        checkVideoFile() ? window.open(`${window.location.origin}/video?${videoSource}`, 'name', 'height=800,width=1280') :
            window.open(videoSource, '_blank', 'height=800, width=1280');
    }

    function checkPdfFile(): boolean {
        return videoSource.match(/.PDF/i) !== null;
    }

    function checkVideoFile(): boolean {
        return videoSource.match(/.MP4|.MOV|.WMV|.AVI|.WEBM|.MKV|.FLV/i) !== null;
    }

    return (
        <Grid item xs={12} sm={4} md={3} lg={2}>
            <Card sx={{height: 230}}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="200"
                        image={data.thumbnail_url}
                        alt="Thumbnail"
                        style={{height: 100, objectFit: 'cover'}}
                    />
                    <CardContent sx={{height: 80}}>
                        <Grid container direction="row" spacing={1}>
                            {(() => {
                                if (checkPdfFile()) {
                                    return <PictureAsPdfIcon/>
                                } else if (checkVideoFile()) {
                                    return <VideocamIcon/>
                                } else {
                                    return <ImageIcon/>
                                }
                            })()}
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                            <Typography variant="subtitle2" style={{marginTop: '10px', flexWrap: 'wrap'}}>
                                {data.title?.length > 96 ? data.title?.substring(0, 96) + "..." : data.title}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button
                        variant="contained"
                        style={{width: '100%', background: '#06B0F8FF'}}
                        onClick={() => handlePopUp()}
                    >
                        View File
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}
