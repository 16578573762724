import {
    Grid,
    FormControl,
    TextField,
    Paper,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import FormHelperText from '@mui/material/FormHelperText';
import { useState } from 'react';

export const ContactInformation = (props: any) => {
    const {calculatorData, extendedServiceContractCostValue, prepaidMaintenanceCostValue, tireAndWheelContractCostValue, handleChange, formValues, setFormValues } = props;
    const [startMinDate, setStartDate] = useState(new Date())
    const [error, setError] = useState('')

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: "red" },
                },
            },
        },
    })
    
    const handleChangeDate = (newValue: any) => {
        const formatedDate = new Date(newValue).toString();
        setFormValues({
            ...formValues,
            delivery_date: {
                ...formValues.delivery_date,
                value: formatedDate
            }
        })
    };

    const handleNumberFieldChange = (e: any, error: string) => {
        const { name, value } = e.target;
        const onlyNums = value.replace(/[^0-9]/g, '');
        if (onlyNums > 60){
            setError(error);
        } else {
            setError('');
            setFormValues({
                ...formValues,
                [name]: {
                    ...formValues[name],
                    value: onlyNums
                }
            })
        }
    }

    // const serviceOrMaintainanceOrWheelExist = calculatorData?.leaseCalculator?.extendedServiceContractCost > 0 || calculatorData?.leaseCalculator?.prepaidMaintenanceCost > 0 ||  calculatorData?.leaseCalculator?.tireAndWheelContractCost > 0;
    const isGAPExist = calculatorData?.leaseCalculator?.guaranteedAutoProtectionCost > 0;
    const gapExistTerm = calculatorData?.leaseCalculator?.term || 0

    return (
        <>
        <ThemeProvider theme={theme}>
        <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                <Grid container direction="row" sx={{ marginBottom: 4 }} spacing={2} >
                    <Grid container item md={12} >
                        <Typography sx={{ fontWeight: "bold", color: "#e93b1b" }}> Contract Information </Typography>
                    </Grid>
                    <Grid container item md={4} spacing={2}>
                        <Grid style={{marginTop: '-23px'}} item md={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Delivery Date"
                                    inputFormat="YYYY-MM-DD"
                                    value={formValues.delivery_date.value}
                                    onChange={handleChangeDate}
                                    minDate = {startMinDate}
                                    renderInput={(params) => <StyledTextField
                                        required
                                        id="filled-input"
                                        inputProps={{ tabIndex: 10 }}
                                        {...params}
                                        error={formValues.delivery_date === null && formValues.delivery_date.error} 
                                        size="small"
                                        helperText={formValues.delivery_date.error && formValues.delivery_date.errorMessage}
                                        label="Delivery Date"
                                        margin="normal"
                                        name="delivery_date" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    id="filled-input"
                                    label="PPM Contract Term"
                                    variant="outlined"
                                    size="small"
                                    name="ppm_contract_term"
                                    value={formValues.ppm_contract_term.value}
                                    onChange={(e: any) => handleNumberFieldChange(e, 'ppm')}
                                    inputProps={{ tabIndex: 13 }}
                                    required = {prepaidMaintenanceCostValue > 0}
                                    error={formValues.ppm_contract_term.error}
                                    helperText={formValues.ppm_contract_term.error && formValues.ppm_contract_term.errorMessage}
                                />
                            </FormControl>
                            { (error && error === 'ppm') && <p style={{color: 'red'}}>Maximum 60 value is allowed.</p> }
                        </Grid>
                    </Grid>

                    <Grid container item md={4} spacing={2}>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    id="filled-input"
                                    label="GAP Contract Term"
                                    variant="outlined"
                                    size="small"
                                    name="gap_contract_term"
                                    value={isGAPExist ? gapExistTerm : formValues.gap_contract_term.value}
                                    disabled={isGAPExist ? true : false}
                                    onChange={(e: any) => handleNumberFieldChange(e, 'gap')}
                                    inputProps={{ tabIndex: 11 }}
                                />
                            </FormControl>
                            { (error && error === 'gap') && <p style={{color: 'red'}}>Maximum 60 value is allowed.</p> }
                        </Grid>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    id="filled-input"
                                    label="Tire/Wheel Contract Term"
                                    variant="outlined"
                                    size="small"
                                    name="tire_contract_term"
                                    value={formValues.tire_contract_term.value}
                                    onChange={(e: any) => handleNumberFieldChange(e, 'tire')}
                                    inputProps={{ tabIndex: 14 }}
                                    required = {tireAndWheelContractCostValue > 0 }
                                    error={formValues.tire_contract_term.error}
                                    helperText={formValues.tire_contract_term.error && formValues.tire_contract_term.errorMessage}
                                />
                            </FormControl>
                            { (error && error === 'tire') && <p style={{color: 'red'}}>Maximum 60 value is allowed.</p> }
                        </Grid>
                    </Grid>
                    <Grid container item md={4} spacing={2}>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    id="filled-input"
                                    label="Service Contract Term"
                                    variant="outlined"
                                    size="small"
                                    name="service_contract_term"
                                    value={formValues.service_contract_term.value}
                                    onChange={(e: any) => handleNumberFieldChange(e, 'service')}
                                    inputProps={{ tabIndex: 12 }}
                                    required = {extendedServiceContractCostValue > 0}
                                    error={formValues.service_contract_term.error}
                                    helperText={formValues.service_contract_term.error && formValues.service_contract_term.errorMessage}
                                />
                            </FormControl>
                            { (error && error === 'service') && <p style={{color: 'red'}}>Maximum 60 value is allowed.</p> }
                        </Grid>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextField
                                    id="filled-input"
                                    label="Equipped With"
                                    variant="outlined"
                                    size="small"
                                    name="equipped_with"
                                    value={formValues.equipped_with.value}
                                    onChange={handleChange}
                                    inputProps={{ tabIndex: 15 }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                        {/* {isGAPExist && ( */}
                            <Grid container item md={4} spacing={2}>
                                <Grid item md={12}>
                                    <FormControl sx={{ width: "100%" }} >
                                        <FormLabel id="demo-row-radio-buttons-group-label">Lessee Term</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="contract_term_length"
                                            value={formValues.contract_term_length.value}
                                        >
                                            <FormControlLabel disabled={true} value={24} control={<Radio />} label="24" />
                                            <FormControlLabel disabled={true} value={36} control={<Radio />} label="36" />
                                            <FormControlLabel disabled={true} value={48} control={<Radio />} label="48" />
                                            <FormControlLabel disabled={true} value={60} control={<Radio />} label="60" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        {/* ) */}
                         {/* } */}
                    </Grid>
                </Paper>
            </ThemeProvider>

        </>
    )
}

export default ContactInformation;

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
    '& .MuiFormHelperText-root':{
        marginTop: "0px !important",
        marginBottom: "-18px !important"
    }
})