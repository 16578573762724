import React, { useState } from 'react';
import { Button, Form, Grid, Header, Segment, Checkbox } from 'semantic-ui-react';
import { logger, network, auth } from '../../../utils';
import './SignIn.css'
import { message } from 'antd';

function ResetPassword(props: any) {
    // const [resetToken, setResetToken] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const { token } = props.match.params;
    const savePassword = async () => {
        if (newPassword == confirmPassword) {
            try {
                await network.POST(`/api/v1/change-dealer-password`,
                    {
                        dealers: {
                            token,
                            newPassword,
                            confirmPassword,
                        }
                    }).then(response => {
                        if (response.data.status == 200) {
                            setTimeout(message.success(response.data.message), 8000)
                            window.location.href = '/login'
                        }
                        if (response.data.status == 401) {
                            setTimeout(message.error(response.data.message), 8000)
                        }
                    })
            } catch (e) {
                // message.error("Token Not matched")
                logger.error("Error sign in", e);
            };
        }
        else {
            message.error("Confirm password and password should be same")
        }

    };


    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column id='login-column'>
                <Header as='h3' style={{ color: '0F1419', marginTop: '10px' }} textAlign='center'>
                    Speed Leasing Dealer Portal
                </Header>
                <Form size='large' onSubmit={() => savePassword()}>
                    <Segment stacked>
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            name="newPassword"
                            type='password'
                            placeholder='New Password'
                            onChange={(e) => setNewPassword(e.target.value)}
                            required={true}
                            minLength={8}
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            name="confirmPassword"
                            type='password'
                            placeholder='Confirm Password'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required={true}
                            minLength={8}
                            match={'newPassword'}

                        />
                        <Button
                            id='login-button'
                            primary
                            fluid
                            size='large'
                            type='submit'
                        >
                            Save Password
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid >
    );
}

export default ResetPassword;