import { Row, Col, Button, Typography } from 'antd';
const { Title } = Typography;

export const CalculatorHeader = (props: any) => {
    const { data, calculateWithoutApp, openInNewTab } = props;

    return (
        <>
            <Row style={{marginBottom: "10px"}}>
                <Col span={3}>
                    {
                        !calculateWithoutApp && (
                            <Button style={{ backgroundColor: "#e93b1b", float: 'left', marginLeft: '25px', marginTop: '20%', color: 'white' }} onClick={openInNewTab}>Order GPS</Button>
                        )
                    }
                </Col>
                <Col span={16}>
                    <div style={{textAlign: 'center', marginTop: '1%', position: "relative", left: "50px"}}>
                        <Title level={4} style={{marginBottom: 3}}><span
                            className="heading-calculator"> {calculateWithoutApp ? 'Quote Calculator' : 'Payment Calculator'} </span></Title>
                        <p className="subTitle"> Please add the vehicle sale price and other related items to calculate
                            your payment. </p>
                    </div>
                    {/* <div style={{textAlign: 'center', position: "relative", left: "50px"}}>
                        <p className="subTitle" style={{color: "#e93b1b"}}> Questions? Please contact Dealer Support
                            at <b>1-844-221-0102</b> (option 1 for Dealer) or <a
                                href="mailto:support@speedleasing.com">support@speedleasing.com</a> </p>
                    </div> */}
                </Col>
                <Col span={5}>
                    {
                        !calculateWithoutApp && (
                            <div style={{marginTop: '3%',position:"relative", alignItems: "center" }}>
                                <p className="paraph">Application Identifier:  <span className="paraph-data">{data?.applicationIdentifier || 'N/A'}</span></p>
                                <p className="paraph">Applicant Name: <span className="paraph-data">{(typeof data?.lessee === 'undefined') || data?.lessee === null ? 'N/A' : (data?.lessee?.firstName || '') + ' ' + (data?.lessee?.lastName || '')}</span></p>
                                <p className="paraph">CoApplicant Name: <span className="paraph-data">{(typeof data?.colessee === 'undefined') || data?.colessee === null ? 'N/A' : (data?.colessee?.firstName || '') + ' ' + (data?.colessee?.lastName || '')}</span></p>
                            </div>
                        )
                    }
                </Col>
            </Row>
        </>
    )
}

export default CalculatorHeader
