import React, { useState } from "react";
import { Layout, Menu, Button, message } from "antd";
import { Link } from "react-router-dom";
import { SiderProps } from "./props";
import { CollapseProps } from "./props/collapseProps";
import {
  HomeOutlined,
  ProfileOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import ArchiveIcon from '@mui/icons-material/Archive';
import { MenuOutlined } from "@ant-design/icons";
import CampaignIcon from '@mui/icons-material/Campaign';
import "./styles/MainSider.css";
import { useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import { useSelector, useDispatch } from "react-redux";

import { logger,  network, auth } from '../../utils';
import {clearUnseenPromotionsCount, clearUnseenTrainingCount} from "../../reducers/LeaseApplicationsReducer";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

const { Sider } = Layout;

interface Props {
  activeKey: string | undefined;
}

const cookieDealershipId =  auth.getDealershipId();

export const MainSider: React.FC<Props> = ({ activeKey }: Props) => {
  const [collapsed, setCollapsed] = useState(CollapseProps);
  const [toolTip, setToolTip] = useState(true);
  const [titleShow, setTitleShow] = useState(false);
  const unseenPromotionsCount = useSelector((state: any) => state?.applications?.unseenPromotionsCount);
  const unseenTrainingCount = useSelector((state: any) => state?.applications?.unseenTrainingCount);
  const dispatch = useDispatch()

  const history = useHistory();

  const handleClick = () => {
    if (collapsed == CollapseProps) {
      setCollapsed(SiderProps);
      setTitleShow(true)
      setToolTip(false);
    } else if (collapsed == SiderProps) {
      setCollapsed(CollapseProps);
      setToolTip(true);
      setTitleShow(false)
    }
  };

const handlePopUp = () =>{
  window.open(`${window.location.origin}/calculator`,'name','height=800,width=1280');
}
  const visitPromotionsByCurrentUser = async () => {
    if (unseenPromotionsCount > 0) {
      try {
        await network.PUT(`/api/v1/promotions/visit-promotions`, {
          dealership_id: auth.getDealershipId()
        })
        dispatch(clearUnseenPromotionsCount({}))
      } catch (e) {
        logger.error("Visiting Promotions Error", e)
      }
    }
  }
  const visitTrainingByCurrentUser = async () => {
    if (unseenTrainingCount > 0) {
      try {
        await network.PUT(`/api/v1/trainings/visited`, {
          dealership_id: auth?.getDealershipId()
        })
        dispatch(clearUnseenTrainingCount({}))
      } catch (e) {
        logger.error("Visiting training Error", e)
      }
    }
  }

  const attachmentUrl = (url: string) => {
    const baseUrl = process.env?.REACT_APP_LOS_API
    return process.env?.REACT_APP_ENV_VAR === 'Development' ? baseUrl && (baseUrl + url) : url
  }

  return (
    <Sider {...(collapsed as object)}>
      <Button
        title={toolTip ? "click to expand menu" : "click to collapse menu"}
        onClick={handleClick}
        className='collapseButton'
      >
        <MenuOutlined style={{ fontSize: '20px' }} />
      </Button>
      <Menu defaultSelectedKeys={[`${activeKey}`]} mode='inline'>
        <Menu.Item key='home' icon={<HomeOutlined style={{ fontSize: '26px' }} />}>
          <Link title="Home" to={`/home`}>
            {titleShow ? "Home" : <div className="Menuelabel1">Home</div>}

          </Link>
        </Menu.Item>

        <Menu.Item title="New Application"
         disabled={cookieDealershipId ? true : false}
         onClick={() => history.push(`/applications/bike`)}
         key='application'
         icon={<ProfileOutlined
         style={{ fontSize: '26px' }}
         />}>
          {titleShow ? "New Application" : <div className="Menuelabel"><span className="word1">New</span> <br /> <span className="word2">Application</span></div>}
        </Menu.Item>

        <Menu.Item title="Archived" key='archived-applications' icon={<ArchiveIcon style={{ fontSize: '26px' }} />}>
          <Link
            className='lastMenu'
            to={`/archived-applications`}
          >
            {titleShow ? "Archived" : <div className="Menuelabel2" >Archived</div>}

          </Link>
        </Menu.Item>


        <Menu.Item key='promotion' title="Promotions" icon={
          <Badge badgeContent={unseenPromotionsCount} color="error">
            <CampaignIcon style={{ fontSize: "26px" }}></CampaignIcon>
          </Badge>
        } onClick={() => {
          visitPromotionsByCurrentUser();
          history.push('/promotions')
        }
        }>
          <Link
            className='lastMenu'
            to={`/promotions`}
            onClick={() => {
              visitPromotionsByCurrentUser();
              history.push('/promotions')
            }}
          >
            {titleShow ? "Promotions" : <div className="Menuelabel3">Promotions</div>}

          </Link>
        </Menu.Item>

        <Menu.Item
            title="Training"
            key='training'
            icon={<Badge badgeContent={unseenTrainingCount} color="error">
              <ModelTrainingIcon style={{ fontSize: '26px' }} />
            </Badge>}
            onClick={() => {
              visitTrainingByCurrentUser();
              history.push('/training')
            }}
        >
          <Link
            className='lastMenu'
            to={`/training`}
            onClick={() => {
              visitTrainingByCurrentUser();
            }}
          >
            {titleShow ? <div>Training&nbsp;& Resources</div> :
            <div className="Menuelabel">
              <span className="word6">Training&nbsp;&</span><br/>
              <span className="word6">Resources</span>
            </div>}
          </Link>
        </Menu.Item>


        <Menu.Item key='calculator' title="Quote Calculator" onClick={handlePopUp} icon={<CalculatorOutlined style={{ fontSize: '26px' }}/>}>
            {titleShow ? "Quote Calculator" : <div className="Menuelabel"><span className="word3">Quote</span> <br/> <span className="word2">Calculator</span> </div>}

        </Menu.Item>
      </Menu>
    </Sider>
  );
};
