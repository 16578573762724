import {
    Grid,
    FormControl,
    Paper,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import FormHelperText from '@mui/material/FormHelperText';

export const ConditionReports = (props: any) => {
    const { formValues, handleChange } = props;
    return (
        <>
            <Paper elevation={4} sx={{ marginTop: "30px", padding: "14px" }}>
                <Grid container direction="row" sx={{ marginBottom: 4 }} spacing={2} >
                    <Grid container item md={12} >
                        <Typography sx={{ fontWeight: "bold", color: "#e93b1b" }}>Condition Report Notes <span style={{ color: "red" }}> *</span></Typography>
                    </Grid>
                    <Grid container item md={12} spacing={2}>
                        <Grid item md={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <StyledTextareaAutosize
                                    required
                                    aria-label="empty textarea"
                                    placeholder="Condition Reports"
                                    minRows={5}
                                    style={{ width: "100%", borderColor: formValues.condition_reports.error ? "#e93b1b" : 'black' }}
                                    name="condition_reports"
                                    value={formValues.condition_reports.value}
                                    onChange={handleChange}
                                />
                                <FormHelperText error={formValues.condition_reports.error}>{formValues.condition_reports.error && formValues.condition_reports.errorMessage}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}


export default ConditionReports;

const StyledTextareaAutosize = styled(TextareaAutosize)({
    '& label.Mui-focused': {
        color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#001629',
        },
    },
    '& .MuiFormHelperText-root':{
        marginTop: "0px !important",
        marginBottom: "-12px !important"
    }
})