import { DualAxes, G2 } from '@ant-design/plots';
export default function FundedBarChart(props: any) {
    const { chartdata2 } = props
    const { registerTheme } = G2;
    registerTheme('custom-theme', {
        defaultColor: ["#ef4829"]
    });
    let barChart = []
    if (chartdata2) {
        let byDate = chartdata2.slice(0)
        barChart = byDate.sort(function (a: any, b: any) {
            return a.Time - b.Time
        })
    } else {
        barChart = [
            {
                Time: '',
                Amount: 0,
                Funded: 0,
            },
        ];
    }

    const barChartconfig = {
        data: [barChart, barChart],
        xField: 'Time',
        yField: ['Amount', 'Funded'],
        geometryOptions: [
            {
                geometry: 'column',
                color: '#ef4829'
            },
            {
                geometry: 'line',
                lineStyle: {
                    lineWidth: 2,
                },
                smooth: true,
            },
        ],
        meta: {
            Amount: {
                alias: 'Amount',
                formatter: (val: any) => {
                    let dollarUS = Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                    })
                    return `${dollarUS.format(val)}`
                }
            }
        },
    };

    return (
        <> <DualAxes {...barChartconfig} /></>
    )
}
