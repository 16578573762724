import React, { useState } from 'react'
import MUITypography from '@mui/material/Typography'
import Chip from '@mui/material/Chip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import BackspaceIcon from '@mui/icons-material/Backspace';
import ApprovalIcon from '@mui/icons-material/Approval';
import CancelIcon from '@mui/icons-material/Cancel';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Select, Input } from "antd";
import { useDispatch } from "react-redux";
import { updatePageState } from '../../../../../reducers/LeaseApplicationsReducer.js'
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Grid, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function Filters(props: any) {
    const dispatch = useDispatch();
    const { selectDropdownStyle, pageState, handleSelectFilterParams, lastQuarterly, currentYear, lastMonth, currentMonth,
        handleChangeDateStart, handleChangeDateEnd, rangeStartDate, rangeEndDate, rangeEndMinimumStartDate, isCustomFilter } = props;

    const { Option } = Select;
    const { Search } = Input;
    const [creditStatus, setCreditStatus] = useState('')
    const [documentStatus, setDocumentStatus] = useState('')

    const filterByCreditStatus = (value: string) => {
        dispatch(updatePageState({ creditStatus: value, filterFlag: true }))
        setCreditStatus(value)
    }
    const filterByDocumentStatus = (value: string) => {
        dispatch(updatePageState({ documentStatus: value, filterFlag: true }))
        setDocumentStatus(value)
    }
    const [searchValue, setSearchValue] = useState('')
    const onChange = (e: any) => {
        if(e.target.value === ''){
            setSearchValue('')
            dispatch(updatePageState({ search: '', dealer: null, date: "all", page: 0 }))
        } else {
            setSearchValue(e.target.value)
        }
    };

    const handleClearFilterFlag = () => {
        dispatch(updatePageState({
            total: 0,
            page: 0,
            limit: 25,
            search: "",
            creditStatus: "",
            documentStatus: "",
            isIncludeUnsubmitted: true,
            filterFlag: false
        }))
        setCreditStatus('')
        setDocumentStatus('')
    };

    const useCreditStatusChipsVariant = (value: string) => {
        return creditStatus === value ? 'filled' : 'outlined'
    }

    const documentStatusChipsVariant = (value: string) => {
        return documentStatus === value ? 'filled' : 'outlined'
    }

    const checkUnsubmittedApp = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updatePageState({ isIncludeUnsubmitted: event.target.checked }))
    };

    return (
        <>
            {
                pageState.filterFlag ? (<Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <StyledClearFilter label="Clear Filters" onDelete={handleClearFilterFlag} />
                </Grid>) : ''
            }

            <Grid container direction="row" sx={{ marginBottom: 4 }} >
                <Grid container item md={5} sx={{ marginTop: "5px" }}>
                    <Grid item md={12}> <MUITypography sx={{ fontSize: "15px", marginBottom: "8px !important", marginRight: "8px !important", fontWeight: "bold" }}>Credit  Status</MUITypography></Grid>
                    <Grid item > <Chip key={1} onClick={() => { filterByCreditStatus("all") }} variant={useCreditStatusChipsVariant('all')} sx={{ marginBottom: 1, marginRight: 1 }} color="info" label="All" /></Grid>
                    <Grid item > <Chip key={2} onClick={() => { filterByCreditStatus("approved") }} variant={useCreditStatusChipsVariant('approved')} sx={{ marginBottom: 1, marginRight: 1 }} color="success" icon={<CheckCircleOutlineIcon style={{ color: (creditStatus === 'approved' ? 'white' : "#06a377"), fontSize: "12px" }} />} label="Approved" /></Grid>
                    <Grid item > <Chip key={3} onClick={() => { filterByCreditStatus("awaiting_credit_decision") }} variant={useCreditStatusChipsVariant('awaiting_credit_decision')} sx={{ marginBottom: 1, marginRight: 1 }} color="secondary" icon={<HourglassBottomIcon style={{ color: (creditStatus === 'awaiting_credit_decision' ? 'white' : "#9c27b0"), fontSize: "12px" }} />} label="Awaiting Credit Decision" /></Grid>
                    <Grid item > <Chip key={4} onClick={() => { filterByCreditStatus("approved_with_contingencies") }} variant={useCreditStatusChipsVariant('approved_with_contingencies')} color="success" sx={{ marginBottom: 1, marginRight: 1 }} icon={<ApprovalIcon style={{ color: (creditStatus === 'approved_with_contingencies' ? 'white' : "green"), fontSize: "12px" }} />} label="Approved With Contingencies" /></Grid>
                    <Grid item > <Chip key={5} onClick={() => { filterByCreditStatus("unsubmitted") }} variant={useCreditStatusChipsVariant('unsubmitted')} sx={{ marginBottom: 1, marginRight: 1 }} color="primary" icon={<CancelIcon style={{ color: (creditStatus === 'unsubmitted' ? 'white' : "blue"), fontSize: "12px" }} />} label="Saved -Not Submitted" /></Grid>
                    <Grid item > <Chip key={6} onClick={() => { filterByCreditStatus("requires_additional_information") }} variant={useCreditStatusChipsVariant('requires_additional_information')} sx={{ marginBottom: 1, marginRight: 1, color: "#db6806" }} icon={<CheckCircleOutlineIcon style={{ color: creditStatus === 'requires_additional_information' ? 'white' : "#db6806", fontSize: "12px" }} />} label="Requires Additional Information" /></Grid>
                    <Grid item > <Chip key={7} onClick={() => { filterByCreditStatus("bike_change_request") }} variant={useCreditStatusChipsVariant('bike_change_request')} sx={{ marginBottom: 1, marginRight: 1 }} color="primary" icon={<ArticleIcon style={{ color: creditStatus === 'bike_change_request' ? 'white' : "blue", fontSize: "12px" }} />} label="Bike Change Requested" /></Grid>
                    <Grid item > <Chip key={8} onClick={() => { filterByCreditStatus("draft") }} variant={useCreditStatusChipsVariant('draft')} sx={{ marginBottom: 1, marginRight: 1 }} color="primary" icon={<ArticleIcon style={{ color: creditStatus === 'draft' ? 'white' : "blue", fontSize: "12px" }} />} label="Draft" /></Grid>
                    <Grid item > <Chip key={9} onClick={() => { filterByCreditStatus("declined") }} variant={useCreditStatusChipsVariant('declined')} sx={{ marginBottom: 1, marginRight: 1 }} color="error" icon={<ThumbDownAltIcon style={{ color: creditStatus === 'declined' ? 'white' : "red", fontSize: "12px" }} />} label="Declined" /></Grid>
                    <Grid item > <Chip key={10} onClick={() => { filterByCreditStatus("withdrawn") }} variant={useCreditStatusChipsVariant('withdrawn')} sx={{ marginBottom: 1, marginRight: 1 }} color="error" icon={<BackspaceIcon style={{ color: creditStatus === 'withdrawn' ? 'white' : "red", fontSize: "12px" }} />} label="Withdrawn" /></Grid>
                </Grid>
                <Grid container item md={2} sx={{ marginTop: "5px" }}></Grid>
                <Grid container item md={5} sx={{ marginTop: "5px" }}>
                    <Grid item md={12}> <MUITypography sx={{ fontSize: "15px", marginBottom: "8px !important", marginRight: "8px !important", fontWeight: "bold" }}>Document Status</MUITypography></Grid>
                    <Grid item ><Chip key={11} onClick={() => { filterByDocumentStatus("all") }} variant={documentStatusChipsVariant('all')} sx={{ marginBottom: 1, marginRight: 1 }} color="info" label="All" /></Grid>
                    <Grid item ><Chip key={12} onClick={() => { filterByDocumentStatus("funding_approved") }} variant={documentStatusChipsVariant('funding_approved')} sx={{ marginBottom: 1, marginRight: 1 }} color="success" icon={<CheckCircleOutlineIcon style={{ color: documentStatus === 'funding_approved' ? 'white' : "#06a377", fontSize: "12px" }} />} label="Funding Approved" /></Grid>
                    <Grid item ><Chip key={13} onClick={() => { filterByDocumentStatus("funding_delay") }} variant={documentStatusChipsVariant('funding_delay')} sx={{ marginBottom: 1, marginRight: 1 }} color="error" icon={<AssignmentLateIcon style={{ color: documentStatus === 'funding_delay' ? 'white' : "red", fontSize: "12px" }} />} label="Funding Delay" /></Grid>
                    <Grid item ><Chip key={14} onClick={() => { filterByDocumentStatus("funded") }} variant={documentStatusChipsVariant('funded')} sx={{ marginBottom: 1, marginRight: 1 }} color="info" icon={<CheckCircleOutlineIcon style={{ color: documentStatus === 'funded' ? 'white' : "blue", fontSize: "12px" }} />} label="Funded" /></Grid>
                    <Grid item ><Chip key={15} onClick={() => { filterByDocumentStatus("canceled") }} variant={documentStatusChipsVariant('canceled')} sx={{ marginBottom: 1, marginRight: 1 }} color="error" icon={<CancelIcon style={{ color: documentStatus === 'canceled' ? 'white' : "red", fontSize: "12px" }} />} label="Canceled" /></Grid>
                    <Grid item ><Chip key={16} onClick={() => { filterByDocumentStatus("lease_package_recieved") }} variant={documentStatusChipsVariant('lease_package_recieved')} sx={{ marginBottom: 1, marginRight: 1 }} color="primary" icon={<CheckCircleOutlineIcon style={{ color: documentStatus === 'lease_package_recieved' ? 'white' : "blue", fontSize: "12px" }} />} label="Lease Package Received" /></Grid>
                    <Grid item ><Chip key={17} onClick={() => { filterByDocumentStatus("documents_issued") }} variant={documentStatusChipsVariant('documents_issued')} sx={{ marginBottom: 1, marginRight: 1 }} color="primary" icon={<ArticleIcon style={{ color: documentStatus === 'documents_issued' ? 'white' : "blue", fontSize: "12px" }} />} label="Documents Issued" /></Grid>
                    <Grid item ><Chip key={18} onClick={() => { filterByDocumentStatus("documents_requested") }} variant={documentStatusChipsVariant('documents_requested')} sx={{ marginBottom: 1, marginRight: 1 }} color="primary" icon={<ArticleIcon style={{ color: documentStatus === 'documents_requested' ? 'white' : "blue", fontSize: "12px" }} />} label="Documents Requested" /></Grid>
                    <Grid item ><Chip key={19} onClick={() => { filterByDocumentStatus("no_documents") }} variant={documentStatusChipsVariant('no_documents')} sx={{ marginBottom: 1, marginRight: 1 }} color="error" icon={<HourglassEmptyIcon style={{ color: documentStatus === 'no_documents' ? 'white' : "red", fontSize: "12px" }} />} label="No Documents" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" sx={{  marginTop: 3 }} >
                <Grid container item md={6}>
                    <Search placeholder="Search" allowClear size="large" value={searchValue} onChange={onChange} className="leaseAppListSearch" onKeyUp={() => { if (searchValue.length > 2) { dispatch(updatePageState({ search: searchValue })) } }} onSearch={(searchVal) => { dispatch(updatePageState({ search: searchVal })) }} enterButton />
                </Grid>

                <Grid container item md={6} justifyContent="flex-end"  >
                    <Grid item  >
                        <Select
                            style={{ width: '220px' }}
                            onChange={handleSelectFilterParams}
                            dropdownStyle={selectDropdownStyle}
                            defaultValue="All"
                        >
                            <Option className="select-option" value={'custom'}>Custom</Option>
                            <Option className="select-option" value={lastMonth()}>Last Month</Option>
                            <Option className="select-option" value={currentMonth()}>Current Month</Option>
                            <Option className="select-option" value={lastQuarterly()}>Last Quarter</Option>
                            <Option className="select-option" value={currentYear()}>Current Year</Option>
                            <Option className="select-option" value={currentYear() - 1}>Previous Year</Option>
                            <Option className="select-option" value="All" >ALL</Option>
                        </Select>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="row"  >
                <Grid container item justifyContent="flex-end"  >
                    <Grid item  >
                        <FormControlLabel label="Include unsubmitted apps?"
                            control={
                                <Checkbox
                                    checked={pageState.isIncludeUnsubmitted}
                                    onChange={checkUnsubmittedApp}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            {
                isCustomFilter &&
                <>
                    <Grid container direction="row"  >

                        <Grid container item justifyContent="flex-end" spacing={2}  >
                            <Grid item sm={3} md={2} lg={2} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="YYYY-MM-DD"
                                        value={rangeStartDate}
                                        onChange={handleChangeDateStart}
                                        renderInput={(params) => <StyledTextField
                                            id="filled-input"
                                            {...params}
                                            size="small"
                                            label="Start Date"
                                            margin="normal"
                                            name="start_date" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sm={3} md={2} lg={2}  >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="YYYY-MM-DD"
                                        value={rangeEndDate}
                                        onChange={handleChangeDateEnd}
                                        minDate={rangeEndMinimumStartDate}
                                        renderInput={(params) => <StyledTextField
                                            id="filled-input"
                                            {...params}
                                            size="small"
                                            label="End Date"
                                            margin="normal"
                                            name="end_date" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}


const StyledTextField = styled(TextField)({
    fontSize: "10px !important",
    '& label.Mui-focused': {
        // color: '#001629',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            // borderColor: '#001629',
        },
        '&.Mui-focused fieldset': {
            // borderColor: '#001629',
        },
    },
    '& .MuiFormHelperText-root': {
        marginTop: "0px !important",
        marginBottom: "-18px !important"
    }
})

const StyledClearFilter = styled(Chip)({
    float: "right",
    marginRight: 12,
    marginTop: 10,
    fontWeight: "600 !important",
    '&  .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 600
    }
})
