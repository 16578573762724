import React, { useState, useEffect } from 'react'
import { Button, styled, alpha, Menu, MenuItem, MenuProps } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArticleIcon from '@mui/icons-material/Article';
import PreviewIcon from '@mui/icons-material/Preview';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import Tooltip from '@mui/material/Tooltip';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Link } from "react-router-dom";
import { auth } from "../../../utils";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />

))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    minWidth: 180,
    boxShadow: '0px 0px 2px 0px #d9d9d9',
    '& .MuiMenu-list': {
      'a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none'
      }
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        transition: "color 1s",
        position: 'relative',
        top: 3
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&:hover': {
        backgroundColor: "#cdc9c933",
        color: "#e93b1b",
        lineHeight: 1.5,
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationcolor: '#e93b1b',
        textDecorationThickness: "2px",
        fontWeight: "500"
      }
    },
  },

}));

const submitData = window.localStorage.getItem("can_submit_speedlease");
const cookieDealershipId =  auth.getDealershipId();

export default function ActionsButton(props: any) {
  const disableBtn = cookieDealershipId ? true : submitData === "false";
  const { params, removeCoApplicant, setUnlockArchiveDialog, setApplicationRow, setResubmitApplicationDialog } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activeRow, setActiveRow] = React.useState(null);
  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setActiveRow(params)
  };

  const [canSubmitBankInfo, setCanSubmitBankInfo] = useState<boolean>(false)
  useEffect(() => {
    if (params.documentStatus !== "No Documents") {
      setCanSubmitBankInfo(true)
    }
  })

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  let paymentCalcNode;

  if (params.actionPermission.canEditPaymentCalculator) {

    paymentCalcNode = (
      <Link className='MuiMenuItem-root' target="_blank"
        to={`/applications/${params.id}/calculator`}
      >
        <EditIcon sx={{ marginRight: 1 }} />
        Edit Payment Calculator
      </Link>
    );


  } else if (params.actionPermission.canChangeBikes) {
    paymentCalcNode = (
      <Link className='MuiMenuItem-root' target="_blank"
        to={`/applications/${params.id}/calculator`}
      >
        <CalculateIcon sx={{ marginRight: 1 }} />
        Open Payment Calculator
      </Link>
    );

  } else if (params.actionPermission.canOpenPaymentCalculator) {
    paymentCalcNode = paymentCalcNode = (
      <Link className='MuiMenuItem-root' target="_blank"
        to={`/applications/${params.id}/calculator`}
      >
        <PreviewIcon sx={{ marginRight: 1 }} />
        View Payment Calculator
      </Link>
    );
  }
  
  return (
    <>
      <Button
        id={params.id}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleDropdownClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
      >
        Action
      </Button>

      <StyledMenu
        id={params.id}
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropdownClose}
        style={{
          fontSize: 12,
        }}
        keepMounted
      >
        <MenuItem disabled= {cookieDealershipId ? false : submitData === "false" ? true : false} onClick={() => { setAnchorEl(null) }}>{paymentCalcNode}</MenuItem>

        {params.actionPermission.canEditCreditApplication &&(
          <MenuItem disabled= {disableBtn} onClick={() => { setAnchorEl(null) }}>
            <Link className='MuiMenuItem-root' target="_blank"
              to={`/applications/${params.id}/bike`}
            >
              <ArticleIcon sx={{ marginRight: 1 }} />
              Edit Credit Application
            </Link>
          </MenuItem>
        )}

        {params.actionPermission.canOpenCreditApplication && (
          <MenuItem disabled= {cookieDealershipId ? false : submitData === "false" ? true : false} onClick={() => { setAnchorEl(null) }}>
            <Link className='MuiMenuItem-root' target="_blank"
              to={`/applications/${params.id}/summary`}
            >
              <PreviewIcon sx={{ marginRight: 1 }} />
              View Credit Application
            </Link>
          </MenuItem>
        )}

        {/* {params.params.actionPermission.canSwapApplicants && (
            <MenuItem>
              <SwapHorizIcon sx={{ marginRight: 1 }} />
              <a href='#' className='MuiMenuItem-root' target="_blank">Swap Applicants</a>
            </MenuItem>
          )} */}

        {params.actionPermission.canAddCoapplicant && (
          <MenuItem disabled= {disableBtn} onClick={() => { setAnchorEl(null) }}>
            <Link className='MuiMenuItem-root' to={`/applications/${params.id}/co-applicant?add_co_applicant=true`} target="_blank">
              <AddIcon sx={{ marginRight: 1 }} />
              Add Co-applicant
            </Link>
          </MenuItem>
        )}

        {!params.actionPermission.expired && (
          <MenuItem disabled= {cookieDealershipId ? false : submitData === "false" ? true : false} onClick={() => { setAnchorEl(null) }}>
            <Link className='MuiMenuItem-root' to={`/applications/${params.id}/attachments`} target="_blank">
              <AttachmentIcon sx={{ marginRight: 1 }} />
              Add Attachment
            </Link>
          </MenuItem>
        )}

        {params.actionPermission.canRemoveCoapplicant && (

          <MenuItem
            className='MuiMenuItem-root'
            disabled= {disableBtn}
            onClick={() => { removeCoApplicant(params.id); setAnchorEl(null) }}>
            <ArchiveIcon sx={{ marginRight: 1 }} />
            Remove Co-applicant
          </MenuItem>
        )}
        {canSubmitBankInfo && (
          <MenuItem disabled= {disableBtn} onClick={() => { setAnchorEl(null) }}>
            <Link className='MuiMenuItem-root' to={`/applications/${params.id}/banking-information`} target="_blank">
              <PublishIcon sx={{ marginRight: 1 }} />
              Submit Bank Information
            </Link>
          </MenuItem>
        )}

        {params.actionPermission.canArchive && (
            <MenuItem
              className='MuiMenuItem-root'
              disabled= {disableBtn}
              onClick={() => {
                setApplicationRow(params)
                setUnlockArchiveDialog(true)
                setAnchorEl(null)
              }}>
              <ArchiveIcon sx={{ marginRight: 1 }} />
              Archive Application
            </MenuItem>
        )}
        {params.actionPermission.canRequestLeaseDocuments && (
            <MenuItem className='MuiMenuItem-root' onClick={() => { setAnchorEl(null) }}>
              <Link className='MuiMenuItem-root' style={{ color: 'red' }} to={`/applications/${params.id}/request-lease-document`} target="_blank">
                <DescriptionIcon sx={{ marginRight: 1 }} />
                Request Lease Documents
              </Link>
            </MenuItem>
        )}
        {params.actionPermission?.canResubmitApp && (
          <MenuItem
            className='MuiMenuItem-root'
            disabled= {disableBtn}
            style={{color: '#0000FF'}}
            onClick={() => {
              setApplicationRow(params);
              setResubmitApplicationDialog(true);
              setAnchorEl(null);
            }}>
            <PublishIcon sx={{ marginRight: 1 }} />
            Resubmit Application
          </MenuItem>
        )}
      </StyledMenu>
    </>
  )
}
