import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import {
  SignIn,
  SignOut,
} from './components/pages';
import SendEmail from './components/pages/signin/SendEmail';
import {
  HomeRenderer,
  NewApplicationRender,
  SavedCalculatorsRenderer,
  ArchivedRenderer,
  ReferenceRenderer
} from './components/pages/dealer';

import RequestLeaseDocuments from './components/pages/dealer/Application/RequestLeaseDocuments';

import {
  BikeInformationRenderer,
  CalculatorRenderer,
  ApplicantRenderer,
  CoApplicantRenderer,
  SummaryRenderer,
  BankingInformationRenderer,
  AttachmentRenderer,
  RequestLeaseDocumentsRenderer,
} from './components/pages/dealer/Application';

import { UserDataContext } from "./contexts/UserDataContext";
import { auth, network } from './utils';
import './App.css';
import './components/layouts/styles/MainLayout.css'
import Promotion from './components/pages/dealer/Promotion/PromotionRenderer'
import Training from './components/pages/dealer/Training/TrainingRenderer'
import Calculator from "./components/pages/dealer/Application/Calculator";
import GeorgiaTaxGuides from './components/pages/dealer/GeorgiaTaxGuides';
import BikeInformation from "./components/pages/dealer/Application/BikeInformation";
import ResetPassword from './components/pages/signin/ResetPassword';
import VideoRenderer from "./components/pages/dealer/Training/VideoRenderer";

// Configure IdleTimer timeout. Default: 60
// const ENV_TIMEOUT_MINUTE : string | undefined = process.env.REACT_APP_IDLE_TIMER_TIMEOUT_MINUTE
let TIMEOUT_MINUTE = 60
if (process.env.REACT_APP_IDLE_TIMER_TIMEOUT_MINUTE !== undefined) {
  TIMEOUT_MINUTE = parseInt(process.env.REACT_APP_IDLE_TIMER_TIMEOUT_MINUTE)
}

function App() {

  const [authenticated] = useState<boolean>(auth.isAuth());
  const validateToken = async () => {
    network.POST(`/api/v1/validate-token`, {})
  }

  let search = new URLSearchParams(window.location.search);

  const handleOnIdle = (event: any) => {
    alert('Your session or idle time is expired. Please login again.')
    auth.logout()
    window.location.href = '/login'
  }

  useEffect(() => {
    if (authenticated) { validateToken() }
  }, []);
 
  return (
    <>
      <BrowserRouter>
        {
          !authenticated &&
          <Route>
            {/* <Redirect to="/login" /> */}
            <Route path="/login" exact component={SignIn} />
            <Route path="/" exact component={SignIn} />
            <Route path="/forgot-password" exact component={SendEmail} />
            <Route path="/change-password/:token" exact component={ResetPassword} />
          </Route>
        }

        {
          !authenticated && (window.location.pathname.split('/')[1] !== "change-password") &&
          <Redirect to="/login" />
        }

        {
          authenticated && (window.location.pathname === '/login' && search?.get("dealership_id")) &&
          <Route path="/login" exact component={SignIn} />
        }

        {
          authenticated &&
          // https://github.com/supremetechnopriest/react-idle-timer#documentation
          <IdleTimer
            events={['keydown', 'wheel', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'visibilitychange']}
            timeout={1000 * 60 * TIMEOUT_MINUTE}
            onIdle={handleOnIdle}
            debounce={500}
            crossTab={{
              emitOnAllTabs: true
            }}
          />
        }

        {
          authenticated &&
          <UserDataContext.Provider value={{ fullName: auth.getFullName(), dealershipName: auth.getDealershipName(), dealershipState: auth.getDealershipState(), dealershipZipcode: auth.getDealershipZipcode() }}>
            <Switch>
              <Route exact path="/login">
                <Redirect to="/home" />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path="/logout" exact component={SignOut} />
              <Route path="/home" exact component={HomeRenderer} />
              <Route path="/georgia-tax-guides" exact component={GeorgiaTaxGuides} />
              <Route path="/archived-applications" exact component={ArchivedRenderer} />
              <Route path="/application" exact component={NewApplicationRender} />
              <Route path="/applications/:leaseApplicationId/bike" exact component={BikeInformationRenderer} />
              <Route path="/applications/bike" exact component={BikeInformation} />
              <Route path="/applications/:leaseApplicationId/calculator" exact component={CalculatorRenderer} />
              <Route path="/calculator" exact component={() => {
                return <Calculator calculateWithoutApp={true} />
              }} />
              <Route path="/applications/:leaseApplicationId/request-lease-document" exact component={RequestLeaseDocumentsRenderer} />
              <Route path="/applications/:leaseApplicationId/applicant" exact component={ApplicantRenderer} />
              <Route path="/applications/:leaseApplicationId/co-applicant" exact component={CoApplicantRenderer} />
              <Route path="/applications/:leaseApplicationId/summary" exact component={SummaryRenderer} />
              <Route path="/promotions" exact component={Promotion} />
              <Route path="/training" exact component={Training} />
              <Route path="/video" exact component={VideoRenderer} />

              <Route path="/saved-calculators" exact component={SavedCalculatorsRenderer} />
              <Route path="/applications/:leaseApplicationId/banking-information" exact component={BankingInformationRenderer} />
              <Route path="/applications/:leaseApplicationId/request-lease-document" exact component={RequestLeaseDocuments} />
              <Route path="/applications/:leaseApplicationId/attachments" exact component={AttachmentRenderer} />
              <Route path="/applications/:leaseApplicationId/references" exact component={ReferenceRenderer} />
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </UserDataContext.Provider>
        }

      </BrowserRouter>
    </>
  );
}

export default App;
