import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import { Paper } from '@mui/material'
import MUITypography from '@mui/material/Typography';
import { Typography, styled } from '@mui/material';
import '../../../../../layouts/styles/Summary.css'


export default function SummaryBike(props: any) {
    const { data } = props

    return (
        <>
            <Paper elevation={12} sx={{ background: "#001529" }}>
                <Typography sx={{ color: "#fff", float: "left", padding: 1, fontWeight: "bold", fontSize: "14px" }} >Bike Information</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table" >
                        <TableBody>
                            <StyledTableRow className="bike-table">
                                <StyledtableCell1 >VIN :</StyledtableCell1>
                                <StyledtableCell2 >{data?.leaseCalculator?.assetVin ? data?.leaseCalculator?.assetVin : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 >New/Used :</StyledtableCell1>
                                <StyledtableCell2 >{data?.leaseCalculator?.newUsed ? data?.leaseCalculator?.newUsed : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Make :</StyledtableCell1>
                                <StyledtableCell2 >{data?.leaseCalculator?.assetMake ? data?.leaseCalculator?.assetMake : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Model : </StyledtableCell1>
                                <StyledtableCell2 >{data?.leaseCalculator?.assetModel ? data?.leaseCalculator?.assetModel : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Year : </StyledtableCell1>
                                <StyledtableCell2 >{data?.leaseCalculator?.assetYear ? data?.leaseCalculator?.assetYear : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Mileage Range : </StyledtableCell1>
                                <StyledtableCell2 >{data?.leaseCalculator?.mileageTier ? data?.leaseCalculator?.mileageTier : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

const StyledtableCell1 = styled(TableCell)({
    fontWeight: "bold", fontSize: "14px"
})
const StyledTableRow = styled(TableRow)({
    "& td": { border: 0 }
})


const StyledtableCell2 = styled(TableCell)({
    fontSize: "14px"
})