import { configureStore } from "@reduxjs/toolkit";
import leaseApplicationsReducer from "./reducers/LeaseApplicationsReducer";

const Store = configureStore({
    reducer: {
        applications: leaseApplicationsReducer,
    },
    middleware:  getDefaultMiddleware =>
      getDefaultMiddleware({
          serializableCheck: false,
      }),
});

export default Store