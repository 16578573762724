
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import { Paper } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import capitalizeFirstLetter from './DataFormat';
import { Link } from 'react-router-dom';
import { Typography, styled } from '@mui/material'
export default function SummaryEmployeeCard(props: any) {

    const { leaseApplicationId, data, applicantFlag, isSubmitted, isAddingCoApplicant } = props
    let lesseeOrCoLessee;
    let routeName = ""
    if (applicantFlag == 1) {
        lesseeOrCoLessee = data?.lessee
        routeName = "applicant";
    } else {
        lesseeOrCoLessee = data?.colessee
        routeName = "co-applicant";
    }

    const CurrencyFormat = require('react-currency-format');
    let timeAtEmployerYears = lesseeOrCoLessee?.timeAtEmployerYears || 0
    let timeAtEmployerMonths = lesseeOrCoLessee?.timeAtEmployerMonths
    let employerMonthsSummary = timeAtEmployerMonths ? (timeAtEmployerMonths > 1 ? (timeAtEmployerMonths + ' months') : (timeAtEmployerMonths + 'month')) : ''
    let employmentDuration = timeAtEmployerYears + ' ' + (timeAtEmployerYears > 1 ? 'years' : 'year') + ' ' + employerMonthsSummary
    const addingCoApplicant = () => {
        return isAddingCoApplicant && routeName === 'co-applicant';
    }
    let redirectRoute = addingCoApplicant() ? `/applications/${leaseApplicationId}/${routeName}?add_co_applicant=true` : `/applications/${leaseApplicationId}/${routeName}`

    return (
        <>
            <Paper elevation={12} sx={{ background: "#5d6b78" }}>
                <Typography sx={{ color: "#fff", float: "left", padding: 1, fontWeight: "bold", fontSize: "14px" }} >Employment Details</Typography>
                <Typography sx={{ color: "#fff", float: "right", padding: 1 }} >
                    <Link to={redirectRoute} style={{display: addingCoApplicant() ? '' : 'none'}}>
                        {!isSubmitted && (<EditOutlinedIcon sx={{ color: "#fff" }} />)}
                    </Link>
                </Typography>
                <TableContainer component={Paper} sx={{ height: "546px" }}>
                    <Table aria-label="customized table" sx={{ height: "546px" }}>
                        <TableBody>
                            <StyledTableRow >
                                <StyledtableCell1 >Employer Name:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.employerName ? capitalizeFirstLetter(lesseeOrCoLessee?.employerName) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Phone Number:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.employerPhoneNumber ? lesseeOrCoLessee?.employerPhoneNumber : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > City: </StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.employmentAddress?.city ? capitalizeFirstLetter(lesseeOrCoLessee?.employmentAddress?.city) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > State:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.employmentAddress?.state ? capitalizeFirstLetter(lesseeOrCoLessee?.employmentAddress?.state) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 >Employment Status:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.employmentStatus ? lesseeOrCoLessee?.employmentStatus : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Job Title:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.jobTitle ? capitalizeFirstLetter(lesseeOrCoLessee?.jobTitle) : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Months/Years Employed:</StyledtableCell1>
                                <StyledtableCell2 >{employmentDuration}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow >
                                <StyledtableCell1 > Gross Monthly Income:</StyledtableCell1>
                                <StyledtableCell2 >{lesseeOrCoLessee?.grossMonthlyIncome ? <CurrencyFormat value={lesseeOrCoLessee?.grossMonthlyIncome} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 'N/A'}</StyledtableCell2>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

const StyledtableCell1 = styled(TableCell)({
    fontWeight: "bold", fontSize: "14px"
})
const StyledTableRow = styled(TableRow)({
    "& td": { border: 0 }
})


const StyledtableCell2 = styled(TableCell)({
    fontSize: "14px"
})

const StyledTypography1 = styled(Typography)({
    color: "#fff",
    float: "left",
    padding: 1,
    fontWeight: "bold",
    fontSize: "14px"
})
const StyledTypography2 = styled(Typography)({
    color: "#fff",
    float: "right",
    padding: 1

})
