import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import Typography from '@mui/material/Typography';
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Grid, Button, Box, Paper, Divider, FormControl, Autocomplete } from "@mui/material";
import { Table, TableContainer, TableRow, TableCell } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import { logger, network } from '../../../../utils';
import ApplicationSteps from './ApplicationSteps';
import '../styles/BikeInformation.css';
import { styled } from '@mui/system';
import { useSelector, useDispatch } from "react-redux";
import { setApplicationDetails, updateBikeInformation } from "../../../../reducers/LeaseApplicationsReducer";

interface Lessee {
  ssn?: string | undefined
}

interface LeaseCalculator {
  id?: string | number | undefined
}

interface RootLeaseCalculator {
  leaseCalculator?: LeaseCalculator
  assetMake?: string
  assetModel?: string
  assetYear?: number
  mileageTier?: string
  newUsed?: string
  assetVin?: string
}

interface Props {
  data?: {
    id: string | number
    lessee: Lessee
    leaseCalculator: RootLeaseCalculator
  }
  dataCheck?: any
}
interface OptionProps {
  value?: string | number
  label?: string
}
interface SnackbarMessageResponse {
  type?: string
  message?: string
}

// const canSubmitData = window.localStorage.getItem("can_submit_speedlease");

export const BikeInformation: React.FC<Props> = (props: any) => {
  const paramsLeaseApplicationId: string = props.match && props.match.params && `${props.match.params.leaseApplicationId}`
  const dataFromStore = useSelector((state: any) => state?.applications?.applicationDetails);
  const data = paramsLeaseApplicationId === 'undefined' ? {} : dataFromStore
  const dataCheck = data?.leaseCalculator?.assetMake
  let leaseApplicationId: string | number | undefined = data?.id
  let leaseCalculatorId: string | number | undefined = data?.leaseCalculator?.leaseCalculator?.id
  const dispatch = useDispatch()

  const [canSubmit, setCanSubmit] = useState<string | undefined>(window.localStorage.getItem("can_submit_speedlease") || '')

  const [makesOptions, setMakesOptions] = useState<OptionProps[]>([])
  const [yearsOptions, setYearsOptions] = useState<OptionProps[]>([])
  const [modelsOptions, setModelsOptions] = useState<OptionProps[]>([])
  const [mileageRangeOptions, setMileageRangeOptions] = useState<OptionProps[]>([])
  const [showViaVIN, setShowViaVIN] = useState<boolean>(false)

  const [vinMake, setVinMake] = useState<string | undefined>('')
  const [vinYear, setVinYear] = useState<any>('')
  const [vinModel, setVinModel] = useState<string | undefined>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [disabledVIN, setDisbaledVIN] = useState<boolean>(false)
  const [vinError, setVinError] = useState(false);
  const vinPattern = /^[A-Za-z0-9]{17}$/;

  const [checkIfSaved, setCheckIfSaved] = useState(false)
  const [vinMessage, setVinMessage] = useState<string>()
  const [controlRules, setControlRules] = useState<string>("25%")
  const [disableBtn, setDisableBtn] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<any>({
    asset_make: {
      value: '',
      error: false,
      errorMessage: 'Make is required!'
    },
    asset_model: {
      value: '',
      error: false,
      errorMessage: 'Model is required!'
    },
    asset_year: {
      value: '',
      error: false,
      errorMessage: 'Year is required!'
    },
    asset_new_used: {
      value: '',
      error: false,
      errorMessage: 'New/Used is required!'
    },
    mileage_range: {
      value: '',
      error: false,
      errorMessage: 'Mileage Range is required!'
    },
  })

  useEffect(() => {
    if (canSubmit === "false") {
      message.warning('This dealership do not have permission to submit application.')
      setDisableBtn(true);
    }
    if (dataCheck !== null) {
      let newFormValues = { ...formValues }
      newFormValues.asset_new_used.value = data?.leaseCalculator?.newUsed
      newFormValues.asset_make.value = data?.leaseCalculator?.assetMake
      newFormValues.asset_year.value = data?.leaseCalculator?.assetYear
      newFormValues.asset_model.value = data?.leaseCalculator?.assetModel
      newFormValues.mileage_range.value = data?.leaseCalculator?.mileageTier
      setFormValues(newFormValues)
      setCheckIfSaved(true)
      setVinMessage(data?.leaseCalculator?.assetVin)
    }
    getMakes()
    if(data?.leaseCalculator !== undefined){
      getYears(data?.leaseCalculator?.assetMake)
      getModels(data?.leaseCalculator?.assetMake, data?.leaseCalculator?.assetYear)
    }
  }, [canSubmit])

  const handleVin = async (e: any) => {
    setVinError(false);
    const vinPattern = /^[A-HJ-NPR-Z0-9]{17}$/;
    if (vinPattern.test(e.target.value)) {
      setDisbaledVIN(true);
      let vin = e.target.value
      setLoading(true)
      try {
        await network
          .GET(`/api/v1/vin_verification/verify-vin?vin=${vin}`)
          .then((res) => {
            let newFormValues = { ...formValues }
            newFormValues.asset_new_used.value = today.getFullYear() - res.data.vehicleInfo.year < 3 ? 'New' : 'Used'

            newFormValues.asset_make.value = res.data.vehicleInfo.make
            setVinMake(res.data.vehicleInfo.make)

            newFormValues.asset_year.value = res.data.vehicleInfo.year
            setVinYear(res.data.vehicleInfo.year)

            setVinModel(res.data.vehicleInfo.model)
            newFormValues.asset_model.value = res.data.vehicleInfo.model

            newFormValues.mileage_range.value = null
            setShowViaVIN(true)
            setLoading(false)
            setVinMessage(e.target.value)
            setControlRules("85%")
            setFormValues(newFormValues)
            message.success('VIN found successfully')
            setDisbaledVIN(false)
          })
          .catch((e) => {
            if (e && e.response.status === 404) {
              setVinMessage("VIN not found")
              message.error('VIN not found')
            } else {
              message.error('Error verifying VIN')
            }
            setDisbaledVIN(false)
            setLoading(false)
          })
      } catch (e) {
        logger.error('Error verifying vin', e)
        setDisbaledVIN(false)
        setLoading(false)
      }
    } else {
      setVinError(true);
    }
  }

  const getMakes = async () => {
    try {
      let result = await network.GET(`/api/v1/bike-information/makes-name-options`)
      if(result?.data.dealership_can_submit === true) {
        window.localStorage.setItem('can_submit_speedlease', 'true');
        setCanSubmit('true')
      } else {
        window.localStorage.setItem('can_submit_speedlease', 'false');
        setCanSubmit('false')
      }
      setMakesOptions(formatOptions(result.data.makes || []))
      setMileageRangeOptions(formatOptions(result.data.mileage_range || []))
    } catch (e) {
      logger.error('Request Error', e)
    }
  }

  const getYears = async (make: string) => {
    try {
      let result = await network.GET(`/api/v1/bike-information/years-options?make=${make}`)
      setYearsOptions(formatOptions(result.data.years || []))
    } catch (e) {
      logger.error('Request Error', e)
    }
  }

  const getModels = async (make: string, year: string | number) => {
    try {
      let result = await network.GET(`/api/v1/bike-information/models-options?make=${make}&year=${year}`)
      setModelsOptions(formatOptions((result.data.models || []), 'collection'))
    } catch (e) {
      logger.error('Request Error', e)
    }
  }

  const handleAssetChange = (e: any) => {
    let newFormValues = { ...formValues }
    newFormValues.asset_new_used.value = e?.label
    setFormValues(newFormValues)
  }

  const handleMakes = (e: any) => {
    let newFormValues = { ...formValues }
    newFormValues.asset_make.value = e?.value
    newFormValues.asset_year.value = ''
    newFormValues.asset_model.value = ''
    setFormValues(newFormValues)
    getYears(e?.value)
  }

  const handleYear = (e: any) => {
    let newFormValues = { ...formValues }
    newFormValues.asset_year.value = e?.value
    newFormValues.asset_model.value = ''
    setFormValues(newFormValues)
    let year = e?.value
    getModels(newFormValues.asset_make.value, year)
  }

  const handleModel = (e: any) => {
    let newFormValues = { ...formValues }
    newFormValues.asset_model.value = e?.value
    setFormValues(newFormValues)
  }

  const handleMileageChange = (e: any) => {
    let newFormValues = { ...formValues }
    newFormValues.mileage_range.value = e?.value
    setFormValues(newFormValues)
  }

  const formatOptions = (options: Object[], type?: string) => {
    let newOptions: Array<object> = []
    if (type === 'collection') {
      options.map((value: any) => {
        newOptions.push({ value: value[1], label: value[0] })
      })
    } else {
      options.map((value: any) => {
        newOptions.push({ value: value, label: value })
      })
    }
    return newOptions
  }

  const submitApplication = async (values: any) => {
    try {
      let data, id;
      if (paramsLeaseApplicationId === 'undefined') {
        data = await network.POST(`/api/v1/dealers/create`, values)
        id = data?.data?.data?.leaseApplication?.id
      }
      else {
        data = await network.PUT(`/api/v1/dealers/update-details?id=${leaseApplicationId}`, values)
        id = leaseApplicationId
      }
      dispatch(setApplicationDetails(data?.data?.data?.leaseApplication))
      let path = `/applications/${id}/applicant`
      history.push(path);
      message.success('Save Successfully')
    } catch (e:any) {
      if(e?.response?.status === 409 && e?.response?.data?.message === 'can submit disabled') {
        window.localStorage.setItem('can_submit_speedlease', 'false');
        setCanSubmit('false')
        message.error('This dealership do not have permission to submit application.')
        setDisableBtn(true)
      } else {
        message.error('Error saving details')
        setDisableBtn(false)
      }
      logger.error('Request Error', e)
    }
  }

  const [btnAttribute, setBtnAttribute] = useState(true)
  const history = useHistory();

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    let formError = false;
    let requiredFields = ['asset_make', 'asset_new_used', 'asset_year', 'asset_model', 'mileage_range']

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;
      if (currentValue === '' || currentValue === null || currentValue === undefined) {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            value: '',
            error: true
          }
        }
        if (requiredFields.includes(currentField)) {
          formError = true
        }
      }
    }
    setFormValues(newFormValues)

    if (!formError) {
      const myValues = {
        leaseCalculatorAttributes: {
          newUsed: formValues.asset_new_used.value,
          assetMake: formValues.asset_make.value,
          assetYear: formValues.asset_year.value,
          assetModel: formValues.asset_model.value,
          mileageTier: formValues.mileage_range.value,
          assetVin: vinMessage,
          asset_source: 'JD_POWER',
        }
      }
      setDisableBtn(true)
      submitApplication(myValues)
    }
  }

  let today = new Date()
  let year = today.getFullYear()

  const assetOption = [
    { label: 'New', id: 1 },
    { label: 'Used', id: 2 }
  ]

  const vinStyle = {
    position: 'absolute',
    marginTop: "15px"
  }

  const vinControl = (theme: any) => ({
    margin: "1px",
    width: "120%",
    [theme.breakpoints.down('md')]: {
      width: "70%",
      position: 'absolute',
      top: controlRules
    },
  })

  const vinDetails = (theme: any) => ({
    marginTop: "38%",
    marginLeft: "3%",
    [theme.breakpoints.down('md')]: {
      marginTop: "80%"
    },
  })


  return (
    <div>
      <ApplicationSteps stepType={`bike`} isSaved={checkIfSaved} leaseApplicationId={`${leaseApplicationId}`} leaseCalculatorId={`${leaseCalculatorId}`} save={null} attribute={btnAttribute} isSubmitted={false} />
      <form onSubmit={handleSubmit}>
        <Box className="titleBox cca-center-text">
          <Typography variant="h4" sx={{ fontSize: "3rem", fontWeight: 400 }}>
            Bike Information
          </Typography>
          {
           canSubmit === "false" &&  
           <Typography variant="h6" sx={{ fontWeight: 400 }} style={{color: "#e93b1b"}}>
            This dealership do not have permission to submit application.
          </Typography>
          }
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs={10}>
              <Paper
                sx={{
                  p: 2,
                  paddingTop: 6,
                  paddingBottom: 8,
                }}
              >
                <Grid container direction="row" justifyContent="space-around" spacing={2} >
                  <Grid item sm={4}>
                    <TableContainer>
                      <Table>
                        <TableRow>
                          <TableCell sx={vinStyle}>
                            <FormControl sx={vinControl} id="form-position" className="radius-control">
                              <StyledTextField id="filled-input" label="VIN" variant="outlined" onChange={(e) => handleVin(e)} size="medium" disabled={disabledVIN} error={vinError}
                                helperText={vinError ? <span style={{ fontSize: '12px' }}>Invalid VIN. VIN length should be 17 characters.</span> : ''} />
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </TableContainer>

                    {showViaVIN && (
                      <TableContainer sx={vinDetails}>
                        <Table>
                          <TableRow>
                            <TableCell style={{ border: "none", width: "auto" }}><b>New/Used</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}><b>:</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}>
                              {showViaVIN && <span>{year - vinYear < 3 ? 'New' : 'Used'} </span>}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: "none", width: "auto" }}><b>Make</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}><b>:</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}>
                              {showViaVIN && <span>{vinMake} </span>}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            < TableCell style={{ border: "none", width: "auto" }}><b>Year</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}><b>:</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}>
                              {showViaVIN && <span>{vinYear}</span>}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: "none", width: "auto" }}><b>Model</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}><b>:</b></TableCell>
                            <TableCell style={{ border: "none", width: "auto" }}>
                              {showViaVIN && <span>{vinModel}</span>}
                            </TableCell>
                          </TableRow>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>

                  <Divider orientation="vertical" flexItem style={{ color: "#e82512", fontWeight: "bold", fontSize: "1.2rem", height: "60vh" }} sx={{ "&::before, &::after": { borderColor: "#e82512", fontWeight: "bold" } }}>
                    OR
                  </Divider>

                  <Grid item sm={4} >
                    <TableContainer className="bike-table-container" >
                      <Table>

                        <TableRow>
                          <TableCell style={{ border: "none", width: "auto" }}>
                            <FormControl sx={{ width: "100%", height: "6vh" }} error={formValues.asset_new_used.error}>
                              <Autocomplete
                                options={assetOption}
                                value={formValues.asset_new_used.value}
                                onChange={(event, value: any) => { handleAssetChange(value) }}
                                className="autocomplete"
                                renderInput={(params) =>
                                  <StyledTextField {...params} label="New/Used" variant="outlined" error={formValues.asset_new_used.error} />
                                }
                              />
                              {formValues.asset_new_used.error && (<StyledFormHelperText>{formValues.asset_new_used.errorMessage}</StyledFormHelperText>)}
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "none", width: "auto" }}>
                            <FormControl sx={{ width: "100%", height: "6vh" }} error={formValues.asset_make.error}>
                              <Autocomplete
                                options={makesOptions}
                                value={formValues.asset_make.value}
                                onChange={(event, value: any) => { handleMakes(value) }}
                                className="autocomplete"
                                renderInput={(params) =>
                                  <StyledTextField {...params} label="Make" variant="outlined" error={formValues.asset_make.error} />
                                }
                              />
                              {formValues.asset_make.error && (<StyledFormHelperText>{formValues.asset_make.errorMessage}</StyledFormHelperText>)}
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "none", width: "auto" }}>
                            <FormControl sx={{ width: "100%", height: "6vh" }} error={formValues.asset_year.error}>
                              <Autocomplete
                                options={yearsOptions}
                                value={formValues.asset_year.value}
                                onChange={(event, value: any) => { handleYear(value) }}
                                className="autocomplete"
                                renderInput={(params) =>
                                  <StyledTextField {...params} label="Year" variant="outlined" error={formValues.asset_year.error} />
                                }
                              />
                              {formValues.asset_year.error && (<StyledFormHelperText>{formValues.asset_year.errorMessage}</StyledFormHelperText>)}
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "none", width: "auto" }}>
                            <FormControl sx={{ width: "100%", height: "6vh" }} error={formValues.asset_model.error}>
                              <Autocomplete
                                options={modelsOptions}
                                value={formValues.asset_model.value}
                                onChange={(event, value: any) => { handleModel(value) }}
                                className="autocomplete"
                                renderInput={(params) =>
                                  <StyledTextField {...params} label="Model" variant="outlined" error={formValues.asset_model.error} />
                                }
                              />
                              {formValues.asset_model.error && (<StyledFormHelperText>{formValues.asset_model.errorMessage}</StyledFormHelperText>)}
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "none", width: "auto" }}>
                            <FormControl sx={{ width: "100%", height: "6vh" }} error={formValues.mileage_range.error}>
                              <Autocomplete
                                disablePortal
                                options={mileageRangeOptions}
                                value={formValues.mileage_range.value}
                                onChange={(event, value: any) => { handleMileageChange(value) }}
                                className="autocomplete"
                                renderInput={(params) =>
                                  <StyledTextField {...params} label="Mileage Range" variant="outlined" error={formValues.mileage_range.error} />
                                }
                              />
                              {formValues.mileage_range.error && (<StyledFormHelperText>{formValues.mileage_range.errorMessage}</StyledFormHelperText>)}
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        {vinMessage &&
                          <TableRow>
                            <TableCell>   <p> <b>VIN Number:</b>  {vinMessage} </p></TableCell>
                          </TableRow>
                        }
                      </Table>
                    </TableContainer>

                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" spacing={2} >
                  <Button variant="contained" style={{ backgroundColor: "#e93b1b" }} type="submit" size="large" disabled={disableBtn}>
                    Go to Applicant Page
                  </Button>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Box>
      </form>
    </div>
  )
}
export default BikeInformation


const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#001629',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#001629',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#001629',
    },
  },
})

const StyledFormHelperText = styled(FormHelperText)({
  color: "#d32f2f",
  marginTop: "-9px"
})